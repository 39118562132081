import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

const Selects = ({ data, variant, id, label, onChange, error, ...props }) => {
  const isError = error ? error : label;

  const handleChange = (e) => {
    onChange(id, e.target.value);
  };

  return (
    <>
      <FormControl fullWidth variant={variant}>
        <InputLabel>{isError}</InputLabel>
        <Select error={error} {...props} onChange={handleChange}>
          {data.map((c, index) => (
            <MenuItem key={index} value={c?.id || c}>
              {c?.name || c}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

Selects.propTypes = {
  variant: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  id: PropTypes.string,
  data: PropTypes.array,
  disabled: PropTypes.bool,
  // onChange: PropTypes.func.isRequired,
};

Selects.defaultProps = {
  variant: "standard",
  label: "",
  id: "",
  error: "",
  data: [],
  disabled: false,
};

export default Selects;

export const SelectsAgent = ({ data, variant, id, label, onChange, error, ...props }) => {
  const isError = error ? error : label;

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <>
      <FormControl fullWidth variant={variant}>
        <InputLabel>{isError}</InputLabel>
        <Select 
          error={error} 
          {...props} onChange={handleChange}
        >
          {data.map((c, index) => (
            <MenuItem key={index} value={c?.id}>
              {c?.email}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

SelectsAgent.propTypes = {
  variant: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  id: PropTypes.string,
  data: PropTypes.array,
  disabled: PropTypes.bool,
  // onChange: PropTypes.func.isRequired,
};

SelectsAgent.defaultProps = {
  variant: "standard",
  label: "",
  id: "",
  error: "",
  data: [],
  disabled: false,
};
