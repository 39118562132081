import { useTheme } from "@emotion/react";
import { AddCircleOutline } from "@mui/icons-material";
import { Box, Button, Grid, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import * as React from "react";

import { useNavigate } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import { from, map, take } from "rxjs";
import { useNotification } from "../../../Contexts/GlobalNotificationContext";
import { useLoading } from "../../../Contexts/GlobalLoadingContext";
import { useStyles } from "../../../Theme/Style";
import { PERMISSIONS } from "../../../constants/data/rolesPermissions.constants";
import { checkPermission } from "../../../helpers/helpers";
import { SalesOrderService } from "../../../services/api/sales-order/sales-order.service";
import { Breadcrumbs } from "../../atoms/Breadcrumbs/Breadcrumbs";
import TabsComponent from "../../atoms/CustomTabs/CustomTabs";
import { TabPanel } from "../../atoms/TabPanel/TabPanel";
import { GeneralPageLayout } from "../../molecules/PageLayout/GeneralPageLayout";
import { SalesOrdersTableWithFilter } from "../../molecules/SalesOrders/SalesOrdersTableWithFilters";

const salesOrderService = new SalesOrderService();

const getStatusCode = (status) => {
  switch (status) {
    case 0:
      return "all";
    case 1:
      return "pending";
    case 2:
      return "approved";
    case 3:
      return "paid";
    default:
      return "all";
  }
};

const Content = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  textAlign: "left",
  padding: "29px 37px",
  color: theme.palette.text.secondary,
}));

const SalesOrdersPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();

  const showCreateButton =
    checkPermission(PERMISSIONS.SALES_ORDERS) ||
    checkPermission(PERMISSIONS.SALES_AGENT_SALES_ORDERS);
  const [status, setStatus] = React.useState("all");
  const [value, setValue] = React.useState(0);
  const [searchCustomer, setSearchCustomer] = React.useState("");
  const [salesOrders, setSalesOrders] = React.useState([]);
  const [paginationData, setPaginationData] = React.useState({});
  const [filterBySalesOrderId, setFilterBySalesOrderId] = React.useState("");
  const { showLoader, hideLoader } = useLoading();
  const { addNotification } = useNotification();

  const handleNewSalesOrder = () => {
    navigate("/admin/sales-order");
  };

  const handleTabChange = React.useCallback((event, newValue) => {
    setValue(newValue);
    setStatus(getStatusCode(newValue));
  }, []);

  const getSalesOrderByOrganization = React.useCallback(
    async (page) => {
      showLoader();
      const nextPage = page + 1;
      const subscription = from(
        salesOrderService.getSalesOrderByOrganizationName(
          searchCustomer,
          nextPage
        )
      )
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            setPaginationData(response.meta);
            setSalesOrders(response.data);
            hideLoader();
          },
          error: (error) => {
            addNotification({ message: error.message, type: "error" });
            hideLoader();
          },
        });

      return () => subscription.unsubscribe();
    },
    [addNotification, hideLoader, searchCustomer, showLoader]
  );

  const getSalesOrderBySalesOrderId = React.useCallback(async () => {
    showLoader();
    const subscription = from(
      salesOrderService.getSalesOrderBySalesOrderId(filterBySalesOrderId)
    )
      .pipe(
        take(1),
        map((response) => ({
          data: [response.data],
          meta: {
            current_page: 1,
            last_page: 1,
            per_page: 1,
            total: 1,
            from: 1,
            to: 1,
          },
        }))
      )
      .subscribe({
        next: (response) => {
          setPaginationData(response.meta);
          setSalesOrders(response.data);
          hideLoader();
        },
        error: (error) => {
          addNotification({ message: error.message, type: "error" });
          hideLoader();
        },
      });

    return () => subscription.unsubscribe();
  }, [filterBySalesOrderId, addNotification, hideLoader, showLoader]);

  const getSalesOrderByPage = React.useCallback(
    async (page) => {
      showLoader();
      const nextPage = page + 1;
      const subscription = from(
        salesOrderService.getPaginatedSalesOrdersByType(status, nextPage)
      )
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            setPaginationData(response.meta);
            setSalesOrders(response.data);
            hideLoader();
          },
          error: (error) => {
            addNotification({ message: error.message, type: "error" });
            hideLoader();
          },
        });

      return () => subscription.unsubscribe();
    },
    [addNotification, hideLoader, showLoader, status]
  );

  const handlePageChange = React.useCallback(
    (page) => {
      if (searchCustomer !== "") {
        getSalesOrderByOrganization(page);
        return;
      }

      if (filterBySalesOrderId !== "") {
        getSalesOrderBySalesOrderId();
        return;
      }

      getSalesOrderByPage(page);
    },
    [
      filterBySalesOrderId,
      getSalesOrderByOrganization,
      getSalesOrderByPage,
      getSalesOrderBySalesOrderId,
      searchCustomer,
    ]
  );

  React.useEffect(() => {
    if (searchCustomer !== "" || filterBySalesOrderId !== "") {
      return;
    }

    (async () => {
      await getSalesOrderByPage(0);
    })();
  }, [filterBySalesOrderId, getSalesOrderByPage, searchCustomer]);

  return (
    <GeneralPageLayout
      pageTitle="All Sales Orders"
      breadcrumbs={<Breadcrumbs lastStep="All Sales Orders" />}
      headerWidgets={
        showCreateButton && (
          <Box>
            <Grid container gap={"16px"} alignItems={"center"} textAlign="left">
              <Button
                variant="contained"
                className={classes.headerButton}
                onClick={handleNewSalesOrder}
              >
                <AddCircleOutline sx={{ mr: 1 }} />
                New Sales Order
              </Button>
            </Grid>
          </Box>
        )
      }
    >
      <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
        <Box gridColumn="span 12">
          <Content>
            <TabsComponent
              value={value}
              handleChange={handleTabChange}
              tabLabels={[
                "All Sales Orders",
                "Sales Orders - Pending",
                "Sales Orders - Approved",
                // "Sales Orders - Complete",
              ]}
            />

            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
            >
              {[0, 1, 2, 3].map((item, index) => (
                <TabPanel
                  key={index}
                  value={value}
                  index={item}
                  dir={theme.direction}
                >
                  <SalesOrdersTableWithFilter
                    enableFilters={item === 0 ? true : false}
                    salesOrdersViewLink="/admin/sales-orders/$id$/view"
                    getSalesOrderByOrganization={getSalesOrderByOrganization}
                    handlePageChange={handlePageChange}
                    getSalesOrderBySalesOrderId={getSalesOrderBySalesOrderId}
                    searchCustomer={searchCustomer}
                    setSearchCustomer={setSearchCustomer}
                    salesOrders={salesOrders}
                    paginationData={paginationData}
                    setFilterBySalesOrderId={setFilterBySalesOrderId}
                    filterBySalesOrderId={filterBySalesOrderId}
                  />
                </TabPanel>
              ))}
            </SwipeableViews>
          </Content>
        </Box>
      </Box>
    </GeneralPageLayout>
  );
};

export default SalesOrdersPage;
