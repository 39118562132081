import React, { useEffect } from "react";
import { Typography, Box, styled, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Notification from "../../Common/Notification";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useParams, useNavigate } from "react-router-dom";
import InnerTopBanner from "../layout/pageLayout/InnerTopBanner/InnerTopBanner";
import { SalesOrderService } from "../../services/api/sales-order/sales-order.service";
import { checkIsAdmin } from "../../helpers/helpers";

const useStyles = makeStyles({
  section: {
    marginTop: "30px",
  },
  termsAndConditionsHeader: {
    fontSize: "24px !important",
    fontWeight: "500 !important",
    marginBottom: "10px !important",
    textTransform: "uppercase",
  },
  termsAndConditionRow: {
    display: "flex",
    "& .MuiTypography-root:first-child": {
      textAlign: "right",
      width: "20px",
      marginRight: "9px",
    },
    "& .MuiTypography-root:last-child": {
      textAlign: "left",
      width: "100%",
    },
  },
});

const StyleNotification = styled(Notification)({
  minHeight: "0px",
});

const SalesOrderProceedComponent = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const classes = useStyles();

  const [salesOrderData, setSalesOrderData] = React.useState({});

  const proceedAction = () => {
    checkIsAdmin()
      ? navigate(`/admin/sales-orders/customer/${id}`)
      : navigate(`/sales-orders/customer/${id}`);
  };

  const salesOrderService = new SalesOrderService();

  const getSalesOrderData = async () => {
    try {
      // setloadingTrue
      const salesOrderResponse =
        await salesOrderService.getSalesOrderBySalesOrderId(id);
      setSalesOrderData(salesOrderResponse.data);
      console.log(salesOrderResponse.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSalesOrderData();
  }, [id]);

  return (
    <>
      <InnerTopBanner
        mainText={salesOrderData?.organization?.name}
        subText={`Sales Order #${salesOrderData?.id || id}`}
      />
      <Box sx={{ padding: "20px 60px 0px 20px" }}>
        <Box sx={{ textAlign: "left" }}>
          <StyleNotification
            sx={{}}
            title="Important"
            body="Please read following instructions carefully before continue with the sales order creation."
          />
          <Box className={classes.section}>
            <Typography className={classes.termsAndConditionsHeader}>
              TO PROCEED WITH YOUR ORDER:
            </Typography>
            <Box>
              <Box className={classes.termsAndConditionRow}>
                <Typography>1.</Typography>
                <Typography>
                  Check carefully that all pre-filled details are correct
                </Typography>
              </Box>
              <Box className={classes.termsAndConditionRow}>
                <Typography>2.</Typography>
                <Typography>
                  If the details are incorrect, please contact your S-Trend
                  sales agent to have them changed
                </Typography>
              </Box>
              <Box className={classes.termsAndConditionRow}>
                <Typography>3.</Typography>
                <Typography>
                  Return final and completed order form to your S-Trend sales
                  agent
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className={classes.section}>
            <Typography className={classes.termsAndConditionsHeader}>
              pricing
            </Typography>
            <Box>
              <Box className={classes.termsAndConditionRow}>
                <Typography>1.</Typography>
                <Typography>
                  All unit prices are exclusive of GST/VAT
                </Typography>
              </Box>
              <Box className={classes.termsAndConditionRow}>
                <Typography>2.</Typography>
                <Typography>
                  Names are subject to an additional cost of 1.00 per unit on
                  sublimated items (already included in the unit price stated on
                  this order form)
                </Typography>
              </Box>
              <Box className={classes.termsAndConditionRow}>
                <Typography>3.</Typography>
                <Typography>
                  Numbers are subject to an additional cost of 1.00 per unit on
                  sublimated items (already included in the unit price stated on
                  this order form)
                </Typography>
              </Box>
              <Box className={classes.termsAndConditionRow}>
                <Typography>4.</Typography>
                <Typography>
                  Artwork completed for any order under 15 units (per style) is
                  subject to artwork fee of 50.00 per style (not included in the
                  unit price stated on this order form)
                </Typography>
              </Box>
              <Box className={classes.termsAndConditionRow}>
                <Typography>5.</Typography>
                <Typography>
                  Logos not provided in vector format are subject to a redraw
                  fee of 20.00 per logo (not included in the unit price stated
                  on this order form)
                </Typography>
              </Box>
              <Box className={classes.termsAndConditionRow}>
                <Typography>6.</Typography>
                <Typography>
                  Colours not selected from the ST colour chart are subject to a
                  colour matching fee of 20.00 per colour (not included in the
                  unit price stated on this order form)
                </Typography>
              </Box>
              <Box className={classes.termsAndConditionRow}>
                <Typography>7.</Typography>
                <Typography>Delivery Fee -</Typography>
              </Box>
              <Box sx={{ paddingLeft: "55px" }}>
                <Typography>
                  - 25.00 flat shipping for 1-10 units of lightweight garments
                  (e.g. polo, singlet, short)
                </Typography>
                <Typography sx={{ paddingLeft: "10px" }}>OR</Typography>
                <Typography>
                  - 1-5 units of heavyweight garments (e.g. jacket, pant,
                  hoodie, fleece top)
                </Typography>
                <Typography>
                  - Any additional items will incur a cost of 1.00 per unit for
                  lightweight garments or 2.00 per unit for heavyweight garments
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className={classes.section}>
            <Typography className={classes.termsAndConditionsHeader}>
              Terms
            </Typography>
            <Box>
              <Box className={classes.termsAndConditionRow}>
                <Typography>1.</Typography>
                <Typography>
                  There can be no changes to sizes and quantities once an
                  invoice is issued
                </Typography>
              </Box>
              <Box className={classes.termsAndConditionRow}>
                <Typography>2.</Typography>
                <Typography>
                  You will receive an invoice within 24-48 hours of order
                  approval
                </Typography>
              </Box>
              <Box className={classes.termsAndConditionRow}>
                <Typography>3.</Typography>
                <Typography>
                  Delays in approvals will result in delivery delay
                </Typography>
              </Box>
              <Box className={classes.termsAndConditionRow}>
                <Typography>4.</Typography>
                <Typography>
                  Your quoted lead time commences from the time of art and
                  deposit clearance
                </Typography>
              </Box>
              <Box className={classes.termsAndConditionRow}>
                <Typography>5.</Typography>
                <Typography>
                  Balance payments is required prior to delivery
                </Typography>
              </Box>
              <Box className={classes.termsAndConditionRow}>
                <Typography>6.</Typography>
                <Typography>
                  Delays in deposit or balance payments will result in delivery
                  delays
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ marginTop: "15px" }}>
            <Typography>
              All set? Continue with creating the sales order
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "10px",
            marginBottom: "10px",
            marginLeft: "auto",
            marginRight: "auto",
            maxWidth: "400px",
          }}
        >
          <Button
            style={{ width: "100%", color: "#fff", padding: "10px 0" }}
            endIcon={<ArrowForwardIcon />}
            variant="contained"
            color="success"
            onClick={() => proceedAction()}
          >
            proceed
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default SalesOrderProceedComponent;
