import { apiConstants } from "../../constants";
import { authHeader } from "../../helpers";

export const cartService = {
  // getSingleParameter,
  // getAllParameters,
  addParameter,
  updateParameter,
  deleteParameter,
  clear,
};

// get single parameter
// function getSingleParameter(id) {
//   const requestOptions = {
//     method: "GET",
//     headers: authHeader(),
//   };

//   return fetch(
//     `${apiConstants.API_URL}/customer/store-items/${id}`,
//     requestOptions
//   ).then(handleResponse);
// }

// get all parameter
// function getAllParameters() {
//   const requestOptions = {
//     method: "GET",
//     headers: authHeader(),
//   };

//   return fetch(
//     `${apiConstants.API_URL}/customer/store-items`,
//     requestOptions
//   ).then(handleResponse);
// }

// add parameter
function addParameter(item) {
  // localStorage.addItem("cart", item);
  return Promise.resolve({ item });
}

// update parameter
function updateParameter(item) {
  return Promise.resolve({ item });
}

// delete parameter
function deleteParameter(item) {
  return Promise.resolve({ item });
}

// clear parameter
function clear() {}

// handle response
function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        // logout()
        // window.location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
