import React from "react";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const columns = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "minqty", headerName: "Minimum Quantity", width: 300 },
  { field: "maxqty", headerName: "Max Quantity", width: 300 },
  { field: "status", headerName: "Status", width: 150 },
];

const rows = [
  { id: 1, minqty: "0", maxqty: "14", status: "Active" },
  { id: 2, minqty: "15", maxqty: "29", status: "Active" },
  { id: 3, minqty: "30", maxqty: "44", status: "Active" },
  { id: 4, minqty: "45", maxqty: "59", status: "Active" },
  { id: 5, minqty: "60", maxqty: "75", status: "Active" },
];

const QuantityRanges = () => {
  return (
    <Box>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        autoHeight={true}
        rowsPerPageOptions={[5]}
        // checkboxSelection
      />
    </Box>
  );
};

export default QuantityRanges;
