import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fabricActions } from "../../../../../../actions";
import {
  Divider,
  Button,
  TextField,
  Grid,
  Stack,
  FormGroup,
  FormControlLabel,
  Switch, Box, Alert,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import styles from "./../ParameterForm.module.scss";
import {authHeader} from "../../../../../../helpers";
import {apiConstants} from "../../../../../../constants";
import AlertToast from "../../../../../common/Toast";
import AlertToastSuccess from "../../../../../common/ToastSuccess";

const Position = (props) => {
  const {
    title,
    titleSingle,
    mode,
    paramdata,
    handleSuccessDialog,
    handleErrorAlert,
  } = props;

  const dispatch = useDispatch();
  const paramState = useSelector((state) => state.fabrics);
  const alertState = useSelector((state) => state.alert);
  const formikRef = React.createRef();
  const [toastMessage, setToastMessage] = React.useState("");
  const handleToastClose = () => setToastState({ open: false });
  const handleToastClose2 = () => setToastState2({ open: false });
  const debug = false;
  // const [submitting, setSubmitting] = useState(false);
  const [toggle, setToggle] = useState(true);
  const successMsg = "";

  const validationSchema = Yup.object({
    name: Yup.string().required("Position Name is required"),
    chinese_name: Yup.string().required("Chinese Name is required"),
    status: Yup.boolean().nullable(),
  });

  const initialValues = {
    name: "",
    chinese_name: "",
    status: true,
  };
  const [toastState, setToastState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });
  const [toastState2, setToastState2] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });

  useEffect(() => {
    const prefillData = () => {
      if (mode === "edit" && paramdata) {
        const fields = ["name", "status", "chinese_name", "technical_name"];

        fields.forEach((field) =>
            formikRef.current.setFieldValue(field, paramdata[field], false)
        );
      }
    };

    prefillData();
  }, []);

  useEffect(() => {
    const handleSuccess = () => {
      if (paramState?.parameter?.data && alertState.type === "success") {
        handleSuccessDialog(successMsg);
      }
    };

    const handleError = () => {
      if (paramState?.error && alertState.type === "error") {
        handleErrorAlert(successMsg);
      }
    };

    handleSuccess();
    handleError();
  }, [paramState, handleSuccessDialog, alertState, handleErrorAlert]);

  const handleSubmit = (values) => {
    if (values && mode === "add") {
 const reqData ={
   "name": values.name,
   "status":  values.status,
   "chinese_name":  values.chinese_name,
 }
        const requestOptions = {
          method: "POST",
          headers: authHeader(),
          body:
              JSON.stringify(reqData)
        };

        fetch(`${apiConstants.API_URL}/positions`, requestOptions).then(
            (response) => {
              response.text().then((text) => {
                const data = text && JSON.parse(text);
                if (!response.ok) {
                  if (response.status === 401) {
                    // logout()
                  }
                  const error = (data && data.error) || response.statusText;
                  const errorMessage = Object.values(error).map((errorKey) => {
                    return errorKey[0];
                  });

                  setToastMessage(errorMessage);
                  setToastState({
                    open: true,
                    vertical: "top",
                    horizontal: "right",
                  });

                } else {
                  setToastMessage("Saved Successfully");
                  setToastState2({
                    open: true,
                    vertical: "top",
                    horizontal: "right",
                  });
                  window.history.back()
                }
              });
            }
        );

    }
    if (values && mode === "edit") {
      // positions/1
      const reqData ={
        "name": values.name,
        "status":  values.status,
        "chinese_name":  values.chinese_name,
      }
      const requestOptions = {
        method: "PUT",
        headers: authHeader(),
        body:
            JSON.stringify(reqData)
      };

      fetch(`${apiConstants.API_URL}/positions/${paramdata.id}`, requestOptions).then(
          (response) => {
            response.text().then((text) => {
              const data = text && JSON.parse(text);
              if (!response.ok) {
                if (response.status === 401) {
                  // logout()
                }
                const error = (data && data.error) || response.statusText;
                const errorMessage = Object.values(error).map((errorKey) => {
                  return errorKey[0];
                });

                setToastMessage(errorMessage);
                setToastState({
                  open: true,
                  vertical: "top",
                  horizontal: "right",
                });

              } else {
                setToastMessage("Saved Successfully");
                setToastState2({
                  open: true,
                  vertical: "top",
                  horizontal: "right",
                });
                window.history.back()
              }
            });
          }
      );

    }
  };

  const handleToggle = (e) => {
    setToggle(e.target.checked);
  };

  return (
      <div>
        <AlertToast
            toastMessage={toastMessage}
            toastState={toastState}
            handleClose={handleToastClose}
        />
        <AlertToastSuccess
            toastMessage={toastMessage}
            toastState={toastState2}
            handleClose={handleToastClose2}
        />
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            innerRef={formikRef}
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values);
              resetForm();
            }}
        >
          {({
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              isValid,
              setFieldValue,
            }) => (
              <Form noValidate autoComplete="off">
                <Grid container spacing={{ xs: 2, sm: 2 }} sx={{ pt: 0, pb: 0 }}>
                  <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        id="name"
                        name="name"
                        label="Position Name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.name && Boolean(errors.name)}
                        helperText={touched.name && errors.name}
                        variant="standard"
                        className={styles.textField}
                        size="small"
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        id="chinese_name"
                        name="chinese_name"
                        label="Chinese Name"
                        value={values.chinese_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.chinese_name && Boolean(errors.chinese_name)}
                        helperText={touched.chinese_name && errors.chinese_name}
                        variant="standard"
                        className={styles.textField}
                        size="small"
                    />
                  </Grid>


                  <Grid item xs={12} md={6}>
                    <FormGroup>
                      <FormControlLabel
                          control={
                            <Switch
                                checked={toggle}
                                onChange={(e) => {
                                  handleToggle(e);
                                  setFieldValue("status", e.target.checked);
                                }}
                                id="status"
                                name="status"
                            />
                          }
                          label="Active"
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12}>
                    <Stack
                        direction="column-reverse"
                        justifyContent="space-between"
                        align-items="center"
                        sx={{
                          flexDirection: {
                            sm: "row",
                          },
                          mt: 2,
                        }}
                    >
                      <Link to="../" style={{ display: "block" }}>
                        <Button
                            size="medium"
                            startIcon={<ArrowBackIcon size="small" />}
                            sx={{ textTransform: "none" }}
                        >
                          Back to {title}
                        </Button>
                      </Link>

                      <span style={{ flex: 1 }}>&nbsp;</span>

                      <LoadingButton
                          color="secondary"
                          variant="contained"
                          fullWidth
                          type="submit"
                          size="large"
                          loading={paramState?.loading}
                          // loadingPosition="end"
                          sx={{
                            width: {
                              xs: "100%",
                              sm: "auto",
                            },
                          }}
                      >
                        {`Save ${titleSingle}`}
                      </LoadingButton>
                    </Stack>
                  </Grid>
                </Grid>

                {debug && (
                    <>
                      <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                      <pre style={{ textAlign: "left" }}>
                  <strong>Values</strong>
                  <br />
                        {JSON.stringify(values, null, 2)}
                </pre>
                      <pre style={{ textAlign: "left" }}>
                  <strong>Errors</strong>
                  <br />
                        {JSON.stringify(errors, null, 2)}
                </pre>
                    </>
                )}
              </Form>
          )}
        </Formik>
      </div>
  );
};

export default Position;
