import React from "react";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";

const Inputs = ({ label, error, ...props }) => {
  const validatedLabel = error ? error : label;
  return (
    <>
      <TextField
        sx={{ width: "100%" }}
        {...props}
        notched={true}
        error={error}
        label={validatedLabel}
      />
    </>
  );
};

Inputs.prototype = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  variant: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  error: PropTypes.any,
};
Inputs.defaultProps = {
  variant: "standard",
  label: "",
  type: "text",
  id: "",
};

export default Inputs;
