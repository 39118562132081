import * as React from "react";
import { Grid, Typography, Box, Button, Paper } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#EFEFEF",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  borderRadius: "6px",
}));

const useStyles = makeStyles({
  shippingDetailsContainer: {
    padding: "20px !important",
    marginBottom: "30px",
  },
  shippingDetails: {
    textAlign: "left",
    "& .MuiTypography-root:first-child": {
      color: "rgba(0, 0, 0, 0.87)",
      fontWeight: 700,
      fontSize: 14,
      marginBottom: "20px",
    },
    "& .MuiTypography-root:not(:first-child)": {
      fontSize: 14,
      fontStyle: "italic",
    },
  },
  editButton: {
    padding: "0 0 0 10px !important",
    fontSize: "13px !important",
    "& .MuiButton-startIcon>*:nth-of-type(1)": {
      fontSize: 13,
    },
  },
});

const ShippingDetailsComponent = ({ ...props }) => {
  const classes = useStyles();
  const {
    handleEditInvoiceDetails,
    abnNo,
    purchaseOrder,
    invoiceTo,
    shipTo,
    customer,
    view,
  } = props;

  return (
    <Item className={classes.shippingDetailsContainer}>
      <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} md={4}>
          <Box className={classes.shippingDetails}>
            <Typography>Prepared By:</Typography>
            <Typography>
              S-Trend Sportswear,
              <br></br>
              Suite 6, Level 3,
              <br></br>
              24 Albert Road,
              <br></br>
              South Melb VIC
              <br></br>
              3205
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box className={classes.shippingDetails}>
            <Typography>
              Invoice To:
              {!customer && !view && (
                <Button
                  className={classes.editButton}
                  onClick={() => handleEditInvoiceDetails("text", "invoice_to")}
                  variant="text"
                  startIcon={<Edit />}
                >
                  Edit
                </Button>
              )}
            </Typography>
            <Typography>{invoiceTo?.contact_name || ""}</Typography>
            <Typography>{invoiceTo.address_line_1}</Typography>
            <Typography>{invoiceTo.address_line_2}</Typography>
            <Typography>{invoiceTo.postal_code}</Typography>
            <Typography>{invoiceTo?.suburb || ""}</Typography>
            <Typography>{invoiceTo?.state || ""}</Typography>
            <Typography>
              {invoiceTo?.country?.name || invoiceTo?.country || ""}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box className={classes.shippingDetails}>
            <Typography>
              Ship To:
              {!customer && !view && (
                <Button
                  className={classes.editButton}
                  variant="text"
                  onClick={() => handleEditInvoiceDetails("text", "ship_to")}
                  startIcon={<Edit />}
                >
                  Edit
                </Button>
              )}
            </Typography>
            <Typography>{shipTo?.contact_name || ""}</Typography>
            <Typography>{shipTo.address_line_1}</Typography>
            <Typography>{shipTo.address_line_2}</Typography>
            <Typography>{shipTo.postal_code}</Typography>
            <Typography>{shipTo?.suburb || ""}</Typography>
            <Typography>{shipTo?.state || ""}</Typography>
            <Typography>
              {shipTo?.country?.name || shipTo?.country || ""}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box className={classes.shippingDetails}>
            <Typography>
              ABN:
              {!customer && !view && (
                <Button
                  className={classes.editButton}
                  onClick={() => handleEditInvoiceDetails("number", "abn")}
                  variant="text"
                  startIcon={<Edit />}
                >
                  Edit
                </Button>
              )}
            </Typography>
            <Typography>{abnNo ? abnNo : "--"}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box className={classes.shippingDetails}>
            <Typography>
              Purchase Order:
              {!customer && !view && (
                <Button
                  className={classes.editButton}
                  onClick={() =>
                    handleEditInvoiceDetails("text", "purchase_order")
                  }
                  variant="text"
                  startIcon={<Edit />}
                >
                  Edit
                </Button>
              )}
            </Typography>
            <Typography>{purchaseOrder ? purchaseOrder : "--"}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box className={classes.shippingDetails}>
            <Typography>Currency:</Typography>
            <Typography>AUD</Typography>
          </Box>
        </Grid>
      </Grid>
    </Item>
  );
};
export default ShippingDetailsComponent;
