import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const NotificationSnackBar = ({ open, notification, onClose }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
    >
      <MuiAlert
        elevation={6}
        variant="standard"
        severity={notification.type || "info"}
        onClose={onClose}
      >
        {notification.message}
      </MuiAlert>
    </Snackbar>
  );
};

export default NotificationSnackBar;
