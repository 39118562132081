import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {userActions} from "../../../actions";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import {
    Grid,
    Box,
    TextField,
    Button,
    Typography,
    FormControl,
    Input,
    InputLabel,
    InputAdornment,
    IconButton,
    Divider,
    FormHelperText,
    Collapse,
    Alert,
    AlertTitle,
    Fade,
} from "@mui/material";
import {
    Visibility,
    VisibilityOff,
    Close as CloseIcon,
} from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import styles from "./Login.module.scss";
import * as yup from "yup";
import {authHeader} from "../../../helpers";
import {apiConstants} from "../../../constants";

const CustomerForgetPassword = () => {
    const dispatch = useDispatch();
    const authentication = useSelector((state) => state.authentication);
    const navigate = useNavigate();
    const formikRef = React.createRef();


    const debug = false;

    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [content, setContent] = useState();
    const [alertType, setAlertType] = useState();

    const validationSchema = Yup.object({
        email: Yup.string().required("Email is required").email("enter valid email"),

    });

    const initialValues = {
        email: "",
    };

    useEffect(() => {
        const updateFormSubmitting = () => {
            if (!authentication.loggingIn && !authentication.loggedIn && alert) {
                formikRef.current.setSubmitting(false);
                // formikRef.current.resetForm();
            }
        };
        updateFormSubmitting();
    }, [authentication, formikRef, alert]);


    const handleSubmit = (values, props) => {
        handleLForgetPw(values);
    };

    const handleLForgetPw = (values) => {
        if (values.email) {
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  email: values.email,
                }),
            };

            fetch(`${apiConstants.API_URL}/password/forgot-password`, requestOptions)
                .then((response) => {
                    if (response.status === 200){
                      setShowErrorMessage(true)
                      setAlertType('success')
                      setContent("Email sent Successfully !")
                    } else {
                      setShowErrorMessage(true)
                      setAlertType('error')
                      setContent("Email not sent !")
                    }

                })

        }
    };

    useEffect(() => {

        if (authentication.loggedIn && authentication.user && authentication?.user?.data?.flag_customer_password == 1) {

            navigate("./../../login");
        }

    }, [authentication])


    return (
        <div>
            <Box>
                <Typography
                    variant="h4"
                    sx={{
                        fontSize: {
                            xs: "1.8rem",
                        },
                        marginBottom: "0.5rem",
                    }}
                >
                    Recover password !
                </Typography>
                <Typography variant="body2">
                    Please Enter the email used to create your account.
                    You will receive an email with further instructions.
                </Typography>

                <Collapse in={showErrorMessage}>
                    <Alert
                        severity={alertType}
                        className={`login-alert login-alert-${alert.type}`}
                        sx={{mt: 3, mb: -1}}
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setShowErrorMessage(false);
                                }}
                            >
                                <CloseIcon fontSize="inherit"/>
                            </IconButton>
                        }
                    >
                        <Fade
                            in={ showErrorMessage}
                            {...( showErrorMessage ? {timeout: 500} : {})}
                        >
                            <AlertTitle className="alert-title" sx={{fontSize: "0.8rem"}}>
                                {content}
                            </AlertTitle>
                        </Fade>

                        {/* <Fade
              in={alert.type && showErrorMessage}
              {...(alert.type && showErrorMessage ? { timeout: 1500 } : {})}
            >
              <small>{alert?.message}</small>
            </Fade> */}
                    </Alert>
                </Collapse>
            </Box>

            <Box>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    innerRef={formikRef}
                    onSubmit={(values, {resetForm}) => {
                        handleSubmit(values);
                        // resetForm();
                    }}
                >
                    {({
                          values,
                          touched,
                          errors,
                          handleChange,
                          handleBlur,
                          isValid,
                          setFieldValue,
                      }) => (
                        <Form noValidate autoComplete="off">
                            <Grid
                                container
                                rowSpacing={2}
                                sx={{
                                    marginTop: {xs: "0.5rem"},
                                    marginBottom: {xs: "1rem"},
                                }}
                            >
                                <Grid item xs={12}>
                                    <FormControl
                                        fullWidth
                                        variant="standard"
                                        error={touched.email && Boolean(errors.email)}
                                        className={styles.textField}
                                    >
                                        <InputLabel htmlFor="password">Email</InputLabel>
                                        <Input
                                            fullWidth
                                            id="email"
                                            name="email"
                                            label="Email"
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            variant="standard"
                                            required
                                        />

                                        {touched.email && errors.email && (
                                            <FormHelperText>{errors.email}</FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <LoadingButton
                                        fullWidth
                                        type="submit"
                                        size="large"
                                        variant="contained"
                                        color="secondary"
                                        sx={{marginTop: "1rem"}}
                                        loading={authentication.loggingIn}
                                    >
                                        Proceed
                                    </LoadingButton>
                                </Grid>

                            </Grid>

                            {debug && (
                                <>
                                    <Divider style={{marginTop: 20, marginBottom: 20}}/>
                                    <pre style={{textAlign: "left"}}>
                    <strong>Values</strong>
                    <br/>
                                        {JSON.stringify(values, null, 2)}
                  </pre>
                                    <pre style={{textAlign: "left"}}>
                    <strong>Errors</strong>
                    <br/>
                                        {JSON.stringify(errors, null, 2)}
                  </pre>
                                </>
                            )}
                        </Form>
                    )}
                </Formik>
            </Box>
        </div>
    );
};

export default CustomerForgetPassword;
