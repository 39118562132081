import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import { Typography, Box, Button, InputBase } from "@mui/material";
import { styled } from "@mui/material/styles";
import moment from "moment";
import ActionsButton from "../../SalesOrders/ActionsButton";
import { convertToPlaces } from "../../../helpers/helpers";

const useStyles = makeStyles({
  salesOrdersFilterBar: {
    margin: "14px 0px 16px",
    backgroundColor: "#EFEFEF",
    padding: "16px",
  },
  filterButton: {
    fontSize: 15,
    color: "#fff !important",
    height: "42px",
    "&:hover": {
      backgroundColor: "#fff !important",
      color: "#512879 !important",
      border: "1px solid #512879",
    },
  },
  dataGrid: {
    "& .MuiTablePagination-displayedRows": {
      fontSize: 12,
    },
    "& .MuiTablePagination-select": {
      fontSize: 12,
    },
    "& .MuiTablePagination-selectLabel": {
      fontSize: 12,
    },
    "& .MuiDataGrid-cell:focus": {
      outline: "none !important",
    },
  },
  salesOrderFilterTitle: {
    color: "rgba(0, 0, 0, 0.87) !important",
    fontSize: "14 !important",
  },
  salesOrderFilterContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
});

const columns = [
  { field: "id", headerName: "ID", resizable: false },
  { field: "invoice_id", headerName: "Invoice ID", resizable: false },
  {
    field: "created_at",
    headerName: "Date",
    width: 150,
    renderCell: (params) => moment(params.row.created_at).format("DD/MM/YYYY"),
  },
  {
    field: "organization_name",
    headerName: "Organization Name",
    sortable: true,
    width: 300,
    minWidth: 100,
    valueGetter: (params) => `${params.row.organization?.name || ""}`,
  },
  {
    field: "transaction_type",
    headerName: "Transaction Type",
    sortable: true,
    width: 300,
    minWidth: 100,
    valueGetter: (params) => `${params.row.transaction_type?.name || ""}`,
  },
  {
    field: "amount",
    headerName: "Amount Paid",
    sortable: true,
    width: 300,
    minWidth: 100,
    valueGetter: (params) => `${convertToPlaces(params.row.amount) || ""}`,
  },
  // { field: 'status', headerName: 'Status', renderCell:(params)=>(<StatusChange {...params} component='invoice'/>), width:200},
  {
    field: "actions",
    headerName: "Actions",
    width: 200,
    type: "actions",
    renderCell: (params) => (
      <ActionsButton {...params} component="transaction" />
    ),
    resizable: false,
  },
];

const StyledInputBase = styled(InputBase)(() => ({
  borderRadius: "4px",
  backgroundColor: "rgba(0, 0, 0, 0.06)",
  "& .MuiInputBase-input": {
    paddingLeft: `calc(1em)`,
    width: "100%",
    height: "38px",
  },
}));

const AllPaymentManagement = ({ ...props }) => {
  const { transactions, status, paginationData, handlePageChange } = props;

  const classes = useStyles();

  const [rows, setRows] = React.useState([]);
  const [filteredResults, setFilteredResults] = React.useState([]);

  const [searchCustomer, setSearchCustomer] = React.useState("");

  React.useEffect(() => {
    if (status === 0) {
      setRows(transactions);
      setFilteredResults(transactions);
    }
    if (searchCustomer === "") {
      setFilteredResults(transactions);
    }
  }, [status, transactions, searchCustomer]);

  const handleFilter = () => {
    const filteredRows = rows.filter((f) =>
      f?.organization_name?.toLowerCase().includes(searchCustomer.toLowerCase())
    );

    if (searchCustomer !== "") {
      setFilteredResults(filteredRows);
    } else {
      setFilteredResults(rows);
    }
  };

  return (
    <>
      <Box className={classes.salesOrdersFilterBar}>
        <Typography
          component={"span"}
          className={classes.salesOrderFilterTitle}
        >
          Filter Transactions
        </Typography>
        <Box className={classes.salesOrderFilterContainer}>
          <Box sx={{ mr: 2 }}>
            <StyledInputBase
              placeholder="Customer Name"
              inputProps={{ "aria-label": "search", type: "text" }}
              onChange={(e) => {
                setSearchCustomer(e.target.value);
              }}
            />
          </Box>
          <Button
            variant="contained"
            className={classes.filterButton}
            onClick={handleFilter}
          >
            filter
          </Button>
        </Box>
      </Box>
      <Box style={{ height: 650, width: "100%" }}>
        <DataGrid
          className={classes.dataGrid}
          rows={filteredResults}
          pageSize={paginationData?.per_page}
          pagination
          getRowId={(row) => row.id}
          checkboxSelection
          rowCount={paginationData?.total}
          onPageChange={handlePageChange}
          columns={columns}
          paginationMode="server"
        />
      </Box>
    </>
  );
};

export default AllPaymentManagement;
