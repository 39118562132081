import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  userActions,
  countryActions,
  organizationActions,
  roleActions,
} from "../../../../actions";
import {
  Divider,
  Button,
  TextField,
  Grid,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  FormHelperText,
  Box,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import "yup-phone";
import styles from "./FormDetail.module.scss";
import defaultProfile from "./../../../../../src/assets/imges/default/defaultProfile.png";
import { fileUploaderActions } from "../../../../actions";

const FormDetail = (props) => {
  const {
    mode,
    paramdata,
    handleSuccessDialog,
    handleErrorAlert,
    setEditProfile,
  } = props;

  const dispatch = useDispatch();
  const profileState = useSelector((state) => state.profile);
  const countryState = useSelector((state) => state.countries);
  const alertState = useSelector((state) => state.alert);
  const organizationState = useSelector((state) => state.organizations);
  const fileUploadState = useSelector((state) => state.fileUploads);
  const formikRef = React.createRef();

  const debug = false;
  const [isAdmin, setIsAdmin] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [userType, setUserType] = useState("internal");
  const [profileImage, setProfileImage] = useState({ imageUrl: "", image: "" });
  const successMsg = "";
  const [disabledEditButton, setDisabledEditButton] = useState(false);

  const validationSchema = Yup.object({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    code: Yup.string(),
    contact_no: Yup.string()
      .required("Contact Number is required")
      .min(10)
      .max(12),
    address_line_1: Yup.string().required("Address Line 1 is required"),
    address_line_2: Yup.string().required("Address Line 2 is required"),
    postal_code: Yup.string().required("Postal Code is required"),
    country_id: Yup.string().required("Country is required"),
    is_admin: Yup.boolean().nullable(),
    status: Yup.boolean().nullable(),
    email: Yup.string().email().required("Email is required"),
    editMode: Yup.boolean(),
    password: Yup.string().when("editMode", {
      is: false,
      then: Yup.string().required("Password is required").min(8),
    }),
    c_password: Yup.string().when("editMode", {
      is: false,
      then: Yup.string()
        .required("Confirm Password is required")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
    }),
    organization_id: Yup.string().when("is_admin", {
      is: false,
      then: Yup.string().required("Organization is required"),
    }),
  });

  const initialValues = {
    first_name: "",
    last_name: "",
    code: "",
    contact_no: "",
    address_line_1: "",
    address_line_2: "",
    postal_code: "",
    country_id: "",
    is_admin: userType === "internal" ? true : false,
    status: true,
    email: "",
    password: "",
    c_password: "",
    editMode: mode === "edit" ? true : false,
    user_role: "",
    organization_id: "",
    sport_Id: "",
  };

  useEffect(() => {
    dispatch(countryActions.getAllParameters());
    dispatch(organizationActions.getAllParameters());
  }, []);

  useEffect(() => {
    const getPrefillData = () => {
      if (mode === "edit" && paramdata?.id) {
        // dispatch(userActions.getUserDetails(paramdata?.id));

        if (!isAdmin) {
          setIsAdmin(false);
          dispatch(roleActions.getAllExternalRole());
        } else {
          dispatch(roleActions.getAllInternalRole());
          setIsAdmin(true);
        }
      }
      if (mode !== "edit") {
        if (!isAdmin) {
          dispatch(roleActions.getAllExternalRole());
        } else {
          dispatch(roleActions.getAllInternalRole());
        }
      }
    };

    getPrefillData();
  }, [dispatch, mode, paramdata?.id, paramdata?.is_admin, isAdmin]);

  useEffect(() => {
    const prefillData = () => {
      if (mode === "edit" && paramdata?.id && profileState?.parameter) {
        const data = profileState?.parameter?.data;

        setUserType(data?.is_admin ? "internal" : "external");
        setIsAdmin(data?.is_admin);

        const fields = [
          "first_name",
          "last_name",
          "contact_no",
          "address_line_1",
          "address_line_2",
          "postal_code",
          "manager_id",
          "is_admin",
          "status",
          "email",
          "sport_Id",
        ];

        fields.forEach((field) =>
          formikRef.current.setFieldValue(field, data[field], false)
        );

        formikRef.current.setFieldValue("country_id", data?.country?.id, false);

        formikRef.current.setFieldValue(
          "user_role",
          data?.roles[0]?.name,
          false
        );

        formikRef.current.setFieldValue(
          "organization_id",
          data?.organization?.id,
          false
        );

        formikRef.current.setFieldValue(
          "code",
          data?.code === null ? "" : data?.code,
          false
        );
      }
    };

    prefillData();
  }, [mode, profileState?.parameter, paramdata?.id, formikRef]);

  useEffect(() => {
    if (fileUploadState?.parameter?.data) {
      setProfileImage({
        imageUrl: profileImage?.imageUrl,
        image: fileUploadState?.parameter?.data?.path,
      });
      setDisabledEditButton(false);
    }
  }, [fileUploadState?.parameter?.data]);

  useEffect(() => {
    profileState?.parameter?.data?.image_url &&
      setProfileImage({
        imageUrl: profileState?.parameter?.data?.image_url,
        image: profileState?.parameter?.data?.image,
      });
  }, [profileState?.parameter?.data?.image_url]);

  useEffect(() => {
    const handleSuccess = () => {
      if (profileState?.parameter?.data && alertState.type === "success") {
        !alertState?.message?.data?.path && handleSuccessDialog(successMsg);
      }
    };

    const handleError = () => {
      if (profileState?.error) {
        handleErrorAlert(successMsg);
      }
    };

    handleSuccess();
    handleError();
  }, [profileState, handleSuccessDialog, alertState, handleErrorAlert]);

  useEffect(() => {
    if (alertState.type === "success" && !alertState?.message?.data?.path) {
      dispatch(userActions.getUserDetails(paramdata?.id));
    }
  }, [alertState?.type, alertState?.message]);

  const handleSubmit = (values) => {
    if (values) {
      dispatch(
        userActions.updateUser(
          paramdata.id,
          values.first_name,
          values.last_name,
          values.code,
          values.contact_no,
          values.address_line_1,
          values.address_line_2,
          values.postal_code,
          values.country_id,
          values.manager_id,
          values.is_admin,
          values.status,
          values.email,
          values.password,
          values.c_password,
          values.organization_id,
          values.sport_Id,
          values.user_role,
          profileImage?.image
        )
      );
    }
  };

  const handleBackToProfileDetails = () => {
    setEditProfile(false);
  };

  const handleImageUpload = (e) => {
    setDisabledEditButton(true);
    const file = e.target.files[0];
    const url = URL.createObjectURL(file);
    setProfileImage({ imageUrl: url });
    dispatch(fileUploaderActions.addProfileImage(file));
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        innerRef={formikRef}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values);
          resetForm();
        }}
      >
        {({ values, touched, errors, handleChange, handleBlur }) => (
          <Form noValidate autoComplete="off">
            <Grid
              container
              spacing={{ xs: 2, sm: 2 }}
              sx={{ pt: 0, pb: 0, marginTop: "10px !important" }}
            >
              <Grid item xs={12} md={12}>
                <Box
                  sx={{
                    border: "1px solid black",
                    width: "220px",
                    height: "220px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "20px",
                    position: "relative",
                  }}
                >
                  <img
                    src={
                      profileImage?.imageUrl
                        ? profileImage?.imageUrl
                        : defaultProfile
                    }
                    style={{ width: "200px", height: "200px" }}
                  />
                  <div
                    style={{ position: "absolute", bottom: 0, width: "100%" }}
                  >
                    <label htmlFor="profileImage">
                      <Button
                        variant="contained"
                        sx={{ pointerEvents: "none", width: "100%" }}
                      >
                        upload
                      </Button>
                    </label>
                    <input
                      accept="image/*"
                      style={{
                        position: "absolute",
                        pointerEvents: "none",
                        visibility: "hidden",
                      }}
                      id="profileImage"
                      name="profileImage"
                      onChange={(e) => handleImageUpload(e)}
                      type="file"
                    />
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="first_name"
                  name="first_name"
                  label="First Name"
                  value={values.first_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.first_name && Boolean(errors.first_name)}
                  helperText={touched.first_name && errors.first_name}
                  variant="standard"
                  className={styles.textField}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="last_name"
                  name="last_name"
                  label="Last Name"
                  value={values.last_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.last_name && Boolean(errors.last_name)}
                  helperText={touched.last_name && errors.last_name}
                  variant="standard"
                  className={styles.textField}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="code"
                  name="code"
                  label="Code"
                  value={values.code}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.code && Boolean(errors.code)}
                  helperText={touched.code && errors.code}
                  variant="standard"
                  className={styles.textField}
                  size="small"
                />
              </Grid>

              {!values.is_admin && (
                <Grid item xs={12} md={6}>
                  <FormControl
                    fullWidth
                    error={
                      touched.organization_id && Boolean(errors.organization_id)
                    }
                    sx={{ height: 40 }}
                  >
                    <InputLabel sx={{ ml: -1.75 }}>Organization</InputLabel>
                    {organizationState?.loading && (
                      <CircularProgress
                        size={20}
                        sx={{ position: "absolute", right: 25, bottom: 10 }}
                      />
                    )}
                    <Select
                      fullWidth
                      id="organization_id"
                      name="organization_id"
                      label="Organization"
                      value={values.organization_id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="standard"
                      className={styles.textField}
                      sx={{ height: 40 }}
                      // size="small"
                    >
                      {organizationState?.items?.data.map((item) => {
                        return (
                          <MenuItem key={item?.id} value={item.id}>
                            {item?.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {touched.organization_id &&
                      Boolean(errors.organization_id) && (
                        <FormHelperText>
                          {touched.organization_id && errors.organization_id}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="contact_no"
                  name="contact_no"
                  label="Contact No"
                  value={values.contact_no}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.contact_no && Boolean(errors.contact_no)}
                  helperText={touched.contact_no && errors.contact_no}
                  variant="standard"
                  className={styles.textField}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  label="Email Address"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  variant="standard"
                  className={styles.textField}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="address_line_1"
                  name="address_line_1"
                  label="Address Line 1"
                  value={values.address_line_1}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.address_line_1 && Boolean(errors.address_line_1)
                  }
                  helperText={touched.address_line_1 && errors.address_line_1}
                  variant="standard"
                  className={styles.textField}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="address_line_2"
                  name="address_line_2"
                  label="Address Line 2"
                  value={values.address_line_2}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.address_line_2 && Boolean(errors.address_line_2)
                  }
                  helperText={touched.address_line_2 && errors.address_line_2}
                  variant="standard"
                  className={styles.textField}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="postal_code"
                  name="postal_code"
                  label="Postal Code"
                  value={values.postal_code}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.postal_code && Boolean(errors.postal_code)}
                  helperText={touched.postal_code && errors.postal_code}
                  variant="standard"
                  className={styles.textField}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  error={touched.country_id && Boolean(errors.country_id)}
                  sx={{ height: 40 }}
                >
                  <InputLabel sx={{ ml: -1.75 }}>Country</InputLabel>
                  {countryState?.loading && (
                    <CircularProgress
                      size={20}
                      sx={{ position: "absolute", right: 25, bottom: 10 }}
                    />
                  )}
                  <Select
                    fullWidth
                    id="country_id"
                    name="country_id"
                    label="Country"
                    value={values.country_id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="standard"
                    className={styles.textField}
                    sx={{ height: 40 }}
                    // size="small"
                  >
                    {countryState?.items?.data.map((item) => (
                      <MenuItem key={item?.id} value={item.id}>
                        {item?.name} ({item?.code})
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.country_id && Boolean(errors.country_id) && (
                    <FormHelperText>
                      {touched.country_id && errors.country_id}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              {values.is_admin ? (
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth sx={{ height: 40 }}>
                    <TextField
                      fullWidth
                      id="user_role"
                      name="user_role"
                      label="Role"
                      value={values.user_role}
                      onBlur={handleBlur}
                      variant="standard"
                      className={styles.textField}
                      size="small"
                      disabled
                    />
                  </FormControl>
                </Grid>
              ) : (
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth sx={{ height: 40 }}>
                    <TextField
                      fullWidth
                      id="user_role"
                      name="user_role"
                      label="Role"
                      value={values.user_role}
                      onBlur={handleBlur}
                      variant="standard"
                      className={styles.textField}
                      size="small"
                      disabled
                    />
                  </FormControl>
                </Grid>
              )}
              {/* <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  variant="standard"
                  error={touched.password && Boolean(errors.password)}
                  className={styles.textField}
                >
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <Input
                    fullWidth
                    id="password"
                    name="password"
                    label="Password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="standard"
                    autoComplete="off"
                    required
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={(e) => {
                            e.preventDefault();
                            setShowPassword(!showPassword);
                          }}
                          onMouseDown={(e) => e.preventDefault()}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {touched.password && errors.password && (
                    <FormHelperText>{errors.password}</FormHelperText>
                  )}
                </FormControl>
              </Grid> */}
              {/* <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  variant="standard"
                  error={touched.c_password && Boolean(errors.c_password)}
                  className={styles.textField}
                >
                  <InputLabel htmlFor="c_password">Confirm Password</InputLabel>
                  <Input
                    fullWidth
                    id="c_password"
                    name="c_password"
                    label="Confirm Password"
                    value={values.c_password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="standard"
                    autoComplete="off"
                    required
                    type={showConfirmPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle c_password visibility"
                          onClick={(e) => {
                            e.preventDefault();
                            setShowConfirmPassword(!showConfirmPassword);
                          }}
                          onMouseDown={(e) => e.preventDefault()}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {touched.c_password && errors.c_password && (
                    <FormHelperText>{errors.c_password}</FormHelperText>
                  )}
                </FormControl>
              </Grid> */}

              <Grid item xs={12}>
                <Stack
                  direction="column-reverse"
                  justifyContent="space-between"
                  align-items="center"
                  sx={{
                    flexDirection: {
                      sm: "row",
                    },
                    mt: 2,
                  }}
                >
                  {/* <Link to="../" style={{ display: "block" }}> */}
                  <Button
                    size="medium"
                    startIcon={<ArrowBackIcon size="small" />}
                    sx={{ textTransform: "none" }}
                    onClick={handleBackToProfileDetails}
                  >
                    Back to Profile Details
                  </Button>
                  {/* </Link> */}

                  <span style={{ flex: 1 }}>&nbsp;</span>

                  <LoadingButton
                    color="secondary"
                    variant="contained"
                    fullWidth
                    type="submit"
                    size="large"
                    loading={profileState?.loading}
                    // loadingPosition="end"
                    sx={{
                      width: {
                        xs: "100%",
                        sm: "auto",
                      },
                    }}
                    disabled={disabledEditButton}
                  >
                    {`Edit Profile`}
                  </LoadingButton>
                </Stack>
              </Grid>
            </Grid>
            {debug && (
              <>
                <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                <pre style={{ textAlign: "left" }}>
                  <strong>Values</strong>
                  <br />
                  {JSON.stringify(values, null, 2)}
                </pre>
                <pre style={{ textAlign: "left" }}>
                  <strong>Errors</strong>
                  <br />
                  {JSON.stringify(errors, null, 2)}
                </pre>
              </>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FormDetail;
