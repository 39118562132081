import React from "react";
import * as DOMPurify from "dompurify";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from "@mui/material";
import styles from "./../ProductionData.module.scss";

const DataTable = (props) => {
  const { columns, data, type } = props;

  return (
    <div className={styles.table}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns &&
                columns.map((column) => (
                  <TableCell key={`col-${column?.en}`}>
                    {column?.en && <Typography>{column?.en}</Typography>}
                    {column?.zh && <Typography>{column?.zh}</Typography>}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.length > 0 ? (
              data.map((row, i) => (
                <TableRow
                  key={`${type}${i}`}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {Object.entries(row).map(([key, value]) => (
                    <TableCell>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(value),
                        }}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <Typography sx={{ p: 3 }}>
                No {type} information to display
              </Typography>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default DataTable;
