import {
  Autocomplete,
  Container,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import React, { useEffect, useState } from "react";
import Inputs from "../Common/Inputs";
import Selects, { SelectsAgent } from "../Common/Selects";
import { apiConstants } from "../constants";
import { authHeader } from "../helpers";

const CreateCustomer = ({ ...props }) => {
  const { newCustomer = false, formik } = props;
  const [countries, setCountries] = useState([]);
  const [contryList, setCountryList] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [organizationId, setOrganizationId] = useState("");
  const [salesAgents, setSalesAgents] = useState([]);
  const [gstEnabled, setGstEnabled] = useState(false);

  const organizationProps = {
    options: organizations,
    getOptionLabel: (option) => option.name || "",
  };

  const setExistingOrganizationDetails = (value) => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    fetch(
      `${apiConstants.API_URL}/organizations/${value?.id}`,
      requestOptions
    ).then((response) => {
      response.text().then((text) => {
        const data = text && JSON.parse(text);
        const detail = data?.data;
        const gstEnabled = detail?.gst_enabled;
        setOrganizationId(detail?.id);

        formik.setFieldValue("country_id", detail?.country?.id);
        formik.setFieldValue("country", detail?.country?.name);
        formik.setFieldValue(
          "address_line_1",
          detail?.address_line_1 ? detail?.address_line_1 : ""
        );
        formik.setFieldValue("address_line_2", detail?.address_line_2);
        formik.setFieldValue("postal_code", detail?.postal_code);
        formik.setFieldValue("email", detail?.email);
        formik.setFieldValue("name", detail?.name);
        formik.setFieldValue("organization_id", detail?.id);
        formik.setFieldValue("state", detail?.state);
        formik.setFieldValue(
          "contact_person_name",
          detail?.contact_person_name
        );
        formik.setFieldValue("suburb", detail?.city);

        formik.setFieldValue(
          "phone",
          detail?.contact_no !== null ? detail?.contact_no : ""
        );
        formik.setFieldValue("sales_rep_id", detail?.sales_rep_id);
        formik.setFieldValue("gst_enabled", gstEnabled === 0 ? false : true);
      });
    });
  };

  console.log({ formik });

  const handleCountry = (id, value) => {
    formik.setFieldValue("country", value);
    let countryId = contryList.filter((c) => c?.name === value)[0]?.id;
    formik.setFieldValue("country_id", countryId);
  };

  const handleSalesAgent = (value) => {
    formik.setFieldValue("sales_rep_id", value);
  };

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };
    fetch(`${apiConstants.API_URL}/countries`, requestOptions).then(
      (response) => {
        response.text().then((text) => {
          const data = text && JSON.parse(text);
          let newCountries = data?.data?.map((c) => c?.name);
          setCountries(newCountries);
          setCountryList(data?.data);
        });
      }
    );

    (async () => {
      let link = `${apiConstants.API_URL}/organizations?page=1`;

      const orgs = [];

      while (link !== null) {
        const { data, links } = await fetch(link, requestOptions).then(
          (response) => {
            return response.text().then((text) => {
              const data = text && JSON.parse(text);
              return data;
            });
          }
        );

        orgs.push(...data);
        link = links.next;
      }
      setOrganizations(orgs);
    })();

    fetch(`${apiConstants.API_URL}/auth/users/role/2`, requestOptions).then(
      (response) => {
        response.text().then((text) => {
          const data = text && JSON.parse(text);
          setSalesAgents(data?.data);
        });
      }
    );
  }, []);

  const handleChange = (value) => {
    setExistingOrganizationDetails(value);
  };

  const handleGSTEnabledToggle = (e) => {
    setGstEnabled(e.target.checked);
    formik.setFieldValue("gst_enabled", e.target.checked);
  };

  return (
    <Container>
      <Stack
        direction={"column"}
        spacing={3}
        sx={{ width: "90%", marginTop: "50px" }}
      >
        {newCustomer ? (
          <>
            <Stack direction={"row"} spacing={5} sx={{ gap: "50px" }}>
              <Inputs
                variant="standard"
                label="Organization Name"
                id="name"
                sx={{ width: "45%" }}
                value={organizationId ? "" : formik.values.name}
                onChange={formik.handleChange}
                error={formik.errors.name}
              />
            </Stack>
            <Stack direction={"row"} spacing={5} sx={{ gap: "50px" }}>
              <Inputs
                variant="standard"
                label="Organization Telephone No"
                sx={{ width: "100%" }}
                value={organizationId ? "" : formik.values.phone}
                onChange={formik.handleChange}
                id="phone"
                error={formik.errors.phone}
              />
              <Inputs
                variant="standard"
                label="Organization Email"
                type={"email"}
                value={organizationId ? "" : formik.values.email}
                onChange={formik.handleChange}
                id="email"
                error={formik.errors.email}
              />
            </Stack>
            <Stack direction={"row"} spacing={5} sx={{ gap: "50px" }}>
              <Inputs
                variant="standard"
                label="Address Line 1"
                sx={{ width: "100%" }}
                value={organizationId ? "" : formik.values.address_line_1}
                onChange={formik.handleChange}
                id="address_line_1"
                error={formik.errors.address_line_1}
              />
              <Inputs
                variant="standard"
                label="Address Line 2"
                sx={{ width: "100%" }}
                value={organizationId ? "" : formik.values.address_line_2}
                onChange={formik.handleChange}
                id="address_line_2"
                error={formik.errors.address_line_2}
              />
            </Stack>
            <Stack direction={"row"} spacing={5} sx={{ gap: "50px" }}>
              <Inputs
                variant="standard"
                label="Suburb"
                sx={{ width: "100%" }}
                value={organizationId ? "" : formik.values.suburb}
                onChange={formik.handleChange}
                id="suburb"
                error={formik.errors.suburb}
              />
              <Inputs
                variant="standard"
                label="State"
                sx={{ width: "100%" }}
                value={organizationId ? "" : formik.values.state}
                onChange={formik.handleChange}
                id="state"
                error={formik.errors.state}
              />
            </Stack>
            <Stack direction={"row"} spacing={5} sx={{ gap: "50px" }}>
              <Inputs
                variant="standard"
                label="Postal Code"
                sx={{ width: "100%" }}
                value={organizationId ? "" : formik.values.postal_code}
                onChange={formik.handleChange}
                id="postal_code"
                error={formik?.errors?.postal_code}
              />
              <SelectsAgent
                label={"Sales Agent"}
                value={formik.values.sales_rep_id}
                error={formik.errors.sales_rep_id}
                onChange={handleSalesAgent}
                id={"sales_rep_id"}
                name={"sales_rep_id"}
                data={salesAgents}
              />
            </Stack>
            <Stack direction={"row"} spacing={5} sx={{ gap: "50px" }}>
              <Selects
                label={"Country"}
                value={organizationId ? "" : formik.values.country}
                error={formik.errors.country}
                onChange={handleCountry}
                id="country"
                name="country"
                data={countries}
              />
              <Inputs
                variant="standard"
                label="Contact Person Name"
                sx={{ width: "100%" }}
                value={organizationId ? "" : formik.values.contact_person_name}
                onChange={formik.handleChange}
                id="contact_person_name"
              />
            </Stack>
            <Stack direction={"row"} spacing={5} sx={{ gap: "50px" }}>
              <FormControl fullWidth variant={"standard"}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={gstEnabled}
                      onChange={(e) => {
                        handleGSTEnabledToggle(e);
                      }}
                      id="gst_enabled"
                      name="gst_enabled"
                    />
                  }
                  label="Enable GST"
                />
              </FormControl>
            </Stack>
          </>
        ) : (
          <>
            <Stack direction={"row"} spacing={5} sx={{ gap: "50px" }}>
              <FormControl fullWidth variant="standard" sx={{ width: "45%" }}>
                <Autocomplete
                  {...organizationProps}
                  id="controlled-demo"
                  includeInputInList
                  onChange={(e, value) => handleChange(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Organization Name"
                      variant="standard"
                    />
                  )}
                  value={formik.values}
                />
              </FormControl>
            </Stack>
            <Stack direction={"row"} spacing={5} sx={{ gap: "50px" }}>
              <Inputs
                variant="standard"
                label="Organization Telephone No"
                sx={{ width: "100%" }}
                value={formik.values.phone}
                onChange={formik.handleChange}
                id="phone"
                error={formik.errors.phone}
              />
              <Inputs
                variant="standard"
                label="Organization Email"
                type={"email"}
                value={formik.values.email}
                onChange={formik.handleChange}
                id="email"
                error={formik.errors.email}
              />
            </Stack>
            <Stack direction={"row"} spacing={5} sx={{ gap: "50px" }}>
              <Inputs
                variant="standard"
                label="Address Line 1"
                sx={{ width: "100%" }}
                value={formik.values.address_line_1}
                onChange={formik.handleChange}
                id="address_line_1"
                error={formik.errors.address_line_1}
              />
              <Inputs
                variant="standard"
                label="Address Line 2"
                sx={{ width: "100%" }}
                value={formik.values.address_line_2}
                onChange={formik.handleChange}
                id="address_line_2"
                error={formik.errors.address_line_2}
              />
            </Stack>
            <Stack direction={"row"} spacing={5} sx={{ gap: "50px" }}>
              <Inputs
                variant="standard"
                label="Suburb"
                sx={{ width: "100%" }}
                value={formik.values.suburb}
                onChange={formik.handleChange}
                id="suburb"
                error={formik.errors.suburb}
              />
              <Inputs
                variant="standard"
                label="State"
                sx={{ width: "100%" }}
                value={formik.values.state}
                onChange={formik.handleChange}
                id="state"
                error={formik.errors.state}
              />
            </Stack>
            <Stack direction={"row"} spacing={5} sx={{ gap: "50px" }}>
              <Inputs
                variant="standard"
                label="Postal Code"
                sx={{ width: "100%" }}
                value={formik.values.postal_code}
                onChange={formik.handleChange}
                id="postal_code"
                error={formik.errors.postal_code}
              />
              <SelectsAgent
                label={"Sales Agent"}
                value={formik.values.sales_rep_id}
                error={formik.errors.sales_rep_id}
                onChange={handleSalesAgent}
                id={"sales_rep_id"}
                name={"sales_rep_id"}
                data={salesAgents}
              />
            </Stack>
            <Stack direction={"row"} spacing={5} sx={{ gap: "50px" }}>
              <Inputs
                variant="standard"
                label="Contact Person Name"
                sx={{ width: "100%" }}
                value={formik.values.contact_person_name}
                onChange={formik.handleChange}
                error={formik.errors.contact_person_name}
                id="contact_person_name"
              />
              <Selects
                label={"Country"}
                value={formik.values.country}
                error={formik.errors.country}
                onChange={handleCountry}
                id="country"
                name="country"
                data={countries}
              />
            </Stack>
          </>
        )}
      </Stack>
    </Container>
  );
};

export default CreateCustomer;
