import { createSlice } from "@reduxjs/toolkit";

const initialValues = {
  openModal: false,
  title: "",
  body: "",
  type: "",
};

const messageSlice = createSlice({
  name: "messages",
  initialState: initialValues,
  reducers: {
    openModal: (state, action) => {
      const { body, title, type } = action.payload;
      return {
        ...state,
        type: type,
        title: title,
        body: body,
        openModal: true,
      };
    },
    closeModal: (state, action) => {
      state.body = "";
      state.title = "";
      state.openModal = false;
      state.type = "";
    },
  },
});

export const { openModal, closeModal } = messageSlice.actions;

export default messageSlice.reducer;
