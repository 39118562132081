import * as React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Grid,
  Button,
  TextField,
  FormControl,
  Autocomplete,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import { useStyles } from "../../../Theme/Style";

const LabelsComponent = (props) => {
  const { labels, handleSubmit, activeItem } = props;
  const classes = useStyles();

  const defaultProps = {
    options: labels,
    getOptionLabel: (option) => option?.name || "",
  };

  const [labelValues, setLabelValue] = React.useState([]);

  React.useEffect(() => {
    setLabelValue(activeItem?.production_order?.labels);
    console.log(activeItem?.production_order?.labels);
  }, [activeItem?.production_order?.labels]);

  const [error, setError] = React.useState({
    status: false,
    message: "",
  });

  const addNewRow = () => {
    const labelDetails = {
      label: {},
      // status:'',
      preview: "",
      url: "",
    };
    setLabelValue([...labelValues, labelDetails]);
  };

  const handleChange = (event, name, index, val) => {
    setError({ status: false });
    const { value } = event.target;
    const rowsInput = [...labelValues];
    rowsInput[index][name] = val;
    rowsInput[index]["url"] = val?.image_url;
    setLabelValue(rowsInput);
  };

  const deleteRows = (index) => {
    const rows = [...labelValues];
    rows.splice(index, 1);
    setLabelValue(rows);
  };

  const handleLabelSubmit = () => {
    if (labelValues.every((el) => el.label && el.url)) {
      handleSubmit(labelValues, "label");
    } else {
      setError({ status: true, message: "Please fill all the values" });
    }
  };

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ backgroundColor: "#512879" }}
      >
        <Grid container className={classes.accordionSummaryTextContainer}>
          <Grid item xs={4}>
            Labels
          </Grid>
          <Grid item xs={4}>
            标签
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        <Box className={classes.tableHeader}>
          <Grid container gap={2}>
            <Grid item xs={6} className={classes.cell}>
              <Box>Label</Box>
              <Box>标签</Box>
            </Grid>
            {/* <Grid item xs={4} className={classes.cell}>
                            <Box>Enable /Disable</Box>
                            <Box>启用/禁用</Box>
                        </Grid> */}
            <Grid item xs={4} className={classes.cell}>
              <Box>Preview</Box>
              <Box>预习</Box>
            </Grid>
            <Grid item xs={1} className={classes.cell}></Grid>
          </Grid>
        </Box>
        {labelValues?.map((subItems, index) => {
          const { label, status, preview, url } = subItems;
          return (
            <Box key={index}>
              <Grid container gap={2} marginY={2}>
                <Grid item xs={6}>
                  <FormControl
                    variant="standard"
                    className={classes.tableBodyCell}
                  >
                    <Autocomplete
                      className={classes.select}
                      {...defaultProps}
                      id="controlled-demo"
                      value={label}
                      autoComplete
                      includeInputInList
                      // onSelect={(event) => handleChange(event,'label',index)}
                      onChange={(e, value) => {
                        handleChange(e, "label", index, value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select"
                          variant="standard"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                {/* <Grid item xs={4}>
                                    <FormControl variant="standard" className={classes.tableBodyCell}>
                                        <Autocomplete
                                            className={classes.select}
                                            {...defaultProps1}
                                            id="controlled-demo"
                                            value={status} 
                                            autoComplete
                                            includeInputInList
                                            //onSelect={(event) => handleChange(event,'status',index)}
                                            onChange={(e, value) => {
                                                handleChange(e, 'status', index, value)
                                            }}
                                            renderInput={(params) => (
                                            <TextField {...params} placeholder="Select" variant="standard" />
                                            )}
                                        />
                                    </FormControl>
                                </Grid> */}
                <Grid item xs={4}>
                  {/* <FormControl variant="standard" className={classes.tableBodyCell}>
                                        <Autocomplete
                                            className={classes.select}
                                            name="preview"
                                            {...defaultProps2}
                                            id="controlled-demo"
                                            value={preview}
                                            autoComplete
                                            includeInputInList
                                           // onSelect={(event) => handleChange(event,'preview',index)}
                                            onChange={(e, value) => {
                                            handleChange(e, 'preview', index, value)
                                            }}
                                            renderInput={(params) => (
                                            <TextField {...params} placeholder="Select" variant="standard" 
                                            />
                                            )}
                                        />
                                    </FormControl> */}

                  <Card sx={{ maxHeight: 200 }}>
                    {url && (
                      <CardMedia
                        component="img"
                        image={url}
                        height={200}
                        content="center"
                      />
                    )}
                  </Card>
                </Grid>
                <Grid item xs={1}>
                  <Button>
                    <RemoveCircleOutline onClick={() => deleteRows(index)} />
                  </Button>
                </Grid>
              </Grid>
            </Box>
          );
        })}
        {error?.status && (
          <Box sx={{ mt: 1 }}>
            <Typography sx={{ color: "red", fontSize: 12 }}>
              {error?.message}
            </Typography>
          </Box>
        )}
        <Box className={classes.buttonsContainer}>
          <Button
            variant="outlined"
            className={classes.addNewRowBtn}
            onClick={addNewRow}
          >
            {" "}
            <AddCircleOutline sx={{ paddingRight: "10px" }} /> Add New Row{" "}
          </Button>
          <Button
            variant="contained"
            className={classes.submitBtn}
            onClick={handleLabelSubmit}
          >
            {" "}
            Save{" "}
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default LabelsComponent;
