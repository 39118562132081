import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    secondary: {
      main: "#4285F4",
      light: "white",
    },
    primary: {
      main: "#512879",
      dark: "#1E1E1E",
      light: "#D5F9FC",
    },
    neutral: {
      main: "#E0E0E0",
      light: "#fff",
      dark: "#9E9E9E",
    },
    default: {
      main: "#F5F5F5",
    },
    error: {
      main: "#D32F2F",
    },
    success: {
      main: "#12830b",
    },
  },
  typography: {
    fontFamily: [
      "Lato",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});
