import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import PageLayout from "../../../../layouts/PageLayout/PageLayout.layout";
import { getCurrentUser } from "../../../../helpers";

const ProtectedAdminRoute = () => {
  const navigate = useNavigate();

  const [user, setUser] = useState(
    getCurrentUser() ? true : null
  )

  useEffect(() => {
    const loginCheck = () => {
      if (!user) {
        navigate("/auth/login");
      }
    }

    loginCheck();
  }, [navigate, user])


  return (
    <PageLayout>
      <Outlet />
    </PageLayout>
  );
};

export default ProtectedAdminRoute;
