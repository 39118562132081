import * as React from "react";
import {
  Box,
  Paper,
  Typography,
  List,
  ListItemButton,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const Content = styled(Paper)(({ theme }) => ({
  backgroundColor: "#EEEEEE",
  ...theme.typography.body2,
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
  priceBookSidebarContainer: {
    p: 0,
    position: "sticky",
    top: 0,
    height: "100vh",
    overflow: "auto",
  },
  typography: {
    fontSize: "14px !important",
    color: "rgba(0, 0, 0, 0.89)",
    fontWeight: "500 !important",
    "& .MuiTypography-root": {
      fontSize: 14,
      color: "#000",
    },
  },
  typographyListText: {
    fontSize: "14px !important",
    color: "rgba(0, 0, 0, 0.89)",
    fontWeight: "500 !important",
    "& .MuiTypography-root": {
      fontSize: 14,
      color: "#000",
    },
  },
  sidebarButtonContainer: {
    padding: "12px 10px 18px",
  },
  sidebarList: {
    position: "relative",
    overflow: "auto",
    height: "70%",
    "& .MuiListItemButton-root.Mui-selected": {
      borderRight: "2px solid #512879",
      backgroundColor: "transparent",
      "& .MuiTypography-root": {
        color: "#512879 !important",
      },
    },
  },
  sidebarHeaderContainer: {
    padding: "16px 16px 20px",
  },
  sidebarFooterContainer: {
    position: "absolute",
    bottom: 0,
    right: 0,
    width: "100%",
  },
});

const PriceBookSideBar = ({
  sportCategories,
  handleSportItemClick,
  selectedSportDetails,
  loadingSidebar,
}) => {
  const classes = useStyles();

  return (
    <Content className={classes.priceBookSidebarContainer}>
      <Box className={classes.sidebarHeaderContainer}>
        <Typography className={classes.typography}>Sport Category</Typography>
      </Box>

      {loadingSidebar ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "400px",
          }}
        >
          <CircularProgress size={50} />
        </Box>
      ) : (
        <>
          <List
            component="nav"
            aria-label="secondary mailbox folder"
            className={classes.sidebarList}
          >
            {sportCategories.map((item) => (
              <ListItemButton
                key={item.id}
                className={classes.sidebarListItem}
                selected={item.id === selectedSportDetails?.id}
                onClick={(event) => handleSportItemClick(item.id)}
              >
                <ListItemText
                  className={classes.typographyListText}
                  primary={item.name}
                />
              </ListItemButton>
            ))}
          </List>
        </>
      )}
    </Content>
  );
};

export default PriceBookSideBar;
