import { Box } from "@mui/material";
import * as React from "react";
import Intro from "../../atoms/Intro/Intro.component";
import { useStyles } from "../../../Theme/Style";

export const GeneralPageLayout = ({
  children,
  breadcrumbs,
  pageTitle,
  headerWidgets,
}) => {
  const classes = useStyles();

  return (
    <Box sx={{ flexGrow: 1, padding: "" }}>
      <Box className={classes.headerContainer}>
        <Intro pageTitle={pageTitle} breadcrumbs={breadcrumbs} />
        {headerWidgets}
      </Box>
      {children}
    </Box>
  );
};
