import React, {useEffect, useState} from 'react';
import {Box, Button, CircularProgress, InputBase, Typography} from "@mui/material";
import Intro from "../../atoms/Intro/Intro.component";
import {useParams} from "react-router-dom";
import {makeStyles} from "@mui/styles";
import {styled} from "@mui/material/styles";
import {DataGrid} from "@mui/x-data-grid";
import moment from "moment";
import StatusChange from "../../SalesOrders/StatusChange";
import ActionsButton from "../../SalesOrders/ActionsButton";
import {authHeader} from "../../../helpers";
import {apiConstants} from "../../../constants";
import {convertToPlaces} from "../../../helpers/helpers";

const useStyles = makeStyles({
    headerContainer: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        padding: "5px 0 5px",
    },

    salesOrderFilterTitle: {
        color: 'rgba(0, 0, 0, 0.87) !important',
        fontSize: '14 !important'
    },
    salesOrderFilterContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    salesOrdersFilterBar: {
        margin: '14px 0px 16px',
        backgroundColor: '#EFEFEF',
        padding: '16px'
    },
});

const StyledInputBase = styled(InputBase)(() => ({
    borderRadius: '4px',
    backgroundColor: 'rgba(0, 0, 0, 0.06)',
    '& .MuiInputBase-input': {
        paddingLeft: `calc(1em)`,
        width: '100%',
        height: '38px',
    },
}));

const data = [
    {
        id: 1,
        created_at: '2023-10-31 03:11:34',
        ItemDescription: 'Sublimed Polo',
        organization_name: 'customer online 01',
        estimated_shipping_charges: 2500,
        extra_charges: 0,
        gst_amount: 2300,
        unit_price: 4600,
        qty: 5,
        grand_total: 23000,
        status:"Approved"
    },
    {
        id: 2,
        created_at: '2023-10-20 03:11:34',
        ItemDescription: 'hoodie',
        organization_name: 'customer online 01',
        estimated_shipping_charges: 2600,
        extra_charges: 0,
        gst_amount: 2400,
        unit_price: 5000,
        qty: 15,
        grand_total: 75000,
        status:"Rejected"
    },
]

const columns = [
    {field: 'id', headerName: 'approval ID', resizable: false, width: 100,},
    {
        field: 'created_at',
        headerName: 'Date',
        width: 150,
        renderCell: params => moment(params.row.created_at).format('DD/MM/YYYY')
    },
    {field: 'organization_name', headerName: 'Organization name', resizable: false, width: 250,},
    {
        field: 'ItemDescription',
        headerName: 'Item Description',
        sortable: true,
        width: 300,
        minWidth: 100,

    },
    {
        field: 'status',
        headerName: 'Status',
        sortable: true,
        width: 100,

    },

    {
        field: 'actions',
        headerName: 'Actions',
        width: 200,
        type: 'actions',
        renderCell: (params) => (<ActionsButton {...params} component='preProdAprClient'/>),
        resizable: false
    },
];

const PreProductionApprovalClient = () => {
    const classes = useStyles();
    const [filterCustomerName, setFilterCustomerName] = useState('');
    const [filteredResults, setFilteredResults] = useState([]);
    const [response, setResponse] = useState('');
    const [loadingComponent, setLoadingComponent] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    const breadcrumbs = [
        <Typography key="3">
            Pre Production Approval
        </Typography>,
    ];

    // useEffect(() => {
    //
    //     setLoadingComponent(true);
    //
    //     const requestOptions = {
    //         method: "GET",
    //         headers: authHeader(),
    //     };
    //
    //     fetch(`${apiConstants.API_URL}/factory-invoices`, requestOptions).then((response) => {
    //         response.text().then((text) => {
    //
    //             const data = text && JSON.parse(text);
    //             setFilteredResults(data?.data);
    //             setResponse(data?.meta);
    //             setLoadingComponent(false);
    //         });
    //     });
    // },[]);
    //
    // function handlePageChange(page) {
    //     setCurrentPage(page)
    //     const requestOptions = {
    //         method: "GET",
    //         headers: authHeader(),
    //     };
    //     let url
    //     if((filterCustomerName != '' )){
    //         url = `${apiConstants.API_URL}/factory-invoices?organization.name=${filterCustomerName}&page=${page+1}`
    //     // }else if((filterItemName == '' ) && (searchCustomer != '' )){
    //     //     url = `${apiConstants.API_URL}/production-orders?organization_name=${searchCustomer}&page=${page+1}`
    //     }else {
    //         url = `${apiConstants.API_URL}/factory-invoices?page=${page+1}`
    //     }
    //
    //     fetch(url,requestOptions).then((response)=>{
    //         response.text().then((text) => {
    //             const data = text && JSON.parse(text);
    //             setFilteredResults(data?.data);
    //             setResponse(data?.meta);
    //
    //         });
    //     });
    // }

    // const handleFilter = () => {
    //     if(filterCustomerName !== ''){
    //
    //         const requestOptions = {
    //             method: "GET",
    //             headers: authHeader(),
    //         };
    //
    //         fetch(`${apiConstants.API_URL}/factory-invoices?organization.name=${filterCustomerName}`, requestOptions).then((response)=>{
    //             response.text().then((text) => {
    //                 const data = text && JSON.parse(text);
    //                 setFilteredResults(data?.data);
    //                 setResponse(data?.meta);
    //
    //             });
    //         });
    //     }
    // }

        return (

            <>
                <Box className={classes.headerContainer}>
                    <Intro
                        pageTitle={"Pre Production Approval"}
                        pageTitleShort=""
                        breadcrumbs={breadcrumbs}
                        additionalWidgets=""
                    />
                </Box>

                {/*<Box className={classes.salesOrdersFilterBar}>*/}
                {/*    <Typography component={'span'} className={classes.salesOrderFilterTitle}>*/}
                {/*        Filter Factory Invoices*/}
                {/*    </Typography>*/}
                {/*    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>*/}
                {/*        <Box className={classes.salesOrderFilterContainer}>*/}
                {/*            <Box sx={{mr: 2}}>*/}
                {/*                <StyledInputBase*/}
                {/*                    placeholder="Customer Name"*/}
                {/*                    inputProps={{'aria-label': 'search', type: 'text'}}*/}
                {/*                    onChange={(e) => {*/}
                {/*                        setFilterCustomerName(e.target.value);*/}
                {/*                    }}*/}
                {/*                    value={filterCustomerName}*/}
                {/*                />*/}
                {/*            </Box>*/}
                {/*            <Button variant="contained" className={classes.filterButton} onClick={handleFilter}>*/}
                {/*                filter*/}
                {/*            </Button>*/}
                {/*        </Box>*/}
                {/*    </Box>*/}

                {/*</Box>*/}

                <Box style={{width: '100%'}}>
                    {loadingComponent ?
                        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px'}}>
                            <CircularProgress size={50}/>
                        </Box>
                        :
                        <DataGrid
                            autoHeight={true}
                            className={classes.dataGrid}
                            rows={data}
                            pageSize={20}
                            pagination
                            getRowId={(row) => row.id}
                            // checkboxSelection
                            rowCount={data.length}
                            // onPageChange={handlePageChange}
                            columns={columns}
                            paginationMode="server"
                        />
                    }
                </Box>
            </>

        );
    };

    export default PreProductionApprovalClient;
