export const trimsConstants = {
    GET_SINGLE_REQUEST: "TRIMS_GET_SINGLE_REQUEST",
    GET_SINGLE_SUCCESS: "TRIMS_GET_SINGLE_SUCCESS",
    GET_SINGLE_FAILURE: "TRIMS_GET_SINGLE_FAILURE",
  
    GET_ALL_REQUEST: "TRIMS_GET_ALL_REQUEST",
    GET_ALL_SUCCESS: "TRIMS_GET_ALL_SUCCESS",
    GET_ALL_FAILURE: "TRIMS_GET_ALL_FAILURE",
  
    ADD_REQUEST: "TRIMS_ADD_REQUEST",
    ADD_SUCCESS: "TRIMS_ADD_SUCCESS",
    ADD_FAILURE: "TRIMS_ADD_FAILURE",
  
    UPDATE_REQUEST: "TRIMS_UPDATE_REQUEST",
    UPDATE_SUCCESS: "TRIMS_UPDATE_SUCCESS",
    UPDATE_FAILURE: "TRIMS_UPDATE_FAILURE",
  
    DELETE_REQUEST: "TRIMS_DELETE_REQUEST",
    DELETE_SUCCESS: "TRIMS_DELETE_SUCCESS",
    DELETE_FAILURE: "TRIMS_DELETE_FAILURE",
  
    CLEAR: "TRIMS_CLEAR",
  };
  