import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, Grid, InputBase, Stack, Typography } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import OrganizationTable from "../../../components/admin/OrganizationManagement/OrganizationTable/OrganizationTable.component";
import BoxedContent from "../../../components/layout/pageLayout/BoxedContent/BoxedContent.component";
import Content from "../../../components/layout/pageLayout/Content/Content.component";
import Intro from "../../../components/atoms/Intro/Intro.component";
import { PERMISSIONS } from "../../../constants/data/rolesPermissions.constants";
import { checkPermission } from "../../../helpers/helpers";

const PageWidgets = ({ buttonLabel, searchLabel, parameter, setFilter, filter }) => {
  const navigate = useNavigate();
  const showCreateOrganization = checkPermission(PERMISSIONS.ORGANIZATION_MANAGEMENT);

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={1}
      >
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase placeholder={`Search ${searchLabel}`} value={filter} onChange={e => setFilter(e.target.value)} />
        </Search>
        {showCreateOrganization &&
        <Button
          variant="contained"
          startIcon={<AddCircleOutlineOutlinedIcon />}
          onClick={() => navigate("add")}
          size="large"
        >
          Add New Organization
        </Button>
        }
      </Stack>
    </Box>
  );
};

const OrganizationList = () => {
  const { parameter } = useParams();
  const [filter, setFilter] = useState('');

  const breadcrumbs = [
    <Typography key="2">Organization Management</Typography>,
  ];

  return (
    <>
      <Intro
        pageTitle="Organization Management"
        pageTitleShort="Organization Management"
        breadcrumbs={breadcrumbs}
        additionalWidgets={
          <PageWidgets
            buttonLabel="Organization"
            searchLabel="Organization Management"
            parameter={parameter}
            setFilter={setFilter}
            filter={filter}
          />
        }
      />

      <Content>
        <Grid container spacing="2">
          <Grid item xs={12}>
            <BoxedContent title="All Organizations" subtitle="" description="">
              <OrganizationTable filter={filter} />
            </BoxedContent>
          </Grid>
        </Grid>
      </Content>
    </>
  );
};

export default OrganizationList;

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  transition: "all 0.3s ease-in-out",
  color: "#232323",
  backgroundColor: alpha(theme.palette.common.black, 0.05),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.black, 0.15),
  },
  "&:focus": {
    backgroundColor: alpha(theme.palette.common.black, 0.15),
  },
  marginRight: theme.spacing(1),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    // marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    fontSize: "0.85rem",
    width: "100%",
    height: "1.85rem",
    [theme.breakpoints.up("md")]: {
      minWidth: "10rem",
    },
  },
}));
