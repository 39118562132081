import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { roleActions } from "../../../actions";
import UserTable from "../../../components/admin/UserManagement/UserTable/UserTable.component";
import BoxedContent from "../../../components/layout/pageLayout/BoxedContent/BoxedContent.component";
import Content from "../../../components/layout/pageLayout/Content/Content.component";
import Intro from "../../../components/atoms/Intro/Intro.component";
import { PERMISSIONS } from "../../../constants/data/rolesPermissions.constants";
import { checkPermission } from "../../../helpers/helpers";

const PageWidgets = ({ buttonLabel, searchLabel, parameter }) => {
  const navigate = useNavigate();
  const showAddNewUser = checkPermission(PERMISSIONS.USER_MANAGEMENT);

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={1}
      >
        {/* <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase placeholder={`Search ${searchLabel}`} />
        </Search> */}
        {showAddNewUser && (
          <Button
            variant="contained"
            startIcon={<AddCircleOutlineOutlinedIcon />}
            onClick={() => navigate("add")}
            size="large"
          >
            Add New User
          </Button>
        )}
      </Stack>
    </Box>
  );
};

const UserList = () => {
  const dispatch = useDispatch();
  const { parameter } = useParams();

  const [userType, setUserType] = useState("internal");
  const [isAdmin, setIsAdmin] = useState(1);
  const roleState = useSelector((state) => state.roles);
  const [roleId, setRoleId] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(roleActions.getAllInternalRole());
  }, [dispatch]);

  useEffect(() => {
    const hasPermission = checkPermission(PERMISSIONS.USER_MANAGEMENT);

    if (!hasPermission) {
      navigate("/");
    }
  }, [navigate]);

  const breadcrumbs = [<Typography key="2">User Management</Typography>];

  const handleRoleTypeChange = (e) => {
    setUserType(e.target.value);
    if (e.target.value === "internal") {
      setIsAdmin(1);
      setRoleId("");
    } else {
      setIsAdmin(0);
      setRoleId("");
    }
  };

  const roleChange = (e) => {
    setRoleId(e.target.value);
  };

  return (
    <>
      <Intro
        pageTitle="User Management"
        pageTitleShort="User Management"
        breadcrumbs={breadcrumbs}
        additionalWidgets={
          <PageWidgets
            buttonLabel="User"
            searchLabel="User Management"
            parameter={parameter}
          />
        }
      />

      <Content>
        <Grid container spacing="2">
          <Grid item xs={12}>
            <BoxedContent title="All Users" subtitle="" description="">
              <Stack direction={"row"}>
                <FormControl fullWidth>
                  <FormLabel id="user_type">User Type</FormLabel>
                  <RadioGroup
                    aria-labelledby="user_type"
                    name="user_type"
                    value={userType}
                    onChange={(e) => {
                      handleRoleTypeChange(e);
                    }}
                    row
                  >
                    <FormControlLabel
                      value="internal"
                      control={<Radio />}
                      label="Internal"
                    />
                    <FormControlLabel
                      value="external"
                      control={<Radio />}
                      label="External"
                      disabled
                    />
                  </RadioGroup>
                </FormControl>
                {userType === "internal" && (
                  <FormControl fullWidth variant="standard">
                    <InputLabel>Filter by Role</InputLabel>
                    <Select
                      fullWidth
                      id="user_role"
                      name="user_role"
                      label="Role"
                      value={roleId}
                      onChange={roleChange}
                      variant="standard"
                      sx={{ height: 40 }}
                    >
                      {roleState?.items?.interItems?.data.map((item) => {
                        return (
                          <MenuItem key={item?.id} value={item?.id}>
                            {item?.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
              </Stack>
              <UserTable isAdmin={isAdmin} roleId={roleId} />
            </BoxedContent>
          </Grid>
        </Grid>
      </Content>
    </>
  );
};

export default UserList;
