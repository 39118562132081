import styled from "@emotion/styled";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TextField,
  FormHelperText,
  Autocomplete,
} from "@mui/material";
import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { convertToPlaces, getDiscountedPrice } from "../../helpers/helpers";
import { LIST_ITEM } from "../../constants/Constants";
import DeleteIcon from "@mui/icons-material/Delete";
import { authHeader } from "../../helpers";
import { apiConstants } from "../../constants";
import AlertToast from "../../components/common/Toast";

const StyledButton = styled(Button)({
  color: "white",
});

const StyledPriceButton = styled(Button)({
  color: "white",
  height: "40px",
});

const style = {
  background: "#FFFFFF",
  boxShadow:
    "0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
  borderRadius: "4px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "798px",
  minHeight: "174px",
  padding: "20px",
};

const AddItemSalesOrderModal = ({ ...props }) => {
  const {
    open,
    handleClose,
    sports,
    items,
    addNewItems,
    countryId,
    gstEnabled = true,
    colors,
  } = props;
  const [activeSport, setActiveSport] = useState("");
  const [selectedSport, setSelectedSport] = useState({ name: "" });
  const [data, setData] = useState([]);
  const [fabrics, setFabrics] = useState([]);
  const [linings, setLinings] = useState([]);
  const [extra, setExtra] = useState([]);
  const [selected, setSelected] = useState({});
  const [discount, setDiscount] = useState("");
  const [extraCharges, setExtraCharges] = useState([]);

  const dispatch = useDispatch();
  const [newItem, updateItem] = useState(LIST_ITEM);
  const [enableAdd, setEnableAdd] = useState(false);
  const [error, setError] = useState({ value: false, name: "", message: "" });
  const [fabricPriceId, setFabricPriceId] = useState("");
  const [priceId, setPriceId] = useState("");
  const [originalUnitPrice, setOriginalUnitPrice] = useState("");
  const [unitPriceError, setUnitPriceError] = useState(false);

  const [toastState, setToastState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });
  const [toastMessage, setToastMessage] = React.useState("");
  const handleToastClose = () => setToastState({ open: false });

  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const [selectedColors, setSelectedColors] = useState({ english_name: "" });

  const clearAll = () => {
    setFabrics([]);
    setLinings([]);
    updateItem({ ...LIST_ITEM, sport_id: activeSport });
    handleClose();
    setExtraCharges([]);
    setSelected({});
    setSelectedSport({ name: "" });
  };
  const handleSubmitData = (newItem) => {
    if (gstEnabled) {
      const gstObj = {
        country_id: countryId,
        total_price: newItem?.total_price * 100,
      };

      const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(gstObj),
      };
      fetch(`${apiConstants.API_URL}/prices/gst-charges`, requestOptions).then(
        (res) => {
          res.text().then((gstText) => {
            const gstData = gstText && JSON.parse(gstText);
            if (!res.ok) {
              const error = (gstData && gstData.error) || res.statusText;
              const errorMessageGST = Object.values(error).map((errorKey) => {
                return errorKey[0];
              });
              setToastMessage(errorMessageGST);
              setToastState({
                open: true,
                vertical: "top",
                horizontal: "right",
              });
            } else {
              const addItem = {
                ...newItem,
                originalUnitPrice: originalUnitPrice,
                original_unit_price: newItem.original_unit_price * 100,
                estimated_shipping_charges: data?.data?.total_shipping_charges,
                gst_amount: gstData?.data.toal_gst_amount,
                total_price: newItem?.total_price * 100,
                selected: true,
              };
              addNewItems(addItem);
              clearAll();
            }
          });
        }
      );
    } else {
      const addItem = {
        ...newItem,
        originalUnitPrice: originalUnitPrice,
        original_unit_price: newItem.original_unit_price * 100,
        estimated_shipping_charges: data?.data?.total_shipping_charges,
        total_price: newItem?.total_price * 100,
        selected: true,
      };
      addNewItems(addItem);
      clearAll();
    }
  };

  const handleSave = () => {
    handleSubmitData(newItem);
  };

  const onChange = (event) => {
    const { value } = event.target;
    const { name, id } = value;
    setError({ value: false });
    updateItem((state) => ({
      ...state,
      name,
      item_id: id,
      sport_id: activeSport,
      id: Date.now(),
    }));
    getFabrics(id);
  };

  const getFabrics = (id) => {
    fetch(
      `${apiConstants.API_URL}/price/items/sport/${activeSport}/item/${id}`,
      requestOptions
    ).then((response) => {
      response.text().then((text) => {
        const data = text && JSON.parse(text);
        setFabrics(data.data);
      });
    });
  };

  const getLinings = (id) => {
    fetch(
      `${apiConstants.API_URL}/price/items/sport/${activeSport}/item/${newItem.item_id}/fabric/${id}`,
      requestOptions
    ).then((response) => {
      response.text().then((text) => {
        const data = text && JSON.parse(text);
        const linings = data?.data;
        linings.push({
          lining_ids: [],
          combined_name: "No Lining",
          price_id: "",
        });
        setLinings(data?.data);
      });
    });
  };

  const getExtra = () => {
    fetch(`${apiConstants.API_URL}/extra-charges`, requestOptions).then(
      (response) => {
        response.text().then((text) => {
          const data = text && JSON.parse(text);
          setExtra(data.data);
        });
      }
    );
  };

  useEffect(() => {
    if (activeSport) {
      getExtra();
      fetch(
        `${apiConstants.API_URL}/price/items/sport/${activeSport}`,
        requestOptions
      ).then((res) => {
        res.text().then((text) => {
          const data = text && JSON.parse(text);
          if (!res.ok) {
            if (res.status === 401) {
              // logout()
            }
            const error = (data && data.error) || res.statusText;
            setToastMessage(error);
            setToastState({
              open: true,
              vertical: "top",
              horizontal: "right",
            });
          } else {
            setData(() => data?.data);
          }
        });
      });
      setEnableAdd(false);
    }
  }, [activeSport]);

  const onChangeFabric = (event) => {
    const { id, name, price_id } = event.target.value;
    setFabricPriceId(price_id);
    setPriceId(price_id);
    updateItem({ ...newItem, fabric_id: id, fabric_name: name });
    getLinings(id);
  };

  const onChangeLinings = (e) => {
    const value = e.target.value;
    let lining_names;
    if (value?.lining_ids !== 0) {
      lining_names = value?.combined_name?.split(",");
    } else {
      lining_names = "";
    }
    setPriceId(value?.price_id);
    updateItem({
      ...newItem,
      lining_ids: value?.lining_ids,
      lining_name: lining_names,
    });
  };

  const getPrice = () => {
    if (!newItem?.item_id) {
      setError({ value: true, name: "item", message: "Item is required" });
    } else if (!newItem?.fabric_id) {
      setError({ value: true, name: "fabric", message: "Fabric is required" });
    } else if (!newItem?.color_id) {
      setError({ value: true, name: "color", message: "color is required" });
    } else {
      const price_id =
        newItem?.lining_ids.length !== 0 ? priceId : fabricPriceId;
      setError({ value: false });
      fetch(
        `${apiConstants.API_URL}/price/${price_id}/qty/${newItem?.qty}`,
        requestOptions
      ).then((response) => {
        response.text().then((text) => {
          const data = text && JSON.parse(text);
          setOriginalUnitPrice(data?.data?.unit_price);
          updateItem({
            ...newItem,
            unit_price: data?.data?.unit_price,
            price_id: data?.data?.price_id,
            original_unit_price: data?.data?.unit_price / 100,
            original_total_price: data?.data?.unit_price * newItem?.qty,
            total_price: (data?.data?.unit_price / 100) * newItem?.qty,
          });
          setEnableAdd(data?.data?.unit_price > 0);
        });
      });
    }
  };

  const onChangeQty = (e) => {
    updateItem({ ...newItem, qty: e.target.value });
  };

  const onChangeDiscount = useCallback(
    (discountAmount) => {
      // Ensure discountAmount is parsed to a float value
      let parsedDiscount = parseFloat(discountAmount);
      if (isNaN(parsedDiscount)) {
        parsedDiscount = 0;
      }

      setDiscount(parsedDiscount);
      let newUnitPrice = getDiscountedPrice(
        newItem.original_unit_price * 100,
        parsedDiscount
      );

      updateItem({
        ...newItem,
        unit_discount: discountAmount,
        discount_percentage: parsedDiscount,
        unit_price: newUnitPrice,
        total_price: (newItem.qty * newUnitPrice) / 100,
      });
    },
    [newItem, updateItem]
  );

  const formattedDiscount = convertToPlaces(discount * 100);

  const onChangeUnitPrice = (e) => {
    let newUnitPrice = getDiscountedPrice(
      e.target.value * 100,
      newItem.discount_percentage
    );

    updateItem({
      ...newItem,
      original_unit_price: e.target.value,
      unit_price: newUnitPrice,
      total_price: newItem.qty * newUnitPrice,
    });
    setEnableAdd(e.target.value * 100 >= originalUnitPrice);
    if (e.target.value * 100 >= originalUnitPrice) {
      setUnitPriceError(false);
    } else {
      setUnitPriceError(true);
    }
  };

  const onChangeExtra = (e) => {
    const { value } = e.target;
    setSelected(() => value);
  };

  const onChangeStyleName = (e) => {
    updateItem({ ...newItem, style: e.target.value });
  };

  const addExtraTotal = () => {
    if (selected.code) {
      let newArra = [...extraCharges];
      newArra.push(selected);
      setExtraCharges(() => [...newArra]);

      setSelected({});
      let totalExtra = 0;
      newArra.map((e) => {
        totalExtra = totalExtra + e.cost;
        return "";
      });
      updateItem((state) => ({
        ...state,
        extra_charges: totalExtra,
        extra_charges_list: [...newArra],
      }));
    }
  };

  const removeExtra = (index) => {
    let newArray = [];
    if (index) {
      newArray = extraCharges.slice(index);
    }
    setExtraCharges([...newArray]);
    let totalExtra = 0;
    newArray.map((e) => {
      totalExtra = totalExtra + e.cost;
      return "";
    });
    updateItem({
      ...newItem,
      extra_charges: totalExtra,
      extra_charges_list: [...newArray],
    });
  };

  const enableRequest = newItem.qty > 0;
  //const enableAdd = newItem.unit_price > 0;
  const showExtra = extraCharges.length > 0;

  const sportProps = {
    options: sports,
    getOptionLabel: (option) => option.name,
  };

  const colorsProps = {
    options: colors,
    getOptionLabel: (option) => option?.english_name || "",
  };

  return (
    <Modal
      open={open}
      onClose={clearAll}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack spacing={3}>
          <Stack direction={"row"} spacing={1}>
            <Typography
              id="modal-modal-title"
              fontWeight={500}
              fontSize={"20px"}
            >
              Add Item
            </Typography>
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            spacing={1}
            alignItems={"end"}
          >
            <FormControl fullWidth variant="standard">
              <Autocomplete
                {...sportProps}
                defaultValue={selectedSport}
                id="auto-complete"
                autoComplete
                includeInputInList
                onChange={(e, value) => {
                  setActiveSport(value.id);
                  setSelectedSport(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Sports Category"
                    variant="standard"
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth variant="standard">
              <InputLabel id="demo-simple-select-label">Select Item</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="item"
                size="small"
                onChange={onChange}
              >
                {data.map((d, index) => (
                  <MenuItem key={index} value={d}>
                    {d?.name}
                  </MenuItem>
                ))}
              </Select>
              {error.value && error.name === "item" && (
                <FormHelperText id="component-error-text" sx={{ color: "red" }}>
                  {error.message}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth variant="standard">
              <InputLabel id="demo-simple-select-label">Style Name</InputLabel>
              <Input
                fullWidth
                placeholder="Style Name"
                id="styleName"
                onChange={onChangeStyleName}
              />
            </FormControl>
          </Stack>
          <Stack direction={"row"} justifyContent={"space-between"} spacing={1}>
            <FormControl fullWidth variant="standard">
              <InputLabel id="demo-simple-select-label">
                Select Fabric
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="fabric"
                size="small"
                onChange={onChangeFabric}
              >
                {fabrics?.map((d, index) => (
                  <MenuItem key={index} value={d}>
                    {d?.name}
                  </MenuItem>
                ))}
              </Select>
              {error.value && error.name === "fabric" && (
                <FormHelperText id="component-error-text" sx={{ color: "red" }}>
                  {error.message}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth variant="standard">
              <Autocomplete
                id="auto-complete"
                {...colorsProps}
                autoComplete
                includeInputInList
                onChange={(e, value) => {
                  updateItem({
                    ...newItem,
                    color_id: value?.id,
                    color: value?.english_name,
                    colorObj: {
                      id: value?.id,
                      english_name: value?.english_name,
                    },
                  });
                }}
                sx={{ minWidth: "150px" }}
                renderInput={(params) => (
                  <TextField {...params} label="Colors" variant="standard" />
                )}
              />
              {error.value && error.name === "color" && (
                <FormHelperText id="component-error-text" sx={{ color: "red" }}>
                  {error.message}
                </FormHelperText>
              )}
            </FormControl>
          </Stack>
          <Stack direction={"row"} justifyContent={"space-between"} spacing={1}>
            <FormControl style={{ width: "380px" }} variant="standard">
              <InputLabel id="demo-simple-select-label">
                Select Linings
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="fabric"
                size="small"
                onChange={onChangeLinings}
              >
                {linings?.map((l, index) => (
                  <MenuItem key={index} value={l}>
                    {(l?.combined_name).split(",").join(" + ")}
                  </MenuItem>
                ))}
              </Select>
              {/* <Autocomplete
              sx={{marginTop: '10px'}}
              {...liningProps}
              id="auto-complete"
              autoComplete
              defaultValue={lining}
              onChange={(e, value) => onChangeLinings(value)}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select Linings" variant="standard" />
              )}
            /> */}
            </FormControl>
            <Input
              placeholder="Quantity"
              style={{ width: "250px" }}
              id="qty"
              onChange={onChangeQty}
            />
            <StyledPriceButton
              variant="contained"
              onClick={() => getPrice()}
              disabled={!enableRequest}
            >
              GET PRICE
            </StyledPriceButton>
          </Stack>
          <Stack direction={"row"} justifyContent={"space-between"} spacing={1}>
            <FormControl variant="standard">
              <TextField
                variant="standard"
                placeholder="Price"
                style={{ width: "250px" }}
                type="number"
                label="Unit Price"
                value={newItem.original_unit_price}
                onChange={onChangeUnitPrice}
                disabled
              />
              {unitPriceError && (
                <FormHelperText id="component-error-text" sx={{ color: "red" }}>
                  Unit price cannot be less than{" "}
                  {convertToPlaces(originalUnitPrice)}
                </FormHelperText>
              )}
            </FormControl>
            <TextField
              variant="standard"
              label="Discount"
              style={{ width: "250px" }}
              prefix="$"
              value={discount}
              onChange={(e) => onChangeDiscount(e.target.value)}
              // InputProps={{
              //   startAdornment: formattedDiscount !== "" && (
              //     <span>{formattedDiscount}</span>
              //   ),
              // }}
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
                // style: { caretColor: "transparent", color: "transparent" },
              }} // Ensure only numeric input and align right
            />

            <TextField
              variant="standard"
              placeholder="Discounted Price"
              style={{ width: "250px" }}
              label="Discounted Price"
              disabled
              value={convertToPlaces(newItem.unit_price)}
            />
          </Stack>
          <Stack direction={"row"} spacing={3}>
            <FormControl fullWidth variant="standard">
              <InputLabel id="demo-simple-select-label">
                Select Extra Charges
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="item"
                size="small"
                onChange={onChangeExtra}
              >
                {extra.map((d, index) => (
                  <MenuItem key={index} value={d}>
                    {d?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              variant="outlined"
              onClick={() => addExtraTotal()}
              sx={{ minWidth: "160px" }}
            >
              ADD CHARGES
            </Button>
          </Stack>
          {showExtra && (
            <Stack sx={{ mt: "20px" }}>
              <Divider>
                <Typography fontWeight={500} fontSize={"20px"}>
                  EXTRA CHARGES
                </Typography>
              </Divider>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Code</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Cost</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {extraCharges.map((e, index) => (
                    <TableRow key={index}>
                      <TableCell>{e.code}</TableCell>
                      <TableCell>{e.name}</TableCell>
                      <TableCell>{convertToPlaces(e.cost)}</TableCell>
                      <TableCell>
                        <DeleteIcon
                          color="warning"
                          onClick={() => removeExtra(index)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      Add-ons {convertToPlaces(newItem.extra_charges)}
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Stack>
          )}

          <Stack direction={"row"} justifyContent={"space-between"}>
            <Button color="success" onClick={() => clearAll()}>
              CLOSE
            </Button>
            <StyledButton
              color="success"
              onClick={() => handleSave()}
              variant="contained"
              disabled={!(enableAdd && newItem.original_unit_price !== 0)}
            >
              ADD TO LIST
            </StyledButton>
          </Stack>
        </Stack>
        <AlertToast
          toastMessage={toastMessage}
          toastState={toastState}
          handleClose={handleToastClose}
        />
      </Box>
    </Modal>
  );
};

AddItemSalesOrderModal.prototype = {
  open: PropTypes.bool,

  handleClose: PropTypes.func.isRequired,
};
AddItemSalesOrderModal.defaultProps = {
  open: false,
};

export default AddItemSalesOrderModal;
