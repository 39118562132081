import { apiConstants } from "../constants";
import { authHeader } from "../helpers";

export const organizationService = {
  getSingleParameter,
  getAllParameters,
  addParameter,
  updateParameter,
  deleteParameter,
  clear,
  addStoreParameter,
};

// get single parameter
function getSingleParameter(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${apiConstants.API_URL}/organizations/${id}`,
    requestOptions
  ).then(handleResponse);
}

// get all parameter
async function getAllParameters() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let link = `${apiConstants.API_URL}/organizations?page=1`;

  const organizatinos = [];

  while (link !== null) {
    const { data, links } = await fetch(link, requestOptions).then(
      handleResponse
    );

    organizatinos.push(...data);
    link = links.next;
  }

  return { data: organizatinos };
}

// add parameter
function addParameter(
  name,
  email,
  contact_no,
  contact_person_name,
  sales_rep_id,
  address_line_1,
  address_line_2,
  city,
  state,
  postal_code,
  use_same_billing_address,
  purchase_order_required,
  country_id,
  payment_term_id,
  status,
  delivery_name,
  delivery_email,
  delivery_contact_no,
  delivery_contact_person_name,
  delivery_address_line_1,
  delivery_address_line_2,
  delivery_postal_code,
  delivery_city,
  delivery_state,
  delivery_country_id,
  gst_enabled,
  user_first_name,
  user_last_name,
  user_code,
  user_contact_no,
  user_email,
  user_address_line_1,
  user_address_line_2,
  user_postal_code,
  user_country_id,
  store_name,
  store_enabled
) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      name: name,
      email: email,
      contact_no: contact_no,
      contact_person_name: contact_person_name,
      sales_rep_id: sales_rep_id,
      address_line_1: address_line_1,
      address_line_2: address_line_2,
      city: city,
      state: state,
      postal_code: postal_code,
      use_same_billing_address: use_same_billing_address,
      purchase_order_required: purchase_order_required,
      country_id: country_id,
      payment_term_id: payment_term_id,
      status: status,
      delivery_name: delivery_name,
      delivery_email: delivery_email,
      delivery_contact_no: delivery_contact_no,
      delivery_contact_person_name: delivery_contact_person_name,
      delivery_address_line_1: delivery_address_line_1,
      delivery_address_line_2: delivery_address_line_2,
      delivery_postal_code: delivery_postal_code,
      delivery_city: delivery_city,
      delivery_state: delivery_state,
      delivery_country_id: delivery_country_id,
      gst_enabled: gst_enabled,
      store_name: store_name,
      store_enabled: store_enabled,
      user: {
        first_name: user_first_name,
        last_name: user_last_name,
        code: user_code,
        contact_no: user_contact_no,
        email: user_email,
        address_line_1: user_address_line_1,
        address_line_2: user_address_line_2,
        postal_code: user_postal_code,
        country_id: user_country_id,
        status: true,
        sport_id: null,
      },
    }),
  };

  if (typeof requestOptions.store_enabled === "string") {
    if (requestOptions.store_enabled === "true") {
      requestOptions.store_enabled = true;
    } else if (
      requestOptions.store_enabled === "false" ||
      requestOptions.store_enabled === ""
    ) {
      requestOptions.store_enabled = false;
    } else {
      requestOptions.store_enabled = false;
    }
  }

  return fetch(`${apiConstants.API_URL}/organizations`, requestOptions).then(
    handleResponse
  );
}

// update parameter
function updateParameter(
  id,
  name,
  email,
  contact_no,
  contact_person_name,
  sales_rep_id,
  address_line_1,
  address_line_2,
  city,
  state,
  postal_code,
  use_same_billing_address,
  purchase_order_required,
  country_id,
  payment_term_id,
  status,
  delivery_name,
  delivery_email,
  delivery_contact_no,
  delivery_contact_person_name,
  delivery_address_line_1,
  delivery_address_line_2,
  delivery_postal_code,
  delivery_city,
  delivery_state,
  delivery_country_id,
  store_enabled,
  gst_enabled
) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify({
      name: name,
      email: email,
      contact_no: contact_no,
      contact_person_name: contact_person_name,
      sales_rep_id: sales_rep_id,
      address_line_1: address_line_1,
      address_line_2: address_line_2,
      city: city,
      state: state,
      postal_code: postal_code,
      use_same_billing_address: use_same_billing_address,
      purchase_order_required: purchase_order_required,
      country_id: country_id,
      payment_term_id: payment_term_id,
      status: status,
      delivery_name: delivery_name,
      delivery_email: delivery_email,
      delivery_contact_no: delivery_contact_no,
      delivery_contact_person_name: delivery_contact_person_name,
      delivery_address_line_1: delivery_address_line_1,
      delivery_address_line_2: delivery_address_line_2,
      delivery_postal_code: delivery_postal_code,
      delivery_city: delivery_city,
      delivery_state: delivery_state,
      delivery_country_id: delivery_country_id,
      store_enabled: store_enabled,
      gst_enabled,
    }),
  };

  return fetch(
    `${apiConstants.API_URL}/organizations/${id}`,
    requestOptions
  ).then(handleResponse);
}

// add parameter
function addStoreParameter(store_name, organization_id) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      name: store_name,
      is_active: true,
      organization_id: organization_id,
    }),
  };

  return fetch(`${apiConstants.API_URL}/stores`, requestOptions).then(
    handleResponse
  );
}

// delete parameter
function deleteParameter(id) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(
    `${apiConstants.API_URL}/organizations/${id}`,
    requestOptions
  ).then(handleResponse);
}

// clear parameter
function clear() {}

// handle response
function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        // logout()
        // window.location.reload(true);
      }

      const error = (data && data.error) || response.statusText;
      let errorMessage = "";
      if (data?.code === 500) {
        errorMessage = (data && data.message) || response.statusText;
      } else {
        errorMessage = Object.values(error).map((errorKey) => {
          return errorKey[0];
        });
      }
      return Promise.reject(errorMessage);
    }

    return data;
  });
}
