import React, { useState } from "react";
import PropTypes from "prop-types";
import { Tabs, Tab, Box, Stack, CircularProgress } from "@mui/material";
import TabPanel from "./TabPanel/TabPanel.component";
import styles from "./VerticalTabs.module.scss";

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const VerticalTabs = (props) => {
  const { categories, loading } = props;

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
      }}
    >
      {loading && (
        <Stack justifyContent="center" alignItems="center" sx={{ p: 3 }}>
          <CircularProgress />
        </Stack>
      )}

      {!loading && (
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label=""
          sx={{
            borderRight: 1,
            borderColor: "divider",
            bgcolor: "#eee",
            minWidth: 200,
            minHeight: "calc(100vh - 15rem)",
          }}
        >
          {categories.map((category, i) => (
            <Tab
              key={`tab${i}`}
              label={category?.label}
              {...a11yProps({ i })}
              className={styles.tab}
              sx={{ alignItems: "flex-start" }}
            />
          ))}
        </Tabs>
      )}

      {!loading &&
        categories.map((category, i) => (
          <TabPanel value={value} index={i} key={`tabpanel${i}`}>
            {category?.data}
          </TabPanel>
        ))}
    </Box>
  );
};

export default VerticalTabs;
