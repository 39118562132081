import React, { useCallback, useEffect, useMemo } from "react";
import { Formik, Form, FieldArray, Field } from "formik";
import {
  TextField,
  Button,
  IconButton,
  Box,
  Grid,
  Typography,
  Select,
  MenuItem,
  Stack,
  CircularProgress,
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import * as Yup from "yup";
import { Add, Delete, Remove } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  fabricActions,
  productionOrderActions,
  trimsActions,
} from "../../../../../../actions";
import {
  useCreateProdOrderTemplateMutation,
  useEditProdOrderTemplateMutation,
  useLazyGetProdTemplateByIdQuery,
} from "../../../../../../services/api/parameters/production.order.template.service";
import { LoadingButton } from "@mui/lab";
import { useNotification } from "../../../../../../Contexts/GlobalNotificationContext";

export const ProductionOrderTemplateForm = ({
  title,
  titleSingle,
  mode,
  paramdata,
  handleSuccessDialog,
  handleErrorAlert,
}) => {
  const dispatch = useDispatch();
  const { addNotification } = useNotification();
  const {
    items: productionOrderCategories,
    loading: productionOrderCategoriesLoading,
  } = useSelector((state) => state.productionOrders);

  const { items: trimItems, loading: trimsLoading } = useSelector(
    (state) => state.trims
  );

  const [
    createProdOrderTemplateMutation,
    { isLoading: createTemplateLoading },
  ] = useCreateProdOrderTemplateMutation();

  const [editProdOrderTemplateMutation, { isLoading: editTempLoading }] =
    useEditProdOrderTemplateMutation();

  const [trigger, { data }, lastPromiseInfo] =
    useLazyGetProdTemplateByIdQuery();

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required("Name is required"),
        productionOderCategories: Yup.array()
          .of(
            Yup.object().shape({
              name: Yup.string()
                .oneOf(
                  productionOrderCategories?.data?.map(({ name }) => name) ||
                    [],
                  "Invalid fabric type"
                )
                .required("Fabric type is required"),
            })
          )
          .min(1, "At least one fabric detail is required"),
        trimDetails: Yup.array()
          .of(
            Yup.object().shape({
              name: Yup.string()
                .oneOf(
                  trimItems?.data?.map(({ name }) => name) || [],
                  "Invalid trim type"
                )
                .required("Trim type is required"),
            })
          )
          .min(1, "At least one trim detail is required"),
      }),
    [productionOrderCategories?.data, trimItems?.data]
  );

  const submitForm = useCallback(
    async ({ id, name, productionOderCategories, trimDetails }) => {
      try {
        if (mode === "add") {
          await createProdOrderTemplateMutation({
            name,
            productionOderCategories,
            trimDetails,
          }).unwrap();
          addNotification({
            message: `Templated Created successfully`,
            type: "success",
          });
        } else if (mode === "edit") {
          await editProdOrderTemplateMutation({
            id,
            name,
            productionOderCategories,
            trimDetails,
          }).unwrap();
          addNotification({
            message: `Templated Updated successfully`,
            type: "success",
          });
        }
      } catch (error) {
        addNotification({
          message: `Templated Saving Failed! Please try again`,
          type: "error",
        });
      }
    },
    [mode]
  );

  useEffect(() => {
    dispatch(productionOrderActions.getAllParameters());
    dispatch(trimsActions.getAllParameters());
  }, [dispatch]);

  useEffect(() => {
    if (mode != "add") {
      trigger({
        id: Number(paramdata?.id),
      });
    }
  }, [paramdata]);

  const initialValues = useMemo(
    () =>
      mode != "add" && data
        ? {
            id: data.id,
            name: data.name,
            productionOderCategories: data.productionOderCategories,
            trimDetails: data.trimDetails,
          }
        : {
            name: "",
            productionOderCategories: [{ name: "" }],
            trimDetails: [{ name: "" }],
          },
    [mode, data]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={submitForm}
      enableReinitialize={true}
    >
      {({ values, errors, touched, setFieldValue }) => (
        <Form>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
              maxWidth: 800,
            }}
          >
            <Stack direction="row" spacing={2} flexGrow={"revert"}>
              {mode !== "add" && (
                <Field
                  sx={{ flex: 1 }}
                  disabled
                  name="id"
                  as={TextField}
                  label="ID"
                  variant="outlined"
                  error={touched.id && Boolean(errors.id)}
                  helperText={touched.id && errors.id}
                />
              )}
              <Field
                disabled={mode === "view"}
                sx={{ flex: 1 }}
                name="name"
                as={TextField}
                label="Name"
                variant="outlined"
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
              />
            </Stack>

            {/* Fabric Details Section */}
            <Grid container spacing={2} alignItems="start">
              <Grid item xs={6}>
                <Typography variant="h6" paddingY={2}>
                  Production Order Fabric Categories
                </Typography>
                <FieldArray name="productionOderCategories">
                  {({ remove, push }) => (
                    <>
                      {values.productionOderCategories?.map(
                        (productionOderCategory, index) => (
                          <Grid
                            marginBottom={2}
                            container
                            spacing={2}
                            key={index}
                            alignItems="center"
                            justifyItems="space-around"
                          >
                            <Grid item xs={mode == "view" ? 12 : 10}>
                              {(productionOrderCategoriesLoading ||
                                !!!productionOrderCategories) &&
                              !!!data ? (
                                <CircularProgress color="info" size={20} />
                              ) : (
                                <Autocomplete
                                  disabled={mode === "view"}
                                  options={
                                    productionOrderCategories?.data || []
                                  }
                                  getOptionLabel={(option) => option.name}
                                  freeSolo
                                  value={productionOderCategory}
                                  onChange={(event, newValue) => {
                                    console.log({ newValue, event });
                                    setFieldValue(
                                      `productionOderCategories[${index}]`,
                                      newValue
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Production Order Category"
                                      variant="outlined"
                                      error={
                                        touched.productionOderCategories &&
                                        touched.productionOderCategories[
                                          index
                                        ] &&
                                        touched.productionOderCategories[index]
                                          .name &&
                                        Boolean(
                                          errors.productionOderCategories &&
                                            errors.productionOderCategories[
                                              index
                                            ]?.name
                                        )
                                      }
                                      helperText={
                                        touched.productionOderCategories &&
                                        touched.productionOderCategories[
                                          index
                                        ] &&
                                        touched.productionOderCategories[index]
                                          .name &&
                                        errors.productionOderCategories &&
                                        errors.productionOderCategories[index]
                                          ?.name
                                      }
                                    />
                                  )}
                                />
                              )}
                            </Grid>
                            {mode !== "view" && (
                              <Grid item xs={2}>
                                <IconButton
                                  onClick={() => remove(index)}
                                  disabled={
                                    values.productionOderCategories.length === 1
                                  }
                                >
                                  <Delete />
                                </IconButton>
                              </Grid>
                            )}
                          </Grid>
                        )
                      )}

                      {mode != "view" && (
                        <Button
                          type="button"
                          variant="outlined"
                          startIcon={<Add />}
                          onClick={() => push({ name: "" })}
                        >
                          Add Production Order Category
                        </Button>
                      )}
                    </>
                  )}
                </FieldArray>
              </Grid>

              <Grid item xs={6}>
                {/* Trim Details Section */}
                <Typography variant="h6" paddingY={2}>
                  Trim Details
                </Typography>
                <FieldArray name="trimDetails">
                  {({ remove, push }) => (
                    <>
                      {values.trimDetails?.map((trim, index) => (
                        <Grid
                          marginBottom={2}
                          container
                          spacing={2}
                          key={index}
                          alignItems="center"
                        >
                          <Grid item xs={mode == "view" ? 12 : 10}>
                            {(trimsLoading || !!!trimItems) && !!!data ? (
                              <CircularProgress color="info" size={20} />
                            ) : (
                              <Autocomplete
                                disabled={mode === "view"}
                                options={trimItems?.data || []}
                                freeSolo
                                getOptionLabel={(option) => option.name}
                                value={trim}
                                onChange={(event, newValue) => {
                                  setFieldValue(
                                    `trimDetails[${index}]`,
                                    newValue
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Trim Type"
                                    variant="outlined"
                                    error={
                                      touched.trimDetails &&
                                      touched.trimDetails[index] &&
                                      touched.trimDetails[index].name &&
                                      Boolean(
                                        errors.trimDetails &&
                                          errors.trimDetails[index]?.name
                                      )
                                    }
                                    helperText={
                                      touched.trimDetails &&
                                      touched.trimDetails[index] &&
                                      touched.trimDetails[index].name &&
                                      errors.trimDetails &&
                                      errors.trimDetails[index]?.name
                                    }
                                  />
                                )}
                              />
                            )}
                          </Grid>
                          {mode != "view" && (
                            <Grid item xs={2}>
                              <IconButton
                                onClick={() => remove(index)}
                                disabled={values.trimDetails.length === 1}
                              >
                                <Delete />
                              </IconButton>
                            </Grid>
                          )}
                        </Grid>
                      ))}
                      {mode != "view" && (
                        <Button
                          type="button"
                          variant="outlined"
                          startIcon={<Add />}
                          onClick={() => push({ name: "" })}
                        >
                          Add Trim Type
                        </Button>
                      )}
                    </>
                  )}
                </FieldArray>
              </Grid>
            </Grid>

            {mode != "view" && (
              <LoadingButton
                disabled={productionOrderCategoriesLoading || trimsLoading}
                loading={createTemplateLoading}
                type="submit"
                variant="contained"
                color="primary"
              >
                {mode == "add" ? "Submit" : "Update"}
              </LoadingButton>
            )}
          </Box>
        </Form>
      )}
    </Formik>
  );
};
