import React, { useState, useRef } from "react";
import {
  Divider,
  Button,
  TextField,
  Select,
  MenuItem,
  Grid,
  FormGroup,
  FormControl,
  FormHelperText,
  FormControlLabel,
  InputLabel,
  Checkbox,
  Box,
} from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import NamesForm from "./NamesForm.component";
import styles from "./QuantityInput.module.scss";

const QuantityForm = (props) => {
  const {
    sizeOptions,
    size = "",
    quantity = "",
    customize = false,
    customizations = [],
    mode = "create",
    editIndex = null,
    updateSizeQunatity,
  } = props;

  const [toggle, setToggle] = useState(customize);
  const [namesNumbers, setNamesNumbers] = useState(customizations);

  const initialValues = {
    size: mode === "edit" ? size : "",
    quantity: mode === "edit" ? quantity : "",
    customize: mode === "edit" ? customize : false,
  };

  const formikRef = React.createRef();

  const debug = false;

  const validationSchema = Yup.object({
    size: Yup.string().required("Size is required"),
    quantity: Yup.number()
      .required("Quantity is required")
      .min(1, "Minimum quantity is 1")
      .typeError("Qunatity must be a number"),
    customize: Yup.boolean().nullable(),
  });

  const handleSubmit = (values) => {
    const szValues = { ...values, customizations: namesNumbers };
    updateSizeQunatity(szValues, mode, editIndex);
    setNamesNumbers([]);
  };

  const handleToggle = (e) => {
    setToggle(e.target.checked);
  };

  return (
    <Box sx={{ p: 2, background: "#f9f9f9", borderRadius: 1 }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        innerRef={formikRef}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values);
          resetForm();
          setToggle(false);
        }}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          isValid,
          setFieldValue,
        }) => (
          <Form noValidate autoComplete="off">
            <Grid container spacing={{ xs: 2, sm: 2 }} sx={{ pt: 0, pb: 0 }}>
              <Grid item xs={12} md={3}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={styles.selectField}
                  size="small"
                  error={touched.size && Boolean(errors.size)}
                >
                  <InputLabel sx={{ backgroundColor: "#fff" }}>
                    Item Size
                  </InputLabel>
                  <Select
                    id="size"
                    name="size"
                    value={values.size}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    renderValue={(value) => `${value.toUpperCase()}`}
                  >
                    {sizeOptions.map((sizeItem) => {
                      return (
                        sizeItem?.status === 1 && (
                          <MenuItem
                            key={sizeItem?.id}
                            value={sizeItem?.code}
                            sx={{ textTransform: "uppercase" }}
                          >
                            {sizeItem?.name}
                          </MenuItem>
                        )
                      );
                    })}
                  </Select>
                  {touched.size && Boolean(errors.size) && (
                    <FormHelperText>{errors.size}</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="quantity"
                  quantity="quantity"
                  label="Item Quantity"
                  value={values.quantity}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.quantity && Boolean(errors.quantity)}
                  helperText={touched.quantity && errors.quantity}
                  variant="outlined"
                  className={styles.textField}
                  size="small"
                  type="number"
                  min="0"
                />
              </Grid>

              <Grid item xs={12} md={mode === "edit" ? 6 : 4}>
                <FormGroup>
                  <FormControlLabel
                    sx={{
                      float: mode === "edit" ? "right" : "none",
                    }}
                    control={
                      <Checkbox
                        checked={toggle}
                        onChange={(e) => {
                          handleToggle(e);
                          setFieldValue("customize", e.target.checked);
                        }}
                        id="customize"
                        name="customize"
                      />
                    }
                    label={
                      mode === "edit"
                        ? "Customize Names & Numbers"
                        : "Customize Names & Numbers"
                    }
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12} md={2}>
                <Button
                  color="secondary"
                  variant="contained"
                  fullWidth
                  type="submit"
                  size="large"
                  sx={{
                    float: "right",
                    width: {
                      xs: "100%",
                      sm: "auto",
                    },
                    mt: "-0.3rem",
                  }}
                  className="btn-form-submit"
                >
                  {mode === "edit" ? "Update" : "Add"} Item
                </Button>
              </Grid>
            </Grid>

            {values.customize && values.quantity > 0 && (
              <Grid item xs={12} sx={{ mt: 1 }}>
                <NamesForm
                  quantity={values.quantity}
                  namesNumbers={namesNumbers}
                  setNamesNumbers={setNamesNumbers}
                  mode={mode}
                />
              </Grid>
            )}

            {debug && (
              <>
                <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                <pre style={{ textAlign: "left" }}>
                  <strong>Values</strong>
                  <br />
                  {JSON.stringify(values, null, 2)}
                </pre>
                <pre style={{ textAlign: "left" }}>
                  <strong>Errors</strong>
                  <br />
                  {JSON.stringify(errors, null, 2)}
                </pre>
              </>
            )}
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default QuantityForm;
