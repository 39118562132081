import React, { useState } from "react";
import {
  Divider,
  Button,
  TextField,
  Grid,
  Stack,
  FormGroup,
  FormControlLabel,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import styles from "./../ParameterForm.module.scss";

const DeliveryFees = (props) => {
  const { title, titleSingle, mode, handleSuccessDialog } = props;
  const debug = false;
  const [submitting, setSubmitting] = useState(false);
  const [toggle, setToggle] = useState(true);
  const successMsg = "";

  const validationSchema = Yup.object({
    ordertype: Yup.string().required("Order Type is required"),
    deliverytype: Yup.string().required("Delivery Type is required"),
    deliverytime: Yup.string().required("Delivery Time is required"),
    extrachargefordelivery: Yup.string().required(
      "Extra charge for the delivery time is required"
    ),
    seconddeliverytime: Yup.string().required(
      "2nd option Delivery time is required"
    ),
    surchargesecondoption: Yup.string().required(
      "Surcharge % for 2nd option Delivery time"
    ),
    thirddeliverytime: Yup.string().required("3rd option Delivery time"),
    surchargethirdoption: Yup.string().required(
      "Surcharge % for 3rd option Delivery time"
    ),
    weighttype: Yup.string().required("Weight Type is required"),
    minitems: Yup.string().required("Minimum number of items required"),
    currencytype: Yup.string(),
    priceforminitem: Yup.string().required("Price for Min item is required"),
    numadditionlitem: Yup.string().required(
      "No. of additional itemis required"
    ),
    priceadditionalitem: Yup.string().required(
      "Price for additional item is required"
    ),
    status: Yup.boolean().nullable(),
  });

  const initialValues = {
    ordertype: "",
    deliverytype: "",
    deliverytime: "",
    extrachargefordelivery: "",
    seconddeliverytime: "",
    surchargesecondoption: "",
    thirddeliverytime: "",
    surchargethirdoption: "",
    weighttype: "",
    minitems: "",
    currencytype: "",
    priceforminitem: "",
    numadditionlitem: "",
    priceadditionalitem: "",
    status: true,
  };

  const handleToggle = (e) => {
    setToggle(e.target.checked);
  };

  const handleSubmit = (values) => {
    console.log(values);
    handleSuccessDialog(successMsg);
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values);
          resetForm();
        }}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          isValid,
          setFieldValue,
        }) => (
          <Form noValidate autoComplete="off">
            <Grid container spacing={{ xs: 2, sm: 2 }} sx={{ pt: 0, pb: 0 }}>
              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={styles.selectField}
                  size="small"
                  error={touched.ordertype && Boolean(errors.ordertype)}
                >
                  <InputLabel sx={{ backgroundColor: "#fff" }}>
                    Order Type
                  </InputLabel>
                  <Select
                    id="ordertype"
                    name="ordertype"
                    value={values.ordertype}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    renderValue={(value) => `${value}`}
                  >
                    <MenuItem value={"New"}>New</MenuItem>
                    <MenuItem value={"Repeat"}>Repeat</MenuItem>
                  </Select>
                  {touched.ordertype && Boolean(errors.ordertype) && (
                    <FormHelperText>{errors.ordertype}</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={styles.selectField}
                  size="small"
                  error={touched.deliverytype && Boolean(errors.deliverytype)}
                >
                  <InputLabel sx={{ backgroundColor: "#fff" }}>
                    Delivery Type
                  </InputLabel>
                  <Select
                    id="deliverytype"
                    name="deliverytype"
                    value={values.deliverytype}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    renderValue={(value) => `${value}`}
                  >
                    <MenuItem value={"Standard"}>Standard</MenuItem>
                    <MenuItem value={"Express"}>Express</MenuItem>
                  </Select>
                  {touched.deliverytype && Boolean(errors.deliverytype) && (
                    <FormHelperText>{errors.deliverytype}</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={styles.selectField}
                  size="small"
                  error={touched.deliverytime && Boolean(errors.deliverytime)}
                >
                  <InputLabel sx={{ backgroundColor: "#fff" }}>
                    Delivery Time
                  </InputLabel>
                  <Select
                    id="deliverytime"
                    name="deliverytime"
                    value={values.deliverytime}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    renderValue={(value) => `${value}`}
                  >
                    <MenuItem value={"6 weeks"}>6 weeks</MenuItem>
                    <MenuItem value={"5 weeks"}>5 weeks</MenuItem>
                    <MenuItem value={"4 weeks"}>4 weeks</MenuItem>
                    <MenuItem value={"3 weeks"}>3 weeks</MenuItem>
                    <MenuItem value={"2 weeks"}>2 weeks</MenuItem>
                    <MenuItem value={"1 week"}>1 week</MenuItem>
                    <MenuItem value={"6 days"}>6 days</MenuItem>
                    <MenuItem value={"5 days"}>5 days</MenuItem>
                    <MenuItem value={"4 days"}>4 days</MenuItem>
                    <MenuItem value={"3 days"}>3 days</MenuItem>
                    <MenuItem value={"2 days"}>2 days</MenuItem>
                    <MenuItem value={"Same day"}>Same day</MenuItem>
                  </Select>
                  {touched.deliverytime && Boolean(errors.deliverytime) && (
                    <FormHelperText>{errors.deliverytime}</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={styles.selectField}
                  size="small"
                  error={
                    touched.extrachargefordelivery &&
                    Boolean(errors.extrachargefordelivery)
                  }
                >
                  <InputLabel sx={{ backgroundColor: "#fff" }}>
                    Extra charge for the delivery time
                  </InputLabel>
                  <Select
                    id="extrachargefordelivery"
                    name="extrachargefordelivery"
                    value={values.extrachargefordelivery}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    renderValue={(value) => `${value}`}
                  >
                    <MenuItem value={"Yes"}>Yes</MenuItem>
                    <MenuItem value={"NO"}>No</MenuItem>
                  </Select>
                  {touched.extrachargefordelivery &&
                    Boolean(errors.extrachargefordelivery) && (
                      <FormHelperText>
                        {errors.extrachargefordelivery}
                      </FormHelperText>
                    )}
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={styles.selectField}
                  size="small"
                  error={
                    touched.seconddeliverytime &&
                    Boolean(errors.seconddeliverytime)
                  }
                >
                  <InputLabel sx={{ backgroundColor: "#fff" }}>
                    2nd option Delivery time
                  </InputLabel>
                  <Select
                    id="seconddeliverytime"
                    name="seconddeliverytime"
                    value={values.seconddeliverytime}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    renderValue={(value) => `${value}`}
                  >
                    <MenuItem value={"6 weeks"}>6 weeks</MenuItem>
                    <MenuItem value={"5 weeks"}>5 weeks</MenuItem>
                    <MenuItem value={"4 weeks"}>4 weeks</MenuItem>
                    <MenuItem value={"3 weeks"}>3 weeks</MenuItem>
                    <MenuItem value={"2 weeks"}>2 weeks</MenuItem>
                    <MenuItem value={"1 week"}>1 week</MenuItem>
                    <MenuItem value={"6 days"}>6 days</MenuItem>
                    <MenuItem value={"5 days"}>5 days</MenuItem>
                    <MenuItem value={"4 days"}>4 days</MenuItem>
                    <MenuItem value={"3 days"}>3 days</MenuItem>
                    <MenuItem value={"2 days"}>2 days</MenuItem>
                    <MenuItem value={"Same day"}>Same day</MenuItem>
                  </Select>
                  {touched.seconddeliverytime &&
                    Boolean(errors.seconddeliverytime) && (
                      <FormHelperText>
                        {errors.seconddeliverytime}
                      </FormHelperText>
                    )}
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="surchargesecondoption"
                  name="surchargesecondoption"
                  label="Surcharge percentage(%) for 2nd option Delivery time"
                  value={values.surchargesecondoption}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.surchargesecondoption &&
                    Boolean(errors.surchargesecondoption)
                  }
                  helperText={
                    touched.surchargesecondoption &&
                    errors.surchargesecondoption
                  }
                  variant="outlined"
                  className={styles.textField}
                  size="small"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={styles.selectField}
                  size="small"
                  error={
                    touched.thirddeliverytime &&
                    Boolean(errors.thirddeliverytime)
                  }
                >
                  <InputLabel sx={{ backgroundColor: "#fff" }}>
                    3rd option Delivery time
                  </InputLabel>
                  <Select
                    id="thirddeliverytime"
                    name="thirddeliverytime"
                    value={values.thirddeliverytime}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    renderValue={(value) => `${value}`}
                  >
                    <MenuItem value={"6 weeks"}>6 weeks</MenuItem>
                    <MenuItem value={"5 weeks"}>5 weeks</MenuItem>
                    <MenuItem value={"4 weeks"}>4 weeks</MenuItem>
                    <MenuItem value={"3 weeks"}>3 weeks</MenuItem>
                    <MenuItem value={"2 weeks"}>2 weeks</MenuItem>
                    <MenuItem value={"1 week"}>1 week</MenuItem>
                    <MenuItem value={"6 days"}>6 days</MenuItem>
                    <MenuItem value={"5 days"}>5 days</MenuItem>
                    <MenuItem value={"4 days"}>4 days</MenuItem>
                    <MenuItem value={"3 days"}>3 days</MenuItem>
                    <MenuItem value={"2 days"}>2 days</MenuItem>
                    <MenuItem value={"Same day"}>Same day</MenuItem>
                  </Select>
                  {touched.thirddeliverytime &&
                    Boolean(errors.thirddeliverytime) && (
                      <FormHelperText>
                        {errors.thirddeliverytime}
                      </FormHelperText>
                    )}
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="surchargethirdoption"
                  name="surchargethirdoption"
                  label="Surcharge percentage(%) for 3rd option Delivery time"
                  value={values.surchargethirdoption}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.surchargethirdoption &&
                    Boolean(errors.surchargethirdoption)
                  }
                  helperText={
                    touched.surchargethirdoption && errors.surchargethirdoption
                  }
                  variant="outlined"
                  className={styles.textField}
                  size="small"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={styles.selectField}
                  size="small"
                  error={touched.weighttype && Boolean(errors.weighttype)}
                >
                  <InputLabel sx={{ backgroundColor: "#fff" }}>
                    Weight Type
                  </InputLabel>
                  <Select
                    id="weighttype"
                    name="weighttype"
                    value={values.weighttype}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    renderValue={(value) => `${value}`}
                  >
                    <MenuItem value={"Heavy Weight"}>Heavy Weight</MenuItem>
                    <MenuItem value={"Light Weight"}>Light Weight</MenuItem>
                  </Select>
                  {touched.weighttype && Boolean(errors.weighttype) && (
                    <FormHelperText>{errors.weighttype}</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="minitems"
                  name="minitems"
                  label="Minimum number of items required"
                  value={values.minitems}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.minitems && Boolean(errors.minitems)}
                  helperText={touched.minitems && errors.minitems}
                  variant="outlined"
                  className={styles.textField}
                  size="small"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="currencytype"
                  name="currencytype"
                  label="Currency Type"
                  value={values.currencytype}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.currencytype && Boolean(errors.currencytype)}
                  helperText={touched.currencytype && errors.currencytype}
                  variant="outlined"
                  className={styles.textField}
                  size="small"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="priceforminitem"
                  name="priceforminitem"
                  label="Price for Min Item"
                  value={values.priceforminitem}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.priceforminitem && Boolean(errors.priceforminitem)
                  }
                  helperText={touched.priceforminitem && errors.priceforminitem}
                  variant="outlined"
                  className={styles.textField}
                  size="small"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="numadditionlitem"
                  name="numadditionlitem"
                  label="No. of additional items"
                  value={values.numadditionlitem}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.numadditionlitem && Boolean(errors.numadditionlitem)
                  }
                  helperText={
                    touched.numadditionlitem && errors.numadditionlitem
                  }
                  variant="outlined"
                  className={styles.textField}
                  size="small"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="priceadditionalitem"
                  name="priceadditionalitem"
                  label="Price for additional items"
                  value={values.priceadditionalitem}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.priceadditionalitem &&
                    Boolean(errors.priceadditionalitem)
                  }
                  helperText={
                    touched.priceadditionalitem && errors.priceadditionalitem
                  }
                  variant="outlined"
                  className={styles.textField}
                  size="small"
                />
              </Grid>

              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={toggle}
                        onChange={(e) => {
                          handleToggle(e);
                          setFieldValue("status", e.target.checked);
                        }}
                        id="status"
                        name="status"
                      />
                    }
                    label="Active"
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <Stack
                  direction="column-reverse"
                  justifyContent="space-between"
                  align-items="center"
                  sx={{
                    flexDirection: {
                      sm: "row",
                    },
                    mt: 2,
                  }}
                >
                  <Link to="../" style={{ display: "block" }}>
                    <Button
                      size="medium"
                      startIcon={<ArrowBackIcon size="small" />}
                      sx={{ textTransform: "none" }}
                    >
                      Back to {title}
                    </Button>
                  </Link>

                  <span style={{ flex: 1 }}>&nbsp;</span>

                  <LoadingButton
                    color="secondary"
                    variant="contained"
                    fullWidth
                    type="submit"
                    size="large"
                    loading={submitting}
                    // loadingPosition="end"
                    sx={{
                      width: {
                        xs: "100%",
                        md: "auto",
                      },
                    }}
                  >
                    {`Save ${titleSingle}`}
                  </LoadingButton>
                </Stack>
              </Grid>
            </Grid>

            {debug && (
              <>
                <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                <pre style={{ textAlign: "left" }}>
                  <strong>Values</strong>
                  <br />
                  {JSON.stringify(values, null, 2)}
                </pre>
                <pre style={{ textAlign: "left" }}>
                  <strong>Errors</strong>
                  <br />
                  {JSON.stringify(errors, null, 2)}
                </pre>
              </>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default DeliveryFees;
