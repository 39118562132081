import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Typography } from "@mui/material";
import defaultProfile from "./../../../../../src/assets/imges/default/defaultProfile.png";
import styled from "@emotion/styled";

const BoxContainer = styled(Box)({
  marginBottom: "10px !important",
});

const StyleLabel = styled(Typography)({
  fontWeight: 700,
});

const ProfileViewComponent = (props) => {
  const dispatch = useDispatch();
  const { profileDetails } = props;
  const details = profileDetails;
  const profileState = useSelector((state) => state.profile);
  const [profileImage, setProfileImage] = useState("");
  // const RoleName = details?.roles?.length !== 0 ? details?.roles[0]?.name: '' ;

  useEffect(() => {
    setProfileImage(profileState?.parameter?.data?.image_url);
  }, [profileState, profileImage]);

  return (
    <div>
      <Grid container>
        <Grid item xs={12} md={3}>
          <Box
            sx={{
              border: "1px solid black",
              width: "220px",
              height: "220px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <img
              src={profileImage ? profileImage : defaultProfile}
              style={{ width: "200px", height: "200px" }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <BoxContainer>
            <StyleLabel>Full Name</StyleLabel>
            <Typography sx={{ textTransform: "capitalize" }}>
              {details?.first_name} {details?.last_name}
            </Typography>
          </BoxContainer>
          <BoxContainer>
            <StyleLabel>Email</StyleLabel>
            <Typography>{details?.email}</Typography>
          </BoxContainer>
          <BoxContainer>
            <StyleLabel>Contact Number</StyleLabel>
            <Typography>{details?.contact_no}</Typography>
          </BoxContainer>
          {/* {RoleName && 
                    <BoxContainer>
                        <StyleLabel>Role</StyleLabel>
                        <Typography>{RoleName}</Typography>
                    </BoxContainer>
                    } */}
        </Grid>
        <Grid item xs={12} md={4}>
          <BoxContainer>
            <StyleLabel>Address</StyleLabel>
            {details?.address_line_1 && (
              <Typography>{details?.address_line_1}</Typography>
            )}
            {details?.address_line_2 && (
              <Typography>{details?.address_line_2}</Typography>
            )}
            {details?.postal_code && (
              <Typography>{details?.postal_code}</Typography>
            )}
            {details?.country?.name && (
              <Typography>{details?.country?.name}</Typography>
            )}
          </BoxContainer>
          {details?.organization && (
            <BoxContainer>
              <StyleLabel>Organization</StyleLabel>
              <Typography>{details?.organization?.name}</Typography>
            </BoxContainer>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default ProfileViewComponent;
