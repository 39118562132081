import React, { useCallback, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useStyles } from "../../../Theme/Style";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { InvoicesService } from "../../../services/api/invoices/invoices.service";
import { checkIsAdmin } from "../../../helpers/helpers";

const CustomGrid = ({
  rows,
  columns,
  perPageCount,
  totalRowCount,
  handlePageChange,
  pages,
  setPages,
  invoiceExport,
}) => {
  const classes = useStyles();

  // State to track selected columns
  const [selectedColumns, setSelectedColumns] = useState(
    columns.reduce((acc, column) => {
      acc[column.field] = true; // Initialize all columns as selected
      return acc;
    }, {})
  );

  // Handle column selection change
  const handleColumnChange = (column) => {
    setSelectedColumns({
      ...selectedColumns,
      [column.field]: !selectedColumns[column.field],
    });
  };

  // Filter columns based on selection
  const filteredColumns = columns.filter(
    (column) => selectedColumns[column.field]
  );

  const downloadInvoicesCsv = (csvContent, filename = "invoices.csv") => {
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const invoicesToCsv = (invoices) => {
    const items = invoices.map((item) => {
      const { invoice_courier_details, sales_order_items, ...rest } = item;
      return rest;
    });

    // invoices
    // .map((item) => {
    //   const salesOrderItems = item.sales_order_items.map((salesItem) => ({
    //     ...item,
    //     ...salesItem,
    //   }));
    //   return salesOrderItems;
    // })
    // .flat();

    const headers = Object.keys(items[0]);

    const csvRows = [];
    csvRows.push(headers.join(",")); // Header row

    items.forEach((item) => {
      const values = headers.map((header) => {
        const value = item[header];

        return typeof value === "string"
          ? value.replace(/,/g, " ")
          : typeof value === "number"
          ? value
          : value?.name || "";
      });
      csvRows.push(values.join(","));
    });

    return csvRows.join("\n");
  };

  const exportInvoices = useCallback(async () => {
    const invoicesService = new InvoicesService();
    const { data } = await invoicesService.getPaginatedInvoicesByStatus(
      "all",
      1,
      99999999
    );

    const csvContent = invoicesToCsv(data);
    downloadInvoicesCsv(csvContent, "invoices.csv");
  }, []);

  return (
    <>
      <FormGroup
        style={{
          padding: "15px",
          backgroundColor: "#f9f9f9",
          borderRadius: "4px",
        }}
        row
      >
        {columns.map((column) => (
          <FormControlLabel
            key={column.field}
            control={
              <Checkbox
                checked={selectedColumns[column.field] || false}
                onChange={() => handleColumnChange(column)}
              />
            }
            label={column.headerName}
          />
        ))}
      </FormGroup>

      <Stack direction="row" justifyContent={"end"} gap={2}>
        {checkIsAdmin() && invoiceExport && (
          <LoadingButton variant="outlined" onClick={exportInvoices}>
            Export All Invoices
          </LoadingButton>
        )}
        {setPages && (
          <FormControl
            sx={{
              width: "100px",
            }}
          >
            <InputLabel id="demo-simple-select-label">Page Size</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={pages}
              label="Page Size"
              onChange={(p) => setPages(p.target.value)}
            >
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </FormControl>
        )}
      </Stack>

      <DataGrid
        style={{
          borderLeft: "none",
          borderRight: "none",
          borderTop: "none",
          width: "100%",
        }}
        autoHeight={true}
        rows={rows}
        pageSize={perPageCount}
        pagination
        // columns={columns}
        columns={filteredColumns}
        rowCount={totalRowCount || 0}
        onPageChange={handlePageChange}
        checkboxSelection
        paginationMode="server"
        className={classes.dataGrid}
      />
    </>
  );
};

export default CustomGrid;
