import React from "react";
import { Grid, Typography, Container, Paper, Button, Stack } from "@mui/material";
import Intro from "../../../components/atoms/Intro/Intro.component";
import Content from "../../../components/layout/pageLayout/Content/Content.component";
import ParameterForm from "../../../components/admin/ProfileManagement/ProfileForm/ProfileForm.component";
import ProfileViewComponent from "../../../components/admin/ProfileManagement/ProfileForm/ProfileView.component";
import { getCurrentUser } from "../../../helpers";

const ProfileEdit = () => {
  const [profileDetails, setProfileDetails] =  React.useState({});
  const [editProfile, setEditProfile] = React.useState(false);

  const currentParameter = {
    id: 0,
    slug: "user-management",
    title: "Users",
    titleSingular: "User",
  };

  const breadcrumbs = [];

  React.useEffect(() => {
    const userDetails = getCurrentUser();
    setProfileDetails(userDetails);
  },[editProfile]);

  const handleProfileDetails = () => {
    setEditProfile(true);
  }

  return (
    <>
      <Intro
        pageTitle={`Profile`}
        pageTitleShort={`Profile`}
        breadcrumbs={breadcrumbs}
        additionalWidgets=""
      />

      <Content>
        <Container sx={{ ml: 0, px: "0 !important" }}>
          <Paper 
          elevation={2}
          sx={{
            width: "100%",
            p: {
              xs: 2,
              md: 4,
            },
          }}
          >
            <Grid item xs={12}>
              <Stack direction={'row'} justifyContent='space-between'>
                <Typography  variant="h3"
                  sx={{
                    fontSize: {
                      xs: "1.1rem",
                      md: "1.2rem",
                    },
                  }}>
                {editProfile ? 'Edit Profile':'Profile Details'}
                </Typography>
                {!editProfile &&
                <Button variant="contained" onClick={handleProfileDetails}>
                  Edit Profile
                </Button>
                }
              </Stack>
              <Grid container spacing="2">
                <Grid item xs={12}>
                  {!editProfile ? 
                    <ProfileViewComponent
                      profileDetails={profileDetails}
                    />
                    :
                    <ParameterForm
                      parameter={currentParameter}
                      mode="edit"
                      paramdata={profileDetails}
                      setEditProfile={setEditProfile}
                    />
                  }
                  
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </Content>
    </>
  );
};

export default ProfileEdit;
