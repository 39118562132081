import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import { Box, Stack, CircularProgress } from "@mui/material";
import { DataGrid  } from "@mui/x-data-grid";
import RowActions from "../../../common/ui/table/DataTable/RowActions/RowActions.component";
import ViewRecordDialog from "../../../common/ui/table/DataTable/ViewRecordDialog/ViewRecordDialog.component";

const RoleTable = (props) => {
  const navigate = useNavigate();
  const { isAdmin, roles, loading } = props;
  const [currentRecord, setCurrentRecord] = useState();
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const isEnabledView = true;
  const isEnabledEdit = true;
  const isEnabledDelete = false;

  const handleViewDialog = (response) => {
    setViewDialogOpen(response);
  };

  const columns = [
    { field: "id", headerName: "Role ID", width: 100, valueGetter: (params) => `${params.row?.id || ''}` },
    { field: "name", headerName: "Role Name", width: 700, valueGetter: (params) => `${params.row?.name || ''}` },
    {
      field: "row_actions",
      headerName: "Actions",
      width: 120,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const actionView = (e) => {
          params.row['roleType'] = params.row?.guard_name === 'api'?'External':'Internal';
          delete  params.row['guard_name'];
          setCurrentRecord(params.row);
          handleViewDialog(true);
        };

        const actionEdit = (e) => {
          setCurrentRecord(params.row);
          navigate(`./${params?.row?.id}/edit`, {
            state: {
              param: params.row,
              mode: "edit",
            },
          });
        };

        const actionDelete = (e) => {
          setCurrentRecord(params.row);
          //handleDeleteDialog(true);
        };

        const options = [
          {
            label: "View",
            action: actionView,
            active: isEnabledView,
          },
          {
            label: "Edit",
            action: actionEdit,
            active: isEnabledEdit,
          },
          {
            label: "Delete",
            action: actionDelete,
            active: isEnabledDelete,
          },
        ];

        return <RowActions row={params.row} options={options} />;
      },
    }
    // { field: "description", headerName: "Description", width: 150, flex: 1 },
    // { field: "count", headerName: "No. of users", width: 100 },
  ]; 

  const recordIdentifier = "id";

  return (
    <Box>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ pt: 3, pb: 6 }}
        >
          <CircularProgress />
        </Stack>
      )
      :(
      roles && 
      <DataGrid
        rows={roles || []}
        columns={columns}
        pageSize={5}
        autoHeight={true}
        rowsPerPageOptions={[5]}
        // checkboxSelection
      />
      )}
      <ViewRecordDialog
        record={currentRecord}
        viewDialogOpen={viewDialogOpen}
        handleViewDialog={handleViewDialog}
        columns={columns}
        recordIdentifier={recordIdentifier}
      />
    </Box>
  );
};

export default RoleTable;
