import React from "react";
import {
  Stack,
  CircularProgress,
  Skeleton,
  CardMedia,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const ProductImage = (props) => {
  const {
    setHover = () => {},
    minHeight = 250,
    padding = 0,
    maxWidth,
    shadow = true,
    image,
    thumbnail,
    loading,
  } = props;

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        minHeight: minHeight,
        boxShadow: shadow ? "0 0 7px 0px rgba(0,0,0,0.1)" : "none",
        borderRadius: 2,
        overflow: "hidden",
        position: "relative",
      }}
    >
      {loading && (
        <>
          <CircularProgress />
          <Skeleton
            variant="rectangular"
            sx={{
              height: "100%",
              display: "block",
              width: "100%",
              position: "absolute",
              borderRadius: 2,
            }}
          />
        </>
      )}

      {!loading && (
        <>
          {image || thumbnail !== null ? (
            <CardMedia
              component="img"
              height=""
              image={image ? image : thumbnail ? thumbnail : null}
              alt="product image"
              sx={{
                minHeight: image || thumbnail !== null ? minHeight : 200,
                padding: padding,
                maxWidth: maxWidth,
              }}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            />
          ) : (
            <Card elevation={0}>
              <CardContent>
                <Typography sx={{ fontSize: 16 }} color="text.secondary">
                  <InfoOutlinedIcon
                    sx={{
                      fontSize: 26,
                      mr: "10px",
                      position: "relative",
                      top: "7px",
                    }}
                  />
                  No Image Provided
                </Typography>
              </CardContent>
            </Card>
          )}
        </>
      )}
    </Stack>
  );
};

export default ProductImage;
