import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  Grid,
  Typography,
  Container,
  Box,
  Stack,
  Button,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
// import Intro from "../../../components/layout/pageLayout/Intro/Intro.component";
import Intro from "../../../components/atoms/Intro/Intro.component";
import Content from "../../../components/layout/pageLayout/Content/Content.component";
import BoxedContent from "../../../components/layout/pageLayout/BoxedContent/BoxedContent.component";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Inputs from "../../../Common/Inputs";
import { apiConstants } from "../../../constants";
import { authHeader } from "../../../helpers";
import { useFormik } from "formik";

const PageWidgets = (props) => {
  const { productionOrderId } = props;
  const navigate = useNavigate();

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={1}
      >
        <Button
          variant="contained"
          onClick={() =>
            navigate(`/admin/production-orders_item/${productionOrderId}/view`)
          }
          size="large"
        >
          View production order
        </Button>
      </Stack>
    </Box>
  );
};

const WebStoeAddItemSingleEdit = () => {
  //------------------------------
  const { id, item } = useParams();
  const [countries, setCountries] = useState("Test Text");
  const [ItemDetails, setItemDetails] = useState([]);

  console.log(id, item);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    fetch(`${apiConstants.API_URL}/store-items/${item}`, requestOptions).then(
      (response) => {
        response.text().then((text) => {
          const data = text && JSON.parse(text);
          console.log(data);
          setItemDetails(data?.data);
          const product = data?.data;
          formik.setFieldValue(
            "productionOrderId",
            product?.production_order?.id
          );
          formik.setFieldValue(
            "name",
            product?.production_order?.price?.item?.name
          );
          formik.setFieldValue(
            "pattern",
            product?.production_order?.pattern_id
          );
          formik.setFieldValue(
            "sport",
            product?.production_order?.price?.sport?.name
          );
          formik.setFieldValue("price", product?.unit_price);
          formik.setFieldValue("visibleOnStore", product?.is_active);
          formik.setFieldValue("storeItemId", product?.id);
          formik.setFieldValue("description", product?.description);
        });
      }
    );
  }, []);

  const currentParameter = {
    id: 0,
    slug: "user-management",
    title: "Users",
    titleSingular: "User",
  };

  const hadleSubmit = (values) => {
    console.log(formik.values);

    const requestOptions = {
      method: "PUT",
      headers: authHeader(),
      body: JSON.stringify({
        production_order_id: formik.values.productionOrderId,
        unit_price: formik.values.price,
        is_active: formik.values.visibleOnStore,
        gender: "male",
        description: formik.values.Description,
      }),
    };

    fetch(
      `${apiConstants.API_URL}/store-items/${formik.values.storeItemId}`,
      requestOptions
    ).then((response) => {
      response.text().then((text) => {
        const data = text && JSON.parse(text);
        console.log(data);
      });
    });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      pattern: "",
      sport: "",
      Category: "",
      price: "",
      visibleOnStore: false,
      description: "",
    },
  });

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      to="/admin/webstore-management/"
    >
      Store Management
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      to="/admin/webstore-management/"
    >
      Web Stores
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      to={`/admin/webstore-management/${id}`}
    >
      {id}
    </Link>,
    <Typography key="3">Darebin Men’s Polo</Typography>,
  ];

  return (
    <>
      <Intro
        pageTitle={`Darebin Men’s Polo`}
        pageTitleShort={`Darebin Men’s Polo`}
        breadcrumbs={breadcrumbs}
        additionalWidgets={
          <PageWidgets productionOrderId={formik.values.productionOrderId} />
        }
      />

      <Content>
        <Container sx={{ ml: 0, px: "0 !important" }}>
          <BoxedContent
            title={`View/Edit item details`}
            subtitle=""
            description=""
          >
            <Grid container spacing="2">
              <Grid item xs={12}>
                {/* <WebstoreItemForm
                  parameter={currentParameter}
                  mode="edit"
                  // productionOrderId={productionOrderId}
                /> */}
                <Stack
                  direction={"column"}
                  spacing={3}
                  sx={{ width: "90%", marginTop: "50px" }}
                >
                  <Stack direction={"row"} spacing={5} sx={{ gap: "50px" }}>
                    <TextField
                      id="standard-read-only-input"
                      label="Patter No"
                      name="pattern"
                      value={formik.values.pattern}
                      sx={{ width: "100%" }}
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="standard"
                    />
                    <TextField
                      id="standard-read-only-input"
                      label="Item Name"
                      name="name"
                      value={formik.values.name}
                      sx={{ width: "100%" }}
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="standard"
                    />
                  </Stack>
                  <Stack direction={"row"} spacing={5} sx={{ gap: "50px" }}>
                    <TextField
                      id="standard-read-only-input"
                      label="Sports Category"
                      name="sport"
                      value={formik.values.sport}
                      sx={{ width: "100%" }}
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="standard"
                    />
                    <TextField
                      id="standard-read-only-input"
                      label="Item Category"
                      name="Category"
                      value={countries}
                      sx={{ width: "100%" }}
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="standard"
                    />
                  </Stack>

                  <Stack
                    direction={"row"}
                    spacing={5}
                    sx={{ width: "100%", gap: "50px" }}
                  >
                    <Stack direction={"row"} spacing={1} sx={{ gap: "50px" }}>
                      <StyledContainer>
                        {/* <img src={drawer} width={"40px"} height={"40px"} alt={""} /> */}
                      </StyledContainer>
                      {/* <StyledImageContainer>

                        </StyledImageContainer> */}
                    </Stack>

                    <Stack
                      direction={"column"}
                      spacing={1}
                      sx={{ width: "100%", gap: "10px" }}
                    >
                      {/* <TextField
                        id="standard"
                        label="Description"
                        // InputProps={{
                        //   readOnly: true,
                        // }}
                        multiline
                        rows={4}
                        sx={{ width: "100%" , height: "100%"}}
                        variant="standard"
                        /> */}

                      <Inputs
                        variant="standard"
                        label="Description"
                        name="description"
                        sx={{ width: "100%", height: "100%" }}
                        multiline
                        rows={2}
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        // error={formik.errors.name}
                      />

                      <Inputs
                        variant="standard"
                        label="Item Price"
                        name="price"
                        sx={{ width: "100%" }}
                        value={formik.values.price}
                        onChange={formik.handleChange}
                        // error={formik.errors.name}
                      />

                      <FormControl fullWidth variant={"standard"}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={formik.values.visibleOnStore}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "visibleOnStore",
                                  e.target.checked
                                );
                              }}
                              id="visibleOnStore"
                              name="visibleOnStore"
                            />
                          }
                          label="Visible on Store"
                        />
                      </FormControl>
                    </Stack>
                  </Stack>
                </Stack>

                <Stack
                  direction={"row"}
                  sx={{ justifyContent: "space-between", marginTop: "30px" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      gap: "10px",
                    }}
                  >
                    <Button variant="contained">BACK TO STORE</Button>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      gap: "10px",
                    }}
                  >
                    <StyledButton
                      variant="contained"
                      onClick={(values) => {
                        hadleSubmit(values);
                      }}
                    >
                      SAVE ITEM
                    </StyledButton>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </BoxedContent>
        </Container>
      </Content>
    </>
  );
};

export default WebStoeAddItemSingleEdit;

const useStyles = makeStyles({
  orderInformationLabel: {
    marginTop: "12px !important",
    fontSize: "12px",
    color: "rgba(0, 0, 0, 0.89)",
  },
  orderInformationValue: {
    marginBottom: "30px !important",
    fontSize: 14,
  },
});

const StyledContainer = styled(Stack)({
  background: "rgba(239, 239, 239, 0.65)",
  border: "2px dashed #9E9E9E",
  borderRadius: "8px",
  width: "300px",
  height: "auto",
  margin: "20px 80px",
});

const StyledImageContainer = styled(Stack)({
  background: "rgba(239, 239, 239, 0.65)",
  border: "2px dashed #9E9E9E",
  borderRadius: "8px",
  width: "200px",
  height: "auto",
});

const StyledButton = styled(Button)({
  color: "white",
  backgroundColor: "green",
});
