import React, { useState } from "react";
import {
  Divider,
  Button,
  TextField,
  Grid,
  Stack,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import styles from "./../ParameterForm.module.scss";

const QuantityRanges = (props) => {
  const { title, titleSingle, mode, handleSuccessDialog } = props;
  const debug = false;
  const [submitting, setSubmitting] = useState(false);
  const [toggle, setToggle] = useState(true);
  const successMsg = "";

  const validationSchema = Yup.object({
    minQty: Yup.number()
      .typeError("Invalid value. Only numbers allowed")
      .positive("Must be a positive number")
      .required("Minimum Quntity is required"),
    maxQty: Yup.number()
      .typeError("Invalid value. Only numbers allowed")
      .positive("Must be a positive number")
      .required("Maximum Quantity is required"),
    status: Yup.boolean().nullable(),
  });

  const initialValues = {
    minQty: "",
    maxQty: "",
    status: true,
  };

  const handleToggle = (e) => {
    setToggle(e.target.checked);
  };

  const handleSubmit = (values) => {
    console.log(values);
    handleSuccessDialog(successMsg);
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values);
          resetForm();
        }}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          isValid,
          setFieldValue,
        }) => (
          <Form noValidate autoComplete="off">
            <Grid container spacing={{ xs: 2, sm: 2 }} sx={{ pt: 0, pb: 0 }}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="minQty"
                  name="minQty"
                  label="Minimum Quntity"
                  value={values.minQty}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.minQty && Boolean(errors.minQty)}
                  helperText={touched.minQty && errors.minQty}
                  variant="outlined"
                  className={styles.textField}
                  size="small"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="maxQty"
                  name="maxQty"
                  label="Maximum Quantity"
                  value={values.maxQty}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.maxQty && Boolean(errors.maxQty)}
                  helperText={touched.maxQty && errors.maxQty}
                  variant="outlined"
                  className={styles.textField}
                  size="small"
                />
              </Grid>

              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={toggle}
                        onChange={(e) => {
                          handleToggle(e);
                          setFieldValue("status", e.target.checked);
                        }}
                        id="status"
                        name="status"
                      />
                    }
                    label="Active"
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <Stack
                  direction="column-reverse"
                  justifyContent="space-between"
                  align-items="center"
                  sx={{
                    flexDirection: {
                      sm: "row",
                    },
                    mt: 2,
                  }}
                >
                  <Link to="../" style={{ display: "block" }}>
                    <Button
                      size="medium"
                      startIcon={<ArrowBackIcon size="small" />}
                      sx={{ textTransform: "none" }}
                    >
                      Back to {title}
                    </Button>
                  </Link>

                  <span style={{ flex: 1 }}>&nbsp;</span>

                  <LoadingButton
                    color="secondary"
                    variant="contained"
                    fullWidth
                    type="submit"
                    size="large"
                    loading={submitting}
                    // loadingPosition="end"
                    sx={{
                      width: {
                        xs: "100%",
                        md: "auto",
                      },
                    }}
                  >
                    {`Save ${titleSingle}`}
                  </LoadingButton>
                </Stack>
              </Grid>
            </Grid>

            {debug && (
              <>
                <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                <pre style={{ textAlign: "left" }}>
                  <strong>Values</strong>
                  <br />
                  {JSON.stringify(values, null, 2)}
                </pre>
                <pre style={{ textAlign: "left" }}>
                  <strong>Errors</strong>
                  <br />
                  {JSON.stringify(errors, null, 2)}
                </pre>
              </>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default QuantityRanges;
