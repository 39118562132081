import { cartConstants } from "../../constants";

const initialState = {
  loading: false,
  items: [],
  current: null,
  error: null,
};

export function cartItems(state = initialState, action) {
  switch (action.type) {
    // get all
    // case cartConstants.GET_ALL_REQUEST:
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    // case cartConstants.GET_ALL_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     items: action.items,
    //     current: null,
    //     error: null,
    //   };
    // case cartConstants.GET_ALL_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.error,
    //   };

    // // get single
    // case cartConstants.GET_SINGLE_REQUEST:
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    // case cartConstants.GET_SINGLE_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     current: action.parameter,
    //     error: null,
    //   };
    // case cartConstants.GET_SINGLE_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.error,
    //   };

    // add
    case cartConstants.ADD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case cartConstants.ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        items: [...state.items, action.parameter.item],
        current: action.parameter.item,
        error: null,
      };
    case cartConstants.ADD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    // update
    case cartConstants.UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case cartConstants.UPDATE_SUCCESS:
      const updatedItemIndex = state.items.findIndex(
        (item) => item.id === action.parameter.item.id
      );
      const changedItems =
        updatedItemIndex !== -1
          ? [
              ...state.items.slice(0, updatedItemIndex),
              action.parameter.item,
              ...state.items.slice(updatedItemIndex + 1),
            ]
          : [...state.items, action.parameter.item];

      return {
        ...state,
        loading: false,
        items: changedItems,
        current: action.parameter.item,
        error: null,
      };
    case cartConstants.UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    // delete
    case cartConstants.DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case cartConstants.DELETE_SUCCESS:
      const updatedItems = state.items.filter(
        (item) => item.id !== action.parameter.item.id
      );
      return {
        ...state,
        loading: false,
        items: updatedItems,
        current: null,
        error: null,
      };
    case cartConstants.DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    // clear
    case cartConstants.CLEAR:
      return {};

    default:
      return state;
  }
}
