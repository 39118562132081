import { roleConstants } from "../constants";

export function roles(state = {}, action) {
    switch (action.type) {
         // get all
      case roleConstants.GETALL_INTERNAL_REQUEST:
        return {
          loading: true,
        };
      case roleConstants.GETALL_INTERNAL_SUCCESS:
        return {
          items:{
            interItems: action.interRoles,
          }
        };
      case roleConstants.GETALL_INTERNAL_FAILURE:
        return {
          error: action.error,
        };

      case roleConstants.GETALL_EXTERNAl_REQUEST:
        return {
            loading: true,
        };
      case roleConstants.GETALL_EXTERNAl_SUCCESS:
        return {
          items:{
            exterItems: action.exterRoles,
          }
        };
      case roleConstants.GETALL_EXTERNAl_FAILURE:
        return {
            error: action.error,
        };

      // get single
      case roleConstants.GET_SINGLE_REQUEST:
        return {
          loading: true,
        };
      case roleConstants.GET_SINGLE_SUCCESS:
        return {
          parameter: action.parameter,
        };
      case roleConstants.GET_SINGLE_FAILURE:
        return {
          error: action.error,
        };
        
      // add
      case roleConstants.ADD_REQUEST:
        return {
          loading: true,
        };
      case roleConstants.ADD_SUCCESS:
        return {
          parameter: action.parameter,
        };
      case roleConstants.ADD_FAILURE:
        return {
          error: action.error,
        };

      // update
      case roleConstants.UPDATE_REQUEST:
        return {
          loading: true,
        };
      case roleConstants.UPDATE_SUCCESS:
        return {
          parameter: action.parameter,
        };
      case roleConstants.UPDATE_FAILURE:
        return {
          error: action.error,
        };
        
      default:
        return state;
    }
}