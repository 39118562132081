import React from "react";
import { Link } from "react-router-dom";
import { Grid, Typography, Container } from "@mui/material";
import Intro from "../../../components/atoms/Intro/Intro.component";
import Content from "../../../components/layout/pageLayout/Content/Content.component";
import BoxedContent from "../../../components/layout/pageLayout/BoxedContent/BoxedContent.component";
import RoleForm from "../../../components/admin/RoleManagement/RoleForm/RoleForm.component";

const RoleAdd = () => {
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to="/admin/user-management/roles">
      Role Management
    </Link>,

    <Typography key="3">Add New Role</Typography>,
  ];

  return (
    <>
      <Intro
        pageTitle={`Add New Role`}
        pageTitleShort={`Add New Role`}
        breadcrumbs={breadcrumbs}
        additionalWidgets=""
      />

      <Content>
        <Container sx={{ ml: 0, px: "0 !important" }}>
          <BoxedContent title={`Enter New Role`} subtitle="" description="">
            <Grid container spacing="2">
              <Grid item xs={12}>
                <RoleForm mode="add"/>
              </Grid>
            </Grid>
          </BoxedContent>
        </Container>
      </Content>
    </>
  );
};

export default RoleAdd;
