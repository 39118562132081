import * as React from "react";
import { useStyles } from "../../../Theme/Style";
import { styled } from "@mui/material/styles";
import { Box, Paper, CircularProgress } from "@mui/material";
import { apiConstants } from "../../../constants";
import { authHeader } from "../../../helpers";
import { useNavigate } from "react-router-dom";
import SalesOrdersCustomerDetails from "./SalesOrdersCustomerDetails";

const Content = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  textAlign: "left",
  padding: "29px 37px",
  color: theme.palette.text.secondary,
}));

const SalesOrderCustomerContainer = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [allSalesOrders, setAllSalesOrders] = React.useState([]);
  const [pendingSalesOrders, setPendingSalesOrders] = React.useState([]);
  const [approvedSalesOrders, setApprovedSalesOrders] = React.useState([]);
  const [paidSalesOrders, setPaidSalesOrders] = React.useState([]);
  const [loadingComponent, setLoadingComponent] = React.useState(false);
  const [paginationData, setPaginationData] = React.useState({});
  const [status, setStatus] = React.useState("all");

  React.useEffect(() => {
    setLoadingComponent(true);
    getSalesOrderByPage("1");
  }, []);

  const getSalesOrderByPage = (page) => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    fetch(
      `${apiConstants.API_URL}/customer/sales-orders?page=${page}`,
      requestOptions
    ).then((response) => {
      response.text().then((text) => {
        const data = text && JSON.parse(text);
        setPaginationData(data.meta);
        setAllSalesOrders(data?.data);
        setLoadingComponent(false);
      });
    });
  };

  const handleSalesOrderTab = (type) => {
    if (type !== "all") {
      getSalesOrderStatusDataByPage(type, "1");
    } else {
      getSalesOrderByPage("1");
    }
  };

  const getSalesOrderStatusDataByPage = (type, page) => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    if (type !== "all") {
      fetch(
        `${apiConstants.API_URL}/customer/sales-orders/status/${type}?page=${page}`,
        requestOptions
      ).then((response) => {
        response.text().then((text) => {
          const data = text && JSON.parse(text);
          if (type === "pending") {
            setPendingSalesOrders(data?.data);
            setPaginationData(data?.meta);
          } else if (type === "approved") {
            setApprovedSalesOrders(data?.data);
            setPaginationData(data?.meta);
          }
        });
      });
    }
  };

  const handlePageChange = (newPage) => {
    console.log(status);
    if (status === "pending" || status === "approved") {
      getSalesOrderStatusDataByPage(status, newPage + 1);
    } else {
      getSalesOrderByPage(newPage + 1);
    }
  };

  const handleNewSalesOrder = () => {
    navigate("/admin/sales-order");
  };

  return (
    <Box sx={{ flexGrow: 1, padding: "20px 20px" }}>
      <Box className={classes.headerContainer}>
        <Box>
          <Box className={classes.headerContainerMainTitle}>
            All Sales Orders
          </Box>
          <Box className={classes.headerContainerSubTitle}>
            All Sales Orders
          </Box>
        </Box>
      </Box>
      <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
        <Box gridColumn="span 12">
          <Content>
            {loadingComponent ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "400px",
                }}
              >
                <CircularProgress size={50} />
              </Box>
            ) : (
              <SalesOrdersCustomerDetails
                salesOrders={allSalesOrders}
                pendingSalesOrders={pendingSalesOrders}
                approvedSalesOrders={approvedSalesOrders}
                paidSalesOrders={paidSalesOrders}
                handleSalesOrderTab={handleSalesOrderTab}
                paginationData={paginationData}
                handlePageChange={handlePageChange}
                setStatus={setStatus}
              />
            )}
          </Content>
        </Box>
      </Box>
    </Box>
  );
};
export default SalesOrderCustomerContainer;
