import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { sizeChartActions } from "../../../../../../actions";
import DataTable from "../../../../../common/ui/table/DataTable/DataTable.component";

const SizingCharts = () => {
  const dispatch = useDispatch();
  const sizeCharts = useSelector((state) => state.sizeCharts);

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "name",
      headerName: "Name",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "pattern_id",
      headerName: "Pattern ID",
      minWidth: 70,
      flex: 1,
    },
    {
      field: "measurement_id",
      headerName: "Measurement ID",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "size_id",
      headerName: "Size ID",
      minWidth: 70,
      flex: 1,
    },
    {
      field: "value",
      headerName: "Value",
      minWidth: 80,
      flex: 1,
    },
    {
      field: "created_at",
      headerName: "Created At",
      width: 150,
      hide: true,
    },
    {
      field: "updated_at",
      headerName: "Updated At",
      width: 150,
      hide: true,
    },
  ];

  const recordIdentifier = "name";

  useEffect(() => {
    dispatch(sizeChartActions.getAllParameters());
  }, [dispatch]);

  return (
    <Box>
      {sizeCharts && (
        <DataTable
          loading={sizeCharts?.loading}
          rows={sizeCharts?.items?.data}
          columns={columns}
          recordIdentifier={recordIdentifier}
          actionIdentifier="sizeChartActions"
        />
      )}
    </Box>
  );
};

export default SizingCharts;
