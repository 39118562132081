import React, { useState, useEffect } from "react";
import {
  Paper,
  Typography,
  Divider,
  Grid,
  Switch,
  FormControlLabel,
  Stack,
  Button,
  FormGroup,
  FormControl,
  Box,
  TextField,
  Radio,
  RadioGroup,
  FormLabel,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { apiConstants } from "../../../../constants";
import { authHeader } from "../../../../helpers";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import styles from "./WebStoreItemForm.module.scss";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const WebStoreItemForm = (props) => {
  const { parameter, handleRefetch } = props;
  // console.log(
  //   "🚀 ~ file: WebStoreItemForm.component.js:37 ~ WebStoreItemForm ~ parameter:",
  //   parameter
  // );
  const debug = false;

  const navigate = useNavigate();

  const [submitting, setSubmitting] = useState(false);
  const [active, setActive] = useState(false);
  const [official, setOfficial] = useState(false);
  const [open, setOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState({
    title: "",
    content: "",
  });
  const poID = parameter?.activeStoreItem
    ? parameter?.storeItem?.production_order_id
    : parameter?.productionOrderId;

  // initial values
  const initialValues = {
    patternNo: parameter?.patternNo,
    itemName: parameter?.itemName,
    sportsCategory: parameter?.sportCategory,
    description: parameter?.activeStoreItem
      ? parameter?.storeItem?.description
      : parameter?.description,
    isActive: parameter?.storeItem?.is_active === 1 ? true : false,
    isOfficial: parameter?.storeItem?.is_official,
    gender: parameter?.storeItem?.gender,
    productionOrderId: parameter?.isMigrated ? parameter?.item?.id : poID,
    organizationId: parameter?.storeItem?.organization_id,
    style: parameter?.style,
    itemPrice: parameter?.activeStoreItem
      ? parameter?.storeItem?.unit_price
      : parameter?.itemPrice,
    adultPrice: parameter?.activeStoreItem
      ? parameter?.storeItem?.adult_price
      : parameter?.itemPrice,
    kidsPrice: parameter?.activeStoreItem
      ? parameter?.storeItem?.kids_price
      : parameter?.itemPrice,
    factoryPrice: parameter?.activeStoreItem
      ? parameter?.storeItem?.factory_price
      : parameter?.itemPrice,
    shippingPrice: parameter?.activeStoreItem
      ? parameter?.storeItem?.shipping_price
      : parameter?.itemPrice,

    // itemCategory: "",
  };

  // validations
  const validationSchema = Yup.object({
    patternNo: Yup.string(),
    itemName: Yup.string().required("Item Name is required"),
    sportsCategory: Yup.string().required("Sports Category is required"),
    description: Yup.string(),
    itemPrice: Yup.number().required("Item Price is required"),
    factoryPrice: Yup.number(),
    isActive: Yup.boolean().nullable(),
    isOfficial: Yup.boolean().nullable(),
    gender: Yup.string().required("Gender is required"),
    style: Yup.string(),
    // itemCategory: Yup.string().required("Item Category is required"),
    // status: Yup.boolean().nullable(),
  });

  // set switch state
  useEffect(() => {
    const updateSwitches = () => {
      setActive(parameter?.storeItem?.is_active === 1 ? true : false);
      setOfficial(parameter?.storeItem?.is_official === 1 ? true : false);
    };

    updateSwitches();
  }, []);

  // handle active toggle
  const handleAtive = (e) => {
    setActive(e.target.checked);
  };

  // handle official toggle
  const handleOfficial = (e) => {
    setOfficial(e.target.checked);
  };

  // handle form submit
  const handleSubmit = (values) => {
    parameter?.activeStoreItem
      ? UpdateStoreItem(values)
      : AddNewStoreItem(values);
    // handleSuccessDialog(successMsg);
  };

  // handle new item add
  const AddNewStoreItem = (values) => {
    setSubmitting(true);

    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify({
        is_active: values.isActive,
        is_official: values.isOfficial,
        production_order_id: values.productionOrderId,
        description: values.description,
        gender: values.gender,
        unit_price: values.itemPrice,
        adult_price: values.adultPrice || values.itemPrice,
        kids_price: values.kidsPrice || values.itemPrice,
        factory_price: values.factoryPrice || values.itemPrice,
        shipping_price: values.shippingPrice || values.itemPrice,
      }),
    };

    fetch(`${apiConstants.API_URL}/store-items`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);

        setSubmitting(false);
        setDialogContent({
          title: `Success!`,
          content: `${parameter?.itemName} successfully saved as a store items`,
        });
        setOpen(true);
      })
      .catch((error) => {
        console.error("Error:", error);

        setSubmitting(false);
      });
  };

  // handle update existing item
  const UpdateStoreItem = (values) => {
    setSubmitting(true);

    const requestOptions = {
      method: "PUT",
      headers: authHeader(),
      body: JSON.stringify({
        is_active: values.isActive,
        is_official: values.isOfficial,
        production_order_id: values.productionOrderId,
        description: values.description,
        gender: values.gender,
        unit_price: values.itemPrice,
        adult_price: values.adultPrice || values.itemPrice,
        kids_price: values.kidsPrice || values.itemPrice,
        factory_price: values.factoryPrice || values.itemPrice,
        shipping_price: values.shippingPrice || values.itemPrice,
      }),
    };

    fetch(
      `${apiConstants.API_URL}/store-items/${parameter?.storeItem?.id}`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);

        setSubmitting(false);
        setDialogContent({
          title: `Success!`,
          content: `${parameter?.itemName} details updated successfully`,
        });
        setOpen(true);
      })
      .catch((error) => {
        console.error("Error:", error);

        setSubmitting(false);
      });
  };

  // handle dialog close
  const handleClose = () => {
    setOpen(false);
    handleRefetch();
    // navigate(-1);
  };

  return (
    <div>
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values);
            resetForm();
          }}
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            isValid,
            setFieldValue,
          }) => (
            <Form noValidate autoComplete="off">
              <Grid container spacing={{ xs: 2, sm: 4 }} sx={{ pt: 0, pb: 0 }}>
                <Grid item lg={4}>
                  <Box sx={{ pr: 3, height: "100%" }}>
                    <Paper elevation={3} sx={{ py: 1, px: 1, height: "100%" }}>
                      <img
                        src={parameter?.itemImage}
                        alt={parameter?.itemName}
                        style={{
                          width: "100%",
                          display: "block",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </Paper>
                  </Box>
                </Grid>

                <Grid item lg={8}>
                  <Grid
                    container
                    spacing={{ xs: 2, sm: 4 }}
                    sx={{ pt: 0, pb: 0 }}
                  >
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        id="itemName"
                        name="itemName"
                        label="Item Name"
                        value={values.itemName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.itemName && Boolean(errors.itemName)}
                        helperText={touched.itemName && errors.itemName}
                        variant="standard"
                        className={styles.textField}
                        size="small"
                        disabled
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        id="style"
                        name="style"
                        label="Style"
                        value={values.style}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.style && Boolean(errors.style)}
                        helperText={touched.style && errors.style}
                        variant="standard"
                        className={styles.textField}
                        size="small"
                        disabled
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        id="patternNo"
                        name="patternNo"
                        label="Pattern Number"
                        value={values.patternNo}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.patternNo && Boolean(errors.patternNo)}
                        helperText={touched.patternNo && errors.patternNo}
                        variant="standard"
                        className={styles.textField}
                        size="small"
                        disabled
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        id="sportsCategory"
                        name="sportsCategory"
                        label="Sports Category"
                        value={values.sportsCategory}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.sportsCategory &&
                          Boolean(errors.sportsCategory)
                        }
                        helperText={
                          touched.sportsCategory && errors.sportsCategory
                        }
                        variant="standard"
                        className={styles.textField}
                        size="small"
                        disabled
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl>
                        <FormLabel className={styles.radioLabel}>
                          Gender
                        </FormLabel>
                        <RadioGroup
                          id="gender"
                          name="gender"
                          label="Gender"
                          value={values.gender}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.gender && Boolean(errors.gender)}
                          // helperText={touched.gender && errors.gender}
                          variant="standard"
                          className={styles.radio}
                          size="small"
                          row
                        >
                          <FormControlLabel
                            value="male"
                            control={<Radio />}
                            label="Male"
                          />
                          <FormControlLabel
                            value="female"
                            control={<Radio />}
                            label="Female"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        id="description"
                        name="description"
                        label="Description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.description && Boolean(errors.description)
                        }
                        helperText={touched.description && errors.description}
                        variant="standard"
                        className={styles.textField}
                        size="small"
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        id="itemPrice"
                        name="itemPrice"
                        label="Unit Price"
                        value={values.itemPrice}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.itemPrice && Boolean(errors.itemPrice)}
                        helperText={touched.itemPrice && errors.itemPrice}
                        variant="standard"
                        className={styles.textField}
                        size="small"
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        id="factoryPrice"
                        name="factoryPrice"
                        label="Factory Price"
                        value={values.factoryPrice}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.factoryPrice && Boolean(errors.factoryPrice)
                        }
                        helperText={touched.factoryPrice && errors.factoryPrice}
                        variant="standard"
                        className={styles.textField}
                        size="small"
                        disabled
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <FormGroup>
                        <FormLabel className={styles.switchLabel}>
                          Item Visibility
                        </FormLabel>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={active}
                              onChange={(e) => {
                                handleAtive(e);
                                setFieldValue("isActive", e.target.checked);
                              }}
                              id="isActive"
                              name="isActive"
                            />
                          }
                          label="Visible on Store"
                        />
                      </FormGroup>
                    </Grid>

                    <Grid item xs={6}>
                      <FormGroup>
                        <FormLabel className={styles.switchLabel}>
                          Is Official?
                        </FormLabel>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={official}
                              onChange={(e) => {
                                handleOfficial(e);
                                setFieldValue(
                                  "isOfficial",
                                  e.target.checked ? 1 : 0
                                );
                              }}
                              id="isOfficial"
                              name="isOfficial"
                            />
                          }
                          label="This is an official item"
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                </Grid>

                {/* <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    id="itemCategory"
                    name="itemCategory"
                    label="Item Category"
                    value={values.itemCategory}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.itemCategory && Boolean(errors.itemCategory)}
                    helperText={touched.itemCategory && errors.itemCategory}
                    variant="outlined"
                    className={styles.textField}
                    size="small"
                  />
                </Grid> */}

                <Grid item xs={12}>
                  <Stack
                    direction="column-reverse"
                    justifyContent="space-between"
                    align-items="center"
                    sx={{
                      flexDirection: {
                        sm: "row",
                      },
                      mt: 2,
                    }}
                  >
                    <Button
                      size="medium"
                      startIcon={<ArrowBackIcon size="small" />}
                      sx={{ textTransform: "none" }}
                      onClick={() => navigate(-1)}
                    >
                      Back to Store
                    </Button>

                    <span style={{ flex: 1 }}>&nbsp;</span>

                    <LoadingButton
                      color="secondary"
                      variant="contained"
                      fullWidth
                      type="submit"
                      size="large"
                      loading={submitting}
                      // loadingPosition="end"
                      sx={{
                        width: {
                          xs: "100%",
                          md: "auto",
                        },
                      }}
                    >
                      {`Save Item Details`}
                    </LoadingButton>
                  </Stack>
                </Grid>
              </Grid>

              {debug && (
                <>
                  <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                  <pre style={{ textAlign: "left" }}>
                    <strong>Values</strong>
                    <br />
                    {JSON.stringify(values, null, 2)}
                  </pre>
                  <pre style={{ textAlign: "left" }}>
                    <strong>Errors</strong>
                    <br />
                    {JSON.stringify(errors, null, 2)}
                  </pre>
                </>
              )}
            </Form>
          )}
        </Formik>
      </div>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <CheckCircleIcon
            color="success"
            sx={{
              position: "relative",
              top: "0.25rem",
              mr: 1,
              display: { xs: "block", sm: "inline-block" },
              margin: { xs: "0 0 1rem 0", sm: "0 0.6rem 0 0" },
            }}
          />
          {dialogContent.title}
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogContent.content}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default WebStoreItemForm;
