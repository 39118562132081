import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link, Typography } from "@mui/material";
import { authHeader } from "../../../helpers";
import { apiConstants } from "../../../constants";
import { Grid } from "@mui/material";
import { getFabrics, getTrims, getLabels } from "../../../helpers";
import Intro from "../../../components/atoms/Intro/Intro.component";
import Content from "../../../components/layout/pageLayout/Content/Content.component";
import ProductImage from "../../../components/admin/ProductionOrders/ProductImage/ProductImage.component";
import ProductionData from "../../../components/admin/ProductionOrders/ProductionData/ProductionData.component";

const ViewProductionOrder = () => {
  // get production order id
  const { id } = useParams();

  // loading state
  const [isLoading, setIsLoading] = useState(false);
  const [productionOrder, setProductionOrder] = useState({});
  const [fabrics, setFabrics] = useState([]);
  const [trims, setTrims] = useState([]);
  const [labels, setLabels] = useState([]);
  const [instructions, setInstructions] = useState("");

  // get production order details
  useEffect(() => {
    const getProductionOrder = () => {
      setIsLoading(true);

      const requestOptions = {
        method: "GET",
        headers: authHeader(),
      };

      fetch(`${apiConstants.API_URL}/production-orders/${id}`, requestOptions)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.text();
        })
        .then((text) => {
          const data = text && JSON.parse(text);
          setProductionOrder(data?.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Fetch error:", error);
          setIsLoading(false);
        });
    };

    getProductionOrder();
  }, [id]);

  // get details
  useEffect(() => {
    // fabrics
    const loadFabrics = () => {
      const fabricData = getFabrics(
        productionOrder?.is_old_po,
        productionOrder?.po_fields
      );
      setFabrics(fabricData);
    };

    // trims
    const loadTrims = () => {
      const trimData = getTrims(
        productionOrder?.is_old_po,
        productionOrder?.po_fields
      );
      setTrims(trimData);
    };

    // labels
    const loadLabels = () => {
      const labelData = getLabels(productionOrder?.labels);
      setLabels(labelData);
    };

    // instructions
    const loadInstructions = () => {
      const insData =
        productionOrder?.special_instructions !== ""
          ? productionOrder?.special_instructions
          : "No special instructions";
      setInstructions(insData);
    };

    loadFabrics();
    loadTrims();
    loadLabels();
    loadInstructions();
  }, [productionOrder]);

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="/admin/production-orders/"
    >
      Production Orders
    </Link>,
    <Typography key="2">Production Order #{id}</Typography>,
  ];

  return (
    <>
      <Intro
        pageTitle={`Production Order #${id}`}
        pageTitleShort="Production Order"
        breadcrumbs={breadcrumbs}
        additionalWidgets=""
      />

      <Content>
        <Grid container spacing={4}>
          {productionOrder && (
            <Grid item xs={12}>
              <ProductImage
                loading={isLoading}
                image={productionOrder?.image_url}
                thumbnail={productionOrder?.thumbnail_url}
                padding={4}
                minHeight={350}
              />
            </Grid>
          )}

          {fabrics && (
            <Grid item xs={12}>
              <ProductionData
                type="fabric"
                loading={isLoading}
                data={fabrics}
              />
            </Grid>
          )}

          {trims && (
            <Grid item xs={12}>
              <ProductionData type="trim" loading={isLoading} data={trims} />
            </Grid>
          )}

          {labels && (
            <Grid item xs={12}>
              <ProductionData type="label" loading={isLoading} data={labels} />
            </Grid>
          )}

          {instructions && (
            <Grid item xs={12}>
              <ProductionData
                type="instructions"
                loading={isLoading}
                data={instructions}
              />
            </Grid>
          )}

          {/* 
          // TODO: After migrating specification data
          <Grid item xs={12}>
            Specifications
          </Grid> */}

          {/* 
          // TODO: After migrating embroidory data
          <Grid item xs={12}>
            Embroidary
          </Grid> 
          */}
        </Grid>
      </Content>
    </>
  );
};

export default ViewProductionOrder;
