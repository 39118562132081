import { getAxiosInstance } from "../axios";

export class PriceBookService {
  async getSportsCategories() {
    try {
      const response = await getAxiosInstance().get("/sports");
      return response.data  ;
    } catch (error) {}
  }

  async getItems() {
    try {
      const response = await getAxiosInstance().get("/items");
      return response.data;
    } catch (error) {}
  }

  async getLinings() {
    try {
      const response = await getAxiosInstance().get("/linings");
      return response.data;
    } catch (error) {}
  }

  async getSpartsItemById (id) {
    try {
      const response = await getAxiosInstance().get(`/sports/${id}`);
      return response.data;
    } catch (error) {}
  }

  async getSportById(id) {
    try {
      const response = await getAxiosInstance().get(`/pricebook/sport/${id}`);
      return response.data;
    } catch (error) {}
  }

  async createPricebookEntry(requestData) {
    try {
      const response = await getAxiosInstance().post("/prices", requestData);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async updatePricebookEntry(id, requestData) {
    try {
      const response = await getAxiosInstance().put(`/prices/${id}`, requestData);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
