import { Visibility, VisibilityOff } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { apiConstants } from "../../../../constants";
import { authHeader } from "../../../../helpers";
import {
  countryActions,
  organizationActions,
  paymentTermActions,
  userActions,
} from "./../../../../actions";
import styles from "./FormDetail.module.scss";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const FormDetail = (props) => {
  const {
    title,
    titleSingle,
    mode,
    paramdata,
    handleSuccessDialog,
    handleErrorAlert,
  } = props;
  console.log("propssssssss", props);
  const dispatch = useDispatch();
  const organizationState = useSelector((state) => state.organizations);
  const paymentTermState = useSelector((state) => state.paymentTerms);
  const countryState = useSelector((state) => state.countries);
  const userState = useSelector((state) => state.users);
  const alertState = useSelector((state) => state.alert);
  const formikRef = React.createRef();

  const debug = false;
  // const [submitting, setSubmitting] = useState(false);
  const [useSameBillingAddr, setUseSameBillingAddr] = useState(true);
  const [useSameBillingAddrForUser, setUseSameBillingAddrForUser] =
    useState(true);
  const [poRequired, setPoRequired] = useState(false);
  const [orgStatus, setOrgStatus] = useState(true);
  const [gstEnabled, setGstEnabled] = useState(true);
  const [salesAgents, setSalesAgents] = useState([]);
  const successMsg = "";
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [createStore, setCreateStore] = useState(true);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [content, setContent] = useState();
  const [alertType, setAlertType] = useState();

  const validationSchema = Yup.object({
    name: Yup.string().required("Organization Name is required"),
    email: Yup.string().email().required("Email is required"),
    contact_person_name: Yup.string().required(
      "Contact Person Name is required"
    ),
    sales_rep_id: Yup.string().required("Sales rep ID is required"),
    address_line_1: Yup.string().required("Address Line 1 is required"),
    address_line_2: Yup.string().nullable(),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State/Province is required"),
    postal_code: Yup.string().required("Postal Code is required"),
    use_same_billing_address: Yup.boolean().nullable(),
    purchase_order_required: Yup.boolean().nullable(),
    gst_enabled: Yup.boolean().nullable(),
    country_id: Yup.string().required("Country is required"),
    payment_term_id: Yup.string().required("Payment Term is required"),
    status: Yup.boolean().nullable(),
    contact_no: Yup.string()
      .required("Phone Number is required")
      .min(10)
      .max(12),

    delivery_name: Yup.string().required("Delivery Name is required"),
    delivery_email: Yup.string().email().required("Delivery Email is required"),
    delivery_address_line_1: Yup.string().required(
      "Delivery Address Line 1 is required"
    ),
    delivery_address_line_2: Yup.string().nullable(),
    delivery_postal_code: Yup.string().required(
      "Delivery Postal Code is required"
    ),
    delivery_city: Yup.string().required("Delivery City is required"),
    delivery_state: Yup.string().required(
      "Delivery State/Province is required"
    ),
    delivery_country_id: Yup.string().required("Delivery Country is required"),
    delivery_contact_no: Yup.string()
      .required("Delivery Phone Number is required")
      .min(10)
      .max(12),
    delivery_contact_person_name: Yup.string(
      "Delivery Contact Person is required"
    ),
    user_first_name: Yup.string().required("First Name is required"),
    user_last_name: Yup.string().required("Last Name is required"),
    user_code: Yup.string(),
    user_contact_no: Yup.string()
      .required("Contact Number is required")
      .min(10)
      .max(12),
    user_address_line_1: Yup.string().required("Address Line 1 is required"),
    user_address_line_2: Yup.string().nullable(),
    user_postal_code: Yup.string().required("Postal Code is required"),
    user_country_id: Yup.string().required("Country is required"),
    user_email: Yup.string().email().required("Email is required"),
    use_same_billing_address_for_user: Yup.boolean().nullable(),
    store_enabled: Yup.boolean().nullable(),
  });

  const validationSchemaEditMode = Yup.object({
    name: Yup.string().required("Organization Name is required"),
    email: Yup.string().email().required("Email is required"),
    contact_person_name: Yup.string().required(
      "Contact Person Name is required"
    ),
    sales_rep_id: Yup.string().required("Sales rep ID is required"),
    address_line_1: Yup.string().required("Address Line 1 is required"),
    address_line_2: Yup.string().nullable(),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State/Province is required"),
    postal_code: Yup.string().required("Postal Code is required"),
    use_same_billing_address: Yup.boolean().nullable(),
    purchase_order_required: Yup.boolean().nullable(),
    gst_enabled: Yup.boolean().nullable(),
    country_id: Yup.string().required("Country is required"),
    payment_term_id: Yup.string().required("Payment Term is required"),
    status: Yup.boolean().nullable(),
    contact_no: Yup.string()
      .required("Phone Number is required")
      .min(10)
      .max(12),

    delivery_name: Yup.string().required("Delivery Name is required"),
    delivery_email: Yup.string().email().required("Delivery Email is required"),
    delivery_address_line_1: Yup.string().required(
      "Delivery Address Line 1 is required"
    ),
    delivery_address_line_2: Yup.string().nullable(),
    delivery_postal_code: Yup.string().required(
      "Delivery Postal Code is required"
    ),
    delivery_city: Yup.string().required("Delivery City is required"),
    delivery_state: Yup.string().required(
      "Delivery State/Province is required"
    ),
    delivery_country_id: Yup.string().required("Delivery Country is required"),
    delivery_contact_no: Yup.string()
      .required("Delivery Phone Number is required")
      .min(10)
      .max(12),
    delivery_contact_person_name: Yup.string(
      "Delivery Contact Person is required"
    ),
    create_store: Yup.boolean().nullable(),
    // user_first_name: Yup.string().required("First Name is required"),
    // user_last_name: Yup.string().required("Last Name is required"),
    // user_code: Yup.string(),
    // user_contact_no: Yup.string()
    //   .required("Contact Number is required")
    //   .min(10)
    //   .max(12),
    // user_address_line_1: Yup.string().required("Address Line 1 is required"),
    // user_address_line_2: Yup.string().nullable(),
    // user_postal_code: Yup.string().required("Postal Code is required"),
    // user_country_id: Yup.string().required("Country is required"),
    // user_email: Yup.string().email().required("Email is required"),
    // user_password: Yup.string().when("editMode", {
    //   is: false,
    //   then: Yup.string().required("Password is required").min(8),
    // }),
    // user_c_password: Yup.string().when("editMode", {
    //   is: false,
    //   then: Yup.string()
    //     .required("Confirm Password is required")
    //     .oneOf([Yup.ref("password"), null], "Passwords must match"),
    // }),
    // use_same_billing_address_for_user: Yup.boolean().nullable(),
  });

  const initialValues = {
    name: "",
    email: "",
    contact_person_name: "",
    sales_rep_id: "",
    address_line_1: "",
    address_line_2: "",
    city: "",
    state: "",
    postal_code: "",
    use_same_billing_address: true,
    purchase_order_required: false,
    country_id: "",
    payment_term_id: "",
    status: true,
    contact_no: "",

    delivery_name: "",
    delivery_email: "",
    delivery_contact_no: "",
    delivery_address_line_1: "",
    delivery_address_line_2: "",
    delivery_postal_code: "",
    delivery_city: "",
    delivery_state: "",
    delivery_country_id: "",
    delivery_contact_person_name: "",
    gst_enabled: true,
    // invoice_to: "",

    user_first_name: "",
    user_last_name: "",
    user_code: "",
    user_contact_no: "",
    user_address_line_1: "",
    user_address_line_2: "",
    user_postal_code: "",
    user_country_id: "",
    user_email: "",
    use_same_billing_address_for_user: true,
    editMode: false,
    store_name: "",
    store_enabled: true,
  };

  useEffect(() => {
    dispatch(paymentTermActions.getAllParameters());
    dispatch(countryActions.getAllParameters());
    dispatch(userActions.getAll());
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };
    fetch(`${apiConstants.API_URL}/auth/users/role/2`, requestOptions).then(
      (response) => {
        response.text().then((text) => {
          const data = text && JSON.parse(text);
          setSalesAgents(data?.data);
        });
      }
    );
  }, [dispatch]);

  useEffect(() => {
    const getPrefillData = () => {
      if (mode === "edit" && paramdata?.id) {
        dispatch(organizationActions.getSingleParameter(paramdata?.id));
      }
    };

    getPrefillData();
  }, [dispatch, mode, paramdata?.id]);

  useEffect(() => {
    const prefillData = () => {
      if (mode === "edit" && paramdata?.id && organizationState?.parameter) {
        const data = organizationState?.parameter?.data;
        console.log(data);
        setGstEnabled(data?.gst_enabled === 0 ? false : true);
        const fields = [
          "name",
          "email",
          "contact_no",
          "contact_person_name",
          "sales_rep_id",
          "address_line_1",
          "address_line_2",
          "city",
          "state",
          "postal_code",
          "use_same_billing_address",
          "purchase_order_required",
          // "country_id",
          "payment_term_id",
          "status",
          "delivery_name",
          "delivery_email",
          "delivery_contact_no",
          "delivery_contact_person_name",
          "delivery_address_line_1",
          "delivery_address_line_2",
          "delivery_postal_code",
          "delivery_city",
          "delivery_state",
          "store_enabled",
          // "delivery_country_id",
        ];

        fields.forEach((field) =>
          formikRef.current.setFieldValue(field, data[field], false)
        );
        formikRef.current.setFieldValue("country_id", data?.country?.id, false);
        formikRef.current.setFieldValue(
          "delivery_country_id",
          data?.delivery_country?.id,
          false
        );
        formikRef.current.setFieldValue(
          "gst_enabled",
          data?.gst_enabled === 0 ? false : true,
          false
        );
        // formikRef.current.setFieldValue('address_line_2', data?.address_line_2 === null, false);
      }
    };

    prefillData();
  }, [mode, organizationState?.parameter, paramdata?.id, formikRef]);

  useEffect(() => {
    const handleSuccess = () => {
      if (organizationState?.parameter?.data && alertState.type === "success") {
        handleSuccessDialog(successMsg);
      }
    };

    const handleError = () => {
      if (organizationState?.error && alertState.type === "error") {
        handleErrorAlert(successMsg);
      }
    };

    handleSuccess();
    handleError();
  }, [organizationState, handleSuccessDialog, alertState, handleErrorAlert]);

  const handleSubmit = (values) => {
    console.log(values);
    if (values && mode === "add") {
      dispatch(
        organizationActions.addParameter(
          values.name,
          values.email,
          values.contact_no,
          values.contact_person_name,
          values.sales_rep_id,
          values.address_line_1,
          values.address_line_2,
          values.city,
          values.state,
          values.postal_code,
          values.use_same_billing_address,
          values.purchase_order_required,
          values.country_id,
          values.payment_term_id,
          values.status,
          values.delivery_name,
          values.delivery_email,
          values.delivery_contact_no,
          values.delivery_contact_person_name,
          values.delivery_address_line_1,
          values.delivery_address_line_2,
          values.delivery_postal_code,
          values.delivery_city,
          values.delivery_state,
          values.delivery_country_id,
          values.gst_enabled,
          values.user_first_name,
          values.user_last_name,
          values.user_code,
          values.user_contact_no,
          values.user_email,
          values.user_address_line_1,
          values.user_address_line_2,
          values.user_postal_code,
          values.user_country_id,
          values.store_name,
          values.store_enabled
        )
      );
    }
    if (values && mode === "edit") {
      dispatch(
        organizationActions.updateParameter(
          paramdata.id,
          values.name,
          values.email,
          values.contact_no,
          values.contact_person_name,
          values.sales_rep_id,
          values.address_line_1,
          values.address_line_2,
          values.city,
          values.state,
          values.postal_code,
          values.use_same_billing_address,
          values.purchase_order_required,
          values.country_id,
          values.payment_term_id,
          values.status,
          values.delivery_name,
          values.delivery_email,
          values.delivery_contact_no,
          values.delivery_contact_person_name,
          values.delivery_address_line_1,
          values.delivery_address_line_2,
          values.delivery_postal_code,
          values.delivery_city,
          values.delivery_state,
          values.delivery_country_id,
          values.store_enabled,
          values.gst_enabled
        )
      );
    }
  };

  const handleBillingAddrToggle = (e) => {
    setUseSameBillingAddr(e.target.checked);
  };

  const handlePOToggle = (e) => {
    setPoRequired(e.target.checked);
  };

  const handleOrgStatusToggle = (e) => {
    setOrgStatus(e.target.checked);
  };

  const handleGSTEnabledToggle = (e) => {
    setGstEnabled(e.target.checked);
  };

  const handleCreateStoreToggle = (e) => {
    setCreateStore(e.target.checked);
  };

  const handleBillingChange = (e) => {
    formikRef.current.setFieldValue([e.target.name], e.target.value);
    if (useSameBillingAddr) {
      if (e.target.name === "name") {
        formikRef.current.setFieldValue("delivery_name", e.target.value);
      }
      if (e.target.name === "contact_person_name") {
        formikRef.current.setFieldValue(
          "delivery_contact_person_name",
          e.target.value
        );
      }
      if (e.target.name === "email") {
        formikRef.current.setFieldValue("delivery_email", e.target.value);
      }

      if (e.target.name === "contact_no") {
        formikRef.current.setFieldValue("delivery_contact_no", e.target.value);
      }

      if (e.target.name === "address_line_1") {
        formikRef.current.setFieldValue(
          "delivery_address_line_1",
          e.target.value
        );
      }

      if (e.target.name === "address_line_2") {
        formikRef.current.setFieldValue(
          "delivery_address_line_2",
          e.target.value
        );
      }

      if (e.target.name === "postal_code") {
        formikRef.current.setFieldValue("delivery_postal_code", e.target.value);
      }

      if (e.target.name === "city") {
        formikRef.current.setFieldValue("delivery_city", e.target.value);
      }

      if (e.target.name === "state") {
        formikRef.current.setFieldValue("delivery_state", e.target.value);
      }

      if (e.target.name === "country_id") {
        formikRef.current.setFieldValue("delivery_country_id", e.target.value);
      }
    }
    if (useSameBillingAddrForUser) {
      if (e.target.name === "email") {
        formikRef.current.setFieldValue("user_email", e.target.value);
      }

      if (e.target.name === "contact_no") {
        formikRef.current.setFieldValue("user_contact_no", e.target.value);
      }

      if (e.target.name === "address_line_1") {
        formikRef.current.setFieldValue("user_address_line_1", e.target.value);
      }

      if (e.target.name === "address_line_2") {
        formikRef.current.setFieldValue("user_address_line_2", e.target.value);
      }

      if (e.target.name === "postal_code") {
        formikRef.current.setFieldValue("user_postal_code", e.target.value);
      }

      if (e.target.name === "city") {
        formikRef.current.setFieldValue("user_city", e.target.value);
      }

      if (e.target.name === "state") {
        formikRef.current.setFieldValue("user_state", e.target.value);
      }

      if (e.target.name === "country_id") {
        formikRef.current.setFieldValue("user_country_id", e.target.value);
      }

      if (e.target.name === "store_enabled") {
        formikRef.current.setFieldValue("store_enabled", e.target.value);
      }
    }
  };

  const handleBillingAddrForUserToggle = (e) => {
    setUseSameBillingAddrForUser(e.target.checked);
  };

  function resetPw() {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: paramdata.email,
      }),
    };

    fetch(
      `${apiConstants.API_URL}/password/forgot-password`,
      requestOptions
    ).then((response) => {
      if (response.status === 200) {
        setShowErrorMessage(true);
        setAlertType("success");
        setContent("Email sent Successfully !");
      } else {
        setShowErrorMessage(true);
        setAlertType("error");
        setContent("Email not sent !");
      }
    });
  }

  function handleClose() {
    setShowErrorMessage(false);
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        // validationSchema={mode === 'edit' ? validationSchemaEditMode : validationSchema}
        enableReinitialize={true}
        innerRef={formikRef}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values);
        }}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          isValid,
          setFieldValue,
        }) => (
          <Form noValidate autoComplete="off">
            <Grid container spacing={{ xs: 2, sm: 2 }} sx={{ pt: 0, pb: 0 }}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="name"
                  name="name"
                  label="Organization Name"
                  value={values.name}
                  onChange={handleBillingChange}
                  onBlur={handleBlur}
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                  variant="standard"
                  className={styles.textField}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  label="Email Address"
                  value={values.email}
                  onChange={handleBillingChange}
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  variant="standard"
                  className={styles.textField}
                  size="small"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="contact_no"
                  name="contact_no"
                  label="Phone Number"
                  value={values.contact_no}
                  onChange={handleBillingChange}
                  onBlur={handleBlur}
                  error={touched.contact_no && Boolean(errors.contact_no)}
                  helperText={touched.contact_no && errors.contact_no}
                  variant="standard"
                  className={styles.textField}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="contact_person_name"
                  name="contact_person_name"
                  label="Contact Person Name"
                  value={values.contact_person_name}
                  onChange={handleBillingChange}
                  onBlur={handleBlur}
                  error={
                    touched.contact_person_name &&
                    Boolean(errors.contact_person_name)
                  }
                  helperText={
                    touched.contact_person_name && errors.contact_person_name
                  }
                  variant="standard"
                  className={styles.textField}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  error={touched.sales_rep_id && Boolean(errors.sales_rep_id)}
                  sx={{ height: 40 }}
                >
                  <InputLabel sx={{ ml: -1.75 }}>Sales Agent</InputLabel>
                  {/* {userState?.loading && (
                    <CircularProgress
                      size={20}
                      sx={{ position: "absolute", right: 25, bottom: 10 }}
                    />
                  )} */}
                  <Select
                    fullWidth
                    id="sales_rep_id"
                    name="sales_rep_id"
                    label="Sales Agent"
                    value={values.sales_rep_id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="standard"
                    className={styles.textField}
                    sx={{ height: 40 }}
                    // size="small"
                  >
                    {salesAgents.map((item) => {
                      return item?.is_admin === 1 ? (
                        <MenuItem key={item?.id} value={item.id}>
                          {item?.first_name} {item?.last_name}
                        </MenuItem>
                      ) : null;
                    })}
                  </Select>
                  {touched.sales_rep_id && Boolean(errors.sales_rep_id) && (
                    <FormHelperText>
                      {touched.sales_rep_id && errors.sales_rep_id}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="address_line_1"
                  name="address_line_1"
                  label="Address Line 1"
                  value={values.address_line_1}
                  onChange={handleBillingChange}
                  onBlur={handleBlur}
                  error={
                    touched.address_line_1 && Boolean(errors.address_line_1)
                  }
                  helperText={touched.address_line_1 && errors.address_line_1}
                  variant="standard"
                  className={styles.textField}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="address_line_2"
                  name="address_line_2"
                  label="Address Line 2"
                  value={values.address_line_2}
                  onChange={handleBillingChange}
                  onBlur={handleBlur}
                  error={
                    touched.address_line_2 && Boolean(errors.address_line_2)
                  }
                  helperText={touched.address_line_2 && errors.address_line_2}
                  variant="standard"
                  className={styles.textField}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="city"
                  name="city"
                  label="Suburb"
                  value={values.city}
                  onChange={handleBillingChange}
                  onBlur={handleBlur}
                  error={touched.city && Boolean(errors.city)}
                  helperText={touched.city && errors.city}
                  variant="standard"
                  className={styles.textField}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="state"
                  name="state"
                  label="State/Province"
                  value={values.state}
                  onChange={handleBillingChange}
                  onBlur={handleBlur}
                  error={touched.state && Boolean(errors.state)}
                  helperText={touched.state && errors.state}
                  variant="standard"
                  className={styles.textField}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="postal_code"
                  name="postal_code"
                  label="Postal Code"
                  value={values.postal_code}
                  onChange={handleBillingChange}
                  onBlur={handleBlur}
                  error={touched.postal_code && Boolean(errors.postal_code)}
                  helperText={touched.postal_code && errors.postal_code}
                  variant="standard"
                  className={styles.textField}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  error={touched.country_id && Boolean(errors.country_id)}
                  sx={{ height: 40 }}
                >
                  <InputLabel sx={{ ml: -1.75 }}>Country</InputLabel>
                  {countryState?.loading && (
                    <CircularProgress
                      size={20}
                      sx={{ position: "absolute", right: 25, bottom: 10 }}
                    />
                  )}
                  <Select
                    fullWidth
                    id="country_id"
                    name="country_id"
                    label="Country"
                    value={values.country_id}
                    onChange={(e) => {
                      handleBillingChange(e);
                    }}
                    onBlur={handleBlur}
                    variant="standard"
                    className={styles.textField}
                    sx={{ height: 40 }}
                    // size="small"
                  >
                    {countryState?.items?.data.map((item) => (
                      <MenuItem key={item?.id} value={item.id}>
                        {item?.name} ({item?.code})
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.country_id && Boolean(errors.country_id) && (
                    <FormHelperText>
                      {touched.country_id && errors.country_id}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={orgStatus}
                        onChange={(e) => {
                          handleOrgStatusToggle(e);
                          setFieldValue("status", e.target.checked);
                        }}
                        id="status"
                        name="status"
                      />
                    }
                    label="Active"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  error={
                    touched.payment_term_id && Boolean(errors.payment_term_id)
                  }
                  sx={{ height: 40 }}
                >
                  <InputLabel sx={{ ml: -1.75 }}>Payment Term</InputLabel>
                  {paymentTermState?.loading && (
                    <CircularProgress
                      size={20}
                      sx={{ position: "absolute", right: 25, bottom: 10 }}
                    />
                  )}
                  <Select
                    fullWidth
                    id="payment_term_id"
                    name="payment_term_id"
                    label="Payment Term"
                    value={values.payment_term_id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="standard"
                    className={styles.textField}
                    sx={{ height: 40 }}
                    // size="small"
                  >
                    {paymentTermState?.items?.data.map((item) => (
                      <MenuItem key={item?.id} value={item.id}>
                        {item?.name} ({item?.code})
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.payment_term_id &&
                    Boolean(errors.payment_term_id) && (
                      <FormHelperText>
                        {touched.payment_term_id && errors.payment_term_id}
                      </FormHelperText>
                    )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={poRequired}
                        onChange={(e) => {
                          handlePOToggle(e);
                          setFieldValue(
                            "purchase_order_required",
                            e.target.checked
                          );
                        }}
                        id="purchase_order_required"
                        name="purchase_order_required"
                      />
                    }
                    label="Purchase Order Required?"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={gstEnabled}
                        onChange={(e) => {
                          handleGSTEnabledToggle(e);
                          setFieldValue("gst_enabled", e.target.checked);
                        }}
                        id="gst_enabled"
                        name="gst_enabled"
                      />
                    }
                    label="Enable GST"
                  />
                </FormGroup>
              </Grid>
              {/* <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="invoice_to"
                  name="invoice_to"
                  label="Invoice To"
                  value={values.invoice_to}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.invoice_to && Boolean(errors.invoice_to)}
                  helperText={touched.invoice_to && errors.invoice_to}
                  variant="standard"
                  className={styles.textField}
                  size="small"
                />
              </Grid> */}
              <Grid item xs={12} md={6}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={useSameBillingAddr}
                        onChange={(e) => {
                          handleBillingAddrToggle(e);
                          setFieldValue(
                            "use_same_billing_address",
                            e.target.checked
                          );
                        }}
                        id="use_same_billing_address"
                        name="use_same_billing_address"
                      />
                    }
                    label="Use Same Billing Address"
                  />
                </FormGroup>
              </Grid>

              {!useSameBillingAddr && (
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={{ xs: 2, sm: 2 }}
                    sx={{ pt: 0, pb: 0 }}
                  >
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        id="delivery_name"
                        name="delivery_name"
                        label="Delivery Name"
                        value={values.delivery_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.delivery_name && Boolean(errors.delivery_name)
                        }
                        helperText={
                          touched.delivery_name && errors.delivery_name
                        }
                        variant="standard"
                        className={styles.textField}
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        id="delivery_email"
                        name="delivery_email"
                        label="Delivery Email Address"
                        value={values.delivery_email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.delivery_email &&
                          Boolean(errors.delivery_email)
                        }
                        helperText={
                          touched.delivery_email && errors.delivery_email
                        }
                        variant="standard"
                        className={styles.textField}
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        id="delivery_contact_no"
                        name="delivery_contact_no"
                        label="Delivery Phone Number"
                        value={values.delivery_contact_no}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.delivery_contact_no &&
                          Boolean(errors.delivery_contact_no)
                        }
                        helperText={
                          touched.delivery_contact_no &&
                          errors.delivery_contact_no
                        }
                        variant="standard"
                        className={styles.textField}
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        id="delivery_contact_person_name"
                        name="delivery_contact_person_name"
                        label="Delivery Contact Person Name"
                        value={values.delivery_contact_person_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.delivery_contact_person_name &&
                          Boolean(errors.delivery_contact_person_name)
                        }
                        helperText={
                          touched.delivery_contact_person_name &&
                          errors.delivery_contact_person_name
                        }
                        variant="standard"
                        className={styles.textField}
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        id="delivery_address_line_1"
                        name="delivery_address_line_1"
                        label="Delivery Address Line 1"
                        value={values.delivery_address_line_1}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.delivery_address_line_1 &&
                          Boolean(errors.delivery_address_line_1)
                        }
                        helperText={
                          touched.delivery_address_line_1 &&
                          errors.delivery_address_line_1
                        }
                        variant="standard"
                        className={styles.textField}
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        id="delivery_address_line_2"
                        name="delivery_address_line_2"
                        label="Delivery Address Line 2"
                        value={values.delivery_address_line_2}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.delivery_address_line_2 &&
                          Boolean(errors.delivery_address_line_2)
                        }
                        helperText={
                          touched.delivery_address_line_2 &&
                          errors.delivery_address_line_2
                        }
                        variant="standard"
                        className={styles.textField}
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        id="delivery_city"
                        name="delivery_city"
                        label="Delivery City/Town"
                        value={values.delivery_city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.delivery_city && Boolean(errors.delivery_city)
                        }
                        helperText={
                          touched.delivery_city && errors.delivery_city
                        }
                        variant="standard"
                        className={styles.textField}
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        id="delivery_state"
                        name="delivery_state"
                        label="Delivery State/Province"
                        value={values.delivery_state}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.delivery_state &&
                          Boolean(errors.delivery_state)
                        }
                        helperText={
                          touched.delivery_state && errors.delivery_state
                        }
                        variant="standard"
                        className={styles.textField}
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        id="delivery_postal_code"
                        name="delivery_postal_code"
                        label="Delivery Postal Code"
                        value={values.delivery_postal_code}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.delivery_postal_code &&
                          Boolean(errors.delivery_postal_code)
                        }
                        helperText={
                          touched.delivery_postal_code &&
                          errors.delivery_postal_code
                        }
                        variant="standard"
                        className={styles.textField}
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl
                        fullWidth
                        error={
                          touched.delivery_country_id &&
                          Boolean(errors.delivery_country_id)
                        }
                        sx={{ height: 40 }}
                      >
                        <InputLabel sx={{ ml: -1.75 }}>
                          Delivery Country
                        </InputLabel>
                        {countryState?.loading && (
                          <CircularProgress
                            size={20}
                            sx={{ position: "absolute", right: 25, bottom: 10 }}
                          />
                        )}
                        <Select
                          fullWidth
                          id="delivery_country_id"
                          name="delivery_country_id"
                          label="Country"
                          value={values.delivery_country_id}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          variant="standard"
                          className={styles.textField}
                          sx={{ height: 40 }}
                          // size="small"
                        >
                          {countryState?.items?.data.map((item) => (
                            <MenuItem key={item?.id} value={item.id}>
                              {item?.name} ({item?.code})
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.delivery_country_id &&
                          Boolean(errors.delivery_country_id) && (
                            <FormHelperText>
                              {touched.delivery_country_id &&
                                errors.delivery_country_id}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {mode !== "edit" && (
                <>
                  <Grid item xs={12} md={12}>
                    <Typography>Create User With Organization</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      id="user_first_name"
                      name="user_first_name"
                      label="First Name"
                      value={values.user_first_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.user_first_name &&
                        Boolean(errors.user_first_name)
                      }
                      helperText={
                        touched.user_first_name && errors.user_first_name
                      }
                      variant="standard"
                      className={styles.textField}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      id="user_last_name"
                      name="user_last_name"
                      label="Last Name"
                      value={values.user_last_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.user_last_name && Boolean(errors.user_last_name)
                      }
                      helperText={
                        touched.user_last_name && errors.user_last_name
                      }
                      variant="standard"
                      className={styles.textField}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={useSameBillingAddrForUser}
                            onChange={(e) => {
                              handleBillingAddrForUserToggle(e);
                              setFieldValue(
                                "use_same_billing_address_for_user",
                                e.target.checked
                              );
                            }}
                            id="use_same_billing_address_for_user"
                            name="use_same_billing_address_for_user"
                          />
                        }
                        label="Use Same Billing Address for User"
                      />
                    </FormGroup>
                  </Grid>
                  {!useSameBillingAddrForUser && (
                    <>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          id="user_contact_no"
                          name="user_contact_no"
                          label="Contact No"
                          value={values.user_contact_no}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.user_contact_no &&
                            Boolean(errors.user_contact_no)
                          }
                          helperText={
                            touched.user_contact_no && errors.user_contact_no
                          }
                          variant="standard"
                          className={styles.textField}
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          id="user_email"
                          name="user_email"
                          label="Email Address"
                          value={values.user_email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.user_email && Boolean(errors.user_email)
                          }
                          helperText={touched.user_email && errors.user_email}
                          variant="standard"
                          className={styles.textField}
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          id="user_address_line_1"
                          name="user_address_line_1"
                          label="Address Line 1"
                          value={values.user_address_line_1}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.user_address_line_1 &&
                            Boolean(errors.user_address_line_1)
                          }
                          helperText={
                            touched.user_address_line_1 &&
                            errors.user_address_line_1
                          }
                          variant="standard"
                          className={styles.textField}
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          id="user_address_line_2"
                          name="user_address_line_2"
                          label="Address Line 2"
                          value={values.user_address_line_2}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.user_address_line_2 &&
                            Boolean(errors.user_address_line_2)
                          }
                          helperText={
                            touched.user_address_line_2 &&
                            errors.user_address_line_2
                          }
                          variant="standard"
                          className={styles.textField}
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          id="user_postal_code"
                          name="user_postal_code"
                          label="Postal Code"
                          value={values.user_postal_code}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.user_postal_code &&
                            Boolean(errors.user_postal_code)
                          }
                          helperText={
                            touched.user_postal_code && errors.user_postal_code
                          }
                          variant="standard"
                          className={styles.textField}
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControl
                          fullWidth
                          error={
                            touched.user_country_id &&
                            Boolean(errors.user_country_id)
                          }
                          sx={{ height: 40 }}
                        >
                          <InputLabel sx={{ ml: -1.75 }}>Country</InputLabel>
                          {countryState?.loading && (
                            <CircularProgress
                              size={20}
                              sx={{
                                position: "absolute",
                                right: 25,
                                bottom: 10,
                              }}
                            />
                          )}
                          <Select
                            fullWidth
                            id="user_country_id"
                            name="user_country_id"
                            label="Country"
                            value={values.user_country_id}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            variant="standard"
                            className={styles.textField}
                            sx={{ height: 40 }}
                            // size="small"
                          >
                            {countryState?.items?.data.map((item) => (
                              <MenuItem key={item?.id} value={item.id}>
                                {item?.name} ({item?.code})
                              </MenuItem>
                            ))}
                          </Select>
                          {touched.user_country_id &&
                            Boolean(errors.user_country_id) && (
                              <FormHelperText>
                                {touched.user_country_id &&
                                  errors.user_country_id}
                              </FormHelperText>
                            )}
                        </FormControl>
                      </Grid>
                    </>
                  )}
                </>
              )}

              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.store_enabled}
                      onChange={(e) => {
                        setFieldValue("store_enabled", e.target.checked);
                      }}
                      id="store_enabled"
                      name="store_enabled"
                    />
                  }
                  label="Enable Web Store"
                />
                {touched.user_password && errors.user_password && (
                  <FormHelperText>{errors.user_password}</FormHelperText>
                )}
                {/* </FormControl> */}
              </Grid>

              <Grid item xs={12}>
                <Stack
                  direction="column-reverse"
                  justifyContent="space-between"
                  align-items="center"
                  sx={{
                    flexDirection: {
                      sm: "row",
                    },
                    mt: 2,
                  }}
                >
                  <Link to="../" style={{ display: "block" }}>
                    <Button
                      size="medium"
                      startIcon={<ArrowBackIcon size="small" />}
                      sx={{ textTransform: "none" }}
                    >
                      Back to {title}
                    </Button>
                  </Link>

                  <span style={{ flex: 1 }}>&nbsp;</span>

                  {mode == "edit" && (
                    <LoadingButton
                      color="warning"
                      variant="contained"
                      fullWidth
                      size="large"
                      loading={organizationState?.loading}
                      onClick={resetPw}
                      disabled={mode != "edit"}
                      sx={{
                        color: "#fff",
                        width: {
                          xs: "100%",
                          sm: "auto",
                        },
                      }}
                    >
                      Reset organization Account password
                    </LoadingButton>
                  )}
                  <span>&nbsp;</span>

                  <LoadingButton
                    color="secondary"
                    variant="contained"
                    fullWidth
                    type="submit"
                    size="large"
                    loading={organizationState?.loading}
                    // loadingPosition="end"
                    sx={{
                      color: "#fff",
                      width: {
                        xs: "100%",
                        sm: "auto",
                      },
                    }}
                  >
                    {`Save ${titleSingle}`}
                  </LoadingButton>
                </Stack>
              </Grid>
            </Grid>

            <Dialog
              open={showErrorMessage}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <CheckCircleIcon
                  color={alertType}
                  sx={{
                    position: "relative",
                    top: "0.25rem",
                    mr: 1,
                    display: { xs: "block", sm: "inline-block" },
                    margin: { xs: "0 0 1rem 0", sm: "0 0.6rem 0 0" },
                  }}
                />
                password reset
              </DialogTitle>

              {content && (
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {typeof content === "string" && content}
                  </DialogContentText>
                </DialogContent>
              )}

              <DialogActions>
                <Button onClick={handleClose} autoFocus>
                  Close
                </Button>
              </DialogActions>
            </Dialog>
            {debug && (
              <>
                <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                <pre style={{ textAlign: "left" }}>
                  <strong>Values</strong>
                  <br />
                  {JSON.stringify(values, null, 2)}
                </pre>
                <pre style={{ textAlign: "left" }}>
                  <strong>Errors</strong>
                  <br />
                  {JSON.stringify(errors, null, 2)}
                </pre>
              </>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FormDetail;
