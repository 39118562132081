import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { coloryActions } from "../../../../../../actions";
import DataTable from "../../../../../common/ui/table/DataTable/DataTable.component";

const Colors = () => {
  const dispatch = useDispatch();
  const colors = useSelector((state) => state.colors);

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "english_name",
      headerName: "English Name",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "chinese_name",
      headerName: "Chinese Name",
      minWidth: 200,
      flex: 1,
    },
  ];

  const recordIdentifier = "english_name";

  useEffect(() => {
    dispatch(coloryActions.getAllParameters());
  }, [dispatch]);

  return (
    <Box>
      {colors && (
        <DataTable
          loading={colors?.loading}
          rows={colors?.items?.data}
          columns={columns}
          recordIdentifier={recordIdentifier}
          actionIdentifier="coloryActions"
        />
      )}
    </Box>
  );
};

export default Colors;
