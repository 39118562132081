import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  FormControl,
} from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import styled from "@emotion/styled";
import Checkbox from "@mui/material/Checkbox";
import PropTypes from "prop-types";
import SuccessModal from "../Common/Modals/SuccessModal";
import QuotationPreviewModal from "../Common/Modals/QuotationPreviewModal";
import { useSelector, useDispatch } from "react-redux";
import { convertToPlaces, convertToDecimalPlaces } from "../helpers/helpers";
import { apiConstants } from "../constants";
import { authHeader, getUserToken } from "../helpers";
import AlertToast from "./common/Toast";
import { useNavigate } from "react-router-dom";
import { removeAll } from "../store/Store/orderSlice";
import { removeCustomerData } from "../store/Store/Slice";
import AdditionalEmailBody from "../Common/Modals/AdditionalEmailBody";
import { useCreateQuotationMutation } from "../services/api/quotations";

const StyledButton = styled(Button)({
  color: "white",
});

const StyledUpdateButton = styled(Button)({
  height: "41px",
});

const StyledHeaders = styled(Typography)({
  fontSize: "16px",
});
const StyledContents = styled(Typography)({
  fontSize: "14px",
  color: "rgba(0, 0, 0, 0.6)",
});

const QuotationSummery = ({ ...props }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { customer } = useSelector((state) => state.customer);
  const { order } = useSelector((state) => state.orders);
  const { enableSubmit, file, loading, setProgress } = props;

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const [preview, updatePreview] = useState(true);
  const [openPreview, setPreview] = useState(false);
  const [openSucces, setSuccess] = useState(false);

  const [toastState, setToastState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });
  const [toastMessage, setToastMessage] = React.useState("");
  const [enableDelivery, setEnableDelivery] = useState(true);
  const [totalDelivery, setTotalDelivery] = React.useState(0);
  const [updateTotalDelivery, setUpdateTotalDelivery] = React.useState(0);
  const [quotationId, setQuotationId] = useState("");
  const handleToastClose = () => setToastState({ open: false });
  const [openAdditional, updateAdditional] = useState(false);
  const [additionalBody, setAdditionalBody] = useState("");
  const [subject, setSubject] = useState("Your Quote is Ready!");
  const [disabledSubmitButton, setDisabledSubmitButton] = useState(false);

  const [
    createQuotation,
    { isError, isLoading, data: quotationCreateResponse },
  ] = useCreateQuotationMutation();

  const handleGetDelivery = useCallback(() => {
    const items = order.map((o) => {
      const delivery = {
        item_id: o.item_id,
        qty: o.qty,
      };
      return delivery;
    });

    const reqData = {
      items: items,
    };

    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify(reqData),
    };

    fetch(
      `${apiConstants.API_URL}/prices/shipping-charges`,
      requestOptions
    ).then((response) => {
      response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
          const error = (data && data.error) || response.statusText;
          const errorMessage = Object.values(error).map((errorKey) => {
            return errorKey[0];
          });
          setToastMessage(errorMessage);
          setToastState({
            open: true,
            vertical: "top",
            horizontal: "right",
          });
          setPreview(false);
        } else {
          setTotalDelivery(data?.data?.total_shipping_charges / 100);
          setUpdateTotalDelivery(data?.data?.total_shipping_charges / 100);
        }
      });
    });
  }, [order]);

  useEffect(() => {
    if (order.length !== 0) {
      handleGetDelivery();
    }
  }, [order, handleGetDelivery]);

  const submitQuotation = () => {
    //updateAdditional(true);
    //saveRequest();
  };

  const submitPreview = () => {
    setPreview(!openPreview);
    setDisabledSubmitButton(false);
  };

  const submitAction = preview ? submitPreview : submitQuotation;

  const submitBtnText = preview
    ? " PREVIEW AND SEND QUOTATION "
    : "SEND QUOTATION";

  const visibleTotals = order.length > 0;

  const handleSuccess = () => {
    dispatch(removeAll({}));
    dispatch(removeCustomerData({}));
    navigate("/admin/quotations");
  };

  const calculateTotal = () => {
    let totalPrice = 0;
    order.map((o) => {
      let calTotal = o.unit_price * o.qty + o.extra_charges;
      return (totalPrice = totalPrice + calTotal);
    });
    return totalPrice + updateTotalDelivery * 100;
  };

  const saveRequest = async () => {
    loading(true);
    updateAdditional(false);
    let fileArray = [];
    const token = getUserToken();
    function formateFile(requestImageOptions, index) {
      {
        const xhr = new XMLHttpRequest();
        xhr.open("POST", `${apiConstants.API_URL}/file/quotation`);

        // Assuming you have a token variable
        xhr.setRequestHeader("Authorization", "Bearer " + token);

        xhr.upload.addEventListener("progress", (event) => {
          if (event.lengthComputable) {
            const progress = Math.round((event.loaded / event.total) * 100);
            setProgress(progress);
          }
        });

        xhr.onload = function () {
          const data = xhr.responseText && JSON.parse(xhr.responseText);

          if (xhr.status === 201) {
            file[index]["image"] = data?.data?.path;
            file[index]["formateUrl"] = true;
            if (file.every((el) => el.formateUrl)) {
              const files = file.map((o) => {
                return o?.image;
              });
              fileArray = files;
              //  await  sentQuotation(fileArray);
              console.log(fileArray, "fileArray");
              console.log({ xhrdata: data });
            }
          } else {
            const error = (data && data.error) || xhr.statusText;
            const errorMessage = Object.values(error).map((errorKey) => {
              return errorKey[0];
            });
            setToastMessage(errorMessage[0]);
            setToastState({
              open: true,
              vertical: "top",
              horizontal: "right",
            });
            loading(false);
            setProgress(undefined);
          }
        };

        xhr.send(requestImageOptions.body);
      }
    }
    console.log("############## nothing happened", 221);
    if (file.length !== 0) {
      console.log("############## nothing happened", 223);

      file?.map((f, index) => {
        const requestImageOptions = {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
          },
          body: f?.image,
        };

        if (!f?.formateUrl) {
          setDisabledSubmitButton(true);
          formateFile(requestImageOptions, index);
        }
      });
      if (file.every((el) => el.formateUrl)) {
        setDisabledSubmitButton(true);
        console.log("############## nothing happened", 241);

        await sentQuotation(fileArray);
      }
    } else {
      console.log("############## nothing happened", 246);

      setDisabledSubmitButton(true);
      await sentQuotation(fileArray);
    }
  };

  const sentQuotation = async (fileArray) => {
    console.log("############## nothing happened", 254);

    const {
      name,
      email,
      address_line_1,
      address_line_2,
      postal_code,
      country_id,
      organization_id,
      phone,
      sales_rep_id,
      gst_enabled,
    } = customer;

    let custom_items = order.filter((f) => f.price_id === null);
    let items = order.filter((f) => f.price_id ?? false);

    let newItems = items.map((o, i) => {
      const {
        color_id,
        unit_discount,
        qty,
        style,
        unit_price,
        original_unit_price,
        total_price,
        artwork_charges,
        extra_charges,
        gst_amount,
        price_id,
        original_total_price,
        extra_charges_list,
        estimated_shipping_charges,
      } = o;

      const extraChargesList = extra_charges_list.map((x) => {
        return {
          extra_charge_id: x.id,
          cost: x.cost,
        };
      });

      const estimatedShippingCharges = enableDelivery
        ? i == 0
          ? updateTotalDelivery * 100
          : 0
        : estimated_shipping_charges;

      return {
        unit_discount,
        qty: Number(qty),
        style,
        unit_price: original_unit_price,
        color_id,
        artwork_charges,
        extra_charges,
        price_id,
        quotation_item_extra_charges: extraChargesList,
        estimated_shipping_charges: estimatedShippingCharges,
      };
    });
    let newCustoms = custom_items.map((n) => {
      const { name, unit_discount, qty, unit_price } = n;
      return {
        name,
        unit_discount,
        qty,
        price: unit_price,
      };
    });

    const reqData = {
      organization_id: organization_id,
      organization_name: name,
      organization_email: email,
      organization_address_line_1: address_line_1,
      organization_address_line_2: address_line_2,
      postal_code: postal_code,
      country_id: country_id,
      contact_no: phone,
      sales_agent_id: sales_rep_id,
      items: newItems,
      custom_items: newCustoms,
      additional_email_body: additionalBody,
      email_subject: subject,
      estimated_shipping_charges: updateTotalDelivery * 100,
      gst_enabled: gst_enabled,
    };
    if (fileArray.length) {
      reqData.quotation_files = fileArray;
    }

    try {
      console.log("############## nothing happened", 347);

      const { id } = await createQuotation(reqData).unwrap();

      console.log("############## nothing happened", 351);

      setQuotationId(id);
      setSuccess(!openSucces);
    } catch (error) {
      console.log("############## nothing happened", error);

      setToastMessage(error?.data?.error?.[0] || error.message);
      setToastState({
        open: true,
        vertical: "top",
        horizontal: "right",
      });
    } finally {
      setDisabledSubmitButton(false);
      setPreview(false);
      loading(false);
    }
  };

  const getExtra = () => {
    let extra = 0;
    order.map((o) => {
      extra = extra + o.extra_charges;
      return extra;
    });
    return extra;
  };

  const closeAdditional = () => {
    updateAdditional(false);
  };

  const confirmQuotation = () => {
    updateAdditional(true);
    setPreview(false);
  };

  const handleEnableDelivery = () => {
    setEnableDelivery(!enableDelivery);
    if (enableDelivery) {
      handleGetDelivery();
    }
  };

  const onchangeDelivery = (e) => {
    setUpdateTotalDelivery(e.target.value);
  };

  // console.log(order)
  return (
    <>
      <Stack spacing={3}>
        <Stack>
          <Typography fontWeight={400}>Quotation Summary</Typography>
          <StyledContents>
            This is a summary of the generated quotation
          </StyledContents>
        </Stack>
        <Stack style={{ height: "120px", overflow: "scroll" }} spacing>
          <StyledHeaders>Customer</StyledHeaders>
          <Box>
            <StyledContents>{customer?.name}</StyledContents>
            <StyledContents>{customer?.address_line_1}</StyledContents>
            <StyledContents>
              {customer?.address_line_2},{customer?.postal_code}
            </StyledContents>
            <StyledContents>{customer?.country}</StyledContents>
          </Box>
        </Stack>
        <Divider style={{ marginTop: "10px" }} />
        <Stack>
          <StyledHeaders position={"sticky"}>Include in Order</StyledHeaders>
          <div
            style={{ overflow: "scroll", minHeight: "85px", height: "100%" }}
          >
            <table style={{ width: "100%" }}>
              <tbody>
                {order.map((o, index) => (
                  <>
                    <tr key={index}>
                      <td style={{ textAlign: "!left" }}>
                        <StyledContents>{o?.name}</StyledContents>
                      </td>
                      <td>
                        <StyledContents
                          style={{ minWidth: "50px", textAlign: "right" }}
                        >
                          * {o?.qty}
                        </StyledContents>
                      </td>
                      <td>
                        <StyledContents
                          style={{ textAlign: "right", minWidth: "100px" }}
                        >
                          {convertToPlaces(o?.unit_price * o?.qty)}
                        </StyledContents>
                      </td>
                    </tr>

                    {visibleTotals && (
                      <tr style={{ backgroundColor: "#E5E5E5" }}>
                        <td>
                          <StyledContents style={{ textAlign: "left" }}>
                            Extra Charges
                          </StyledContents>
                        </td>
                        <td></td>
                        <td>
                          {/*<StyledContents style={{ textAlign: "right" }}>*/}
                          {/*  {convertToPlaces(getExtra())}*/}
                          {/*</StyledContents>*/}
                        </td>
                      </tr>
                    )}

                    {o.extra_charges_list && (
                      <>
                        {o.extra_charges_list.map((odr, index) => (
                          <>
                            <tr style={{ backgroundColor: "#E5E5E5" }}>
                              <td>
                                <StyledContents style={{ textAlign: "left" }}>
                                  {odr.name}
                                </StyledContents>
                              </td>
                              <td></td>
                              <td>
                                <StyledContents style={{ textAlign: "right" }}>
                                  {convertToPlaces(odr?.cost * o?.qty)}
                                </StyledContents>
                              </td>
                            </tr>
                          </>
                        ))}
                      </>
                    )}
                  </>
                ))}
                {visibleTotals && updateTotalDelivery && (
                  <tr style={{ backgroundColor: "#E5E5E5" }}>
                    <td>
                      <StyledContents style={{ textAlign: "left" }}>
                        Delivery
                      </StyledContents>
                    </td>
                    <td></td>
                    <td>
                      <StyledContents style={{ textAlign: "right" }}>
                        {convertToDecimalPlaces(updateTotalDelivery)}
                      </StyledContents>
                    </td>
                  </tr>
                )}
                {visibleTotals && (
                  <tr style={{ backgroundColor: "#E5E5E5" }}>
                    <td>
                      <StyledContents style={{ textAlign: "left" }}>
                        Total
                      </StyledContents>
                    </td>
                    <td></td>
                    <td>
                      <StyledContents style={{ textAlign: "right" }}>
                        {convertToPlaces(calculateTotal())}
                      </StyledContents>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <Divider style={{ marginTop: "10px" }} />
        </Stack>

        <Stack spacing={1}>
          <FormControlLabel
            control={
              <Checkbox
                value={enableDelivery}
                checked={enableDelivery}
                onChange={handleEnableDelivery}
              />
            }
            label="Update Delivery Charges"
          />
          {enableDelivery && (
            <>
              <StyledHeaders>Delivery / Shipping</StyledHeaders>
              <Stack spacing={2} direction={"row"}>
                <FormControl ariant="standard" fullWidth>
                  <TextField
                    id="outlined-controlled"
                    size="small"
                    value={updateTotalDelivery}
                    onChange={onchangeDelivery}
                    disabled={!enableDelivery}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                {/* <StyledUpdateButton variant="outlined" 
              onClick={handleUpdateDelivery} disabled={!enableDelivery}
              >Update</StyledUpdateButton> */}
              </Stack>
            </>
          )}
        </Stack>
        <Stack>
          <FormControlLabel
            control={
              <Checkbox
                value={preview}
                checked={preview}
                onChange={() => updatePreview(!preview)}
              />
            }
            label="Preview quotation before send"
          />

          <StyledButton
            sx={{ marginTop: "10px" }}
            variant="contained"
            color="success"
            endIcon={<ArrowForwardIcon />}
            onClick={() => submitAction()}
            disabled={!enableSubmit}
          >
            {submitBtnText}
          </StyledButton>
          <SuccessModal
            open={openSucces}
            handleClose={handleSuccess}
            title={"Quotation Sent Successfully"}
            body={`Quotation #${quotationId} is created successfully and sent to the customer email ${customer?.email}. Thank you.`}
          />
          <AlertToast
            toastMessage={toastMessage}
            toastState={toastState}
            handleClose={handleToastClose}
          />
          <QuotationPreviewModal
            open={openPreview}
            handleClose={submitPreview}
            successAction={confirmQuotation}
          />
          <AdditionalEmailBody
            open={openAdditional}
            handleClose={closeAdditional}
            type={"success"}
            label={"Quotation Email body Preview"}
            setAdditionalBody={setAdditionalBody}
            additionalBody={additionalBody}
            note={""}
            saveRequest={saveRequest}
            buttonText={"Submit"}
            disabledSubmitButton={disabledSubmitButton}
            setSubject={setSubject}
            subject={subject}
          />
        </Stack>
        {/* <SuccessModal
          title={"Quotation has been saved successfuly"}
          open={openSucces}
          handleClose={handleSuccess}
          body={"Quotation has been saved successfuly"}
        /> */}
      </Stack>
    </>
  );
};

QuotationSummery.propTypes = {
  enableSubmit: PropTypes.bool,
  customerDetails: PropTypes.object,
};

QuotationSummery.defaultProps = {
  enableSubmit: false,
  customerDetails: {},
};

export default QuotationSummery;
