import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  userActions,
  countryActions,
  organizationActions,
  sportCategoryActions,
  roleActions,
} from "./../../../../actions";
import {
  Divider,
  Button,
  TextField,
  Grid,
  Stack,
  FormGroup,
  FormControl,
  InputLabel,
  FormControlLabel,
  Input,
  FormLabel,
  RadioGroup,
  Radio,
  Switch,
  Select,
  MenuItem,
  CircularProgress,
  FormHelperText,
  InputAdornment,
  IconButton,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import "yup-phone";
import { Link } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import styles from "./FormDetail.module.scss";

const FormDetail = (props) => {
  const {
    title,
    titleSingle,
    mode,
    paramdata,
    handleSuccessDialog,
    handleErrorAlert,
  } = props;

  const dispatch = useDispatch();
  const userState = useSelector((state) => state.users);
  const countryState = useSelector((state) => state.countries);
  const alertState = useSelector((state) => state.alert);
  const organizationState = useSelector((state) => state.organizations);
  const sportState = useSelector((state) => state.sportCategories);
  const roleState = useSelector((state) => state.roles);
  const formikRef = React.createRef();

  const debug = false;
  // const [submitting, setSubmitting] = useState(false);
  const [isAdmin, setIsAdmin] = useState(true);
  const [userStatus, setUserStatus] = useState(true);
  const [allUsers, setAllUsers] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [userType, setUserType] = React.useState("internal");

  const successMsg = "";

  const validationSchema = Yup.object({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    code: Yup.string(),
    contact_no: Yup.string()
      .required("Contact Number is required")
      .min(10)
      .max(12),
    address_line_1: Yup.string().required("Address Line 1 is required"),
    address_line_2: Yup.string().required("Address Line 2 is required"),
    postal_code: Yup.string().required("Postal Code is required"),
    country_id: Yup.string().required("Country is required"),
    // manager_id: Yup.string().required("Manager is required"),
    is_admin: Yup.boolean().nullable(),
    status: Yup.boolean().nullable(),
    email: Yup.string().email().required("Email is required"),
    editMode: Yup.boolean(),
    // user_role: Yup.string(),
    organization_id: Yup.string().when("is_admin", {
      is: false,
      then: Yup.string().required("Organization is required"),
    }),
    // sport_Id: Yup.string(),
  });

  const initialValues = {
    first_name: "",
    last_name: "",
    code: "",
    contact_no: "",
    address_line_1: "",
    address_line_2: "",
    postal_code: "",
    country_id: "",
    // manager_id: "",
    is_admin: userType === "internal" ? true : false,
    status: true,
    email: "",
    editMode: mode === "edit" ? true : false,
    user_role: "",
    organization_id: "",
    sport_Id: "",
  };

  // "organization_id":null,
  // "sport_Id_id":null

  // Organization Name

  // Sport

  useEffect(() => {
    dispatch(countryActions.getAllParameters());
    dispatch(userActions.getAll());
    dispatch(organizationActions.getAllParameters());
    dispatch(sportCategoryActions.getAllParameters());
    setAllUsers(userState);
  }, []);

  useEffect(() => {
    const getPrefillData = () => {
      if (mode === "edit" && paramdata?.id) {
        dispatch(userActions.getSingle(paramdata?.id));
        console.log(paramdata);
        if (!isAdmin) {
          setIsAdmin(false);
          dispatch(roleActions.getAllExternalRole());
        } else {
          dispatch(roleActions.getAllInternalRole());
          setIsAdmin(true);
        }
      }
      if (mode !== "edit") {
        if (!isAdmin) {
          dispatch(roleActions.getAllExternalRole());
        } else {
          dispatch(roleActions.getAllInternalRole());
        }
      }
    };

    getPrefillData();
  }, [dispatch, mode, paramdata?.id, paramdata?.is_admin, isAdmin]);

  useEffect(() => {
    const prefillData = () => {
      if (mode === "edit" && paramdata?.id && userState?.parameter) {
        const data = userState?.parameter?.data;

        setUserType(data?.is_admin ? "internal" : "external");
        setIsAdmin(data?.is_admin);

        const fields = [
          "first_name",
          "last_name",
          "code",
          "contact_no",
          "address_line_1",
          "address_line_2",
          "postal_code",
          "manager_id",
          "is_admin",
          "status",
          "email",
          "sport_Id",
        ];

        fields.forEach((field) =>
          formikRef.current.setFieldValue(field, data[field], false)
        );

        formikRef.current.setFieldValue("country_id", data?.country?.id, false);

        formikRef.current.setFieldValue(
          "user_role",
          data?.roles[0]?.name,
          false
        );

        formikRef.current.setFieldValue(
          "organization_id",
          data?.organization?.id,
          false
        );
      }
    };

    prefillData();
  }, [mode, userState?.parameter, paramdata?.id, formikRef]);

  useEffect(() => {
    const handleSuccess = () => {
      if (userState?.parameter?.data && alertState.type === "success") {
        handleSuccessDialog(successMsg);
      }
    };

    const handleError = () => {
      if (userState?.error) {
        handleErrorAlert(successMsg);
      }
    };

    handleSuccess();
    handleError();
  }, [userState, handleSuccessDialog, alertState, handleErrorAlert]);

  const handleSubmit = (values) => {
    if (values && mode === "add") {
      dispatch(
        userActions.addUser(
          values.first_name,
          values.last_name,
          values.code,
          values.contact_no,
          values.address_line_1,
          values.address_line_2,
          values.postal_code,
          values.country_id,
          values.manager_id,
          values.is_admin,
          values.status,
          values.email,
          values.organization_id,
          values.sport_Id,
          values.user_role
        )
      );
    }
    if (values && mode === "edit") {
      console.log(values.status);
      dispatch(
        userActions.updateUser(
          paramdata.id,
          values.first_name,
          values.last_name,
          values.code,
          values.contact_no,
          values.address_line_1,
          values.address_line_2,
          values.postal_code,
          values.country_id,
          values.manager_id,
          values.is_admin,
          values.status,
          values.email,
          values.organization_id,
          values.sport_Id,
          values.user_role
        )
      );
    }
  };

  const handleIsAdminToggle = (e) => {
    setIsAdmin(e.target.checked);
  };

  const handleUserStatusToggle = (e) => {
    setUserStatus(e.target.checked);
  };

  const handleUserTypeChange = (e) => {
    setUserType(e.target.value);

    if (e.target.value === "internal") {
      setIsAdmin(true);
      dispatch(roleActions.getAllInternalRole());
    } else {
      setIsAdmin(false);
      dispatch(roleActions.getAllExternalRole());
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        innerRef={formikRef}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values);
        }}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          isValid,
          setFieldValue,
        }) => (
          <Form noValidate autoComplete="off">
            <Grid container spacing={{ xs: 2, sm: 2 }} sx={{ pt: 0, pb: 0 }}>
              <Grid item xs={12} md={12}>
                <FormControl>
                  <FormLabel id="user_type">User Type</FormLabel>
                  <RadioGroup
                    aria-labelledby="user_type"
                    name="user_type"
                    value={userType}
                    onChange={(e) => {
                      handleUserTypeChange(e);
                      if (e.target.value === "internal") {
                        setFieldValue("is_admin", true);
                      } else {
                        setFieldValue("is_admin", false);
                      }
                    }}
                    row
                  >
                    <FormControlLabel
                      value="internal"
                      control={<Radio />}
                      label="Internal"
                    />
                    <FormControlLabel
                      value="external"
                      control={<Radio />}
                      label="External"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="first_name"
                  name="first_name"
                  label="First Name"
                  value={values.first_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.first_name && Boolean(errors.first_name)}
                  helperText={touched.first_name && errors.first_name}
                  variant="standard"
                  className={styles.textField}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="last_name"
                  name="last_name"
                  label="Last Name"
                  value={values.last_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.last_name && Boolean(errors.last_name)}
                  helperText={touched.last_name && errors.last_name}
                  variant="standard"
                  className={styles.textField}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="code"
                  name="code"
                  label="Code"
                  value={values.code}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.code && Boolean(errors.code)}
                  helperText={touched.code && errors.code}
                  variant="standard"
                  className={styles.textField}
                  size="small"
                />
              </Grid>

              {console.log(
                "🚀 ~ file: FormDetail.component.js:369 ~ FormDetail ~ isAdmin",
                values.is_admin
              )}

              {!values.is_admin && (
                <Grid item xs={12} md={6}>
                  <FormControl
                    fullWidth
                    error={
                      touched.organization_id && Boolean(errors.organization_id)
                    }
                    sx={{ height: 40 }}
                  >
                    <InputLabel sx={{ ml: -1.75 }}>Organization</InputLabel>
                    {organizationState?.loading && (
                      <CircularProgress
                        size={20}
                        sx={{ position: "absolute", right: 25, bottom: 10 }}
                      />
                    )}
                    <Select
                      fullWidth
                      id="organization_id"
                      name="organization_id"
                      label="Organization"
                      value={values.organization_id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="standard"
                      className={styles.textField}
                      sx={{ height: 40 }}
                      // size="small"
                    >
                      {organizationState?.items?.data.map((item) => {
                        return (
                          <MenuItem key={item?.id} value={item.id}>
                            {item?.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {touched.organization_id &&
                      Boolean(errors.organization_id) && (
                        <FormHelperText>
                          {touched.organization_id && errors.organization_id}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="contact_no"
                  name="contact_no"
                  label="Contact No"
                  value={values.contact_no}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.contact_no && Boolean(errors.contact_no)}
                  helperText={touched.contact_no && errors.contact_no}
                  variant="standard"
                  className={styles.textField}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  label="Email Address"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  variant="standard"
                  className={styles.textField}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="address_line_1"
                  name="address_line_1"
                  label="Address Line 1"
                  value={values.address_line_1}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.address_line_1 && Boolean(errors.address_line_1)
                  }
                  helperText={touched.address_line_1 && errors.address_line_1}
                  variant="standard"
                  className={styles.textField}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="address_line_2"
                  name="address_line_2"
                  label="Address Line 2"
                  value={values.address_line_2}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.address_line_2 && Boolean(errors.address_line_2)
                  }
                  helperText={touched.address_line_2 && errors.address_line_2}
                  variant="standard"
                  className={styles.textField}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="postal_code"
                  name="postal_code"
                  label="Postal Code"
                  value={values.postal_code}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.postal_code && Boolean(errors.postal_code)}
                  helperText={touched.postal_code && errors.postal_code}
                  variant="standard"
                  className={styles.textField}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  error={touched.country_id && Boolean(errors.country_id)}
                  sx={{ height: 40 }}
                >
                  <InputLabel sx={{ ml: -1.75 }}>Country</InputLabel>
                  {countryState?.loading && (
                    <CircularProgress
                      size={20}
                      sx={{ position: "absolute", right: 25, bottom: 10 }}
                    />
                  )}
                  <Select
                    fullWidth
                    id="country_id"
                    name="country_id"
                    label="Country"
                    value={values.country_id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="standard"
                    className={styles.textField}
                    sx={{ height: 40 }}
                    // size="small"
                  >
                    {countryState?.items?.data.map((item) => (
                      <MenuItem key={item?.id} value={item.id}>
                        {item?.name} ({item?.code})
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.country_id && Boolean(errors.country_id) && (
                    <FormHelperText>
                      {touched.country_id && errors.country_id}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              {/* <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  error={touched.manager_id && Boolean(errors.manager_id)}
                  sx={{ height: 40 }}
                >
                  <InputLabel sx={{ ml: -1.75 }}>Manager</InputLabel>
                  {countryState?.loading && (
                    <CircularProgress
                      size={20}
                      sx={{ position: "absolute", right: 25, bottom: 10 }}
                    />
                  )}
                  <Select
                    fullWidth
                    id="manager_id"
                    name="manager_id"
                    label="Manger"
                    value={values.manager_id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="standard"
                    className={styles.textField}
                    sx={{ height: 40 }}
                    // size="small"
                  >
                    {allUsers?.items?.data.map((item) => {
                      return item?.is_admin ? (
                        <MenuItem key={item?.id} value={item.id}>
                          {item?.first_name} {item?.last_name}
                        </MenuItem>
                      ) : null;
                    })}
                  </Select>
                  {touched.manager_id && Boolean(errors.manager_id) && (
                    <FormHelperText>
                      {touched.manager_id && errors.manager_id}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid> */}

              {/* <Grid item xs={12} md={6}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isAdmin}
                        onChange={(e) => {
                          handleIsAdminToggle(e);
                          setFieldValue("is_admin", e.target.checked);
                        }}
                        id="is_admin"
                        name="is_admin"
                      />
                    }
                    label="Is Admin?"
                  />
                </FormGroup>
              </Grid> */}
              {values.is_admin ? (
                <Grid item xs={12} md={6}>
                  <FormControl
                    fullWidth
                    error={touched.user_role && Boolean(errors.user_role)}
                    sx={{ height: 40 }}
                  >
                    <InputLabel sx={{ ml: -1.75 }}>
                      User Access Level (Role)
                    </InputLabel>
                    {countryState?.loading && (
                      <CircularProgress
                        size={20}
                        sx={{ position: "absolute", right: 25, bottom: 10 }}
                      />
                    )}
                    <Select
                      fullWidth
                      id="user_role"
                      name="user_role"
                      label="User Access Level (Role)"
                      value={values.user_role}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="standard"
                      className={styles.textField}
                      sx={{ height: 40 }}
                      // size="small"
                    >
                      {roleState?.items?.interItems?.data.map((item) => {
                        return (
                          <MenuItem key={item?.id} value={item?.name}>
                            {item?.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {touched.user_role && Boolean(errors.user_role) && (
                      <FormHelperText>
                        {touched.user_role && errors.user_role}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              ) : (
                <Grid item xs={12} md={6}>
                  <FormControl
                    fullWidth
                    error={touched.user_role && Boolean(errors.user_role)}
                    sx={{ height: 40 }}
                  >
                    <InputLabel sx={{ ml: -1.75 }}>
                      User Access Level (Role)
                    </InputLabel>
                    {countryState?.loading && (
                      <CircularProgress
                        size={20}
                        sx={{ position: "absolute", right: 25, bottom: 10 }}
                      />
                    )}
                    <Select
                      fullWidth
                      id="user_role"
                      name="user_role"
                      label="User Access Level (Role)"
                      value={values.user_role}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="standard"
                      className={styles.textField}
                      sx={{ height: 40 }}
                      // size="small"
                    >
                      {roleState?.items?.exterItems?.data.map((item) => {
                        return (
                          <MenuItem key={item?.id} value={item?.name}>
                            {item?.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {touched.user_role && Boolean(errors.user_role) && (
                      <FormHelperText>
                        {touched.user_role && errors.user_role}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              )}


              {/* <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  error={touched.sport_Id && Boolean(errors.sport_Id)}
                  sx={{ height: 40 }}
                >
                  <InputLabel sx={{ ml: -1.75 }}>Sport</InputLabel>
                  {organizationState?.loading && (
                    <CircularProgress
                      size={20}
                      sx={{ position: "absolute", right: 25, bottom: 10 }}
                    />
                  )}
                  <Select
                    fullWidth
                    id="sport_Id"
                    name="sport_Id"
                    label="Sport"
                    value={values.sport_Id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="standard"
                    className={styles.textField}
                    sx={{ height: 40 }}
                    // size="small"
                  >
                    {sportState?.items?.data.map((item) => {
                      return (
                        <MenuItem key={item?.id} value={item.id}>
                          {item?.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {touched.sport_Id && Boolean(errors.sport_Id) && (
                    <FormHelperText>
                      {touched.sport_Id && errors.sport_Id}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid> */}
              {/* {!values?.is_admin && 
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    id="organization_category"
                    name="organization_category"
                    label="Organization Category"
                    value={values.organization_category}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.organization_category && Boolean(errors.organization_category)}
                    helperText={touched.organization_category && errors.organization_category}
                    variant="standard"
                    className={styles.textField}
                    size="small"
                  />
                </Grid>
              } */}
              <Grid item xs={12} md={6}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.status}
                        onChange={(e) => {
                          // handleUserStatusToggle(e);
                          // console.log(e.target.checked)
                          setFieldValue(
                            "status",
                            e.target.checked === true ? 1 : 0
                          );
                        }}
                        id="status"
                        name="status"
                      />
                    }
                    label="Active"
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <Stack
                  direction="column-reverse"
                  justifyContent="space-between"
                  align-items="center"
                  sx={{
                    flexDirection: {
                      sm: "row",
                    },
                    mt: 2,
                  }}
                >
                  <Link to="../" style={{ display: "block" }}>
                    <Button
                      size="medium"
                      startIcon={<ArrowBackIcon size="small" />}
                      sx={{ textTransform: "none" }}
                    >
                      Back to {title}
                    </Button>
                  </Link>

                  <span style={{ flex: 1 }}>&nbsp;</span>

                  <LoadingButton
                    color="secondary"
                    variant="contained"
                    fullWidth
                    type="submit"
                    size="large"
                    loading={userState?.loading}
                    // loadingPosition="end"
                    sx={{
                      width: {
                        xs: "100%",
                        sm: "auto",
                      },
                    }}
                  >
                    {`Save ${titleSingle}`}
                  </LoadingButton>
                </Stack>
              </Grid>
            </Grid>
            {debug && (
              <>
                <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                <pre style={{ textAlign: "left" }}>
                  <strong>Values</strong>
                  <br />
                  {JSON.stringify(values, null, 2)}
                </pre>
                <pre style={{ textAlign: "left" }}>
                  <strong>Errors</strong>
                  <br />
                  {JSON.stringify(errors, null, 2)}
                </pre>
              </>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FormDetail;
