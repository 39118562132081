import React from "react";
import CreateCustomer from "./CreateCustomer";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { Container } from "@mui/system";
import { Stack, Typography } from "@mui/material";

const Customer = ({
  stopNext,
  formik,
  enableNewCustomer = true,
  newCustomer,
  setNewCustomer,
}) => {
  const handleCustomer = (status) => {
    setNewCustomer(status);
  };

  return (
    <div>
      {/* enter user details form title*/}
      <Container>
        <Stack
          justifyContent={"space-between"}
          sm={{ flexDirection: "column" }}
          xs={{ flexDirection: "column" }}
          flexDirection={"row"}
        >
          <Typography fontSize={"24px"}>Enter customer information</Typography>
          {enableNewCustomer && (
            <ButtonGroup aria-label="outlined primary button group">
              <Button
                variant="contained"
                onClick={() => handleCustomer(true)}
                color={newCustomer ? "neutral" : "default"}
              >
                NEW CUSTOMER
              </Button>

              <Button
                variant="contained"
                onClick={() => handleCustomer(false)}
                color={newCustomer ? "default" : "neutral"}
              >
                EXISTING CUSTOMER
              </Button>
            </ButtonGroup>
          )}
        </Stack>
      </Container>

      {/* forms */}
      <CreateCustomer
        newCustomer={newCustomer}
        stopNext={stopNext}
        formik={formik}
      />
    </div>
  );
};

export default Customer;
