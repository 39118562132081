import * as React from "react";
import { styled } from "@mui/material/styles";
import { Typography, Divider, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { convertToPlaces } from "../../helpers/helpers";

const Content = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
  orderInformationLabel: {
    marginTop: "12px !important",
    fontSize: 16,
    color: "rgba(0, 0, 0, 0.89)",
  },
  orderInformationValue: {
    marginBottom: "30px !important",
    fontSize: 14,
  },
});

const PaymentDetailsComponent = ({ ...props }) => {
  const classes = useStyles();
  const { invoiceDetails, orderIds } = props;
  const uniqIds = [...new Set(orderIds)];
  return (
    <Content>
      <Typography sx={{ fontSize: 20, color: "rgba(0, 0, 0, 0.87)" }}>
        Payment Details
      </Typography>
      <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6} sx={{ marginTop: "27px" }}>
          <Typography className={classes.orderInformationLabel}>
            Customer Name:
          </Typography>
          <Typography className={classes.orderInformationValue}>
            {invoiceDetails?.organization?.name}
          </Typography>

          <Divider></Divider>

          <Typography className={classes.orderInformationLabel}>
            Invoice Issued Date:
          </Typography>
          <Typography className={classes.orderInformationValue}>
            {moment(invoiceDetails?.issued_date).format("DD/MM/YYYY")}
          </Typography>

          <Divider></Divider>

          <Typography className={classes.orderInformationLabel}>
            Order Number:
          </Typography>
          <Typography className={classes.orderInformationValue}>
            {invoiceDetails?.sales_order?.id}
            {uniqIds.map((id, index) => (
              <>
                <span>{id}</span>
                {uniqIds.length - 1 !== index && <span>,</span>}
              </>
            ))}
          </Typography>

          <Divider></Divider>

          <Typography className={classes.orderInformationLabel}>
            Total Amount
          </Typography>
          <Typography className={classes.orderInformationValue}>
            {convertToPlaces(invoiceDetails?.final_grand_total)}
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ marginTop: "27px" }}>
          <Typography className={classes.orderInformationLabel}>
            Customer ID
          </Typography>
          <Typography className={classes.orderInformationValue}>
            {invoiceDetails?.organization?.id}
          </Typography>

          <Divider></Divider>

          <Typography className={classes.orderInformationLabel}>
            Invoice Sent Date:
          </Typography>
          <Typography className={classes.orderInformationValue}>
            {moment(invoiceDetails?.updated_at).format("DD/MM/YYYY")}
          </Typography>

          <Divider></Divider>

          <Typography className={classes.orderInformationLabel}>
            Payment Term:
          </Typography>
          <Typography className={classes.orderInformationValue}>
            {invoiceDetails?.payment_facility?.facility}
          </Typography>

          <Divider></Divider>

          <Typography className={classes.orderInformationLabel}>
            Balance to be Paid:
          </Typography>
          <Typography className={classes.orderInformationValue}>
            {convertToPlaces(invoiceDetails?.balance_amount)}
          </Typography>
        </Grid>
      </Grid>
    </Content>
  );
};

export default PaymentDetailsComponent;
