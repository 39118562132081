import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { couriersActions } from "../../../../../../actions";
import DataTable from "../../../../../common/ui/table/DataTable/DataTable.component";
import { apiConstants } from "../../../../../../constants";
import { authHeader } from "../../../../../../helpers";
import AlertToast from "../../../../../common/Toast";
import AlertToastSuccess from "../../../../../common/ToastSuccess";
import { useGetAllProdOrderTemplatesQuery } from "../../../../../../services/api/parameters/production.order.template.service";

const ProductionOrderTemplateList = () => {
  const columns = [
    { field: "id", headerName: "ID", width: 70 },

    {
      field: "name",
      headerName: "Name",
      minWidth: 200,
      flex: 1,
    },
  ];

  const recordIdentifier = "name";

  const { data, isLoading } = useGetAllProdOrderTemplatesQuery(undefined, {
    refetchOnFocus: true,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
  });

  return (
    <Box>
      <DataTable
        loading={isLoading}
        rows={data || []}
        columns={columns}
        recordIdentifier={recordIdentifier}
        actionIdentifier="production_order_template"
        isEnabledEdit={true}
        hasSeparateView={{ active: true, param: "id", suffix: "/view" }}
      />
    </Box>
  );
};

export default ProductionOrderTemplateList;
