import { checkIsAdmin } from "../../../helpers/helpers";
import { getAxiosInstance } from "../axios";
import { getValue, storeValue } from "../persist";

export class SalesOrderService {
  async getPaginatedSalesOrders(page) {
    const key = `sales-orders-${page}`;
    try {
      const value = await getValue(key);
      if (value) {
        return value;
      }

      const response = await getAxiosInstance().get(
        `/sales-orders?page=${page}`
      );
      const data = response.data;
      storeValue(key, data);
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async getPaginatedSalesOrdersByType(status, page) {
    const key = `sales-orders-${status}-${page}`;
    try {
      const value = await getValue(key);
      if (value) {
        return value;
      }

      const processedStatus =
        !status || status === 0 || status === "all" ? "" : `/status/${status}`;
      let path = `sales-orders${processedStatus}?page=${page}`;
      const response = await getAxiosInstance().get(path);
      const data = response.data;
      storeValue(key, data);
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async getSalesOrderByOrganizationName(organizationName, page) {
    try {
      const response = await getAxiosInstance().get(
        `/sales-orders?organization.name=${organizationName}&page=${page}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async getSalesOrderBySalesOrderId(salesOrderId) {
    try {
      const path = checkIsAdmin()
        ? `/sales-orders/${salesOrderId}`
        : `/customer/sales-orders/${salesOrderId}`;
      const response = await getAxiosInstance().get(path);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}
