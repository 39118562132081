import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { fabricActions } from "../../../../../../actions";
import DataTable from "../../../../../common/ui/table/DataTable/DataTable.component";

const Fabrics = () => {
  const dispatch = useDispatch();
  const fabrics = useSelector((state) => state.fabrics);

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "name",
      headerName: "Fabric Name",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "chinese_name",
      headerName: "Chinese Name",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "technical_name",
      headerName: "Technical Name",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 100,
      flex: 1,
      hide: true,
    },

    {
      field: "created_at",
      headerName: "Created At",
      width: 150,
      hide: true,
    },
    {
      field: "updated_at",
      headerName: "Updated At",
      width: 150,
      hide: true,
    },
  ];

  const recordIdentifier = "name";

  useEffect(() => {
    dispatch(fabricActions.getAllParameters());
  }, [dispatch]);

  return (
    <Box>
      {fabrics && (
        <DataTable
          loading={fabrics?.loading}
          rows={fabrics?.items?.data}
          columns={columns}
          recordIdentifier={recordIdentifier}
          actionIdentifier="fabricActions"
        />
      )}
    </Box>
  );
};

export default Fabrics;
