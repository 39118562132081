import * as React from 'react';
import { Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    section:{
        marginBottom:'60px'
    },
    termsAndConditionsHeader:{
        fontSize:'24px !important', 
        fontWeight:'500 !important', 
        marginBottom:'30px !important'
    },
    termsAndConditionRow:{
        display:'flex',
        "& .MuiTypography-root:first-child":{
            textAlign: 'right',
            width: '20px',
            marginRight:'9px'
        },
        "& .MuiTypography-root:last-child":{
            textAlign: 'left',
            width: '100%'
        }
    }
});

const TermsAndConditionComponent = () => {
    const classes = useStyles();

    return(
        <Box sx={{textAlign:'left'}}>
            <Box className={classes.section}>
                <Typography className={classes.termsAndConditionsHeader}>HOW TO PAY?</Typography>
                <Box>
                    <Typography sx={{marginBottom:'20px'}}>For direct deposits or Internet banking, please use the following:</Typography>

                    <Typography>Bank Name: ANZ Banking Group</Typography>
                    <Typography>BSB Number: 013-366 </Typography>
                    <Typography>Account Name: S-Trend Pty Ltd </Typography>
                    <Typography>Account Number: 4382 70697 </Typography>
                
                    <Typography sx={{marginTop:'20px'}}>Payment can also be made by cheque payable to S-TREND PTY Ltd</Typography>
                </Box>
            </Box>
            <Box className={classes.section}>
                <Typography className={classes.termsAndConditionsHeader}>PAYMENT TERMS</Typography>
                <Box>
                    <Typography>50% deposit before bulk production, balance paid before delivery.</Typography>
                    <Typography>S-Trend Sportswear remains the owner of all goods until payment is received in full</Typography>
                </Box>
            </Box>
            <Box className={classes.section}>
                <Typography className={classes.termsAndConditionsHeader}>TERMS AND CONDITIONS</Typography>
                <Box>
                    <Box className={classes.termsAndConditionRow}>
                        <Typography>1.</Typography>
                        <Typography>
                        <strong>Delivery: </strong>
                        Orders are delivered within 4-6 weeks from artwork approval, with the exception of some accessories. If you have specific deadlines, please let us know in advance as express options may be available.
                        </Typography>
                    </Box>
                    <Box className={classes.termsAndConditionRow}>
                        <Typography>2.</Typography>
                        <Typography><strong>Pricing: </strong> All prices exclude GST.</Typography>
                    </Box>
                    <Box className={classes.termsAndConditionRow}>
                        <Typography>3.</Typography>
                        <Typography>
                        <strong>Credit Card Payments: </strong> A 2% surcharge applies.
                        </Typography>
                    </Box>
                    <Box className={classes.termsAndConditionRow}>
                        <Typography>4.</Typography>
                        <Typography>
                        <strong>Payment Terms: </strong> A 50% deposit is required to begin production, with the balance due before dispatch.
                        </Typography>
                    </Box>
                    <Box className={classes.termsAndConditionRow}>
                        <Typography>5.</Typography>
                        <Typography>
                        <strong>Artwork Fees: </strong> Artwork is included with 3 revisions for orders of 16+ units per style. For orders under 16 units, a $50.00 design fee per style applies, with 50% refunded for orders of 5+ units.
                        </Typography>
                    </Box>
                    <Box className={classes.termsAndConditionRow}>
                        <Typography>6.</Typography>
                        <Typography>
                        <strong>Logo Format: </strong> Non-vector logos (.ai, .pdf, .eps) require a $20.00 redraw fee.
                        </Typography>
                    </Box>
                    <Box className={classes.termsAndConditionRow}>
                        <Typography>7.</Typography>
                        <Typography>
                        <strong>Personalisation: </strong> For sublimated items, personalisation such as names and numbers incurs an additional cost of $1/unit, this is already incorporated into your quoted unit price. 
                        </Typography>
                    </Box>
                    <Box className={classes.termsAndConditionRow}>
                        <Typography>8.</Typography>
                        <Typography>
                        <strong>Pre-Production Samples: </strong> The logo application on garments will be tailored to each style, with the provided artwork serving as a reference only for size and placement. We will provide pre-production visual samples for your approval before moving forward with bulk production.
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default TermsAndConditionComponent;