import React from "react";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const columns = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "percentage", headerName: "Percentage", width: 150 },
  { field: "country", headerName: "Country", width: 150 },
  { field: "orgid", headerName: "Organization ID", width: 150 },
  { field: "status", headerName: "Status", width: 150 },
];

const rows = [
  {
    id: 1,
    percentage: "10%",
    country: "Country 1",
    orgid: "Organization ID 1",
    status: "Active",
  },
  {
    id: 2,
    percentage: "20%",
    country: "Country 2",
    orgid: "Organization ID 2",
    status: "Active",
  },
  {
    id: 3,
    percentage: "20%",
    country: "Country 3",
    orgid: "Organization ID 3",
    status: "Active",
  },
  {
    id: 4,
    percentage: "30%",
    country: "Country 4",
    orgid: "Organization ID 4",
    status: "Active",
  },
  {
    id: 5,
    percentage: "40%",
    country: "Country 5",
    orgid: "Organization ID 5",
    status: "Active",
  },
];

const GstTax = () => {
  return (
    <Box>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        autoHeight={true}
        rowsPerPageOptions={[5]}
        // checkboxSelection
      />
    </Box>
  );
};

export default GstTax;
