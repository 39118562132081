import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  Grid,
  Typography,
  Box,
  ButtonGroup,
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useParams, Link } from "react-router-dom";
import { apiConstants } from "../../constants";
import { authHeader } from "../../helpers";
import PaymentSummaryDetails from "./PaymentSummaryDetails";
import Intro from "../atoms/Intro/Intro.component";
import InvoiceSteppers from "./InvoiceStepper";
import { VERTICAL } from "../../constants/Constants";
import PaymentDetailsComponent from "./PaymentDetails";
import RecordPaymentModal from "../../Common/Modals/RecordPaymentModal";
import AlertToast from "../common/Toast";
import AddCommentModal from "../../Common/Modals/AddCommentModal";
import moment from "moment";
import { checkPermission } from "../../helpers/helpers";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ViewInvoiceModal from "../../Common/Modals/ViewInvoiceModal";
import ProgressCircularWithBackdrop from "../../Common/ProgressCircular/ProgressCircularWithBackdrop";
import { PERMISSIONS } from "../../constants/data/rolesPermissions.constants";

const StyledStepperContainer = styled(Stack)({
  width: "100%",
  minHeight: "500px",
  marginTop: "27px",
});

const useStyles = makeStyles({
  invoiceHeaderTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "40px 0",
  },
  invoiceHeaderButtonGroup: {
    width: "100%",
    margin: "40px 0",
    borderColor: "transparent",
    "& .MuiButtonGroup-grouped:first-child": {
      color: "#fff !important",
      width: "100%",
      fontSize: 15,
      borderColor: "transparent !important",
      "&:hover": {
        backgroundColor: "#512879 !important",
      },
    },
    "& .MuiButtonGroup-grouped:nth-child(2)": {
      color: "#fff !important",
      width: "100%",
      fontSize: 15,
      backgroundColor: "#3F91D9",
      borderColor: "transparent !important",
    },
    "& .MuiButtonGroup-grouped:last-child": {
      width: "100%",
      color: "#fff !important",
      fontSize: 15,
      backgroundColor: "#12830b",
    },
  },
  invoiceSubHeaderContainer: {
    display: "flex",
    alignItems: "center",
    "& .MuiTypography-root": {
      fontWeight: "400 !important",
      fontSize: "13px !important",
      textTransform: "capitalize",
    },
  },
  editButton: {
    padding: "0 0 0 10px !important",
    fontSize: "13px !important",
    "& .MuiButton-startIcon>*:nth-of-type(1)": {
      fontSize: 13,
    },
  },
  sendInvoiceButton: {
    width: "100%",
    fontWeight: "700 !important",
    fontSize: "16px !important",
    color: "#fff !important",
  },
});

const ViewInvoicePaymentDetails = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [addModal, setAddModal] = useState(false);
  const closeAddModal = () => setAddModal(false);
  const showRecordPayment = checkPermission(PERMISSIONS.PAYMENT);

  const [openDialog, setOpenDialog] = React.useState(false);
  const handleClose = () => {
    setOpenDialog(false);
  };
  const [successMessage, setSuccessMessage] = React.useState(
    "Saved Successfully!"
  );

  const [openViewInvoice, setOpenViewInvoice] = React.useState(false);

  const [openProgressCircular, setOpenProgressCircular] = React.useState(false);

  const handleViewInvoiceClose = () => {
    setOpenViewInvoice(false);
  };

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to="/admin/invoices">
      All Invoices
    </Link>,
    <Typography key="3">{"View Invoice"}</Typography>,
  ];

  const [invoiceDetails, setInvoiceDetails] = React.useState({});
  const [activeStep, setActiveStep] = React.useState(1);
  const [steps, setSteps] = React.useState([
    {
      label: "Invoice Created",
      date: "",
      color: "default",
    },
    {
      label: "Invoice Sent",
      date: "",
      color: "default",
    },
  ]);

  const [toastState, setToastState] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });
  const [toastMessage, setToastMessage] = useState("");
  const handleToastClose = () => setToastState({ open: false });
  const [comment, setComment] = useState("");
  const [addNote, setAddNote] = useState(false);
  const salesOrderId = invoiceDetails?.sales_order?.id;
  const [orderIds, setOrderIds] = useState([]);
  const [disabledRecordPaymentButton, setDisabledRecordPaymentButton] =
    useState(false);
  const [
    showProductionOrderProceedButton,
    setShowProductionOrderProceedButton,
  ] = useState(true);

  const addMessage = (message) => {
    setOpenProgressCircular(true);
    setAddNote(false);
    //add message and generate production order.....
    const recordObj = {
      comment: message,
    };
    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify(recordObj),
    };
    fetch(
      `${apiConstants.API_URL}/invoices/${id}/proceed-for-production`,
      requestOptions
    ).then((response) => {
      response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
          if (response.status === 401) {
            // logout()
          }
          const error = (data && data.error) || response.statusText;
          const errorMessage = Object.values(error).map((errorKey) => {
            return errorKey[0];
          });
          setOpenProgressCircular(false);
          setToastMessage(errorMessage);
          setToastState({
            open: true,
            vertical: "top",
            horizontal: "right",
          });
        } else {
          setComment(() => message);
          setOpenProgressCircular(false);
          setSuccessMessage("Generate Production Order Successfully");
          setOpenDialog(true);
          setShowProductionOrderProceedButton(false);
        }
      });
    });
  };

  const dateFormate = (date) => {
    return moment(date).format("DD/MM/YYYY HH:mm:ss");
  };

  useEffect(() => {
    getInvoiceData();
  }, []);

  const getInvoiceData = () => {
    setOpenProgressCircular(true);
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    fetch(`${apiConstants.API_URL}/invoices/${id}`, requestOptions).then(
      (response) => {
        response.text().then((text) => {
          const data = text && JSON.parse(text);
          if (!response.ok) {
            setOpenProgressCircular(false);
          } else {
            const orderIdsArray = data?.data?.sales_order_items.map((order) => {
              return order?.sales_order_id;
            });
            setOrderIds(orderIdsArray);
            setInvoiceDetails(data?.data);
            let defaultSteps = [steps[0], steps[1]];
            defaultSteps[0].date = dateFormate(data?.data?.created_at);
            defaultSteps[1].date = dateFormate(data?.data?.created_at);
            const transactions = data?.data.account_transactions.map((a) => {
              return {
                label: a?.transaction_type?.name,
                date: dateFormate(a?.created_at),
                description: a?.note,
                amount: a?.amount / 100,
                image: a?.image,
              };
            });
            const combinedArray = defaultSteps.concat(transactions);
            setActiveStep(combinedArray.length - 1);
            setSteps(combinedArray);
            setOpenProgressCircular(false);
            const checkItemsInProduction = data?.data?.sales_order_items?.every(
              (el) => el?.production_order === null
            );
            setShowProductionOrderProceedButton(checkItemsInProduction);
          }
        });
      }
    );
  };

  const amountFormat = (amount) => {
    return parseFloat(amount / 100)?.toFixed(2);
  };

  const amountDecimalFormat = (amount) => {
    if (amount >= 0) {
      return parseFloat(amount).toFixed(2);
    }
  };

  const handleRecordPayment = () => {
    setAddModal(true);
  };

  const handleGenerateProductionOrder = () => {
    setAddNote(true);
  };

  const submitRecordPayment = (obj) => {
    setOpenProgressCircular(true);
    setDisabledRecordPaymentButton(true);

    const recordObj = {
      amount: obj.amount * 100,
      payment_date: obj.payment_date || obj.date,
      note: obj.note,
      external_id: null,
      // "recipient_id": null,
      giver_id: 3,
      transaction_type_id: obj?.payment_method?.id,
      invoice_id: invoiceDetails?.id,
      organization_id: invoiceDetails?.organization?.id,
      attachment: obj?.attachment,
    };

    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify(recordObj),
    };

    fetch(`${apiConstants.API_URL}/account-transactions`, requestOptions).then(
      (response) => {
        response.text().then((text) => {
          const data = text && JSON.parse(text);
          if (!response.ok) {
            if (response.status === 401) {
              // logout()
            }
            const error = (data && data.error) || response.statusText;
            const errorMessage = Object.values(error).map((errorKey) => {
              return errorKey[0];
            });
            setOpenProgressCircular(false);
            // setDisabledRecordPaymentButton(false);
            setToastMessage(errorMessage);
            setToastState({
              open: true,
              vertical: "top",
              horizontal: "right",
            });
          } else {
            getInvoiceData();
            setOpenProgressCircular(true);
            const BalanceToPaid =
              invoiceDetails?.final_grand_total -
              (invoiceDetails?.total_payed_amount + data?.data.amount);
            const statusObj = {
              status_id: BalanceToPaid === 0 ? 2 : 3,
            };
            const statusRequestOptions = {
              method: "PUT",
              headers: authHeader(),
              body: JSON.stringify(statusObj),
            };

            fetch(
              `${apiConstants.API_URL}/invoices/update-status/${id}`,
              statusRequestOptions
            ).then((res) => {
              res.text().then((t) => {
                const data1 = t && JSON.parse(t);
                if (!res.ok) {
                  if (res.status === 401) {
                    // logout()
                  }
                  const error = (data1 && data1.error) || res.statusText;
                  const errorMessage = Object.values(error).map((errorKey) => {
                    return errorKey[0];
                  });
                  setOpenProgressCircular(false);
                  setDisabledRecordPaymentButton(false);
                  setToastMessage(errorMessage);
                  setToastState({
                    open: true,
                    vertical: "top",
                    horizontal: "right",
                  });
                } else {
                  closeAddModal();
                  setDisabledRecordPaymentButton(false);
                  setOpenProgressCircular(false);
                }
              });
            });
          }
        });
      }
    );
  };

  return (
    <Box sx={{ p: 3 }}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} sm={12} md={8}>
          <Box className={classes.invoiceHeaderTitle}>
            <Intro
              pageTitle={`Invoice #${id}`}
              pageTitleShort="View Invoice"
              breadcrumbs={breadcrumbs}
              additionalWidgets=""
            />
          </Box>
        </Grid>
        {showRecordPayment && (
          <Grid item xs={12} sm={12} md={4}>
            <ButtonGroup
              variant="contained"
              className={classes.invoiceHeaderButtonGroup}
              aria-label="outlined primary button group"
            >
              <Button onClick={() => setOpenViewInvoice(true)}>
                View Invoice
              </Button>
              <Button onClick={handleRecordPayment}>Record Payment</Button>
            </ButtonGroup>
          </Grid>
        )}
        <Grid item xs={12} md={8}>
          <PaymentDetailsComponent
            invoiceDetails={invoiceDetails}
            orderIds={orderIds}
          />
          <Typography sx={{ fontSize: 20, color: "rgba(0, 0, 0, 0.87)" }}>
            Payment History
          </Typography>
          <StyledStepperContainer
            direction={"column"}
            sx={{ minWidth: "160px" }}
          >
            <InvoiceSteppers
              activeStep={activeStep}
              orientation={VERTICAL}
              steps={steps}
              alternativeLabel={false}
            />
          </StyledStepperContainer>
        </Grid>
        <Grid item xs={12} md={4}>
          <PaymentSummaryDetails
            invoiceDetails={invoiceDetails}
            amountFormat={amountFormat}
            amountDecimalFormat={amountDecimalFormat}
          />
          {showProductionOrderProceedButton && (
            <ButtonGroup
              variant="contained"
              className={classes.invoiceHeaderButtonGroup}
              aria-label="outlined primary button group"
            >
              <Button onClick={handleGenerateProductionOrder}>
                Generate Production Order
              </Button>
            </ButtonGroup>
          )}
        </Grid>
      </Grid>
      <RecordPaymentModal
        handleClose={closeAddModal}
        open={addModal}
        invoiceDetails={invoiceDetails}
        submitRecordPayment={submitRecordPayment}
        disabledSubmit={disabledRecordPaymentButton}
      />
      <AlertToast
        toastMessage={toastMessage}
        toastState={toastState}
        handleClose={handleToastClose}
      />
      <AddCommentModal
        open={addNote}
        handleClose={setAddNote}
        request={addMessage}
        type={"success"}
        buttonText={"Submit"}
        headerText={"Generate to Production Order"}
        bodyText={""}
        reason={
          "By Submitting this you will be confirming this to production order"
        }
        label={"Special Note"}
      />
      <ViewInvoiceModal
        open={openViewInvoice}
        handleClose={handleViewInvoiceClose}
      />
      <ProgressCircularWithBackdrop open={openProgressCircular} />
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <CheckCircleIcon
            color="success"
            sx={{
              position: "relative",
              top: "0.25rem",
              mr: 1,
              display: { xs: "block", sm: "inline-block" },
              margin: { xs: "0 0 1rem 0", sm: "0 0.6rem 0 0" },
            }}
          />
          {successMessage}
        </DialogTitle>

        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ViewInvoicePaymentDetails;
