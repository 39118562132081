import { Typography } from "@mui/material";
import * as React from "react";
import styled from "@emotion/styled";

const StyledTypography = styled(Typography)`
  color: ${(props) => {
    switch (props.status) {
      case "approved":
        return "#512879";
      case "accepted":
        return "#512879";
      case "pending":
        return "#000000";
      case "paid":
        return "#12830b";
      case "partially-paid":
        return "#512879";
      case "rejected":
        return "red";
      case "canceled":
        return "red";
      case "completed":
        return "#12830b";
      default:
        return "black";
    }
  }};
  font-size: 14px;
`;

const StatusChange = ({ ...props }) => {
  const { row, component } = props;
  return (
    <>
      {component === "salesOrder" && (
        <StyledTypography status={row.status.keyword}>
          {row.status.name}
        </StyledTypography>
      )}
      {row?.quotation_status && (
        <StyledTypography status={row.quotation_status.keyword}>
          {row.quotation_status.name}
        </StyledTypography>
      )}
      {component === "invoice" && (
        <StyledTypography status={row.status.keyword}>
          {row.status.keyword === "pending" ? "Invoice Sent" : row.status.name}
        </StyledTypography>
      )}
      {
        component === "productionOrders" &&
          (row.status.keyword === "completed" ? (
            <StyledTypography status={row.status.keyword}>
              {"Completed"}
            </StyledTypography>
          ) : (
            <StyledTypography status={row.status.keyword}>
              {row.status.name}
            </StyledTypography>
          ))
        //<StyledTypography status={row.status.keyword}>{row.status.name}</StyledTypography>
      }
    </>
  );
};

export default StatusChange;
