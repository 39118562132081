import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import * as React from "react";

const StyledTypography = styled(Typography)`
  color: ${(props) => {
    switch (props.status) {
      case "approved":
        return "#512879";
      case "accepted":
        return "#512879";
      case "pending":
        return "#000000";
      case "paid":
        return "#12830b";
      case "partially-paid":
        return "#512879";
      case "rejected":
        return "red";
      case "canceled":
        return "red";
      case "completed":
        return "#12830b";
      case "in_production":
        return "#09751b";
      default:
        return "black";
    }
  }};
  font-size: 14px;
`;

const SalesOrderStatusLable = ({ row }) => {
  return (
    <StyledTypography status={row.status.keyword}>
      {row.status.keyword === "in_production"
        ? "Release for Production"
        : row.status.name}
    </StyledTypography>
  );
};

export default SalesOrderStatusLable;
