import { makeStyles } from "@mui/styles";
import { theme } from "./Theme";

export const useStyles = makeStyles({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "5px 0 35px",
  },
  headerContainerMainTitle: {
    fontWeight: 500,
    fontSize: "1.6rem",
    lineHeight: "40.8px",
    color: "rgba(0, 0, 0, 0.87)",
    textAlign: "left",
  },
  headerContainerSubTitle: {
    fontSize: 16,
    lineHeight: "24px",
    color: "rgba(0, 0, 0, 0.6)",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
  },
  accordion: {
    boxShadow: "none !important",
    paddingTop: "50px",
  },
  accordionSummary: {
    margin: "0px",
    minHeight: "48px",
    backgroundColor: "#512879 !important",
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "0px !important",
    },
  },
  accordionSummaryTextContainer: {
    color: "#fff",
  },
  accordionDetails: {
    padding: "12px 0 !important",
    textAlign: "left",
    fontSize: 14,
  },
  cell: {
    textAlign: "left",
    fontSize: 14,
    fontWeight: 700,
  },
  buttonsContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginTop: "29px",
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
    marginTop: "29px",
  },
  addNewRowBtn: {
    fontWeight: "500 !important",
    fontSize: "15px !important",
    minWidth: "137px !important",
    height: "32px",
  },
  submitBtn: {
    fontSize: 15,
    color: "#fff !important",
    fontWeight: 500,
  },
  shippingSubmitBtn: {
    fontSize: 15,
    color: "#fff !important",
    fontWeight: 500,
    width: "156px",
  },
  select: {
    "& .MuiInput-input": {
      fontSize: "14px",
    },
    "& .MuiAutocomplete-endAdornment": {
      right: "18px",
    },
  },
  textField: {
    "& .MuiInputLabel-root": {
      fontSize: "14px !important",
    },
    "& .MuiFormLabel-root-MuiInputLabel-root": {
      fontSize: "14px !important",
    },
  },
  imageContainer: {
    width: "100%",
    margin: "12px 0",
  },
  tableHeader: {
    background: "#000",
    color: "#fff",
    padding: "12px 16px",
  },
  tableBodyCell: {
    marginTop: "19px !important",
    minWidth: 120,
    width: "100%",
  },
  specificationImageContainer: {
    textAlign: "center",
    marginTop: "30px",
  },
  itemRemoveIcon: {
    position: "absolute",
    right: 0,
    padding: "21px 0px",
    color: "rgba(0, 0, 0, 0.54)",
  },
  headerButton: {
    color: "#fff !important",
    fontWeight: "500 !important",
    fontSize: "15px !important",
    height: "46.67px",
    "&:hover": {
      backgroundColor: `${theme.palette.common.white} !important`,
      color: "#512879 !important",
      border: "1px solid #512879 !important",
    },
  },
  priceBookDetailsAction: {
    fontSize: "14px !important",
  },
  tableHeaderCell: {
    fontSize: "14px !important",
    fontWeight: "700 !important",
  },
  checkBoxContainer: {
    textAlign: "left",
    "& .MuiFormControlLabel-label": {
      fontSize: 14,
    },
  },
  notificationContainer: {
    backgroundColor: "rgba(2, 136, 209, 0.2)",
    borderRadius: "4px",
    padding: "14px 16px",
    alignItems: "center",
    gap: "13.83px",
    "& .MuiTypography-root": {
      fontSize: 14,
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
  priceBookEntryTitle: {
    marginBottom: "32px !important",
    fontSize: "24px !important",
    fontWeight: "400 !important",
    color: "rgba(0, 0, 0, 0.87) !important",
  },
  priceBookEntrySubTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
  },
  priceBookActionContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "absolute",
    bottom: 0,
    width: "100%",
    "& .MuiButton-root": {
      fontSize: "15px !important",
      "& :first-child": {
        color: "#512879",
      },
    },
    "& :last-child": {
      color: "#fff",
    },
  },
  liningCell: {
    "& :last-child": {
      display: "none",
    },
  },
  outlinedGroupButton: {
    height: "30px",
    "& .MuiButtonGroup-grouped:not(:first-of-type)": {
      width: "38px !important",
      backgroundColor: "rgba(25, 118, 210, 0.04)",
      fontSize: 12,
      "&:hover": {
        color: "#fff !important",
        backgroundColor: "#512879 !important",
      },
    },
    "& .MuiButtonGroup-grouped:not(:last-of-type)": {
      fontSize: 12,
      "&:hover": {
        color: "#fff !important",
        backgroundColor: "#512879 !important",
      },
    },
  },
});
