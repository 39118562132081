import { baseApi } from "..";

const quotationApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getQuotationById: builder.query({
      query: (id) => `quotations/${id}`,
      transformResponse: ({ data }) => data,
    }),

    createQuotation: builder.mutation({
      query: (data) => ({
        url: `quotations`,
        method: "POST",
        body: data,
      }),
      transformResponse: ({ data }) => data,
    }),
  }),

  overrideExisting: false,
});

export const { useGetQuotationByIdQuery, useCreateQuotationMutation } =
  quotationApi;
