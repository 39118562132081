import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  sportCategoryActions,
  storeItemActions,
  cartActions,
  sizeActions,
} from "../../../actions";
import { checkCart, getCartItem } from "../../../helpers";
import {
  Typography,
  Link,
  Grid,
  Box,
  Paper,
  Alert,
  Stack,
  CircularProgress,
} from "@mui/material";
import Intro from "../../../components/atoms/Intro/Intro.component";
import Content from "../../../components/layout/pageLayout/Content/Content.component";
import ItemComments from "../../../components/customer/store/ItemView/ItemComments/ItemComments.component";
// import PlayerNumbers from "../../../components/customer/store/ItemView/PlayerNumbers/PlayerNumbers.component";
import GarmentImage from "../../../components/customer/store/GarmentImage/GarmentImage.component";
// import AdditionalItems from "../../../components/customer/store/AdditionalItems/AdditionalItems.component";
import ItemDetails from "../../../components/customer/store/ItemView/ItemDetails/ItemDetails.component";
import DeliveryOptions from "../../../components/customer/store/ItemView/DeliveryOptions/DeliveryOptions.component";
import SizeChart from "../../../components/customer/store/ItemView/SizeChart/SizeChart.component";
import CartButton from "../../../components/customer/store/CartButton/CartButton.component";
import QuantitiesTable from "../../../components/customer/store/ItemView/QuantitiesTable/QuantitiesTable.component";
import QuantitiesInput from "../../../components/customer/store/ItemView/QuantitiesInput/QuantitiesInput.component";

const CustomerStoreItem = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { itemId } = useParams();

  const [currentItem, setCurrentItem] = useState({});
  const [isInCart, setIsInCart] = useState();
  const [deliveryMethod, setDeliveryMethod] = useState("");
  const [deliveryMethodError, setDeliveryMethodError] = useState(false);
  const [sizeQuantity, setSizeQuantity] = useState([]);
  const [sizeQuantityError, setSizeQuantityError] = useState(false);
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [itemTotal, setItemTotal] = useState(0);
  const [addToCartClicked, setAddToCartClicked] = useState(false);
  const [sizes, setSizes] = useState([]);
  const [data, setData] = useState({
    items: [],
  });
  const [comments, setComments] = useState("");

  const userState = useSelector((state) => state.authentication?.user);
  const storeItemsState = useSelector((state) => state.storeItems);
  const cartState = useSelector((state) => state.cartItems);
  const sizeState = useSelector((state) => state.sizes);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/store">
      Store - {userState?.organization?.name}
    </Link>,
    <Typography key="2">{currentItem?.name}</Typography>,
  ];

  // add to cart
  const handleAddtoCart = () => {
    // validate
    const isValid = validateCart();

    if (isValid) {
      // Update 'currentItem' with the new 'deliveryData'
      const itemData = {
        deliveryMethod: deliveryMethod,
        deliveryCharge: deliveryCharge,
        quantity: quantity,
        itemTotal: itemTotal,
        sizeQuantity: sizeQuantity,
        comments: comments,
      };

      setCurrentItem((prevCurrentItem) => ({
        ...prevCurrentItem,
        ...itemData,
      }));

      setAddToCartClicked(true);

      console.log(currentItem);
    }
  };

  // update cart
  const handleUpdateCart = () => {
    // validate
    const isValid = validateCart();

    if (isValid) {
      // Update 'currentItem' with the new data
      const itemData = {
        deliveryMethod: deliveryMethod,
        deliveryCharge: deliveryCharge,
        quantity: quantity,
        itemTotal: itemTotal,
        sizeQuantity: sizeQuantity,
        comments: comments,
      };

      setCurrentItem((prevCurrentItem) => ({
        ...prevCurrentItem,
        ...itemData,
      }));

      dispatch(
        cartActions.updateParameter({
          ...currentItem,
          ...itemData,
        })
      );

      console.log(currentItem);
    }
  };

  // remove from cart
  const handleRemoveFromCart = () => {
    dispatch(cartActions.deleteParameter(currentItem));
    setIsInCart(false);
  };

  // validate
  const validateCart = () => {
    // check if delivery method set
    if (!deliveryMethod) {
      setDeliveryMethodError(true);
      return false;
    } else {
      setDeliveryMethodError(false);
    }

    // check if sizes set
    if (sizeQuantity.length < 1) {
      setSizeQuantityError(true);
      return false;
    } else {
      setSizeQuantityError(false);
    }

    return true;
  };

  // TODO delivey fee calculation
  const calculateDeliveryFee = (deliveryMethod, quantity) => {
    const dFee =
      deliveryMethod === "deliver-to-client"
        ? 100
        : deliveryMethod === "deliver-to-s-trend"
        ? 50
        : 20;

    return dFee * quantity;
  };

  // update size/quantity
  const updateSizeQunatity = (values, mode, index) => {
    if (mode === "edit" && index !== null) {
      setSizeQuantity((prevSizeQuantity) => {
        const updatedArray = prevSizeQuantity.map((item, i) => {
          if (i === index) {
            return values; // Update the specific index
          }
          return item;
        });
        return updatedArray;
      });
    } else {
      setSizeQuantity([...sizeQuantity, values]);
    }
  };

  // current item
  useEffect(() => {
    const filterItemDetails = () => {
      const item = {};

      if (storeItemsState?.current) {
        const currentItem = storeItemsState?.current?.data;

        item.id = currentItem?.id;
        item.poId = currentItem?.production_order?.id;
        item.name = currentItem?.production_order?.price?.item?.name;
        item.style = currentItem?.production_order?.pattern_id;
        item.unitprice = currentItem?.production_order?.store_item?.unit_price;
        item.thumbnail =
          currentItem?.production_order?.image_url !== null
            ? currentItem?.production_order?.image_url
            : currentItem?.production_order?.thumbnail_url !== null
            ? currentItem?.production_order?.thumbnail_url
            : currentItem?.production_order?.store_item?.image_url;
        item.organization_id =
          currentItem?.production_order?.store_item?.organization_id;
      }

      setCurrentItem(item);
    };

    filterItemDetails();
  }, [storeItemsState]);

  // update quantity
  useEffect(() => {
    const calculateTotalQuantity = () => {
      if (sizeQuantity.length > 0) {
        const totalQuantity = sizeQuantity.reduce((total, item) => {
          return total + item?.quantity;
        }, 0);

        setQuantity(totalQuantity);
      }
    };

    calculateTotalQuantity();
  }, [sizeQuantity]);

  // update delivery fee
  useEffect(() => {
    const updateDeliveryMethod = () => {
      const dFee = calculateDeliveryFee(deliveryMethod, quantity);
      setDeliveryCharge(dFee);
    };
    updateDeliveryMethod();
  }, [deliveryMethod, quantity]);

  // update total
  useEffect(() => {
    const updateTotal = () => {
      setItemTotal(currentItem?.unitprice * quantity);
    };

    updateTotal();
  }, [currentItem?.unitprice, quantity]);

  //  get sport categories
  useEffect(() => {
    dispatch(sportCategoryActions.getAllParameters());
  }, [dispatch]);

  // get store items
  useEffect(() => {
    dispatch(storeItemActions.getSingleParameter(itemId));
  }, [dispatch, itemId]);

  // get sizes
  useEffect(() => {
    dispatch(sizeActions.getAllParameters());
  }, [dispatch]);

  // get all store items
  // useEffect(() => {
  //   dispatch(storeItemActions.getAllParameters());
  // }, [dispatch]);

  // check if the item already in cart
  useEffect(() => {
    const checkInCart = () => {
      const isPresent = checkCart(currentItem);

      if (isPresent) {
        const cItem = getCartItem(currentItem?.id);

        setIsInCart(isPresent);
        setDeliveryMethod(cItem?.deliveryMethod);
        setComments(cItem?.comments);
        setSizeQuantity(cItem?.sizeQuantity);
      }
    };

    checkInCart();
  }, [cartState.items, currentItem]);

  // submit on state change
  useEffect(() => {
    if (addToCartClicked) {
      // Check if the "Add to Cart" button was clicked
      if (
        (currentItem.deliveryMethod &&
          currentItem.deliveryCharge &&
          currentItem.quantity,
        currentItem.itemTotal)
      ) {
        dispatch(cartActions.addParameter(currentItem));
        setAddToCartClicked(false); // Reset the flag
      }
    }
  }, [addToCartClicked, currentItem, dispatch]);

  return (
    <>
      <Intro
        pageTitle={`${currentItem?.name ? currentItem.name : ""}`}
        pageTitleShort={`${currentItem?.name}`}
        pageSubTitle=""
        breadcrumbs={breadcrumbs}
        additionalWidgets=""
      />

      <Content>
        <Grid container>
          <Grid item xs={5} sx={{ pr: 6 }}>
            <Box>
              <Paper>
                <GarmentImage
                  id={currentItem?.poId}
                  thumbnail={currentItem?.thumbnail}
                  organizationId={currentItem?.organization_id}
                  padding={3}
                  minHeight={500}
                />
              </Paper>
            </Box>
            {/* <AdditionalItems /> */}
          </Grid>

          <Grid item xs={7}>
            <Grid container>
              <Grid item xs={12} sx={{ mb: 2 }}>
                <ItemDetails
                  currentItem={currentItem}
                  isInCart={isInCart}
                  handleAddtoCart={handleAddtoCart}
                  handleUpdateCart={handleUpdateCart}
                  handleRemoveFromCart={handleRemoveFromCart}
                  itemTotal={itemTotal}
                  unitprice={currentItem?.unitprice}
                  quantity={quantity}
                />
              </Grid>

              {sizeQuantityError && (
                <Grid item xs={12} sx={{ mb: 3 }}>
                  <Alert severity="error">
                    Please make sure to select <strong>SIZE</strong> and{" "}
                    <strong>QUANTITY</strong> before adding items to the cart
                  </Alert>
                </Grid>
              )}

              <Grid item xs={12} sx={{ mb: 4 }}>
                <DeliveryOptions
                  deliveryMethod={deliveryMethod}
                  deliveryMethodError={deliveryMethodError}
                  setDeliveryMethod={setDeliveryMethod}
                />
              </Grid>

              <Grid item xs={12} sx={{ mb: 4 }}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Box>
                    <Typography
                      variant="h4"
                      sx={{ fontSize: "1.1rem", fontWeight: 600 }}
                    >
                      Sizes and Quantities
                    </Typography>
                    <Typography variant="body2">
                      Please select the size and the quantities you wish to add
                      to the order
                    </Typography>
                  </Box>

                  <SizeChart />
                </Stack>

                <Grid item xs={12} sx={{ mt: 2 }}>
                  <Alert severity="warning" title="Important!">
                    Numbers and names will be printed exactly as per this table
                    so please ensure spelling and case of letters are correct
                    (e.g. Capitals)
                    <br />
                    <br />
                    If the names and/or numbers are not to be printed onto
                    garments and are packing purposes only, please specify under
                    “Comments”"
                    <br />
                    <br />
                    Please be sure to double check our size chart provided above
                    to ensire you receive your desired garment size.
                  </Alert>
                </Grid>

                <Grid item xs={12} sx={{ mt: 1 }}>
                  {sizeState?.loading && (
                    <CircularProgress size={24} sx={{ mt: 3 }} />
                  )}
                  {sizeState?.items?.data && (
                    <QuantitiesInput
                      sizes={sizeState?.items?.data}
                      updateSizeQunatity={updateSizeQunatity}
                    />
                  )}
                </Grid>

                <Grid item xs={12} sx={{ mt: 1 }}>
                  {sizeQuantity.length > 0 && (
                    <QuantitiesTable
                      sizeQuantity={sizeQuantity}
                      setSizeQuantity={setSizeQuantity}
                      sizes={sizeState?.items?.data}
                      updateSizeQunatity={updateSizeQunatity}
                      totalQuantity={quantity}
                    />
                  )}
                </Grid>
              </Grid>

              {/* <Grid item xs={12} sx={{ mb: 4 }}>
                <PlayerNumbers />
              </Grid> */}

              <Grid item xs={12} sx={{ mb: 0 }}>
                <ItemComments comments={comments} setComments={setComments} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Content>

      {cartState?.items.length > 0 && <CartButton />}
    </>
  );
};

export default CustomerStoreItem;
