import * as React from "react";
import { ArrowDropDown } from "@mui/icons-material";
import { styled, alpha } from "@mui/material/styles";
import { ButtonGroup, Button, Menu, MenuItem } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { PERMISSIONS } from "../../../constants/data/rolesPermissions.constants";
import { useStyles } from "../../../Theme/Style";
import { PRE_PRODUCTION_APPROVAL } from "../../../constants/Constants";
import { checkIsAdmin, checkPermission } from "../../../helpers/helpers";
import { useMemo } from "react";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    border: "1px solid #512879",
    borderRadius: "5px 0px 5px 5px",
    minWidth: 180,
    color: "#512879",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& a": {
      textDecoration: "none !important",
    },
    "& .MuiMenu-list": {
      padding: "4px 0",
      textTransform: "uppercase",
    },
    "& .MuiMenuItem-root": {
      fontSize: 12,
      fontWeight: 500,
      color: "#512879 !important",

      "& .MuiSvgIcon-root": {
        fontSize: 12,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export const ApprovalActionButton = ({ id, status, type }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const path = "pre-production-approval";

  const isToInitiate = useMemo(
    () => status === PRE_PRODUCTION_APPROVAL.STATUS.CREATED,
    []
  );

  const useCanInitiate = useMemo(
    () =>
      checkIsAdmin() &&
      isToInitiate &&
      checkPermission(PERMISSIONS.PRODUCTION_ORDERS),
    [isToInitiate]
  );

  const handleView = () => {
    if (checkIsAdmin()) {
      navigate(`/admin/${path}/${id}`);
      return;
    }
    navigate(`/${path}/${id}`);
  };

  const initiateApproval = () => {
    useCanInitiate && navigate(`/admin/${path}/${id}/initiate`);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const actions = React.useMemo(() => {
    switch (status) {
      case PRE_PRODUCTION_APPROVAL.STATUS.CREATED:
        return [];
      case PRE_PRODUCTION_APPROVAL.STATUS.SUBMITTED:
        return [];
      case PRE_PRODUCTION_APPROVAL.STATUS.APPROVED:
        return [];
      case PRE_PRODUCTION_APPROVAL.STATUS.REJECTED:
        return [
          {
            label: "Re-Initiate",
            action: () => {
              navigate(`/admin/${path}/${id}/initiate`);
            },
          },
        ];
      default:
        return [];
    }
  }, [status]);

  return (
    <div>
      <ButtonGroup
        aria-label="outlined primary button group"
        className={classes.outlinedGroupButton}
      >
        {!isToInitiate ? (
          <Button onClick={handleView}>{"View"}</Button>
        ) : (
          useCanInitiate && (
            <Button onClick={initiateApproval}>{"Initiate"}</Button>
          )
        )}

        {actions.length > 0 && (
          <>
            <Button
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              sx={{
                backgroundColor: "#512879 !important",
                color: "#fff !important",
              }}
              disableElevation
              onClick={handleClick}
            >
              <ArrowDropDown />
            </Button>

            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              {actions.map(({ label, action }, index) => (
                <MenuItem onClick={action} disableRipple key={index}>
                  {label}
                </MenuItem>
              ))}
            </StyledMenu>
          </>
        )}
      </ButtonGroup>
    </div>
  );
};
