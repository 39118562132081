// get fabrics
const getFabrics = (migrated, poFields) => {
  let fabrics = [];

  // format migrated data
  if (migrated === 1) {
    fabrics = poFields
      .filter((item) => item?.is_trim !== 1)
      .map(({ name, chinese_name, po_field_values }) => ({
        item: `${name} <br/>${chinese_name}`,
        type: `${po_field_values?.value} <br/>${po_field_values?.chinese_value}`,
        color: po_field_values?.color
          ? `${po_field_values?.color?.english_name} <br/> ${po_field_values?.color?.chinese_name}`
          : "Not specified",
      }));
  }

  return fabrics;
};

// get trims
const getTrims = (migrated, poFields) => {
  let trims = [];

  // format migrated data
  if (migrated === 1) {
    trims = poFields
      .filter((item) => item?.is_trim === 1)
      .map(({ name, chinese_name, po_field_values }) => ({
        item: `${name} <br/>${chinese_name}`,
        type: `${po_field_values?.value} <br/>${po_field_values?.chinese_value}`,
        color: po_field_values?.color
          ? `${po_field_values?.color?.english_name} <br/> ${po_field_values?.color?.chinese_name}`
          : "Not specified",
      }));
  }

  return trims;
};

// get labels
const getLabels = (labels) => {
  let labelData = [];

  if (labels) {
    labelData = labels.map(({ name, image_url }) => ({
      label: name,
      preview: `<img src="${image_url}" alt="${name} label" style="max-width: 200px;" />`,
    }));
  }

  // format migrated data
  // if (migrated === 1) {
  //   trims = poFields
  //     .filter((item) => item?.is_trim === 1)
  //     .map(({ name, chinese_name, po_field_values }) => ({
  //       item: `${name} <br/>${chinese_name}`,
  //       type: `${po_field_values?.value} <br/>${po_field_values?.chinese_value}`,
  //       color: po_field_values?.color
  //         ? `${po_field_values?.color}`
  //         : "Not specified",
  //     }));
  // }

  return labelData;
};

export { getFabrics, getTrims, getLabels };
