import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import styled from "@emotion/styled";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { ArrowBack } from "@mui/icons-material";
import Stepper from "../Common/Stepper";
import CustomerSalesDetails from "../components/CustomerSalesDetails";
import {
  ORDER_STEPS,
  ORDER_DETAIL,
  CONCEPT_DETAIL,
} from "../constants/Constants";
import CustomerConceptDetails from "../components/CustomerConceptDetails";
import SuccessModal from "../Common/Modals/SuccessModal";
import OrderSummery from "../components/SalesOrder/OrderSummery";
import { useParams, Link } from "react-router-dom";
import FailedModal from "../Common/Modals/FailedModal";
import Intro from "../components/atoms/Intro/Intro.component";
import { apiConstants } from "../constants";
import { authHeader } from "../helpers";
import AlertToast from "../components/common/Toast";
import { useNavigate } from "react-router-dom";
import CreateNewOrganizationModal from "../Common/Modals/CreateNewOrganizationModal";
import ProgressCircularWithBackdrop from "../Common/ProgressCircular/ProgressCircularWithBackdrop";
import ReactQuill from "react-quill";
import TextField from "@mui/material/TextField";
import InnerTopBanner from "../components/layout/pageLayout/InnerTopBanner/InnerTopBanner";

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.neutral.light,
  backgroundColor: theme.palette.success.main,
  width: "100%",
}));

const StyledButton2 = styled(Button)({
  color: "white",
});

const StyledCard = styled(Stack)(({ theme }) => ({
  minWidth: "350px",
  flexDirection: "column",
  display: "flex",
  textAlign: "left",
  gap: "10px",
}));
const style = {
  background: "#FFFFFF",
  boxShadow:
    "0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
  borderRadius: "4px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90%",
  padding: "10px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
};

const StyledStpper = styled(Box)({
  width: "800px !important",
});

const SalesOrderPage = () => {
  const navigate = useNavigate();

  const [activeStage, setAcitve] = useState(ORDER_DETAIL);
  const [openSuccess, setSuccess] = useState(false);
  const [items, setItems] = useState([]);
  const [orderId, setOrderId] = useState(null);
  const [quotation, setQuotation] = useState({});
  const { id, view } = useParams();
  const [colors, setColors] = useState([]);
  const [organization, setOrganization] = useState({});
  const [quotationId, setQuotationId] = useState("");
  const [sports, updateSports] = useState([]);
  const [openNewOrganization, setOpenNewOrganization] = useState(false);
  const [additionalBody, setAdditionalBody] = useState(
    "<p>Thank you for ordering with S-Trend Sportswear!</p><p>We are pleased to confirm that your Sales Order has been generated as per the confirmed quotation which you submitted.&nbsp;</p><p>The Sales Order can be viewed in following link. As our prices are dictated by the quantity ordered, please let us know if you wish to alter the quantities we have provided in the quote.</p><p>To move forward, please access the link and use your credentials to login to the system and complete the Sales Order by entering the required details as per the screens. Finally you have to confirm the Sales Order by pressing the “Confirm” button.&nbsp;</p><p><br></p>"
  );
  const [subject, setSubject] = useState("Sales Order Completed By Customer");
  const [open, setOpen] = React.useState(false);

  const [grandTotal, setGrandTotal] = useState(0);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to="/admin/sales-orders">
      All Sales Orders
    </Link>,
    <Typography key="1">{`${view ? "View" : "New"} Sale Order`}</Typography>,
  ];

  const [toastState, setToastState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });
  const [toastMessage, setToastMessage] = React.useState("");
  const handleToastClose = () => setToastState({ open: false });
  const [totalDelivery, setTotalDelivery] = React.useState(0);
  const [updateTotalDelivery, setUpdateTotalDelivery] = React.useState(0);
  const [openProgressCircular, setOpenProgressCircular] = React.useState(false);
  const [quotationStatus, setQuotationStatus] = React.useState(null);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };
    if (view) {
      fetch(`${apiConstants.API_URL}/sales-orders/${id}`, requestOptions).then(
        (response) => {
          response.text().then((text) => {
            const data = text && JSON.parse(text);
            console.log("data", data);
            setTotalDelivery(data?.data?.estimated_shipping_charges / 100);
            setUpdateTotalDelivery(
              data?.data?.estimated_shipping_charges / 100
            );
            setOrganization(data?.data?.organization);
            setQuotationStatus(data?.data?.status?.keyword);
            setGrandTotal(data?.data?.grand_total);

            const salesOrderItems = data.data.items;

            let newItems = [];
            salesOrderItems.map(function (s) {
              let extraCharges = s?.sales_order_item_extra_charges.map(
                (c) => c?.extra_charge
              );
              const object = {
                ...s,
                image: s.image,
                image_url: s.image_url,
                colorObj: s.color,
                color: s.color.english_name,
                color_id: s.color.id,
                note: s?.note,
                extra_charges_list: extraCharges,
              };
              newItems.push(object);
            });
            setItems(() => [...newItems]);
            if (data?.data.quotation?.id) {
              setQuotationId(data?.data.quotation.id);
              fetch(
                `${apiConstants.API_URL}/quotations/${data?.data.quotation.id}`,
                requestOptions
              ).then((response) => {
                response.text().then((text) => {
                  const res = text && JSON.parse(text);
                  //setOrganization(res?.data?.organization);

                  const quotationFiles = res?.data?.quotation_files.map((f) => {
                    f.name = f.file.split("/")[2].split("_")[1];
                    return f;
                  });
                  res.data.quotation_files = quotationFiles;
                  setQuotation(res?.data);
                });
              });
            }
          });
        }
      );
    } else {
      setQuotationId(id);
      fetch(`${apiConstants.API_URL}/quotations/${id}`, requestOptions).then(
        (response) => {
          response.text().then((text) => {
            const data = text && JSON.parse(text);
            setOrganization(data?.data?.organization);
            const { custom_items, items, estimated_shipping_charges } =
              data?.data;
            setTotalDelivery(estimated_shipping_charges / 100);
            setUpdateTotalDelivery(estimated_shipping_charges / 100);
            const listItems = items.map((l) => {
              let extraCharges = l?.quotation_item_extra_charges.map(
                (c) => c?.extra_charge
              );
              const item = {
                ...l,
                extra_charges_list: extraCharges,
              };
              return item;
            });

            setItems(() => [...custom_items, ...listItems]);
            const quotationFiles = data?.data?.quotation_files.map((f) => {
              f.name = f.file.split("/")[2].split("_")[1];
              return f;
            });
            data.data.quotation_files = quotationFiles;
            setQuotation(data?.data);
            if (!data?.data?.organization) {
              setOpenNewOrganization(true);
            }
          });
        }
      );
    }
    fetch(`${apiConstants.API_URL}/sports`, requestOptions).then((response) => {
      response.text().then((text) => {
        const data = text && JSON.parse(text);
        updateSports(data?.data);
      });
    });
    fetch(`${apiConstants.API_URL}/colors`, requestOptions).then((response) => {
      response.text().then((text) => {
        const data = text && JSON.parse(text);
        setColors(data?.data);
      });
    });
  }, [id]);

  let buttonText;
  if (view) {
    buttonText = activeStage === ORDER_DETAIL ? "NEXT" : "";
  } else {
    buttonText = activeStage === ORDER_DETAIL ? "NEXT" : "SUBMIT AND SEND";
  }

  const nextAction = () => {
    if (buttonText === "NEXT") {
      if (items.every((el) => el.color_id)) {
        setAcitve(CONCEPT_DETAIL);
      } else {
        setToastMessage("Color of every item should be selected");
        setToastState({
          open: true,
          vertical: "top",
          horizontal: "right",
        });
      }
      return;
    } else if (buttonText === "SUBMIT AND SEND") {
      submitModel();
    }

    // submitModel();
  };

  const submitSalesOrder = () => {
    setOpenProgressCircular(true);
    const orders = [];
    items?.map((item, index) => {
      const extraChargesList = item?.extra_charges_list?.map((x) => {
        return {
          extra_charge_id: x.id,
          cost: x.cost,
        };
      });

      delete item["colorObj"];
      if (item.selected) {
        delete item["id"];
      }

      if (
        !items.every((el) => el.estimated_shipping_charges) &&
        totalDelivery === updateTotalDelivery
      ) {
        const delivery = [
          {
            item_id: item.item_id || item.price?.item?.id,
            qty: item.qty,
          },
        ];

        const reqData = {
          items: delivery,
        };

        const requestOptions = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(reqData),
        };
        setOpenProgressCircular(false); // need to remove this after testing
        fetch(
          `${apiConstants.API_URL}/prices/shipping-charges`,
          requestOptions
        ).then((response) => {
          response.text().then((text) => {
            const data = text && JSON.parse(text);
            if (!response.ok) {
              const error = (data && data.error) || response.statusText;
              const errorMessage = Object.values(error).map((errorKey) => {
                return errorKey[0];
              });
              setToastMessage(errorMessage);
              setToastState({
                open: true,
                vertical: "top",
                horizontal: "right",
              });
            } else {
              item["estimated_shipping_charges"] =
                data?.data?.total_shipping_charges;
              let newObt = {
                ...item,
                price_id: item.selected ? item?.price_id : item?.price?.id,
                sales_order_item_extra_charges: extraChargesList,
                item_details: [],
                estimated_shipping_charges: item?.estimated_shipping_charges,
              };
              orders.push(newObt);
              if (items.length === orders.length) {
                submitData(orders);
              }
            }
          });
        });
      } else {
        const estimatedShippingCharges =
          totalDelivery !== updateTotalDelivery
            ? (updateTotalDelivery / items?.length) * 100
            : item?.estimated_shipping_charges;

        let newObt = {
          ...item,
          price_id: item.selected ? item?.price_id : item?.price?.id,
          sales_order_item_extra_charges: extraChargesList,
          item_details: [],
          estimated_shipping_charges: estimatedShippingCharges,
        };

        const {
          style,
          unit_discount,
          image,
          image_2,
          image_3,
          item_details_file_path,
          flag_name_no,
          qty,
          artwork_charges,
          extra_charges,
          parent_id,
          price_id,
          sales_order_item_extra_charges,
          item_details,
          unit_price,
          color_id,
          note,
        } = newObt;

        // TODO: fix sales order generation calculation issues
        orders.push({
          style,
          unit_discount,
          image,
          image_2,
          image_3,
          item_details_file_path,
          flag_name_no,
          qty,
          artwork_charges,
          extra_charges,
          parent_id,
          price_id,
          sales_order_item_extra_charges,
          item_details,
          unit_price,
          color_id,
          note,
        });

        if (items?.length === orders?.length) {
          submitData(orders);
        }
      }
    });
  };

  const submitData = (oldItems) => {
    let data = {
      order_method_id: 1,
      invoice_id: null,
      organization_id: organization?.id,
      quotation_id: quotationId,
      status_id: 1,
      items: oldItems,
      estimated_shipping_charges: updateTotalDelivery * 100,
      additional_email_body: additionalBody,
      email_subject: subject,
    };

    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify(data),
    };

    if (items.every((el) => el.image)) {
      fetch(`${apiConstants.API_URL}/sales-orders`, requestOptions).then(
        (response) => {
          response.text().then((text) => {
            const data = text && JSON.parse(text);
            if (!response.ok) {
              if (response.status === 401) {
                // logout()
              }
              const error = (data && data.error) || response.statusText;
              const errorMessage = Object.values(error).map((errorKey) => {
                return errorKey[0];
              });
              setOpenProgressCircular(false);
              setToastMessage(errorMessage);
              setToastState({
                open: true,
                vertical: "top",
                horizontal: "right",
              });
            } else {
              setOpenProgressCircular(false);
              setOrderId(data?.data?.id);
              setSuccess(!openSuccess);
            }
          });
        }
      );
    } else {
      setToastMessage("Design is required for every items");
      setToastState({
        open: true,
        vertical: "top",
        horizontal: "right",
      });
    }
  };

  const backToOrderDetails = () => {
    setAcitve(ORDER_DETAIL);
  };

  const updateItems = (index, newItem) => {
    let newArra = [...items];

    if (newItem) {
      newArra[index] = newItem;
      console.log(newArra, "newArra");
    } else {
      newArra = newArra.filter((x) => x.id !== newArra[index]?.id);
      handleGetDelivery(newArra, "delete");
    }
    setItems(newArra);
  };

  const addNewItems = (newItem) => {
    const itemArray = [...items];
    itemArray.push(newItem);
    handleGetDelivery(itemArray, "new");
    setItems(itemArray);
  };

  const handleGetDelivery = (newItems, type) => {
    const items = newItems.map((o) => {
      const delivery = {
        item_id: o.item_id || o.price?.item?.id,
        qty: o.qty,
      };
      return delivery;
    });

    const reqData = {
      items: items,
    };

    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify(reqData),
    };

    fetch(
      `${apiConstants.API_URL}/prices/shipping-charges`,
      requestOptions
    ).then((response) => {
      response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
          const error = (data && data.error) || response.statusText;
          const errorMessage = Object.values(error).map((errorKey) => {
            return errorKey[0];
          });
          setToastMessage(errorMessage);
          setToastState({
            open: true,
            vertical: "top",
            horizontal: "right",
          });
        } else {
          type !== "delete" &&
            setTotalDelivery(data?.data?.total_shipping_charges / 100);
          setUpdateTotalDelivery(data?.data?.total_shipping_charges / 100);
        }
      });
    });
  };

  const handleSuccessClose = () => {
    setSuccess(false);
    navigate("/admin/sales-orders");
  };

  const handleNewOrganizationClose = (organizationDetails) => {
    setOpenNewOrganization(false);
    setOrganization(organizationDetails);
  };

  const pageRender = () => {
    if (activeStage === ORDER_DETAIL) {
      return (
        <CustomerSalesDetails
          items={items}
          updateItems={updateItems}
          colors={colors}
          view={view}
          sports={sports}
          addNewItems={addNewItems}
          countryId={quotation?.country?.id}
          gstEnabled={quotation?.gst_enabled}
        />
      );
    }
    return (
      <CustomerConceptDetails
        items={items}
        updateItems={updateItems}
        setLoading={setOpenProgressCircular}
      />
    );
  };

  // console.log(items, "itmmmsssssssssssssssssss")

  function submitModel() {
    setOpen(true);
  }

  const onChange = (e) => {
    console.log(e);
    setAdditionalBody(() => e);
  };
  const onChange2 = (e) => {
    console.log(e);
    setSubject(() => e.target.value);
  };

  return (
    <>
      <Intro
        pageTitle={`${view ? "View" : "New"} Sale Order`}
        pageTitleShort={`${view ? "View" : "New"} Sale Order`}
        breadcrumbs={breadcrumbs}
        additionalWidgets=""
      />
      <InnerTopBanner
        mainText={organization?.name}
        subText={`Sales Order #${id}`}
      />
      <Stack spacing={2}>
        <StyledStpper>
          <Stepper steps={ORDER_STEPS} />
        </StyledStpper>
        <Stack direction={"row"} spacing={1}>
          <Card style={{ textAlign: "left", padding: "10px" }}>
            {pageRender()}
          </Card>
          <StyledCard>
            {items !== 0 && (
              <OrderSummery
                grandTotal={grandTotal}
                items={items}
                organization_name={quotation?.organization_name}
                file={quotation?.quotation_files}
                totalDelivery={totalDelivery}
                updateTotalDelivery={updateTotalDelivery}
                setUpdateTotalDelivery={setUpdateTotalDelivery}
                handleGetDelivery={handleGetDelivery}
                view={view}
              />
            )}
            {buttonText !== "" && (
              <StyledButton
                style={{ height: "68px" }}
                endIcon={<ArrowForwardIcon />}
                variant="contained"
                onClick={() => nextAction()}
                disabled={items.length === 0}
              >
                {buttonText}
              </StyledButton>
            )}
            {activeStage !== ORDER_DETAIL && (
              <Button
                style={{ height: "68px" }}
                startIcon={
                  <ArrowBack sx={{ paddingRight: "12px", width: "32px" }} />
                }
                variant="text"
                onClick={() => backToOrderDetails()}
              >
                Back Order Details
              </Button>
            )}
            {
              /* accept behalf of the customer */
              quotationStatus === "pending" && (
                <Box>
                  <Button
                    size="large"
                    sx={{ width: "100%", padding: "15px" }}
                    variant="contained"
                    // onClick={handleAcceptQuotation} disabled={!isAcceptBehlafClientChecked}
                    onClick={() => navigate(`/admin/sales-orders/accept/${id}`)}
                  >
                    Accept Sales order for customer
                  </Button>
                </Box>
              )
            }
          </StyledCard>
        </Stack>

        <SuccessModal
          open={openSuccess}
          handleClose={handleSuccessClose}
          title="Sales Order Submitted Successfully"
          body={`Sales order #${orderId} is submitted successfully and sent to the customer email ${quotation?.organization_email}. Thank you`}
        />
        <FailedModal />
        <CreateNewOrganizationModal
          openOrganization={openNewOrganization}
          handleClose={handleNewOrganizationClose}
          quotation={quotation}
        />
        <ProgressCircularWithBackdrop open={openProgressCircular} />
        <AlertToast
          toastMessage={toastMessage}
          toastState={toastState}
          handleClose={handleToastClose}
        />
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Stack
              direction={"column"}
              spacing={2}
              sx={{ padding: "0 5px 0 5px" }}
            >
              <TextField
                id="standard-basic"
                label="Email Subject"
                variant="standard"
                defaultValue={subject}
                onChange={onChange2}
              />
              <br />
              <br />
              <TextField
                id="standard-basic"
                label="Email Subject"
                variant="standard"
                defaultValue={subject}
                onChange={onChange2}
              />
              <br />
              <br />
              <Typography fontSize={"16px"}>
                Dear (<i>customer_name</i>),
              </Typography>
              <ReactQuill
                style={{ height: "230px" }}
                value={additionalBody}
                onChange={onChange}
              />

              <Typography style={{ paddingTop: "50px" }}>
                The Sales Order can be accessed by the URL (xxxxxxxxxxxxxxxxx)
                and Confirm Option is available.
                <br />
                Have any questions? Please contact
                customerservice@s-trend.com.au or simply reply to this email and
                we will be in touch as soon as possible.
                <br />
                Kind regards,
                <br />
                S-Trend Accounts Team
              </Typography>
            </Stack>
            <Stack direction={"row"} justifyContent="space-between">
              <Button color="success" onClick={() => setOpen(false)}>
                CLOSE
              </Button>
              <StyledButton2
                color={"success"}
                variant="contained"
                onClick={() => submitSalesOrder()}
                // disabled={disabledSubmitButton}
              >
                Save and Submit
              </StyledButton2>
            </Stack>
          </Box>
        </Modal>
      </Stack>
    </>
  );
};

export default SalesOrderPage;
