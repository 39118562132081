import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Grid,
  Button,
  FormControl,
  TextField,
  Autocomplete,
  Typography,
  Card,
  CardMedia,
  DialogContent,
  Dialog,
  AppBar,
  Toolbar,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useStyles } from "../../../Theme/Style";
import { PRE_PRODUCTION_APPROVAL } from "../../../constants/Constants";
import { capitalizeFirstLetter } from "../../../helpers/helpers";
import { useState } from "react";

const ApprovalDetailTemplate = ({ approvalData }) => {
  const classes = useStyles();
  const [image, setImage] = useState({ open: false, uri: "" });

  if (approvalData?.status !== PRE_PRODUCTION_APPROVAL.STATUS.APPROVED)
    return null;

  const handleClose = () => {
    setImage({ open: false, uri: undefined });
  };

  const openImageView = (uri) => {
    setImage({ open: true, uri });
  };

  return (
    <>
      <Accordion className={classes.accordion}>
        <AccordionSummary
          className={classes.accordionSummary}
          expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid container className={classes.accordionSummaryTextContainer}>
            <Grid item xs={4}>
              {capitalizeFirstLetter(approvalData.type)} Approval
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <Box className={classes.tableHeader}>
            <Grid container spacing={2}>
              {approvalData.image_urls.map((uri, index) => (
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} key={index}>
                  <Card>
                    <CardMedia
                      component={"img"}
                      height={350}
                      image={uri}
                      onClick={() => openImageView(uri)}
                    />
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Dialog open={image.open} onClose={handleClose} fullScreen>
        <DialogContent>
          <AppBar elevation={0} sx={{ position: "relative" }}>
            <Toolbar sx={{ px: { sm: 2 } }}>
              <Typography sx={{ flex: 1 }} variant="h6" component="div">
                Image Preview
              </Typography>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <img src={image.uri} alt="image" style={{ width: "100%" }} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export const PreProductionApprovalDetailsComponent = ({
  preProdApprovals: { designApproval, prototypeApproval },
}) => {
  const classes = useStyles();

  return (
    <>
      <ApprovalDetailTemplate approvalData={designApproval} />
      <ApprovalDetailTemplate approvalData={prototypeApproval} />
    </>
  );
};
