import { getAxiosInstance } from "../axios";
import { getValue, storeValue } from "../persist";

export class QuotationsService {
  async getPaginatedQuotations(page) {
    const key = `quotations-${page}`;
    try {
      const persistedValue = getValue(key);
      if (persistedValue) {
        return persistedValue;
      }

      const response = await getAxiosInstance().get(`quotations?page=${page}`);
      storeValue(key, response.data);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async getPaginatedQuotationByStatus(status, page) {
    const key = `quotations-${status}-${page}`;
    try {
      const persistedValue = getValue(key);
      if (persistedValue) {
        return persistedValue;
      }

      const processedStatus =
        !status || status === 0 || status === "all" ? "" : `/status/${status}`;
      let path = `quotations${processedStatus}?page=${page}`;
      const response = await getAxiosInstance().get(path);
      storeValue(key, response.data);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async getQuotationByOrganizationName(organizationName, page) {
    try {
      const response = await getAxiosInstance().get(
        `/quotations?organization.name=${organizationName}&page=${page}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async getQuotationByQuotationId(quotationId) {
    const key = `quotations-${quotationId}`;
    try {
      const persistedValue = getValue(key);
      if (persistedValue) {
        return persistedValue;
      }

      const response = await getAxiosInstance().get(`/quotations?id=${quotationId}`);
      storeValue(key, response.data);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async viewQuotationAsAdmin(quotationId) {
    try {
      const response = await getAxiosInstance().get(`/quotations/${quotationId}`);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async viewQuotationAsCustomer(quotationId) {
    try {
      const response = await getAxiosInstance().get(
        `/customer/quotations/${quotationId}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async viewPublicQuotationToAccept(quotationGuid) {
    try {
      const response = await getAxiosInstance().get(
        `/customer/quotations/${quotationGuid}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async getAllCustomerQuotations(page) {
    const key = `customer-quotations-${page}`;
    try {
      const persistedValue = getValue(key);
      if (persistedValue) {
        return persistedValue;
      }

      const response = await getAxiosInstance().get(
        `/customer/quotations?page=${page}`
      );
      storeValue(key, response.data);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async updateQuotationById(quotationId, notes) {
    try {
      const response = await getAxiosInstance().put(
        `/quotations/${quotationId}/accept-quotation`,
        notes
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}
