import React, {useState} from 'react';
import Intro from "../../../components/atoms/Intro/Intro.component";
import Content from "../../../components/layout/pageLayout/Content/Content.component";
import {
    Box,
    Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    FormControl,
    FormHelperText,
    Grid, IconButton,
    Input, InputAdornment,
    InputLabel,
    Paper,
    Stack,
    Typography
} from "@mui/material";
import {Form, Formik} from "formik";
import styles from "./Login.module.scss";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import * as Yup from "yup";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {apiConstants} from "../../../constants";
import {authHeader, getCurrentUser, getUserToken} from "../../../helpers";
import {userActions} from "../../../actions/user.actions";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

const ChangePassword = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const formikRef = React.createRef();
    const isAdmin = getCurrentUser()?.is_admin === 1;
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [content, setContent] = useState();

    const validationSchema = Yup.object({
        oldPassword: Yup.string().required("Old Password is required"),
        newPassword: Yup.string().required("New Password is required"),
        confirmPassword: Yup.string().required("Confirm Password is required").oneOf([Yup.ref('newPassword')], 'Passwords must match'),
    });

    const initialValues = {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
    };

    const breadcrumbs = [];


    const handleSubmit = (values, props) => {
        handleLForgetPw(values);
    };

    const handleLForgetPw = (values) => {
        if (values.oldPassword && values.newPassword && values.confirmPassword) {
            const requestOptions = {
                method: "POST",
                headers: authHeader(),
                body: JSON.stringify({
                    current_password: values.oldPassword,
                    password: values.newPassword,
                    password_confirmation: values.confirmPassword,
                }),
            };

            fetch(`${apiConstants.API_URL}${isAdmin?'/password/change':'/customer/password/reset'}`, requestOptions)
                .then((response) => {
                    response.text().then((text) => {
                        const data = text && JSON.parse(text);
                        if (response.status === 200) {
                            dispatch(userActions.logout());
                            navigate(isAdmin ? "/auth/login" : "/login");
                        } else {
                            setShowErrorMessage(true)
                            // setAlertType('error')
                            setContent(data.error)
                        }
                    })
                })

        }
    };

    function handleClose() {
        setShowErrorMessage(false)
    }

    return (
        <>
            <Intro
                pageTitle={`Change password`}
                pageTitleShort={`Change password`}
                breadcrumbs={breadcrumbs}
                additionalWidgets=""
            />

            <Content>
                <Container sx={{ml: 0, px: "0 !important"}}>
                    <Paper
                        elevation={2}
                        sx={{
                            width: "100%",
                            p: {
                                xs: 2,
                                md: 4,
                            },
                        }}
                    >
                        <Grid item xs={12}>
                            <Stack direction={'row'} justifyContent='space-between'>
                                <Typography variant="h3"
                                            sx={{
                                                fontSize: {
                                                    xs: "1.1rem",
                                                    md: "1.2rem",
                                                },
                                            }}>
                                    {'Change password'}
                                </Typography>
                            </Stack>
                            <Grid container spacing="2">
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    innerRef={formikRef}
                                    onSubmit={(values, {resetForm}) => {
                                        handleSubmit(values);
                                        // resetForm();
                                    }}
                                >
                                    {({
                                          values,
                                          touched,
                                          errors,
                                          handleChange,
                                          handleBlur,
                                          isValid,
                                          setFieldValue,
                                      }) => (
                                        <Form noValidate autoComplete="off">
                                            <Grid
                                                container
                                                rowSpacing={2}
                                                sx={{
                                                    marginTop: {xs: "0.5rem"},
                                                    marginBottom: {xs: "1rem"},
                                                }}
                                            >
                                                <Grid item xs={12}>
                                                    <FormControl
                                                        fullWidth
                                                        variant="standard"
                                                        error={touched.oldPassword && Boolean(errors.oldPassword)}
                                                        className={styles.textField}
                                                    >
                                                        <InputLabel htmlFor="password">Old Password</InputLabel>
                                                        <Input
                                                            fullWidth
                                                            id="oldPassword"
                                                            name="oldPassword"
                                                            label="Old Password"
                                                            value={values.oldPassword}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            variant="standard"
                                                            required
                                                            type={showOldPassword ? "text" : "password"}
                                                            autoComplete="current-password"
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            setShowOldPassword(!showOldPassword);
                                                                        }}
                                                                        onMouseDown={(e) => e.preventDefault()}
                                                                        edge="end"
                                                                    >
                                                                        {showOldPassword ? <VisibilityOff /> : <Visibility />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                        />

                                                        {touched.oldPassword && errors.oldPassword && (
                                                            <FormHelperText>{errors.oldPassword}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <FormControl
                                                        fullWidth
                                                        variant="standard"
                                                        error={touched.newPassword && Boolean(errors.newPassword)}
                                                        className={styles.textField}
                                                    >
                                                        <InputLabel htmlFor="password">New Password</InputLabel>
                                                        <Input
                                                            fullWidth
                                                            id="newPassword"
                                                            name="newPassword"
                                                            label="New Password"
                                                            value={values.newPassword}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            variant="standard"
                                                            required
                                                            type={showNewPassword ? "text" : "password"}
                                                            autoComplete="current-password"
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            setShowNewPassword(!showNewPassword);
                                                                        }}
                                                                        onMouseDown={(e) => e.preventDefault()}
                                                                        edge="end"
                                                                    >
                                                                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                        />

                                                        {touched.newPassword && errors.newPassword && (
                                                            <FormHelperText>{errors.newPassword}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <FormControl
                                                        fullWidth
                                                        variant="standard"
                                                        error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                                                        className={styles.textField}
                                                    >
                                                        <InputLabel htmlFor="password">Confirm Password</InputLabel>
                                                        <Input
                                                            fullWidth
                                                            id="confirmPassword"
                                                            name="confirmPassword"
                                                            label="Confirm Password"
                                                            value={values.confirmPassword}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            variant="standard"
                                                            required
                                                            type={showConfirmPassword ? "text" : "password"}
                                                            autoComplete="current-password"
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            setShowConfirmPassword(!showConfirmPassword);
                                                                        }}
                                                                        onMouseDown={(e) => e.preventDefault()}
                                                                        edge="end"
                                                                    >
                                                                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                        />

                                                        {touched.confirmPassword && errors.confirmPassword && (
                                                            <FormHelperText>{errors.confirmPassword}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Button
                                                        fullWidth
                                                        type="submit"
                                                        size="large"
                                                        variant="contained"
                                                        color="secondary"
                                                        sx={{marginTop: "1rem"}}
                                                    >
                                                        Change Password
                                                    </Button>
                                                </Grid>

                                            </Grid>
                                        </Form>
                                    )}
                                </Formik>
                            </Grid>
                        </Grid>
                    </Paper>
                </Container>
            </Content>

            <Dialog
                open={showErrorMessage}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <CheckCircleIcon
                        color="warning"
                        sx={{
                            position: "relative",
                            top: "0.25rem",
                            mr: 1,
                            display: { xs: "block", sm: "inline-block" },
                            margin: { xs: "0 0 1rem 0", sm: "0 0.6rem 0 0" },
                        }}
                    />
                    password reset
                </DialogTitle>

                {content && (
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {typeof content === "string" && content}
                        </DialogContentText>
                    </DialogContent>
                )}

                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ChangePassword;
