import React from "react";
import PropTypes from "prop-types";
import { Box, Stack, styled, Typography } from "@mui/material";
import { INFO, SUCCESS, ERROR } from "../constants/Constants";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const StyedBox = styled(Box)({
  background:
    "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #0288D1;",
  padding: "1rem",
  borderRadius: "5px",
  minHeight: "3rem",
});

const StyledText = styled(Typography)({
  fontSize: "15px",
});
const Notification = ({ ...props }) => {
  const { title, body, extra } = props;

  return (
    <StyedBox>
      <Stack direction={"row"} spacing={1}>
        <ErrorOutlineIcon color="primary" />
        <Stack>
          {title && (
            <Typography style={{ fontSize: "15px", fontWeight: 500 }}>
              {title}
            </Typography>
          )}
          <StyledText>{body}</StyledText>
          <StyledText>{extra}</StyledText>
        </Stack>
      </Stack>
    </StyedBox>
  );
};

Notification.propTypes = {
  type: PropTypes.oneOf([INFO, SUCCESS, ERROR]),
  title: PropTypes.string,
  body: PropTypes.string,
};
Notification.defaultProps = {
  type: INFO,
  title: "",
  body: "",
  extra: "",
};

export default Notification;
