import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
  Grid,
  Typography,
  Box,
  Stack,
  InputBase,
  Button,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  FormControlLabel,
} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import Intro from "../../../components/atoms/Intro/Intro.component";
import Content from "../../../components/layout/pageLayout/Content/Content.component";
import BoxedContent from "../../../components/layout/pageLayout/BoxedContent/BoxedContent.component";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RoleTable from "../../../components/admin/RoleManagement/RoleTable/RoleTable.component";
import { roleActions } from "../../../actions/role.actions";

const PageWidgets = ({ buttonLabel, searchLabel, parameter }) => {
  const navigate = useNavigate();

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={1}
      >
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase placeholder={`Search ${searchLabel}`} />
        </Search>
        <Button
          variant="contained"
          startIcon={<AddCircleOutlineOutlinedIcon />}
          onClick={() => navigate("add")}
          size="large"
        >
          Add New Role
        </Button>
      </Stack>
    </Box>
  );
};

const RoleList = () => {
  const dispatch = useDispatch();
  const roleState = useSelector((state) => state.roles);
  const { parameter } = useParams();
  const [roleType, setRoleType] = useState("internal");
  const [isAdmin, setIsAdmin] = useState(true);
  const [rolesDetails, setRolesDetails] = useState();
  const [roles, setRoles] = useState([]);

  const breadcrumbs = [<Typography key="2">Role Management</Typography>];

  useEffect(() => {
    dispatch(roleActions.getAllInternalRole());
    setRolesDetails(roleState);
  }, []);

  useEffect(() => {
    if (isAdmin) {
      setRoles(roleState?.items?.interItems?.data);
    } else {
      setRoles(roleState?.items?.exterItems?.data);
    }
  }, [roleState?.items]);

  const handleRoleTypeChange = (e) => {
    setRoleType(e.target.value);
    if (e.target.value === "internal") {
      setIsAdmin(true);
      dispatch(roleActions.getAllInternalRole());
    } else {
      setIsAdmin(false);
      dispatch(roleActions.getAllExternalRole());
    }
  };

  return (
    <>
      <Intro
        pageTitle="Role Management"
        pageTitleShort="Role Management"
        breadcrumbs={breadcrumbs}
        additionalWidgets={
          <PageWidgets
            buttonLabel="User"
            searchLabel="Role Management"
            parameter={parameter}
          />
        }
      />

      <Content>
        <Grid container spacing="2">
          <Grid item xs={12}>
            <BoxedContent title="All Roles" subtitle="" description="">
              <FormControl>
                <FormLabel id="user_type">Role Type</FormLabel>
                <RadioGroup
                  aria-labelledby="user_type"
                  name="user_type"
                  value={roleType}
                  onChange={(e) => {
                    handleRoleTypeChange(e);
                  }}
                  row
                >
                  <FormControlLabel
                    value="internal"
                    control={<Radio />}
                    label="Internal"
                  />
                  <FormControlLabel
                    value="external"
                    control={<Radio />}
                    label="External"
                  />
                </RadioGroup>
              </FormControl>
              {isAdmin ? (
                <RoleTable
                  isAdmin={isAdmin}
                  roles={roles}
                  loading={rolesDetails?.loading}
                />
              ) : (
                <RoleTable
                  isAdmin={isAdmin}
                  roles={roles}
                  loading={rolesDetails?.loading}
                />
              )}
            </BoxedContent>
          </Grid>
        </Grid>
      </Content>
    </>
  );
};

export default RoleList;

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  transition: "all 0.3s ease-in-out",
  color: "#232323",
  backgroundColor: alpha(theme.palette.common.black, 0.05),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.black, 0.15),
  },
  "&:focus": {
    backgroundColor: alpha(theme.palette.common.black, 0.15),
  },
  marginRight: theme.spacing(1),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    // marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    fontSize: "0.85rem",
    width: "100%",
    height: "1.85rem",
    [theme.breakpoints.up("md")]: {
      minWidth: "10rem",
    },
  },
}));
