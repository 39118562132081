import { useTheme } from "@emotion/react";
import { Box, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import * as React from "react";

import SwipeableViews from "react-swipeable-views";
import { from, map, take } from "rxjs";
import { useNotification } from "../../../Contexts/GlobalNotificationContext";
import { useLoading } from "../../../Contexts/GlobalLoadingContext";
import { InvoicesService } from "../../../services/api/invoices/invoices.service";
import { Breadcrumbs } from "../../atoms/Breadcrumbs/Breadcrumbs";
import TabsComponent from "../../atoms/CustomTabs/CustomTabs";
import { TabPanel } from "../../atoms/TabPanel/TabPanel";
import { InvoicesTableWithFilter } from "../../molecules/Invoices/InvoicesTableWithFilter";
import { GeneralPageLayout } from "../../molecules/PageLayout/GeneralPageLayout";
import { useDispatch, useSelector } from "react-redux";
import { paymentTermActions } from "../../../actions";
import { LoadingButton } from "@mui/lab";

const invoicesService = new InvoicesService();

const getStatusCode = (status) => {
  switch (status) {
    case 0:
      return "all";
    // case 1:
    //   return "pending";
    // case 2:
    //   return "paid";
    // case 3:
    //   return "partially-paid";
    // case 4:
    //   return "canceled";
    default:
      return "all";
  }
};

const Content = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  textAlign: "left",
  padding: "29px 37px",
  color: theme.palette.text.secondary,
}));

const InvoicesPage = () => {
  const theme = useTheme();

  const [status, setStatus] = React.useState("all");
  const [value, setValue] = React.useState(0);
  const [searchCustomer, setSearchCustomer] = React.useState("");
  const [filterByPOId, setFilterByPOId] = React.useState("");
  const [filterByDates, setFilterByDates] = React.useState({
    start_date: "",
    end_date: "",
  });
  const [invoices, setInvoices] = React.useState([]);
  const [paginationData, setPaginationData] = React.useState({});
  const [pages, setPages] = React.useState(20);
  const [filterByInvoiceId, setFilterByInvoiceId] = React.useState("");
  const { showLoader, hideLoader } = useLoading();
  const { addNotification } = useNotification();

  const handleTabChange = React.useCallback((event, newValue) => {
    setValue(newValue);
    setStatus(getStatusCode(newValue));
  }, []);

  const getInvoicesByOrganization = React.useCallback(
    async (page) => {
      showLoader();
      const nextPage = page + 1;
      const subscription = from(
        invoicesService.getInvoiceByOrganizationName(searchCustomer, nextPage)
      )
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            setPaginationData(response.meta);
            setInvoices(response.data);
            hideLoader();
          },
          error: (error) => {
            addNotification({ message: error.message, type: "error" });
            hideLoader();
          },
        });

      return () => subscription.unsubscribe();
    },
    [addNotification, hideLoader, searchCustomer, showLoader]
  );

  const getInvoicesByPOId = React.useCallback(
    async (page) => {
      showLoader();
      const nextPage = page + 1;
      const subscription = from(
        invoicesService.getInvoiceByPOId(filterByPOId, nextPage)
      )
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            setPaginationData(response.meta);
            setInvoices(response.data);
            hideLoader();
          },
          error: (error) => {
            addNotification({ message: error.message, type: "error" });
            hideLoader();
          },
        });

      return () => subscription.unsubscribe();
    },
    [addNotification, filterByPOId, hideLoader, showLoader]
  );

  const getInvoicesByDate = React.useCallback(
    async (page) => {
      showLoader();
      const nextPage = page + 1;
      const subscription = from(
        invoicesService.getInvoiceByDates(filterByDates, nextPage)
      )
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            setPaginationData(response.meta);
            setInvoices(response.data);
            hideLoader();
          },
          error: (error) => {
            addNotification({ message: error.message, type: "error" });
            hideLoader();
          },
        });

      return () => subscription.unsubscribe();
    },
    [addNotification, filterByDates, hideLoader, showLoader]
  );

  const getInvoiceByInvoiceId = React.useCallback(async () => {
    showLoader();
    const subscription = from(invoicesService.getInvoiceById(filterByInvoiceId))
      .pipe(
        take(1),
        map((response) => ({
          data: [response.data],
          meta: {
            current_page: 1,
            last_page: 1,
            per_page: 1,
            total: 1,
            from: 1,
            to: 1,
          },
        }))
      )
      .subscribe({
        next: (response) => {
          setPaginationData(response.meta);
          setInvoices(response.data);
          hideLoader();
        },
        error: (error) => {
          addNotification({ message: error.message, type: "error" });
          hideLoader();
        },
      });

    return () => subscription.unsubscribe();
  }, [filterByInvoiceId, addNotification, hideLoader, showLoader]);

  const getInvoicesByPage = React.useCallback(
    async (page) => {
      showLoader();
      const nextPage = page + 1;
      const subscription = from(
        invoicesService.getPaginatedInvoicesByStatus(status, nextPage, pages)
      )
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            setPaginationData(response.meta);
            setInvoices(response.data);
            hideLoader();
          },
          error: (error) => {
            addNotification({ message: error.message, type: "error" });
            hideLoader();
          },
        });

      return () => subscription.unsubscribe();
    },
    [addNotification, hideLoader, showLoader, status, pages]
  );

  const handlePageChange = React.useCallback(
    (page) => {
      if (searchCustomer !== "") {
        getInvoicesByOrganization(page);
        return;
      }

      if (filterByInvoiceId !== "") {
        getInvoiceByInvoiceId();
        return;
      }

      if (filterByPOId !== "") {
        getInvoicesByPOId(page);
        return;
      }

      if (filterByDates.start_date !== null && filterByDates.end_date !== null) {
        getInvoicesByDate(page);
        return;
      }

      getInvoicesByPage(page);
    },
    [searchCustomer, filterByInvoiceId, filterByPOId, filterByDates.start_date, filterByDates.end_date, getInvoicesByPage, getInvoicesByOrganization, getInvoiceByInvoiceId, getInvoicesByPOId, getInvoicesByDate]
  );

  const clearFilters = React.useCallback(() => {
    setSearchCustomer("");
    setFilterByInvoiceId("");
    setFilterByPOId("");
    setFilterByDates({ start_date: "", end_date: "" });
    getInvoicesByPage(0);
  }, [getInvoicesByPage]);

  React.useEffect(() => {
    // Initial load - run only once
    (async () => {
      await getInvoicesByPage(0);
    })();
    // Empty dependency array to ensure it runs only once on mount
  }, []);
  

  React.useEffect(() => {
    
    if (searchCustomer !== "" || filterByInvoiceId !== "" || filterByPOId !== "" || filterByDates.start_date !== null || filterByDates.end_date !== null) {
      return;
    }

    (async () => {
      await getInvoicesByPage(0);
    })();
  }, [filterByInvoiceId, searchCustomer, filterByPOId, filterByDates, pages, getInvoicesByPage]);

  // get payment terms
  const dispatch = useDispatch();
  const paymentTerms = useSelector((state) => state.paymentTerms);
  React.useEffect(() => {
    dispatch(paymentTermActions.getAllParameters());
  }, [dispatch]);

  return (
    <GeneralPageLayout
      pageTitle="All Invoices"
      breadcrumbs={<Breadcrumbs lastStep="All Invoices" />}
    >
      <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
        <Box gridColumn="span 12">
          <Box>
            <TabsComponent
              value={value}
              handleChange={handleTabChange}
              tabLabels={[
                "All Invoices",
                // "Invoices - Pending",
                // "Invoices - Paid",
                // "Invoices - Partialy Paid",
                // "Invoices - Canceled",
              ]}
            />

            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
            >
              {[0, 1, 2, 3, 4].map((item, index) => (
                <TabPanel
                  key={index}
                  value={value}
                  index={item}
                  dir={theme.direction}
                >
                  <InvoicesTableWithFilter
                    enableFilters={item === 0 ? true : false}
                    invoicesViewLink="/admin/invoices/$id$/view"
                    getInvoiceByOrganization={getInvoicesByOrganization}
                    getInvoiceByPOId={getInvoicesByPOId}
                    getInvoiceByDate={getInvoicesByDate}
                    handlePageChange={handlePageChange}
                    getInvoiceByInvoiceId={getInvoiceByInvoiceId}
                    searchCustomer={searchCustomer}
                    setSearchCustomer={setSearchCustomer}
                    clearFilters={clearFilters}
                    setFilterByPOId={setFilterByPOId}
                    setFilterByDates={setFilterByDates}
                    filterByPOId={filterByPOId}
                    filterByDates={filterByDates}
                    invoices={invoices}
                    paginationData={paginationData}
                    setFilterByInvoiceId={setFilterByInvoiceId}
                    filterByInvoiceId={filterByInvoiceId}
                    paymentTerms={paymentTerms?.items?.data}
                    pages={pages}
                    setPages={setPages}
                  />
                </TabPanel>
              ))}
            </SwipeableViews>
          </Box>
        </Box>
      </Box>
    </GeneralPageLayout>
  );
};

export default InvoicesPage;
