import styled from "@emotion/styled";
import {
  Box,
  Button,
  Input,
  Modal,
  Stack,
  Typography,
  FormHelperText,
} from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  REJECT_REQUEST,
  ADD_REQUEST,
  REQUEST_REASON,
  REJECT_REASON,
} from "../../constants/Constants";

const StyledButton = styled(Button)({
  color: "white",
});

const style = {
  background: "#FFFFFF",
  boxShadow:
    "0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
  borderRadius: "4px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "600px",
  height: "365px",
  padding: "10px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
};

const AddtionalCustomerQuotation = ({ ...props }) => {
  const { open, handleClose, type, request, error } = props;

  const buttonType = type === ADD_REQUEST ? "success" : "error";
  const buttonText = type === ADD_REQUEST ? ADD_REQUEST : REJECT_REQUEST;
  const headerText =
    type === ADD_REQUEST ? "Additional Request" : "Request Change";
  const bodyText = type === ADD_REQUEST ? REQUEST_REASON : REJECT_REASON;
  const reason =
    type === ADD_REQUEST ? "Your Requests" : "Reason(s) for Change";

  const [reasons, setReasons] = useState("");
  const onChange = (e) => {
    setReasons(() => e.target.value);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack direction={"column"} spacing={2} sx={{ padding: "0 5px 0 5px" }}>
          <Typography fontSize={"24px"}>{headerText}</Typography>
          <Typography fontSize={"14px"}>{bodyText}</Typography>
          <Typography fontSize={"16px"} style={{ color: "#00000099" }}>
            {reason}
          </Typography>
        </Stack>
        <Input style={{ outline: "none" }} onChange={onChange} />
        {error?.value && (
          <FormHelperText id="component-error-text" sx={{ color: "red" }}>
            {error.message}
          </FormHelperText>
        )}
        <Stack direction={"row"} justifyContent="space-between">
          <Button color="success" onClick={() => handleClose(!open)}>
            CLOSE
          </Button>
          <StyledButton
            color={buttonType}
            variant="contained"
            onClick={() => request("reject", reasons)}
          >
            {buttonText}
          </StyledButton>
        </Stack>
      </Box>
    </Modal>
  );
};

AddtionalCustomerQuotation.propTypes = {
  open: PropTypes.bool,

  handleClose: PropTypes.func.isRequired,

  type: PropTypes.oneOf([REJECT_REQUEST, ADD_REQUEST]),

  request: PropTypes.func.isRequired,
};
AddtionalCustomerQuotation.defaultProps = {
  open: false,
  type: REJECT_REQUEST,
};

export default AddtionalCustomerQuotation;
