import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { styled } from "@mui/styles";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { setAtiveSport } from "../store/Store/orderSlice";

const StyledTabs = styled(Tabs)({
  backgroundColor: "#E0E0E0",
});

const ScrollableTabsButtonAuto = ({ tabs, id }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(id?0:'');

  React.useEffect(() => {
    if(tabs.length !== 0){
      setValue(tabs.length-1);
      dispatch(setAtiveSport(tabs[tabs.length-1]?.id));
    }
  },[tabs, dispatch]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(setAtiveSport(tabs[newValue]?.id || 0));
  };

  if (!tabs) return;

  return (
    <Box style={{ margin: "10px 0" }}>
      {tabs.length !==0 && 
      <StyledTabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        {tabs.map((tab) => (
          <Tab label={tab?.name} />
        ))}
      </StyledTabs>
      }
    </Box>
  );
};

ScrollableTabsButtonAuto.propTypes = {
  /**
   * Tabs to be displayed
   *
   * */
  tabs: PropTypes.array,
};
ScrollableTabsButtonAuto.defaultProps = {
  tabs: [],
};

export default ScrollableTabsButtonAuto;
