import { Box, CircularProgress, Paper, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import { useParams } from "react-router-dom";
import { apiConstants } from "../../constants";
import { authHeader } from "../../helpers";
import Intro from "../atoms/Intro/Intro.component";
import AlertToast from "../common/Toast";
import ProductionOrderDetails from "./ProductionOrderDetails";

const Content = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  textAlign: "left",
  padding: "29px 37px",
  color: theme.palette.text.secondary,
}));

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "5px 0 35px",
  },
  headerContainerMainTitle: {
    fontWeight: 500,
    fontSize: 34,
    lineHeight: "40.8px",
    color: "rgba(0, 0, 0, 0.87)",
    textAlign: "left",
  },
  headerContainerSubTitle: {
    fontSize: 16,
    lineHeight: "24px",
    color: "rgba(0, 0, 0, 0.6)",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
  },
  headerContainerSubTitleIcon: {
    fontSize: "14px !important",
    color: "rgba(0, 0, 0, 0.54)",
    padding: "0 14.9px",
  },
  headerButtonContainer: {
    display: "flex",
    alignItems: "center",
  },
  printButtonStyle: {
    fontSize: 14,
    fontWeight: 500,
    backgroundColor: "#F5F5F5 !important",
    borderColor: "#d2d0d0 !important",
  },
  downloadButtonStyle: {
    fontSize: 14,
    fontWeight: 500,
    backgroundColor: "#E0E0E0 !important",
  },
  tabs: {
    background: "#fff",
    border: "1px solid #512879",
    color: "#512879",
    borderRadius: "4px",
    "& .MuiTab-root.Mui-selected": {
      background: "#512879",
      color: "#fff",
    },
  },
});

const ProductionOrder = () => {
  const theme = useTheme();
  const classes = useStyles();
  const { proceededProductionOrder } = useParams();

  const breadcrumbs = [
    <Typography key="3">
      {proceededProductionOrder
        ? "All Invoices In Production"
        : "All Production Order"}
    </Typography>,
  ];

  const [value, setValue] = React.useState(0);
  const [loadingComponent, setLoadingComponent] = React.useState(false);
  const [loadingTabComponent, setLoadingTabComponent] = React.useState(false);
  const [allProductionOrders, setAllProductionOrders] = React.useState([]);
  const [pendingProductionOrders, setPendingProductionOrders] = React.useState(
    []
  );
  const [completeProductionOrders, setCompleteProductionOrders] =
    React.useState([]);
  const [approveProductionOrders, setApproveProductionOrders] = React.useState(
    []
  );
  const [allInvoiceInProduction, setAllInvoiceInProduction] = React.useState(
    []
  );
  const [paginationData, setPaginationData] = React.useState({});
  const [paginationSalesOrderData, setPaginationSalesOrderData] =
    React.useState({});
  const [status, setStatus] = React.useState("all");

  const [toastState, setToastState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });

  const [toastMessage, setToastMessage] = React.useState("");
  const handleToastClose = () => setToastState({ open: false });

  const getAllProductOrders = (number) => {
    setLoadingComponent(true);
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    fetch(
      `${apiConstants.API_URL}/production-orders?page=${number}`,
      requestOptions
    ).then((response) => {
      response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
          if (response.status === 401) {
            // logout()
          }
          const error = (data && data.error) || response.statusText;
          const errorMessage = Object.values(error).map((errorKey) => {
            return errorKey[0];
          });
          setLoadingComponent(false);
          setToastMessage(errorMessage);
          setToastState({
            open: true,
            vertical: "top",
            horizontal: "right",
          });
        } else {
          setAllProductionOrders(data?.data);
          setPaginationData(data.meta);
          setLoadingComponent(false);
        }
      });
    });
  };

  const getProductOrdersStatusByPage = (type, page) => {
    setLoadingTabComponent(true);
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    if (type !== "all") {
      fetch(
        `${apiConstants.API_URL}/production-orders?status=${type}&page=${page}`,
        requestOptions
      ).then((response) => {
        response.text().then((text) => {
          const data = text && JSON.parse(text);
          if (type === "pending") {
            setPendingProductionOrders(data?.data);
            setPaginationData(data?.meta);
          } else if (type === "completed") {
            setCompleteProductionOrders(data?.data);
            setPaginationData(data?.meta);
          } else if (type === "approved") {
            setApproveProductionOrders(data?.data);
            setPaginationData(data?.meta);
          }
          setLoadingTabComponent(false);
        });
      });
    }
  };

  const getAllInvoiceInProduction = (number) => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    fetch(
      `${apiConstants.API_URL}/production-ready-invoices?page=${number}`,
      requestOptions
    ).then((response) => {
      response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
          if (response.status === 401) {
            // logout()
          }
          const error = (data && data.error) || response.statusText;
          const errorMessage = Object.values(error).map((errorKey) => {
            return errorKey[0];
          });

          setToastMessage(errorMessage);
          setToastState({
            open: true,
            vertical: "top",
            horizontal: "right",
          });
        } else {
          setAllInvoiceInProduction(data?.data);
          setPaginationSalesOrderData(data.meta);
        }
      });
    });
  };

  React.useEffect(() => {
    getAllProductOrders("1");
    getAllInvoiceInProduction("1");
  }, []);

  const handleInvoiceInProductionsPageChange = (newPage) => {
    getAllInvoiceInProduction(newPage + 1);
  };

  const handlePageChange = (newPage) => {
    if (status === "pending" || status === "complete" || status === "approve") {
      getProductOrdersStatusByPage(status, newPage + 1);
    } else {
      getAllProductOrders(newPage + 1);
    }
  };

  const handleProductionOrdersTab = (type) => {
    if (type !== "all") {
      getProductOrdersStatusByPage(type, "1");
    } else {
      getAllProductOrders("1");
    }
  };

  return (
    <Box sx={{ flexGrow: 1, padding: "20px 20px" }}>
      <Box className={classes.headerContainer}>
        <Intro
          pageTitle={
            proceededProductionOrder
              ? "All Invoices In Production"
              : "All Production Order"
          }
          pageTitleShort=""
          breadcrumbs={breadcrumbs}
          additionalWidgets=""
        />
      </Box>
      <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
        {/* <Box gridColumn="span 3">
                    <ProductionOrdersSideBar/>
                </Box> */}
        <Box gridColumn="span 12">
          <Content>
            {loadingComponent ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                }}
              >
                <CircularProgress size={50} />
              </Box>
            ) : (
              <ProductionOrderDetails
                productionOrders={allProductionOrders}
                pendingProductionOrders={pendingProductionOrders}
                completeProductionOrders={completeProductionOrders}
                approveProductionOrders={approveProductionOrders}
                paginationData={paginationData}
                handlePageChange={handlePageChange}
                handleInvoiceInProductionsPageChange={
                  handleInvoiceInProductionsPageChange
                }
                status={proceededProductionOrder}
                allInvoiceInProduction={allInvoiceInProduction}
                paginationSalesOrderData={paginationSalesOrderData}
                setStatus={setStatus}
                handleProductionOrdersTab={handleProductionOrdersTab}
                loadingTabComponent={loadingTabComponent}
              />
            )}
          </Content>
        </Box>
      </Box>
      <AlertToast
        toastMessage={toastMessage}
        toastState={toastState}
        handleClose={handleToastClose}
      />
    </Box>
  );
};
export default ProductionOrder;
