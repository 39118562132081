import React from "react";
import { Switch, Case, Default } from "react-if";

import PaymentTerms from "./PaymentTerms/PaymentTerms.component";
import SportCategories from "./SportsCategories/SportCategories.component";
import Fabrics from "./Fabrics/Fabrics.component";
import QuantityRanges from "./QuantityRanges/QuantityRanges.component";
import ItemsStyles from "./ItemsStyles/ItemsStyles.component";
import ProductionOrder from "./ProductionOrders/ProductionOrders.component";
import Pattern from "./Patterns/Patterns.component";
import Labels from "./Labels/Labels.component";
import GstTax from "./GstTax/GstTax.component";
import Colors from "./Colors/Colors.component";
import DeliveryFees from "./DeliveryFees/DeliveryFees.component";
import BankDetails from "./BankDetails/BankDetails.component";
import Linings from "./Linings/Linings.component";
import ExtraCharges from "./ExtraCharges/ExtraCharges.component";
import ArtworkCharges from "./ArtworkCharges/ArtworkCharges.component";
import Measurements from "./Measurements/Measurements.component";
import Sizes from "./Sizes/Sizes.component";
import SizingCharts from "./SizingCharts/SizingCharts.component";
import TrimsList from "./Trims/trims.component";
import CouriersList from "./Couriers/couriers.component";
import PositionsList from "./Position/position.component";
import ProductionOrderTemplateList from "./ProductionOrderTemplate/ProductionOrderTemplate.component";
import PaymentMethodsList from "./PaymentMethod/PaymentMethods.component";

const ParameterTable = (props) => {
  const { parameter } = props;

  return (
    <div>
      <Switch>
        <Case condition={parameter?.slug === "payment-terms"}>
          <PaymentTerms
            title={parameter?.title}
            titleSingle={parameter?.titleSingular}
          />
        </Case>
        <Case condition={parameter?.slug === "sports-categories"}>
          <SportCategories
            title={parameter?.title}
            titleSingle={parameter?.titleSingular}
          />
        </Case>
        <Case condition={parameter?.slug === "fabrics"}>
          <Fabrics
            title={parameter?.title}
            titleSingle={parameter?.titleSingular}
          />
        </Case>
        <Case condition={parameter?.slug === "quantity-ranges"}>
          <QuantityRanges
            title={parameter?.title}
            titleSingle={parameter?.titleSingular}
          />
        </Case>
        <Case condition={parameter?.slug === "items-styles"}>
          <ItemsStyles
            title={parameter?.title}
            titleSingle={parameter?.titleSingular}
          />
        </Case>
        <Case condition={parameter?.slug === "production-order-items"}>
          <ProductionOrder
            title={parameter?.title}
            titleSingle={parameter?.titleSingular}
          />
        </Case>
        <Case condition={parameter?.slug === "patterns"}>
          <Pattern
            title={parameter?.title}
            titleSingle={parameter?.titleSingular}
            paramSlug={parameter?.slug}
          />
        </Case>
        <Case condition={parameter?.slug === "sizing-charts"}>
          <SizingCharts
            title={parameter?.title}
            titleSingle={parameter?.titleSingular}
          />
        </Case>
        <Case condition={parameter?.slug === "labels"}>
          <Labels
            title={parameter?.title}
            titleSingle={parameter?.titleSingular}
          />
        </Case>
        <Case condition={parameter?.slug === "gst-tax"}>
          <GstTax
            title={parameter?.title}
            titleSingle={parameter?.titleSingular}
          />
        </Case>
        <Case condition={parameter?.slug === "colors"}>
          <Colors
            title={parameter?.title}
            titleSingle={parameter?.titleSingular}
          />
        </Case>
        <Case condition={parameter?.slug === "delivery-fees"}>
          <DeliveryFees
            title={parameter?.title}
            titleSingle={parameter?.titleSingular}
          />
        </Case>
        <Case condition={parameter?.slug === "bank-details"}>
          <BankDetails
            title={parameter?.title}
            titleSingle={parameter?.titleSingular}
          />
        </Case>
        <Case condition={parameter?.slug === "linings"}>
          <Linings
            title={parameter?.title}
            titleSingle={parameter?.titleSingular}
          />
        </Case>
        <Case condition={parameter?.slug === "extra-charges"}>
          <ExtraCharges
            title={parameter?.title}
            titleSingle={parameter?.titleSingular}
          />
        </Case>
        <Case condition={parameter?.slug === "artwork-charges"}>
          <ArtworkCharges
            title={parameter?.title}
            titleSingle={parameter?.titleSingular}
          />
        </Case>
        <Case condition={parameter?.slug === "measurements"}>
          <Measurements
            title={parameter?.title}
            titleSingle={parameter?.titleSingular}
          />
        </Case>
        <Case condition={parameter?.slug === "sizes"}>
          <Sizes
            title={parameter?.title}
            titleSingle={parameter?.titleSingular}
          />
        </Case>
        <Case condition={parameter?.slug === "trims"}>
          <TrimsList
            title={parameter?.title}
            titleSingle={parameter?.titleSingular}
          />
        </Case>
        <Case condition={parameter?.slug === "couriers"}>
          <CouriersList
            title={parameter?.title}
            titleSingle={parameter?.titleSingular}
          />
        </Case>
        <Case condition={parameter?.slug === "position"}>
          <PositionsList
            title={parameter?.title}
            titleSingle={parameter?.titleSingular}
          />
        </Case>
        <Case condition={parameter?.slug === "production-order-templates"}>
          <ProductionOrderTemplateList
            title={parameter?.title}
            titleSingle={parameter?.titleSingular}
          />
        </Case>
        <Case condition={parameter?.slug === "payment-methods"}>
          <PaymentMethodsList
            title={parameter?.title}
            titleSingle={parameter?.titleSingular}
          />
        </Case>
        <Default>No Details</Default>
      </Switch>
    </div>
  );
};

export default ParameterTable;
