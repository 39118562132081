import { createSlice } from "@reduxjs/toolkit";
import { sub } from "date-fns";

const initialState = {
  customer: {
    name: "",
    phone: "",
    email: "",
    address_line_1: "",
    address_line_2: "",
    suburb: "",
    state: "",
    contact_person_name: "",
    postal_code: "",
    country: "",
    customer_id: "",
    country_id: "",
    sales_rep_id: "",
    gst_enabled: false,
  },
  isValidated: false,
};

const cartSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    create: (state, action) => {
      state.customer = action.payload;
    },
    validated: (state) => {
      state.isValidated = true;
    },
    removeCustomerData: (state) => {
      return initialState;
    },
  },
});
export const { create, validated, removeCustomerData } = cartSlice.actions;

export default cartSlice.reducer;
