import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Collapse,
  Divider,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import React, { forwardRef, useMemo } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import { PERMISSIONS } from "../../../../../constants/data/rolesPermissions.constants";
import { checkIsAdmin, checkPermission } from "../../../../../helpers/helpers";
import styles from "./Navigation.module.scss";

const ListItemLink = (props) => {
  const { primary, to, mode } = props;

  const renderLink = useMemo(
    () =>
      forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <li style={{ paddingLeft: mode === "sub" ? "1rem" : 0 }}>
      <ListItem button component={renderLink}>
        {/* {icon ? <SvgIcon>{icon}</SvgIcon> : null} */}
        <ListItemText primary={primary} className={styles.navlink} />
      </ListItem>
    </li>
  );
};

const Navigation = () => {
  const isAdmin = checkIsAdmin();
  const [openPO, setOpenPO] = React.useState(false);
  const [openFI, setOpenFI] = React.useState(false);
  const [openRI, setOpenRI] = React.useState(false);
  const [openApr, setOpenApr] = React.useState(false);

  const handleClickPo = React.useCallback(() => {
    setOpenPO(!openPO);
  }, [openPO]);

  const handleClickFI = React.useCallback(() => {
    setOpenFI(!openFI);
  }, [openFI]);

  const handleClickRI = React.useCallback(() => {
    setOpenRI(!openRI);
  }, [openRI]);

  const handleClickApr = React.useCallback(() => {
    setOpenApr(!openApr);
  }, [openApr]);

  return (
    <>
      {isAdmin ? (
        <List
          sx={{ width: "100%", maxWidth: 360, color: "#fff" }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          className={styles.navlist}
        >
          <ListItemLink to="/admin/dashboard" primary="Dashboard" />

          {(checkPermission(PERMISSIONS.QUOTATIONS) ||
            checkPermission(PERMISSIONS.SALES_AGENT_QUOTATIONS)) && (
            <ListItemLink to="/admin/quotations" primary="Quotations" />
          )}

          {(checkPermission(PERMISSIONS.INVOICES) ||
            checkPermission(PERMISSIONS.SALES_AGENT_INVOICES)) && (
            <ListItemLink to="/admin/invoices" primary="Invoices" />
          )}

          {(checkPermission(PERMISSIONS.SALES_ORDERS) ||
            checkPermission(PERMISSIONS.SALES_AGENT_SALES_ORDERS)) && (
            <ListItemLink to="/admin/sales-orders" primary="Sales Orders" />
          )}

          {checkPermission(PERMISSIONS.VIEW_PRICEBOOK_RECORDS) && (
            <ListItemLink to="/admin/pricebook" primary="Pricebook" />
          )}

          {checkPermission(PERMISSIONS.PRODUCTION_ORDERS) && (
            <>
              <ListItemButton onClick={handleClickPo}>
                <ListItemText primary="Production Orders" />
                {openPO ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openPO} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemLink
                    mode="sub"
                    to="/admin/production-orders"
                    primary="All Production Orders"
                  />
                  <ListItemLink
                    mode="sub"
                    to="/admin/production-orders/proceededProductionOrder"
                    primary="Invoices In Production"
                  />
                </List>
              </Collapse>
            </>
          )}
          {checkPermission(PERMISSIONS.PRODUCTION_ORDERS) && (
            <>
              <ListItemButton onClick={handleClickFI}>
                <ListItemText primary="Factory Invoices" />
                {openFI ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openFI} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemLink
                    mode="sub"
                    to="/admin/factory-invoice"
                    primary="View Factory Invoices"
                  />
                  <ListItemLink
                    mode="sub"
                    to="/admin/Generate-factoryInvoices"
                    primary="Generate Factory Invoice"
                  />
                </List>
              </Collapse>
            </>
          )}

          {checkPermission(PERMISSIONS.PRODUCTION_ORDERS) && (
            <>
              <ListItemButton onClick={handleClickRI}>
                <ListItemText primary="Reseller Invoices" />
                {openRI ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openRI} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemLink
                    mode="sub"
                    to="/admin/reseller-invoices"
                    primary="View Reseller Invoices"
                  />
                  <ListItemLink
                    mode="sub"
                    to="/admin/Generate-reseller-invoice"
                    primary="Generate Reseller Invoices"
                  />
                </List>
              </Collapse>
            </>
          )}

          {checkPermission(PERMISSIONS.PRODUCTION_ORDERS) && (
            <>
              <ListItemButton onClick={handleClickApr}>
                <ListItemText primary="Pre Production Approvals" />
                {openApr ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openApr} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemLink
                    mode="sub"
                    to="/admin/pattern-approvals"
                    primary="Pattern Approvals"
                  />
                  <ListItemLink
                    mode="sub"
                    to="/admin/prototype-approvals"
                    primary="Proto type Approvals"
                  />
                </List>
              </Collapse>
            </>
          )}

          {/*<ListItemLink*/}
          {/*    to="/admin/pre-production-approvals"*/}
          {/*    primary="Pre Production Approval"*/}
          {/*/>*/}

          {checkPermission(PERMISSIONS.WEB_STORE_MANAGEMENT) && (
            <ListItemLink
              to="/admin/webstore-management"
              primary="Web Stores"
            />
          )}

          {/* {checkPermission(PERMISSIONS.PAYMENT) && (
            <ListItemLink to="/admin/payments" primary="Payment Management" />
          )} */}

          {/* {checkPermission(PERMISSIONS.SHIPPING) && (
            <ListItemLink to="/" primary="Shipping Management" />
          )} */}

          {checkPermission(PERMISSIONS.USER_MANAGEMENT) && (
            <ListItemLink
              to="/admin/user-management/users"
              primary="User Management"
            />
          )}

          {checkPermission(PERMISSIONS.ROLE_MANAGEMENT) && (
            <ListItemLink
              to="/admin/user-management/roles"
              primary="Role Management"
            />
          )}

          {checkPermission(PERMISSIONS.ORGANIZATION_MANAGEMENT) && (
            <ListItemLink
              to="/admin/organization-management"
              primary="Organization Management"
            />
          )}

          {checkPermission(PERMISSIONS.PARAMETER_MANAGEMENT) && (
            <ListItemLink
              to="/admin/parameter-management"
              primary="Parameter Management"
            />
          )}

          {checkPermission(PERMISSIONS.REPORTS) && (
            <ListItemLink to="/" primary="Reports" />
          )}
        </List>
      ) : (
        <List
          sx={{ width: "100%", maxWidth: 360, color: "#fff" }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          className={styles.navlist}
        >
          <ListItemLink to="/quotations" primary="Quotations" />

          <ListItemLink to="/sales-orders" primary="Sales Orders" />

          <ListItemLink to="/invoices" primary="Invoices" />

          <ListItemLink
            to="/admin/reseller-invoices"
            primary="View Resell Invoice"
          />

          <>
            <ListItemButton onClick={handleClickApr}>
              <ListItemText primary="Pre Production Approvals" />
              {openApr ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openApr} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemLink
                  mode="sub"
                  to="/pattern-approvals"
                  primary="Pattern Approvals"
                />
                <ListItemLink
                  mode="sub"
                  to="/prototype-approvals"
                  primary="Proto type Approvals"
                />
              </List>
            </Collapse>
          </>

          <Divider />

          <ListItemLink to="/store" primary="Customer Store" />
        </List>
      )}
    </>
  );
};

export default Navigation;
