import * as React from 'react';
import AddTrackingDetailsModal from './Modals/AddTrackingDetailsModal';
import {
   Dialog,
   DialogTitle,
   DialogContent,
   DialogContentText,
   DialogActions,
   Button
  } from "@mui/material";
import { authHeader } from '../helpers';
import { apiConstants } from '../constants';
import AlertToast from '../components/common/Toast';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useSelector } from 'react-redux';

const ViewTrackingDetails = ({...props}) => {
    const { row, column, handlePageChange, paginationData } = props;
    const couriersParam = useSelector((state) => state.couriers);
    const [data, setData] = React.useState({});
    // const uniqIds = row?.invoice_courier_details; 
    const [uniqIds, setUniqIds] = React.useState([]);
    // const isClient = uniqIds.some((i)=> i?.shipping_type?.id === 2 );
    const [isClientAddress, setIsClientAddress] = React.useState(false);
    const [openTrackingDetailsModal, setOpenTrackingDetailsModal] = React.useState(false);
    const [openConfirmTrackingDetails, setOpenConfirmTrackingDetails] = React.useState(false);
    const [trackingDetails, setTrackingDetails] = React.useState({});
    const [couriers, setCouriers] = React.useState([]);
    const [toastState, setToastState] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'right',
    });
    const [toastMessage, setToastMessage] = React.useState('');
    const handleToastClose = () => setToastState({open:false});
    const [successMessage, setSuccessMessage ] = React.useState('Saved Successfully!');
    const [openDialog, setOpenDialog] = React.useState(false);

    React.useEffect(()=>{
        try {
            setUniqIds(row?.invoice_courier_details);
            const isClient = row?.invoice_courier_details.some((i)=> i?.shipping_type?.id === 2 );
            setIsClientAddress(isClient);
        } catch(error) {
            setIsClientAddress(false);
        }
    },[row?.invoice_courier_details]);

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleTrackingDetailsModalClose = () => {
        setOpenTrackingDetailsModal(false);
        handlePageChange(paginationData?.current_page-1);
    }

    const handleConfirmTrackingDetailsClose = () => {
        setOpenConfirmTrackingDetails(false);
    }

    const submitTrackingId = (values) => {
        setTrackingDetails(values);
        setOpenConfirmTrackingDetails(true);
    }

    const handleTrackingDetails = (data) => {
        setCouriers(couriersParam?.items?.data);
        const trackingData = data;
        trackingData['ship_to'] = row?.ship_to;
        trackingData['isClientAddress'] = isClientAddress;
        setData(trackingData);
        setOpenTrackingDetailsModal(true);
    }

    const confirmTrackingAndSubmit = () => {
        const shippingTypeId = trackingDetails?.is_client_Address ? 2 : 1;
        const reqData = {
            "shipping_type_id": shippingTypeId,
            "courier_id": trackingDetails?.courier_id,
            "tracking_code": trackingDetails?.tracking_number,
        }
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(reqData),
        };
        
        fetch(`${apiConstants.API_URL}/invoices/${row?.id}/set-tracking-code`, requestOptions).then((response)=>{
            response.text().then((text) => {
            const data = text && JSON.parse(text);
            if (!response.ok) {
              const error = (data && data.error) || response.statusText;
              const errorMessage = Object.values(error).map((errorKey) => {
                  return errorKey[0];
              })
              setToastMessage(errorMessage);
              setToastState({
                open:true, vertical:'top', horizontal:'right'
              });
            }
            else{
                handlePageChange(paginationData?.current_page-1);
                setIsClientAddress(true);
                setSuccessMessage(`Handed over courier and to be ship to Client Address Successfully!`);
                setOpenDialog(true);
                handleTrackingDetailsModalClose();
                handleConfirmTrackingDetailsClose();
            }
          });
        });
    }
   
    return(
        <>
        {column === 'trackingNumber' ?
        (uniqIds && uniqIds.length !==0 ?
        ((uniqIds.map((i, index) => (
            <>
            <a onClick={()=>handleTrackingDetails(i)}>{i?.tracking_code}</a>
            {uniqIds.length - 1 !== index &&
                <span>,&nbsp;</span>
            }
            </>
        ))))
        :
        <div>-</div>
        ):
        (uniqIds && uniqIds.length !== 0 ? 
        (isClientAddress ? 
            <div>Client Address</div>
            :
            <div>Head Office</div>
        ):
        (<div>-</div>)
        )
        
        }
        <AddTrackingDetailsModal
        open={openTrackingDetailsModal}
        type={'show'}
        handleClose={handleTrackingDetailsModalClose}
        couriers={couriers}
        submitTrackingId={submitTrackingId}
        data={data}
        />
        <Dialog
            open={openDialog}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            <CheckCircleIcon
                color="success"
                sx={{
                position: "relative",
                top: "0.25rem",
                mr: 1,
                display: { xs: "block", sm: "inline-block" },
                margin: { xs: "0 0 1rem 0", sm: "0 0.6rem 0 0" },
                }}
            />
            {successMessage}
            </DialogTitle>

            <DialogActions>
            <Button onClick={handleClose} autoFocus>
                Close
            </Button>
            </DialogActions>
        </Dialog>
        <Dialog
            open={openConfirmTrackingDetails}
            onClose={handleConfirmTrackingDetailsClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >
            <DialogTitle id="alert-dialog-title">
            {"Confirm Shipping Delivery Address?"}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Are you sure,  
                {trackingDetails?.is_client_Address ? `ship to this client address: ${row?.ship_to}` : 'ship to Head Office'} ?
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleConfirmTrackingDetailsClose}>Disagree</Button>
            <Button onClick={confirmTrackingAndSubmit} autoFocus>
                Agree
            </Button>
            </DialogActions>
        </Dialog>
        <AlertToast
            toastMessage={toastMessage} 
            toastState={toastState}
            handleClose={handleToastClose}
        />
        </>
    )
}

export default ViewTrackingDetails;