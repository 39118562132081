import { InfoRounded } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  AlertTitle,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { alertActions, roleActions } from "../../../../actions";
import { rolePermissions } from "../../../../constants/data/rolesPermissions.constants";
import AlertToast from "../../../common/Toast";
import styles from "./RoleForm.module.scss";

const RoleForm = (props) => {
  const getPermissionGroupings = Object.keys(rolePermissions);
  const permissionsWithIds = Object.values(rolePermissions).flatMap(
    (role) => role.permissions
  );
  const defaultCheckedStatus = permissionsWithIds
    .sort((a, b) => a.sourceId - b.sourceId)
    .map((permission) => permission.id)
    .reduce((map, permission) => {
      map[permission] = false;
      return map;
    }, {});

  const initialValues = {
    role: "",
    roleType: "",
  };

  const dispatch = useDispatch();
  const [checkedState, setCheckedState] = useState(defaultCheckedStatus);
  const roleState = useSelector((state) => state.roles);

  const navigate = useNavigate();
  const alertState = useSelector((state) => state.alert);
  const { title, titleSingle, mode, paramdata } = props;
  const debug = false;
  const [submitting, setSubmitting] = useState(false);
  const successMsg = "";
  const [allRoles, setAllRoles] = useState();
  const formikRef = React.createRef();
  const [toastMessage, setToastMessage] = React.useState("");
  const handleToastClose = () => setToastState({ open: false });
  const [change, setChange] = useState(false);

  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);
  const [dialogMode, setDialogMode] = useState(null);

  const [type, setType] = useState("admin-api");

  const getPermissionsByGrouping = (groupName) => {
    return rolePermissions[groupName];
  };

  const handleRolePermission = useCallback(
    (e) => {
      const { id, checked, name } = e.target;
      setCheckedState({
        ...checkedState,
        [id]: checked,
      });
    },
    [checkedState]
  );

  const [toastState, setToastState] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });

  const validationSchema = Yup.object({
    role: Yup.string().required("Role Name is required"),
    roleType: Yup.string().required("Role Type is required"),
  });

  const handleClose = useCallback(() => {
    dispatch(alertActions.clear());
    setOpen(false);
    navigate(-1);
  }, [dispatch, navigate]);

  const handleErrorClose = useCallback(() => {
    setError(false);
    dispatch(alertActions.clear());
  }, [dispatch]);

  const handleErrorAlert = useCallback(() => {
    setError(true);
    setTimeout(() => {
      handleErrorClose();
    }, 6000);
  }, [handleErrorClose]);

  const handleSuccessDialog = useCallback((msg, mode) => {
    setDialogContent(msg);
    setDialogMode(mode);
    setOpen(true);
  }, []);

  const handleRoleTypeChange = useCallback(
    (e) => {
      formikRef.current.setFieldValue("roleType", e.target.value, false);
      setType(e.target.value);
    },
    [formikRef]
  );

  useEffect(() => {
    const handleSuccess = () => {
      if (roleState?.parameter?.data && alertState.type === "success") {
        handleSuccessDialog(successMsg);
      }
    };

    const handleError = () => {
      if (roleState?.error && alertState.type === "error") {
        handleErrorAlert(successMsg);
      }
    };

    handleSuccess();
    handleError();
  }, [roleState, handleSuccessDialog, alertState, handleErrorAlert]);

  useEffect(() => {
    setChange(mode === "edit" ? false : true);
    setAllRoles(roleState);
  }, [defaultCheckedStatus, mode, roleState]);

  useEffect(() => {
    const getPrefillData = () => {
      if (mode === "edit" && paramdata?.id) {
        dispatch(roleActions.getSingle(paramdata?.id));
      }
    };
    getPrefillData();
  }, [dispatch, mode, paramdata?.id]);

  useEffect(() => {
    const prefillData = () => {
      if (mode === "edit" && paramdata) {
        const data = paramdata;
        formikRef.current.setFieldValue("role", data?.name, false);
        if (data?.roleType === "Internal" || data?.guard_name === "admin-api") {
          formikRef.current.setFieldValue("roleType", "admin-api", false);
          setType("admin-api");
        } else {
          formikRef.current.setFieldValue("roleType", "api", false);
          setType("api");
        }

        const state = checkedState;
        const permissions = allRoles?.parameter?.data?.permissions;

        if (!permissions) {
          return;
        }

        for (const permission of permissions) {
          const id = permission?.id;
          const name = Object.keys(defaultCheckedStatus)[id - 1];
          state[name] = true;
        }
        setCheckedState(state);
      }
    };
    prefillData();
  }, [
    allRoles?.parameter?.data?.permissions,
    // checkedState,
    // defaultCheckedStatus,
    // formikRef,
    mode,
    paramdata,
  ]);

  useEffect(() => {
    dispatch(alertActions.clear());
  }, [dispatch]);

  const handleSubmit = (values) => {
    let permissionsItem = [];
    if (type === "admin-api") {
      permissionsWithIds.forEach((item) => {
        if (checkedState[item.id]) {
          permissionsItem.push(item.name);
        }
      });
    }

    console.log({ permissionsItem })
    if (type === "admin-api") {
      if (Object.values(checkedState).some((el) => el)) {
        if (values && mode === "add") {
          dispatch(
            roleActions.addRole(
              values.role,
              values.description,
              values.roleType,
              permissionsItem
            )
          );
        }
        if (values && mode === "edit") {
          dispatch(
            roleActions.updateRole(
              paramdata.id,
              values.role,
              values.description,
              values.roleType,
              permissionsItem
            )
          );
        }
      } else {
        setToastMessage("At least one permission need to set");
        setToastState({
          open: true,
          vertical: "top",
          horizontal: "right",
        });
      }
    } else {
      if (values && mode === "add") {
        dispatch(
          roleActions.addRole(
            values.role,
            values.description,
            values.roleType,
            permissionsItem
          )
        );
      }
      if (values && mode === "edit") {
        dispatch(
          roleActions.updateRole(
            paramdata.id,
            values.role,
            values.x,
            values.roleType,
            permissionsItem
          )
        );
      }
    }
  };

  return (
    <div>
      {error && (
        <Alert severity="error" sx={{ mb: 3 }} onClose={handleErrorClose}>
          <AlertTitle>Error adding role</AlertTitle>
        </Alert>
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        innerRef={formikRef}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values);
          resetForm();
          setCheckedState(defaultCheckedStatus);
        }}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          isValid,
          setFieldValue,
        }) => (
          <Form noValidate autoComplete="off">
            <Grid container spacing={{ xs: 2, sm: 2 }} sx={{ pt: 0, pb: 0 }}>
              <Grid item xs={6} md={6}>
                <TextField
                  fullWidth
                  id="role"
                  name="role"
                  label="Role Name"
                  value={values.role}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.role && Boolean(errors.role)}
                  helperText={touched.role && errors.role}
                  variant="outlined"
                  className={styles.textField}
                  // size="small"
                  disabled={mode === "edit"}
                />
              </Grid>

              <Grid item xs={6} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Role Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Role Type"
                    name="roleType"
                    value={values.roleType}
                    onChange={(e) => {
                      handleRoleTypeChange(e);
                    }}
                    onBlur={handleBlur}
                    error={touched.roleType && Boolean(errors.roleType)}
                    // renderValue={(value) => `${value}`}
                    // className={styles.textField}
                    disabled={mode === "edit"}
                    // size="small"
                  >
                    <MenuItem value={"admin-api"}>Internal</MenuItem>
                    <MenuItem value={"api"}>External</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {type === "admin-api" && (
                <>
                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <Alert severity="info">
                      Please select capabilities you wish to assign for this
                      role. Capabilities from multiple categories can be
                      assigned to a single role
                    </Alert>
                  </Grid>

                  {getPermissionGroupings.map((groupId, index) => (
                    <Grid item xs={12} key={index}>
                      <Card
                        elevation={0}
                        variant="outlined"
                        sx={{ bgcolor: "#fbfbfb" }}
                      >
                        <Typography variant="h6" sx={{ fontSize: "1.2rem" }}>
                          {getPermissionsByGrouping(groupId).name}
                        </Typography>
                        <List className={styles.list}>
                          {getPermissionsByGrouping(groupId).permissions.map(
                            (permission, id) => (
                              <ListItem
                                key={id}
                                secondaryAction={
                                  <IconButton edge="end" aria-label="comments">
                                    <InfoRounded />
                                  </IconButton>
                                }
                                disablePadding
                              >
                                <ListItemButton dense>
                                  <ListItemIcon>
                                    <Checkbox
                                      id={permission.id}
                                      name={permission.id}
                                      checked={checkedState[permission.id]}
                                      edge="start"
                                      tabIndex={-1}
                                      onChange={handleRolePermission}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText primary={permission.name} />
                                </ListItemButton>
                              </ListItem>
                            )
                          )}
                        </List>
                      </Card>
                    </Grid>
                  ))}
                </>
              )}
              <Grid item xs={12}>
                <Stack
                  direction="column-reverse"
                  justifyContent="space-between"
                  align-items="center"
                  sx={{
                    flexDirection: {
                      sm: "row",
                    },
                    mt: 2,
                  }}
                >
                  <Link to="../" style={{ display: "block" }}>
                    <Button
                      size="medium"
                      startIcon={<ArrowBackIcon size="small" />}
                      sx={{ textTransform: "none" }}
                    >
                      Back to {title}
                    </Button>
                  </Link>

                  <span style={{ flex: 1 }}>&nbsp;</span>

                  <LoadingButton
                    color="secondary"
                    variant="contained"
                    fullWidth
                    type="submit"
                    size="large"
                    loading={submitting}
                    // loadingPosition="end"
                    sx={{
                      width: {
                        xs: "100%",
                        md: "auto",
                      },
                    }}
                  >
                    {/* {`Save ${titleSingle}`} */}
                    Save Role
                  </LoadingButton>
                </Stack>
              </Grid>
            </Grid>

            {debug && (
              <>
                <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                <pre style={{ textAlign: "left" }}>
                  <strong>Values</strong>
                  <br />
                  {JSON.stringify(values, null, 2)}
                </pre>
                <pre style={{ textAlign: "left" }}>
                  <strong>Errors</strong>
                  <br />
                  {JSON.stringify(errors, null, 2)}
                </pre>
              </>
            )}
          </Form>
        )}
      </Formik>
      <AlertToast
        toastMessage={toastMessage}
        toastState={toastState}
        handleClose={handleToastClose}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <CheckCircleIcon
            color="success"
            sx={{
              position: "relative",
              top: "0.25rem",
              mr: 1,
              display: { xs: "block", sm: "inline-block" },
              margin: { xs: "0 0 1rem 0", sm: "0 0.6rem 0 0" },
            }}
          />
          {`Role ${
            mode === "add" ? "Created" : mode === "edit" ? "Updated" : ""
          } Successfully!`}
        </DialogTitle>

        {dialogContent && (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {typeof dialogContent === "string" && dialogContent}
            </DialogContentText>
          </DialogContent>
        )}

        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RoleForm;
