import { Stack, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { convertToPlaces } from "../../helpers/helpers";
import Dividers from "../../Common/Dividers";
import PropTypes from "prop-types";

const StyledItemTotal = styled(Stack)(({ theme }) => ({
  width: "100%",
  minWidth: "250px",
  backgroundColor: theme.palette.neutral.dark,
  color: theme.palette.neutral.light,
  textAlign: "left",
  padding: "10px",
  borderRadius: "5px",
}));

const StyledText = styled(Typography)({
  fontSize: "14px",
});

const StyledDivider = styled(Dividers)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.neutral.main}`,
}));

const StyledTotalText = styled(Typography)({
  fontSize: "14px",
  fontWeight: 700,
});

const ItemSummeryCustomer = ({ ...props }) => {
  const {
    item,
    qty,
    unit_price,
    total_price,
    estimated_shipping_charges,
    artwork_charges,
    extra_charges,
    sub_total,
  } = props;

  return (
    <StyledItemTotal spacing={2}>
      <Typography fontSize={"24px"}>{item?.name}</Typography>
      <Stack spacing={1}>
        <Stack direction={"row"} justifyContent="space-between">
          <StyledText> Total Units</StyledText>
          <StyledText> {qty}</StyledText>
        </Stack>
        <Stack direction={"row"} justifyContent="space-between">
          <StyledText> Unit Price</StyledText>
          <StyledText> {convertToPlaces(unit_price)}</StyledText>
        </Stack>
        <Stack direction={"row"} justifyContent="space-between">
          <StyledText> Unit Total</StyledText>
          <StyledText>{convertToPlaces(total_price)}</StyledText>
        </Stack>
        <Stack direction={"row"} justifyContent="space-between">
          <StyledText>Estimated Shipping</StyledText>
          <StyledText>{convertToPlaces(estimated_shipping_charges)}</StyledText>
        </Stack>
        {/* <Stack direction={"row"} justifyContent="space-between">
          <StyledText>Artwork charges</StyledText>
          <StyledText>{convertToPlaces(artwork_charges)}</StyledText>
        </Stack> */}
        <Stack direction={"row"} justifyContent="space-between">
          <StyledText>Other Charges</StyledText>
          <StyledText>{convertToPlaces(extra_charges)}</StyledText>
        </Stack>
        <StyledDivider />
        <Stack direction={"row"} justifyContent="space-between">
          <StyledTotalText>Sub Total</StyledTotalText>
          <StyledTotalText>{convertToPlaces(sub_total)}</StyledTotalText>
        </Stack>
      </Stack>
    </StyledItemTotal>
  );
};

ItemSummeryCustomer.propTypes = {
  sub_total: PropTypes.number,
  extra_charges: PropTypes.number,
  artwork_charges: PropTypes.number,
  total_price: PropTypes.number,
  unit_price: PropTypes.number,
  qty: PropTypes.number,
  item: PropTypes.object,
};

ItemSummeryCustomer.defaultProps = {
  sub_total: 0,
  extra_charges: 0,
  artwork_charges: 0,
  total_price: 0,
  unit_price: 0,
  qty: 0,
  item: {},
};

export default ItemSummeryCustomer;
