import React from "react";
import { ButtonGroup, Button, Menu, MenuItem } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";
import { styled, alpha } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import { useStyles } from "../../../Theme/Style";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    border: "1px solid #512879",
    borderRadius: "5px 0px 5px 5px",
    minWidth: 180,
    color: "#512879",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& a": {
      textDecoration: "none !important",
    },
    "& .MuiMenu-list": {
      padding: "4px 0",
      textTransform: "uppercase",
    },
    "& .MuiMenuItem-root": {
      fontSize: 12,
      fontWeight: 500,
      color: "#512879 !important",

      "& .MuiSvgIcon-root": {
        fontSize: 12,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export const QuotationsActionButton = ({
  isViewOnly,
  pathToView,
  quoteId,
  status,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [openCloseQuotationModal, setOpenCloseQuotationModal] =
    React.useState(false);

  const handleQuotationCloseModal = () => {
    console.log("hey");
    setOpenCloseQuotationModal(true);

    console.log("openCloseQuotationModal", openCloseQuotationModal);
  };

  const handleQuotationCloseModalClose = () => {
    setOpenCloseQuotationModal(false);
  };

  const handleClick = React.useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const generateSalesOrderLink = `/admin/sales-orders/${quoteId}`;
  const closeSalesOrderLink = `/admin/quotations/${quoteId}/close-quotation`;

  // console.log('status', status)

  if (isViewOnly) {
    return (
      <ButtonGroup
        aria-label="outlined primary button group"
        // className={classes.outlinedGroupButton}
      >
        <Button
          variant="outlined"
          onClick={() => navigate(pathToView.replace("$id$", quoteId))}
          className={classes.outlinedGroupButton}
        >
          View
        </Button>
      </ButtonGroup>
    );
  }

  return (
    <>
      <ButtonGroup
        aria-label="outlined primary button group"
        className={classes.outlinedGroupButton}
      >
        <Button
          variant="outlined"
          onClick={() => navigate(pathToView.replace("$id$", quoteId))}
        >
          View
        </Button>
        <Button
          id="demo-customized-button"
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          sx={{
            backgroundColor:
              Boolean(anchorEl) && status !== "pending"
                ? "#512879 !important"
                : undefined,
            color:
              Boolean(anchorEl) && status !== "pending"
                ? "#fff !important"
                : undefined,
          }}
          disableElevation
          onClick={handleClick}
        >
          <ArrowDropDown />
        </Button>
      </ButtonGroup>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {status === "accepted" && (
          <Link to={generateSalesOrderLink}>
            <MenuItem onClick={handleClose} disableRipple>
              Generate Sales Order
            </MenuItem>
          </Link>
        )}
      </StyledMenu>
    </>
  );
};
