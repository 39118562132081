import * as React from "react";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";

export const Breadcrumbs = ({ setStep, steps, lastStep }) => {
  return (
    <>
      {steps &&
        steps.length > 0 &&
        steps.map((step, index) => (
          <Link
            underline="hover"
            key="1"
            color="inherit"
            to=""
            onClick={() => setStep()}
            style={{
              fontFamily: "Verlag",
              fontStyle: "italic",
            }}
          >
            {step}
          </Link>
        ))}
      <Typography
        key="3"
        style={{
          fontFamily: "Verlag",
          fontStyle: "italic",
        }}
      >
        {lastStep}
      </Typography>
    </>
  );
};
