import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { sportCategoryActions, storeItemActions } from "../../../actions";
import { createSlug } from "../../../helpers/helpers";
import Intro from "../../../components/atoms/Intro/Intro.component";
import Content from "../../../components/layout/pageLayout/Content/Content.component";
import VerticalTabs from "../../../components/customer/store/VerticalTabs/VerticalTabs.component";
import GarmentsByCategory from "../../../components/customer/store/GarmentsByCategory/GarmentsByCategory.component";

const CustomerStore = () => {
  const dispatch = useDispatch();

  const sportCategories = useSelector(
    (state) => state.sportCategories?.items?.data
  );
  const sportCategoriesState = useSelector((state) => state.sportCategories);
  const userState = useSelector((state) => state.authentication?.user);
  console.log("🚀 ~ file: index.js:19 ~ CustomerStore ~ userState:", userState);
  const storeItems = useSelector((state) => state.storeItems);

  const [productCategories, setProductCategories] = useState([]);

  useEffect(() => {
    dispatch(sportCategoryActions.getAllParameters());
  }, [dispatch]);

  useEffect(() => {
    dispatch(storeItemActions.getAllParameters());
  }, [dispatch]);

  useEffect(() => {
    const filterProductData = () => {
      const tabData = [];

      sportCategories &&
        storeItems &&
        sportCategories.map((item) => {
          const tab = {
            id: item?.id,
            label: item?.name,
            slug: createSlug(item?.name),
            data: <GarmentsByCategory />,
          };

          const garments = storeItems?.items?.data?.filter(
            (garment) =>
              garment?.production_order?.price?.sport?.id === item?.id
          );

          tab.data = (
            <GarmentsByCategory
              loading={storeItems?.loading}
              category={item?.name}
              items={garments}
            />
          );

          if (garments?.length > 0) {
            tabData.push(tab);
          }
        });

      const allItems = {
        id: 0,
        label: "All",
        slug: "all",
        data: (
          <GarmentsByCategory
            loading={storeItems?.loading}
            category="All"
            items={storeItems?.items?.data}
          />
        ),
      };

      storeItems?.items?.data?.length > 0 && tabData.unshift(allItems);

      setProductCategories(tabData);
    };

    filterProductData();
  }, [sportCategories, storeItems]);

  const breadcrumbs = [<Typography key="3">Customer Store</Typography>];

  return (
    <>
      <Intro
        pageTitle={`Store - ${
          userState?.data
            ? userState?.data?.organization?.name
            : userState?.organization?.name
        }`}
        pageTitleShort="Store"
        pageSubTitle=""
        breadcrumbs={breadcrumbs}
        additionalWidgets=""
      />

      <Content>
        <VerticalTabs
          categories={productCategories}
          loading={
            storeItems?.loading || sportCategoriesState?.loading ? true : false
          }
        />
      </Content>
    </>
  );
};

export default CustomerStore;
