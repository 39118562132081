export const cartConstants = {
  // GET_SINGLE_REQUEST: "STORE_ITEM_GET_SINGLE_REQUEST",
  // GET_SINGLE_SUCCESS: "STORE_ITEM_GET_SINGLE_SUCCESS",
  // GET_SINGLE_FAILURE: "STORE_ITEM_GET_SINGLE_FAILURE",
  // GET_ALL_REQUEST: "STORE_ITEM_GET_ALL_REQUEST",
  // GET_ALL_SUCCESS: "STORE_ITEM_GET_ALL_SUCCESS",
  // GET_ALL_FAILURE: "STORE_ITEM_GET_ALL_FAILURE",

  ADD_REQUEST: "CART_ITEM_ADD_REQUEST",
  ADD_SUCCESS: "CART_ITEM_ADD_SUCCESS",
  ADD_FAILURE: "CART_ITEM_ADD_FAILURE",

  UPDATE_REQUEST: "CART_ITEM_UPDATE_REQUEST",
  UPDATE_SUCCESS: "CART_ITEM_UPDATE_SUCCESS",
  UPDATE_FAILURE: "CART_ITEM_UPDATE_FAILURE",

  DELETE_REQUEST: "CART_ITEM_DELETE_REQUEST",
  DELETE_SUCCESS: "CART_ITEM_DELETE_SUCCESS",
  DELETE_FAILURE: "CART_ITEM_DELETE_FAILURE",

  CLEAR: "CART_CLEAR",
};
