import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Grid, Typography, Container } from "@mui/material";
import Intro from "../../../components/atoms/Intro/Intro.component";
import Content from "../../../components/layout/pageLayout/Content/Content.component";
import BoxedContent from "../../../components/layout/pageLayout/BoxedContent/BoxedContent.component";
import UserForm from "../../../components/admin/UserManagement/UserForm/UserForm.component";

const UserEdit = () => {
  const location = useLocation();
  const currentParameter = {
    id: 0,
    slug: "user-management",
    title: "Users",
    titleSingular: "User",
  };

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      to="/admin/user-management/users"
    >
      User Management
    </Link>,

    <Typography key="3">Edit User</Typography>,
  ];

  return (
    <>
      <Intro
        pageTitle={`Edit User`}
        pageTitleShort={`Edit User`}
        breadcrumbs={breadcrumbs}
        additionalWidgets=""
      />

      <Content>
        <Container sx={{ ml: 0, px: "0 !important" }}>
          <BoxedContent title={`Enter User`} subtitle="" description="">
            <Grid container spacing="2">
              <Grid item xs={12}>
                <UserForm
                  parameter={currentParameter}
                  mode="edit"
                  paramdata={location?.state?.param}
                />
              </Grid>
            </Grid>
          </BoxedContent>
        </Container>
      </Content>
    </>
  );
};

export default UserEdit;
