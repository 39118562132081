import { webstoreConstants } from "../constants";

export function webstores(state = {}, action) {
  switch (action.type) {
    // get all
    case webstoreConstants.GET_SINGLE_REQUEST:
      return {
        loading: true,
      };
    case webstoreConstants.GET_SINGLE_SUCCESS:
      return {
        store: action.parameter,
      };
    case webstoreConstants.GET_SINGLE_FAILURE:
      return {
        error: action.error,
      };

    // get all
    case webstoreConstants.GET_ALL_REQUEST:
      return {
        loading: true,
      };
    case webstoreConstants.GET_ALL_SUCCESS:
      return {
        items: action.items,
      };

    case webstoreConstants.GET_ALL_FAILURE:
      return {
        error: action.error,
      };

    // add
    case webstoreConstants.ADD_REQUEST:
      return {
        loading: true,
      };
    case webstoreConstants.ADD_SUCCESS:
      return {
        parameter: action.parameter,
      };
    case webstoreConstants.ADD_FAILURE:
      return {
        error: action.error,
      };

    // update
    case webstoreConstants.UPDATE_REQUEST:
      return {
        loading: true,
      };
    case webstoreConstants.UPDATE_SUCCESS:
      return {
        parameter: action.parameter,
      };
    case webstoreConstants.UPDATE_FAILURE:
      return {
        error: action.error,
      };

    // delete
    case webstoreConstants.DELETE_REQUEST:
      return {
        loading: true,
      };
    case webstoreConstants.DELETE_SUCCESS:
      return {
        parameter: action.parameter,
      };
    case webstoreConstants.DELETE_FAILURE:
      return {
        error: action.error,
      };

    // clear
    case webstoreConstants.CLEAR:
      return {};

    default:
      return state;
  }
}
