import React, { useState } from "react";
import styled from "@emotion/styled";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Checkbox,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Typography,
  TextField,
  FormHelperText,
} from "@mui/material";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import moment from 'moment';

const StyledButton = styled(Button)({
  color: "white",
});

const style = {
  background: "#FFFFFF",
  boxShadow:
    "0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
  borderRadius: "4px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "798px",
  minHeight: "174px",
  padding: "20px",
};

const AddTrackingDetailsModal = ({ ...props }) => {
  const { open, type, handleClose, couriers, submitTrackingId, data } = props;
  const [enableClientShippingAddress, setEnableClientShippingAddress] = useState(true);
  const formikRef = React.createRef();
  const [status, setStatus] = React.useState(type==='show'?'edit':'add');

  const validationSchema = Yup.object({
    tracking_number: Yup.string().required("Tracking Number is required"),
    courier_id: Yup.string().required("Courier is required"),
    is_client_Address: Yup.boolean().nullable(),
  });

  const initialValues = {
    tracking_number:'',
    courier_id:'',
    is_client_Address: true,
  }

  React.useEffect(()=>{
    if(type==='show'){
      setStatus('edit');
    }
  },[open]);

  const clearAll = () => {
    handleClose();
    setEnableClientShippingAddress(true);
  };

  const handleSubmit = (values) => {
    submitTrackingId(values);
  };

  const resetForm = () => {
    formikRef.resetForm();
  }

  const handleEnableHeadOffice = (e) => {
    setEnableClientShippingAddress(!enableClientShippingAddress);
    formikRef.current.setFieldValue("is_client_Address", !enableClientShippingAddress);
  }

  const editAction = () => {
    setStatus('add');
  }
  const viewAction = () => {
    setStatus('edit');
  }

  return (
    <Modal
      open={open}
      onClose={clearAll}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
      <Stack spacing={2}>
        <Stack direction={"column"} spacing={2}>
        <Stack direction={"row"} justifyContent="space-between">
          <Typography
            id="modal-modal-title"
            fontWeight={500}
            fontSize={"20px"}
          >
            Shipment/Courier Management
          </Typography>
          {type === 'show' && !data?.isClientAddress &&
            (status === 'edit' ?
            <Button  variant="outlined"  onClick={editAction}>
              <ModeEditOutlineOutlinedIcon fontSize="20px" /> Update Courier details
            </Button>
            :
            <Button  variant="outlined"  onClick={viewAction}>
              <ArrowBackIcon fontSize="20px" />
            </Button>
            )
          }
        
          </Stack>
          {type==='new' &&
            <Typography fontSize={"14px"}>Add courier details</Typography>
          }
          
        </Stack>
      </Stack>
      {status !== 'edit' ?
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        innerRef={formikRef}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values);
          //resetForm();
        }}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          isValid,
          setFieldValue,
        }) => (
        <Form noValidate autoComplete="off">
          <Stack spacing={2}>
            
            <Stack direction={"row"} justifyContent={"space-between"} spacing={1}>
            <FormControl fullWidth 
              error={touched.courier_id && Boolean(errors.courier_id)}
              variant="standard">
                <InputLabel id="demo-simple-select-label">
                  Select Courier
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="courier_id"
                  name="courier_id"
                  size="small"
                  onChange={handleChange}
                >
                  {couriers?.map((d, index) => (
                    <MenuItem key={index} value={d?.id}>
                      {d?.name}
                    </MenuItem>
                  ))}
                </Select>
                {touched.courier_id && Boolean(errors.courier_id) && (
                  <FormHelperText>
                  {touched.courier_id && errors.courier_id}
                  </FormHelperText>
                )}
              </FormControl>
              <TextField
                fullWidth
                id="tracking_number"
                name="tracking_number"
                variant="standard"
                placeholder="Tracking Number"
                type="text"
                label="Tracking Number"
                error={touched.tracking_number && Boolean(errors.tracking_number)}
                helperText={touched.tracking_number && errors.tracking_number}
                value={values.tracking_number}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Stack>

            <Stack>
            <Typography fontSize={"14px"}>
              Client Shipping address :
            </Typography>
            <Typography>
            {data?.ship_to},{data?.organization?.country?.name}
            </Typography>
            {type !== 'show' &&
            <>
              <Typography fontSize={"14px"} sx={{marginTop:'10px'}}>If you want this order ship to Head Office. Click Head office checkbox before submit</Typography>
              <FormControlLabel
                  control={
                  <Checkbox
                      value={!enableClientShippingAddress}
                      checked={!enableClientShippingAddress}
                      onChange={handleEnableHeadOffice}
                  />
                  }
                  label="Head Office"
                  id="is_client_Address"
                  name="is_client_Address"
              />
            </>
            }
            </Stack>

            <Stack direction={"row"} justifyContent={"space-between"} >
              <Button color="success" onClick={() => clearAll()}>
                CLOSE
              </Button>
              <StyledButton
                color="success"
                type="submit"
                onClick={() => handleSubmit()}
                variant="contained"
                disabled={false}
              >
                Submit
              </StyledButton>
            </Stack>
          </Stack>
        </Form>
        )}
      </Formik>
      :
      <Stack sx={{marginTop:'10px'}}>
        <Stack>
          <Typography fontSize={"14px"}>
            <b>Date</b>: {moment(data?.shipped_date).format("DD MMM YYYY")}
          </Typography>
          <Typography fontSize={"14px"}>
          <b>Courier Name</b>: {data?.courier?.name}
          </Typography>
          <Typography fontSize={"14px"}>
          <b>Courier Tracking URL</b>: {data?.courier?.tracking_url}
          </Typography>
          <Typography fontSize={"14px"}>
          <b>Tracking Number</b>: {data?.tracking_code}
          </Typography>
          <Typography fontSize={"14px"}>
          <b>Destination Address</b>: {`${data?.shipping_type?.id === 2 ? data?.ship_to : 'Head Office'}`}
          </Typography>
          <Typography>
          
          </Typography>
        </Stack>
        <Stack direction={"row"} justifyContent={'flex-end'} spacing={2}>
          <Button color="success" onClick={() => clearAll()}>
            CLOSE
          </Button>
        </Stack>
      </Stack>
      }
      </Box>
    
    </Modal>
  );
};

AddTrackingDetailsModal.prototype = {
  open: PropTypes.bool,

  handleClose: PropTypes.func.isRequired,
};
AddTrackingDetailsModal.defaultProps = {
  open: false,
};

export default AddTrackingDetailsModal;
