import styled from "@emotion/styled";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
  Box,
  InputAdornment,
  FormControl,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  convertToPlaces,
  convertToDecimalPlaces,
} from "../../../helpers/helpers";
import { useSelector } from "react-redux";

const StyledTd = styled("td")({
  fontSize: "14px",
  fontWeight: "400",
});

const StyledStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  flexDirection: "column",
  height: "100%",
  borderRadius: "5px",
  color: theme.palette.neutral.light,
  padding: "5px 10px 15px 15px",
}));

const StyledTotals = styled(Typography)({
  fontSize: "14px",
});

const StyledHeaders = styled(Typography)({
  fontSize: "16px",
});
const StyledContents = styled(Typography)({
  fontSize: "14px",
  // color: '#fff'
});

const SalesOrderSummery = ({ ...props }) => {
  const [enableRedraw, setRedraw] = useState(false);
  const [enableColor, setColor] = useState(false);
  const [newCost, setNewCost] = useState({
    redraw: 0,
    matchColor: 0,
  });

  const {
    items = [],
    file,
    view,
    updateTotalDelivery,
    setUpdateTotalDelivery,
    handleGetDelivery,
  } = props;
  const { customer } = useSelector((state) => state.customer);
  const [enableDelivery, setEnableDelivery] = useState(true);

  let totalPrice = 0;
  let totalExcludingTax = 0;
  let totalShipping = 0;
  let totalGst = 0;
  let extraCharges = 0;

  items?.map((item) => {
    totalPrice = totalPrice + item?.total_price;
    totalShipping = totalShipping + item?.estimated_shipping_charges || 0;
    totalGst = totalGst + item?.gst_amount || 0;
    totalExcludingTax = totalExcludingTax + item?.total_price;
    extraCharges = extraCharges + item?.extra_charges || 0;
    return "";
  });

  useEffect(() => {
    if (items.length !== 0) {
      handleGetDelivery();
    }
  }, [items, handleGetDelivery]);

  const matchColor = parseFloat(newCost.matchColor) * 100 || 0;
  const redraw = parseFloat(newCost.redraw) * 100 || 0;

  let grandTotal =
    totalPrice + updateTotalDelivery * 100 + totalGst + extraCharges;

  const handleEnableDelivery = () => {
    setEnableDelivery(!enableDelivery);
    if (enableDelivery) {
      handleGetDelivery();
    }
  };

  const onchangeDelivery = (e) => {
    setUpdateTotalDelivery(e.target.value);
  };

  return (
    <>
      <StyledStack spacing={2}>
        <Stack>
          <Typography fontSize={"24px"} fontWeight={"400"}>
            Sales Order Summary
          </Typography>
          <Typography color={"neutral.main"} fontSize={"14px"}>
            This is a summary of the generated sales order
          </Typography>
        </Stack>
        <Stack style={{ height: "120px", overflow: "scroll" }} spacing>
          <StyledHeaders color={"neutral.main"}>Customer:</StyledHeaders>
          <Box>
            <StyledContents>{customer?.name}</StyledContents>
            <StyledContents>{customer?.address_line_1}</StyledContents>
            <StyledContents>
              {customer?.address_line_2},{customer?.postal_code}
            </StyledContents>
            <StyledContents>{customer?.country}</StyledContents>
          </Box>
        </Stack>
        <Stack spacing={2}>
          <Typography color={"neutral.main"}>Include in Order:</Typography>
          <Divider style={{ backgroundColor: "#E0E0E0" }} />
          <Stack>
            {items && (
              <table>
                <tbody>
                  {items.map((item) => (
                    <tr>
                      <StyledTd>
                        {item?.price?.item?.name || item?.name}
                      </StyledTd>
                      <StyledTd>* {item?.qty}</StyledTd>
                      <StyledTd style={{ textAlign: "right" }}>
                        {convertToPlaces(item?.total_price)}
                      </StyledTd>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </Stack>
          <Stack spacing={1}>
            <Stack direction={"row"} justifyContent="space-between">
              <StyledTotals>Add-ons</StyledTotals>
              <StyledTotals>{convertToPlaces(extraCharges)}</StyledTotals>
            </Stack>
            {/* <Stack direction={"row"} justifyContent="space-between">
              <StyledTotals> Discounts applied</StyledTotals>
              <StyledTotals>{convertToPlaces(totalDiscount)}</StyledTotals>
            </Stack> */}
            <Stack direction={"row"} justifyContent="space-between">
              <StyledTotals>Total Excluding Tax</StyledTotals>
              <StyledTotals>
                {convertToPlaces(totalExcludingTax) || 0.0}
              </StyledTotals>
            </Stack>
            {/* {enableColor && (
              <Stack direction={"row"} justifyContent="space-between">
                <StyledTotals>Color Matching Charges</StyledTotals>
                <StyledTotals>
                  {convertToDecimalPlaces(newCost.matchColor)}
                </StyledTotals>
              </Stack>
            )} */}
            {/* {enableRedraw && (
              <Stack direction={"row"} justifyContent="space-between">
                <StyledTotals>Logo redraw charges</StyledTotals>
                <StyledTotals>{convertToDecimalPlaces(newCost.redraw)}</StyledTotals>
              </Stack>
            )} */}

            <Stack direction={"row"} justifyContent="space-between">
              <StyledTotals>Estimated Shipping</StyledTotals>
              <StyledTotals>
                {convertToDecimalPlaces(updateTotalDelivery)}
              </StyledTotals>
            </Stack>
            {customer?.gst_enabled && (
              <>
                <Divider style={{ backgroundColor: "#E0E0E0" }} />
                <Stack direction={"row"} justifyContent="space-between">
                  <StyledTotals>GST</StyledTotals>
                  <StyledTotals>{convertToPlaces(totalGst)}</StyledTotals>
                </Stack>
              </>
            )}
            <Divider style={{ backgroundColor: "#E0E0E0" }} />
            <Stack direction={"row"} justifyContent="space-between">
              <StyledTotals style={{ fontWeight: "900" }}>
                Grand Total
              </StyledTotals>
              <StyledTotals style={{ fontWeight: "900" }}>
                {convertToPlaces(grandTotal)}
              </StyledTotals>
            </Stack>
            <Stack spacing={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={enableDelivery}
                    checked={enableDelivery}
                    onChange={handleEnableDelivery}
                  />
                }
                label="Update Delivery Charges"
              />
              {enableDelivery && (
                <>
                  <StyledHeaders>Delivery / Shipping</StyledHeaders>
                  <Stack spacing={2} direction={"row"}>
                    <FormControl
                      variant="standard"
                      style={{ color: "#fff" }}
                      fullWidth
                    >
                      <TextField
                        sx={{
                          "& .MuiInputBase-root": {
                            color: "#fff", // Text color for the input
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#fff", // Border color
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#fff", // Border color on hover
                          },
                          "& .Mui-disabled": {
                            color: "#fff", // Text color when disabled
                            "-webkit-text-fill-color": "#fff", // To fix color in some browsers when disabled
                          },
                        }}
                        id="outlined-controlled"
                        size="small"
                        value={updateTotalDelivery}
                        onChange={onchangeDelivery}
                        disabled={!enableDelivery}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </Stack>
                </>
              )}
            </Stack>
          </Stack>
        </Stack>
      </StyledStack>
    </>
  );
};

SalesOrderSummery.propTypes = {
  items: PropTypes.array,
};
SalesOrderSummery.defualtProps = {
  items: {
    items: [],
  },
};

export default SalesOrderSummery;
