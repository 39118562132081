export function storeValue(key, value) {
  try {
    console.log("storing value in local storage", { key, value });
    const lastUpdatedAt = Date.now();
    localStorage.setItem(key, JSON.stringify({value, lastUpdatedAt}));
  } catch (error) {
    console.log("error in storing value in local storage", { error, key, value });
  }
}

export function getValue(key) {
  try {
    console.log("getting value from local storage", { key });
    const {value, lastUpdatedAt} = JSON.parse(localStorage.getItem(key));

    if (Date.now() - lastUpdatedAt > 1000 * 60 * 1) {
      removePersisted(key);
      return null;
    }

    return value;
  } catch (error) {
    console.log("error in getting value from local storage", { error, key });
    return null;
  }
}

export function hasPersisted(key) {
  if(!key) return false;
  
  return !!localStorage.getItem(key);
}

export function removePersisted(key) {
  localStorage.removeItem(key);
}

export const persistKeys = {
  countries: "countries",
  patterns: "patterns",
  colors: "colors",
  fabrics: "fabrics",
  labels: "labels",
  trims: "trims",
  invoices: "invoices",
  poFields: "poFields",
  quotations: "quotations",
  organizations: "organizations",
};
