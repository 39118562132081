import React, { useEffect, useMemo } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import CalculatedRows from "../components/CalculatedRows";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { addActiveAll, removeActiveAll } from "../store/Store/orderSlice";
import { useState } from "react";

const StyledWrapper = styled("div")({
  overflowX: "auto",
  overflowY: "auto",
});

const DenseTable = ({ searchItem }) => {
  const dispatch = useDispatch();
  const { activeSport, listedItems, allActiveSport, order } = useSelector(
    (state) => state.orders
  );

  const showLining = useMemo(
    () => listedItems.some((element) => element?.lining_name?.length > 0),
    [listedItems]
  );

  const [data, setData] = useState([]);
  // const hasBeenActivated = allActiveSport.includes(activeSport);
  const hasBeenActivated =
    listedItems?.length == 0
      ? false
      : listedItems.every((element) => {
          return order.includes(element);
        });
  console.log(data, "dataaaaaaaaaaaaaaaaaaa");
  useEffect(() => {
    setData(
      listedItems.filter(
        (l) =>
          l.sport_id === activeSport &&
          l.name.toLowerCase().includes(searchItem.toLowerCase())
      )
    );
  }, [activeSport, listedItems, searchItem]);

  const addAllItems = (e) => {
    let value = e.target.checked;
    if (value) {
      dispatch(removeActiveAll({ activeSport }));
      dispatch(addActiveAll({ activeSport }));
    } else dispatch(removeActiveAll({ activeSport }));
  };

  return (
    <StyledWrapper>
      <TableContainer>
        <Table
          sx={{ overflow: "true", width: "1000px" }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell style={{ minWidth: "250px" }}>
                {/* <Checkbox
                  onChange={addAllItems}
                  id={activeSport}
                  checked={hasBeenActivated}
                /> */}
                Item
              </TableCell>
              <TableCell align="right">Style Name</TableCell>
              <TableCell align="right" style={{ minWidth: "150px" }}>
                Fabric
              </TableCell>
              {showLining ? (
                <TableCell align="right" style={{ minWidth: "150px" }}>
                  Lining Item
                </TableCell>
              ) : null}
              <TableCell align="right">Quantity</TableCell>
              <TableCell align="right">Unit Price</TableCell>
              <TableCell align="right">Extra Charge</TableCell>
              <TableCell align="right">Discount</TableCell>
              <TableCell align="right" style={{ minWidth: "130px" }}>
                Discounted Price
              </TableCell>
              <TableCell align="center" style={{ minWidth: "130px" }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <CalculatedRows
                row={row}
                index={index}
                key={row.index}
                showLining={showLining}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledWrapper>
  );
};

DenseTable.defaultProps = {
  searchItem: "",
};

DenseTable.propTypes = {
  searchItem: PropTypes.string,
};

export default DenseTable;
