import * as React from 'react';
import { useStyles } from '../../../Theme/Style';
import { styled } from '@mui/material/styles';
import { Box, Paper, CircularProgress, Typography} from "@mui/material";
import Intro from '../../atoms/Intro/Intro.component';
import { authHeader } from '../../../helpers';
import { apiConstants } from '../../../constants';
import InvoicesCustomerDetails from './InvoicesCustomerDetails';

const Content = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    textAlign: 'left',
    padding:'29px 37px',
    color: theme.palette.text.secondary,
}));

const InvoicesCustomerContainer = () => {
    const classes = useStyles();

    const breadcrumbs = [<Typography key="3">All Invoices</Typography>];

    const [ allInvoices, setAllInvoices ] = React.useState([]);
    const [paginationData, setPaginationData] = React.useState({});
    const [loadingComponent, setLoadingComponent] = React.useState(false);

    React.useEffect(() => {
        setLoadingComponent(true);
        getInvoicesByPage('1');
    }, [])

    const getInvoicesByPage = (number) => {
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
        };

        fetch(`${apiConstants.API_URL}/customer/invoices?page=${number}`, requestOptions).then((response)=>{
            response.text().then((text) => {
                const data = text && JSON.parse(text);
                setPaginationData(data.meta);
                setAllInvoices(data?.data);
                setLoadingComponent(false);
            });
        });
    }

    const handlePageChange = (newPage) => {
        getInvoicesByPage(newPage+1);
    }

    return(
       <>
        <Box sx={{ flexGrow: 1, marginBottom:'40px'}}>
            <Box className={classes.headerContainer}>
                <Intro
                    pageTitle={`All Invoices`}
                    pageTitleShort="All Quotations"
                    breadcrumbs={breadcrumbs}
                    additionalWidgets=""
                />
            </Box>
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                <Box gridColumn="span 12" >
                    <Content>
                        {loadingComponent ? 
                            <Box sx={{display:'flex', justifyContent:'center', alignItems:'center',  minHeight:'400px'}}>
                                <CircularProgress size={50}/>
                            </Box>
                            :
                            <InvoicesCustomerDetails 
                            invoices={allInvoices} 
                            paginationData={paginationData}
                            handlePageChange={handlePageChange}
                            />
                        }
                    </Content>
                </Box>
            </Box>
           
        </Box>
       </>
    )
}

export default InvoicesCustomerContainer;

