import axios from "axios";
import store from "../store/Store/store";
import { openModal } from "../store/Store/messagesSlice";
import { apiConstants } from "../constants";
import { getCurrentUser, getUserToken } from "../helpers";

axios.defaults.baseURL = `${apiConstants.API_URL}`;
const user = getCurrentUser();
const token = getUserToken();

if (user && token) {
  axios.defaults.headers.common = {
    Authorization: "Bearer " + token,
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };
}

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    store.dispatch(
      openModal({
        title: error?.message,
        body: JSON.stringify(error?.response?.data?.error) || "Error",
      })
    );
  }
);

export default axios;
