import React, { useState } from "react";
import {
  Button,
  Container,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import SearchIcon from "@mui/icons-material/Search";
import Table from "../Common/Table";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Tabs from "../Common/Tabs";
import CustomLineModal from "../Common/Modals/CustomLineModal";
import AutoCompleted from "../Common/AutoCompleted";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import AddItemModal from "../Common/Modals/AddItemModal";
import { removeOrder, removeList } from "../store/Store/orderSlice";
import { useDispatch } from "react-redux";

const StyledStack = styled(Stack)({
  justifyContent: "space-between",
  width: "425px",
});
const StyledDiv = styled("div")({
  overflowX: "auto",
  overflowY: "auto",
  height: "500px",
});

const OrderDetails = ({ ...props }) => {
  const dispatch = useDispatch();
  const { sports, selectedSports, updateSelectedSports, order, listedItems } =
    props;
  const { activeSport } = useSelector((state) => state.orders);

  const [open, setOpen] = React.useState(false);
  //const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [search, setSearch] = useState("");
  const [addModal, setAddModal] = useState(false);
  const closeAddModal = () => setAddModal(false);
  console.log(activeSport, "actttttttttttttttttt");
  const addSports = (value) => {
    console.log("vallllllllllllll", value);
    if (value.length == 0) {
      updateSelectedSports([value]);
    } else {
      updateSelectedSports([...value]);
    }
    console.log(
      value,
      value.length,
      "now selected",
      selectedSports,
      selectedSports.length,
      "set sport"
    );
    console.log(value.length < selectedSports.length, "checked");
    if (value?.length < selectedSports?.length) {
      const uniqueResult = selectedSports.filter(function (objOne) {
        return !value.some(function (objTwo) {
          return objOne.id == objTwo.id;
        });
      });

      if (uniqueResult.length !== 0) {
        console.log(uniqueResult[0], "uniqueResultArrayObjOne");
        listedItems?.forEach(function (l) {
          if (l?.sport_id === uniqueResult[0]?.id) {
            console.log(l, "l");
            let newItem = {
              ...l,
              selected: false,
            };
            //order?.length > 0 && dispatch(removeOrder({ newItem }));
            listedItems?.length > 0 && dispatch(removeList({ newItem }));
            order?.forEach(function (o) {
              if (o?.sport_id === uniqueResult[0]?.id) {
                console.log(o, "o");
                let newItem = {
                  ...o,
                  selected: false,
                };
                console.log(o, "o");
                order?.length > 0 && dispatch(removeOrder({ newItem }));
                // listedItems?.length > 0 && dispatch(removeList({ newItem }));
              }
            });
          }
        });
      }
    }
  };

  const handleChange = (event) => {
    setSearch(event.target.value);
  };

  return (
    <StyledDiv>
      <Container sx={{ textAlign: "left" }}>
        <StyledStack spacing={3}>
          <Typography fontSize={"24px"}>Order Details</Typography>
          <Typography xs={{ weight: "700" }} fontSize={"16px"}>
            Select Sport Categories
          </Typography>
          <AutoCompleted
            label="Category"
            placeholder="placeholder"
            variant="standard"
            options={sports}
            onChange={addSports}
            defaultValues={selectedSports}
          />
        </StyledStack>
      </Container>
      <Container
        sx={{
          textAlign: "left",
          marginTop: "10px",
        }}
      >
        <Stack direction={"column"} spacing={2} marginTop={"10px"}>
          <Typography fontWeight={"700"} fontSize={"16px"}>
            Select Item Details
          </Typography>
          {/* Table filtering  */}
          <Tabs tabs={selectedSports} />
          {/*  */}
          <Stack direction={"row"} justifyContent={"space-between"}>
            {/* <FormControl>
              <OutlinedInput
                value={search}
                size="small"
                onChange={handleChange}
                placeholder="Search Item"
                endAdornment={
                  <InputAdornment>
                    <IconButton edge="end">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl> */}
            <Stack direction={"row"} spacing={1}>
              <Button
                variant="outlined"
                startIcon={<AddCircleOutlineIcon />}
                onClick={() => setAddModal(true)}
                disabled={!activeSport}
              >
                ADD ITEM
              </Button>
              <Button
                color="success"
                variant="outlined"
                startIcon={<AddCircleOutlineIcon />}
                // onClick={() => submitQuotation()}
                onClick={() => setOpen(true)}
                disabled={!activeSport}
              >
                ADD CUSTOM ITEM
              </Button>
            </Stack>
            <AddItemModal handleClose={closeAddModal} open={addModal} />
            <CustomLineModal handleClose={handleClose} open={open} />
          </Stack>
          <Table searchItem={search} />
        </Stack>
      </Container>
    </StyledDiv>
  );
};

export default OrderDetails;
