import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Stack, Stepper, Step, StepLabel, Typography } from "@mui/material";
import { HORIZONTAL, VERTICAL } from "../../constants/Constants";

const QontoConnector = styled(StepLabel)(({ theme }) => ({
  ".Mui-completed .MuiStepIcon-root": {
    color: theme.palette?.success.main,
  },
  ".MuiStepConnector-root .MuiStepConnector-vertical": {
    marginRight: "49px !important",
  },
}));

const QontoConnector1 = styled(StepLabel)(({ theme }) => ({
  ".Mui-completed .MuiStepIcon-root": {
    color: theme.palette?.success.main,
  },
}));

const InvoiceSteppers = ({
  steps,
  activeStep,
  orientation,
  alternativeLabel,
}) => {
  return (
    <Stack sx={{ width: "100%" }} spacing={4}>
      <Stepper
        activeStep={activeStep}
        orientation={orientation}
        // alternativeLabel={alternativeLabel}
      >
        {steps.map((row, index) => (
          <Step key={index}>
            <StepLabel>
              <Typography sx={{ fontWeight: 700, fontSize: 13 }}>
                {row.date}
              </Typography>
              <Typography
                sx={{
                  color: row.color === "default" ? "#512879" : "#12830b",
                  fontWeight: 600,
                  width: "100%",
                }}
              >
                {row.label} {row.amount && ` -  $${row.amount}`}
              </Typography>
            </StepLabel>
            <Typography
              sx={{
                marginLeft: "12px",
                paddingLeft: "20px",
                paddingRight: "8px",
                borderLeft: "1px solid #bdbdbd",
              }}
            >
              {row.description}
            </Typography>
            {row.image && (
              <Typography
                sx={{
                  marginLeft: "12px",
                  paddingLeft: "20px",
                  paddingRight: "8px",
                  borderLeft: "1px solid #bdbdbd",
                }}
              >
                <img src={row.image} alt="Invoice Attachment" style={{ maxWidth: "300px" }} />
              </Typography>
            )}
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
};

InvoiceSteppers.propTypes = {
  /**
   * Stpes to be displayed
   */
  steps: PropTypes.array.isRequired,

  /**
   *  Active stage
   */
  activeStep: PropTypes.number,

  /**
   *
   */
  orientation: PropTypes.oneOf([HORIZONTAL, VERTICAL]),

  /**
   *
   */
  alternativeLabel: PropTypes.bool,
};

InvoiceSteppers.defaultProps = {
  activeStep: 1,
  orientation: HORIZONTAL,
  steps: [],
  alternativeLabel: false,
};

export default InvoiceSteppers;
