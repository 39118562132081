import React, { useCallback, useState } from "react";
import GlobalNotificationContext from "../Contexts/GlobalNotificationContext";
import NotificationSnackBar from "../components/atoms/SnackBar/SnackBar";
import SuccessModal from "../Common/Modals/SuccessModal";

const NotificationProvider = (props) => {
  const [notification, setNotification] = useState({
    message: undefined,
    type: undefined,
    onClose: undefined,
  });

  const clearNotification = () => {
    setNotification({
      message: undefined,
      type: undefined,
    });
  };

  const addNotification = useCallback(({ message, type, onClose }) => {
    setNotification({ message, type, onClose });

    const timeout = setTimeout(() => {
      clearNotification();
    }, 15000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <GlobalNotificationContext.Provider
      value={{ notification, addNotification, clearNotification }}
    >
      {props.children}
      {notification.type === "success-model" ? (
        <SuccessModal
          title={notification.message.title}
          open={!!notification.message}
          handleClose={() => {
            clearNotification();
            notification.onClose?.();
          }}
          body={notification.message.body}
          extra={notification.message.extra}
        />
      ) : (
        <NotificationSnackBar
          open={!!notification.message}
          notification={notification}
          onClose={() => {
            clearNotification();
            notification.onClose?.();
          }}
        />
      )}
    </GlobalNotificationContext.Provider>
  );
};

export default NotificationProvider;
