import React from "react";
import { Box, Typography, Stack, TextField } from "@mui/material";

const Field = (props) => {
  const { name, type, en, zh, data } = props;

  return (
    <div>
      <TextField
        id={name}
        label={`${en} | ${zh}`}
        fullWidth
        multiline={type === "textare" ? true : false}
        variant="standard"
        defaultValue={data}
        sx={{ pointerEvents: "none" }}
      />
    </div>
  );
};

const TextPanel = (props) => {
  const { text, data } = props;
  const { title, subtitle, body, fields } = text;

  return (
    <Box sx={{ p: 2 }}>
      <Stack spacing={2}>
        {title?.en && (
          <Typography variant="h6">
            {title?.en} <br /> {title?.zh}
          </Typography>
        )}

        {subtitle?.en && (
          <Typography variant="subtitle">
            {subtitle?.en} <br /> {subtitle?.zh}
          </Typography>
        )}

        {body?.en && (
          <Typography variant="body2">
            {body?.en} <br /> {body?.zh}
          </Typography>
        )}

        {fields && (
          <Box>
            {fields.length > 0 && (
              <Stack spacing={2} sx={{ mb: 2 }}>
                {fields.map((field) => (
                  <Field
                    key={field?.name}
                    name={field?.name}
                    type={field?.type}
                    en={field?.en}
                    zh={field?.zh}
                    data={data}
                  />
                ))}
              </Stack>
            )}
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export default TextPanel;
