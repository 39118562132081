import * as React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Grid,
  Button,
  Stack,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@mui/styles";
import { useCallback } from "react";

const useStyles = makeStyles({
  accordion: {
    // boxShadow: "none !important",
  },
  accordionSummaryTextContainer: {
    // color: "black",
  },
  cell: {
    textAlign: "left",
    fontSize: 14,
    fontWeight: 700,
  },
  cellValue: {
    textAlign: "left",
    fontSize: 14,
  },
  row: {
    borderBottom: "1px solid #efefef",
    padding: "8px 16px",
  },
  totalCounter: {
    fontSize: "14px",
    textAlign: "left",
  },
  sizeCountContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
  },
  sizeCount: {
    backgroundColor: "rgba(0, 0, 0, 0.12)",
    display: "flex",
    borderRadius: "20px",
    minWidth: "50px",
    textAlign: "center",
    fontSize: "14px",
    alignItems: "center",
  },
  sizeCountIcon: {
    backgroundColor: "#512879",
    color: "#fff",
    minWidth: "20px",
    height: "20px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "14px",
    margin: "3px 6px 3px 3px",
    textTransform: "capitalize",
  },
  sizeCountValue: {
    margin: "3px 6px 3px 3px",
  },
});

const SizeAndNamesComponent = (props) => {
  const classes = useStyles();
  const { item } = props;

  console.log("item_details#####################", item.item_details);

  const downloadUsingAnchorElement = async () => {
    const anchor = document.createElement("a");
    anchor.href = item?.item_details_file_url;
    document.body.appendChild(anchor);
    anchor.click();
  };

  const getNameAndSizes =
    useCallback(() => {
      return item.item_details?.map(({ size, name, number, id }) => ({
        id,
        size_id: size.id,
        sizeCode: size.code,
        number: number || "-",
        name: name || "-",
      }));
    }, [item]) || [];

  const getSizeCounts = useCallback(() => {
    const res =
      item.item_details?.reduce((acc, { size }) => {
        const existingSize = acc.get(size.id);
        if (existingSize) {
          existingSize.quantity += 1;
        } else {
          acc.set(size.id, {
            size_id: size.id,
            quantity: 1,
            sizeCode: size.code,
          });
        }
        return acc;
      }, new Map()) || [];

    return Array.from(res.values());
  }, [item]);

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ backgroundColor: "#D5F9FC" }}
      >
        <Grid container className={classes.accordionSummaryTextContainer}>
          <Grid item xs={4}>
            Sizes and Names
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        {item?.flag_name_no !== 0 && (
          <>
            <Box
              sx={{ background: "#000", color: "#fff", padding: "12px 16px" }}
            >
              <Grid container>
                <Grid item xs={4} className={classes.cell}>
                  <Box>Garment Size</Box>
                </Grid>
                <Grid item xs={4} className={classes.cell}>
                  <Box>Number</Box>
                </Grid>
                <Grid item xs={4} className={classes.cell}>
                  <Box>Name</Box>
                </Grid>
              </Grid>
            </Box>
            {getNameAndSizes()?.map((item, index) => (
              <Box key={index} className={classes.row}>
                <Grid container>
                  <Grid item xs={4} className={classes.cellValue}>
                    {item.sizeCode}
                  </Grid>
                  <Grid item xs={4} className={classes.cellValue}>
                    {item.number}
                  </Grid>
                  <Grid item xs={4} className={classes.cellValue}>
                    {item.name}
                  </Grid>
                </Grid>
              </Box>
            ))}
          </>
        )}
        <Box sx={{ background: "#f1f1f2", padding: "16px" }}>
          <Grid container>
            <Grid item xs={8}>
              <Box className={classes.totalCounter}>
                {`Total Units: ${item?.qty}`}
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box className={classes.sizeCountContainer}>
                {getSizeCounts()?.map((count, index) => (
                  <Box key={index} className={classes.sizeCount}>
                    <Box className={classes.sizeCountIcon}>
                      {count.sizeCode}
                    </Box>
                    <Box className={classes.sizeCountValue}>
                      {count.quantity}
                    </Box>
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Stack direction={"row"} alignItems={"center"} sx={{ mt: 2 }}>
          {item?.item_details_file_url && (
            <Button onClick={downloadUsingAnchorElement}>
              Download Sizes and Names Excel File
            </Button>
          )}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default SizeAndNamesComponent;
