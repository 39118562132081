import React from "react";
import { CircularProgress, Backdrop } from "@mui/material";

const ProgressCircularWithBackdrop = (props) => {
  const { open, progress } = props;

  return (
    <Backdrop sx={{ zIndex: 1 }} open={open}>
      <CircularProgress
        size={50}
        value={progress}
        variant={progress ? "determinate" : "indeterminate"}
      />
    </Backdrop>
  );
};

export default ProgressCircularWithBackdrop;
