import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  Divider,
  Grid,
  Modal,
  Stack,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import Intro from "../../../atoms/Intro/Intro.component";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { PRE_PRODUCTION_APPROVAL } from "../../../../constants/Constants";
import {
  useGetProdApprovalsByIdQuery,
  useInitiatePreProductionApprovalMutation,
  useRejectOrApprovePreProductionApprovalMutation,
  useSkipDesignApprovalMutation,
  useSkipOverallPatternApprovalMutation,
  useUploadApprovalImageMutation,
} from "../../../../services/api/prod-approval";
import {
  capitalizeFirstLetter,
  checkIsAdmin,
  checkPermission,
} from "../../../../helpers/helpers";
import { useNotification } from "../../../../Contexts/GlobalNotificationContext";
import { PERMISSIONS } from "../../../../constants/data/rolesPermissions.constants";
import { useFormik } from "formik";
import * as yup from "yup";
import { zip } from "lodash";

let breadcrumbs = ({ mode, type }) => [
  <Link
    underline="hover"
    key="1"
    color="inherit"
    to={
      type == PRE_PRODUCTION_APPROVAL.NAMES.DESIGN
        ? "/admin/pattern-approvals"
        : "/admin/prototype-approvals"
    }
  >
    All Pre Production Approvals
  </Link>,
  <Typography key="3">{`${mode} ${capitalizeFirstLetter(
    type
  )} Approval`}</Typography>,
];

const useStyles = makeStyles({
  orderItemDetailsBar: {
    backgroundColor: "#e3e3e3",
    padding: "9px 0px 9px 28px",
  },
  label: {
    fontSize: "12px",
    color: "#000",
    opacity: 0.85,
    textAlign: "left",
  },
  detail: {
    color: "#000",
    opacity: 0.6,
    textAlign: "left",
  },
  imageContainer: {
    textAlign: "center",
    width: "10rem",
    height: "10rem",
    objectFit: "cover",
  },
  imageActions: {
    display: "flex",
    flexDirection: "row",
    padding: ".2rem",
    cursor: "pointer",
    gap: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  informationLabel: {
    marginTop: "12px !important",
    fontSize: 16,
    color: "rgba(0, 0, 0, 0.89)",
  },
  informationValue: {
    margin: "10px 0px !important",
    fontSize: 14,
  },
  rejected_note_box: {
    borderRadius: "8px",
    backgroundColor: "#FFEBEB",
    padding: "16px 24px",
    border: "1px solid #FF0000",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
});

const PreProdAprDetailedView = () => {
  const classes = useStyles();
  const [image, setImage] = React.useState({ open: false, uri: "" });
  const [images, setImages] = useState([]);
  const { id, initiate } = useParams();
  const { addNotification } = useNotification();
  const navigate = useNavigate();
  const [isInitiating, setIsInitiating] = useState(false);

  const isAdmin = useMemo(() => checkIsAdmin(), []);

  const { data, isLoading, isFetching, isError, refetch } =
    useGetProdApprovalsByIdQuery(id, {
      refetchOnMountOrArgChange: true,
      skip: false,
    });

  const { type, status, rejected_note } = data || {};

  const uiType =
    type === PRE_PRODUCTION_APPROVAL.NAMES.DESIGN ? "Pattern" : "Prototype";

  const [initiateApproval, { isLoading: isUploading }] =
    useInitiatePreProductionApprovalMutation();

  const [uploadArtwork, { isLoading: isImageUploading }] =
    useUploadApprovalImageMutation();

  const [
    rejOrAcceptApprovalMutation,
    { isLoading: isCustomerApprovalLoading },
  ] = useRejectOrApprovePreProductionApprovalMutation();

  const canInitiate = useMemo(() => {
    return (
      initiate === "initiate" &&
      (status === PRE_PRODUCTION_APPROVAL.STATUS.CREATED ||
        status === PRE_PRODUCTION_APPROVAL.STATUS.REJECTED)
    );
  }, [status]);

  const isRejected = () => {
    return status === PRE_PRODUCTION_APPROVAL.STATUS.REJECTED;
  };

  const mode = canInitiate ? "Initiate" : "View";

  const redirectToList = useCallback(() => {
    if (data?.type === PRE_PRODUCTION_APPROVAL.NAMES.DESIGN)
      isAdmin
        ? navigate("/admin/pattern-approvals")
        : navigate("/pattern-approvals");
    if (data?.type === PRE_PRODUCTION_APPROVAL.NAMES.PROTOTYPE)
      isAdmin
        ? navigate("/admin/prototype-approvals")
        : navigate("/prototype-approvals");
  }, [data]);

  const handleInitiate = useCallback(async () => {
    const { id: production_order_id } = data?.production_order || {};

    if (production_order_id) {
      if (images.length < 2) {
        addNotification({
          message: "Please upload at least 2 images",
          type: "error",
        });

        return;
      }

      try {
        setIsInitiating(true);

        const formData = images
          .filter(({ file }) => !!file)
          .map(({ file }) => {
            const formData = new FormData();

            formData.append("image", file);
            return formData;
          });

        const uploadedImagesPaths = [];
        const uploadedImages = [];

        for (let fd of formData) {
          const { url, path } = await uploadArtwork({
            type,
            formData: fd,
          }).unwrap();
          if (path) uploadedImagesPaths.push(path);
          if (url) uploadedImages.push(url);
        }

        const imagePaths = uploadedImagesPaths.concat(
          images.filter(({ file }) => !file).map(({ name }) => name)
        );

        await initiateApproval({
          id,
          production_order_id,
          type,
          images: imagePaths,
        }).unwrap();

        setIsInitiating(false);
        addNotification({
          message: "Approval initiated successfully",
          type: "success",
        });
        redirectToList();
      } catch (error) {
        addNotification({
          message: "Error while uploading images",
          type: "error",
        });
      }
    }
  }, [data, images]);

  useEffect(() => {
    // Redirect to list page if user does not have permission to initiate
    if (canInitiate && !checkPermission(PERMISSIONS.PRODUCTION_ORDERS)) {
      addNotification({
        message:
          "You do not have permission to initiate this approval. You will be redirected to the list page.",
        type: "error",
      });

      setTimeout(() => {
        redirectToList();
      }, 3000);
    }
  }, [data, canInitiate, status]);

  console.log("data", images);

  useEffect(() => {
    if (data?.images instanceof Array) {
      zip(data.images, data.image_urls).forEach(([name, uri], index) => {
        setImages((pre) => [
          ...pre,
          {
            id: index,
            uri,
            name,
            file: null,
          },
        ]);
      });
    }
  }, [data]);

  const openImageView = (uri) => {
    setImage({ open: true, uri });
  };

  const handleClose = () => {
    setImage({ open: false, uri: "" });
  };

  const deleteImage = (index) => {
    const newImages = images.filter(({ id }) => id !== index);
    setImages(newImages);
  };

  const handleImageUpload = (files) => {
    if (files.length == 0) return;

    if (images.length >= PRE_PRODUCTION_APPROVAL.MAX_IMAGES) {
      addNotification({
        message: "Maximum images limit reached",
        type: "error",
      });
      return;
    }

    setImages((pre) => {
      const newImages = Array.from(files)
        .filter((file) => {
          const validTypes = ["image/jpeg", "image/png", "image/gif"];
          const validSize = file.size / 1024 <= 3072;
          const isValidType = validTypes.includes(file.type);

          if (!isValidType) {
            addNotification({
              message: "Invalid file type. Only JPEG, PNG, GIF are allowed",
              type: "error",
            });
          }

          if (!validSize) {
            addNotification({
              message: "File size should be less than 3MB",
              type: "error",
            });
          }

          return isValidType && validSize;
        })
        .filter(
          (file, index) =>
            index + pre.length < PRE_PRODUCTION_APPROVAL.MAX_IMAGES
        )
        .map((file, index) => ({
          id: pre.length + index + 1,
          name: file.name,
          uri: URL.createObjectURL(file),
          file,
        }));

      return [...pre, ...newImages];
    });
  };

  const canAddNewImage = useMemo(() => {
    return images.length < PRE_PRODUCTION_APPROVAL.MAX_IMAGES;
  }, [images]);

  const onCustomerApproval = (accept) => async (note) => {
    try {
      await rejOrAcceptApprovalMutation({ id, accept, note }).unwrap();
      addNotification({
        message: `Approval ${accept ? "Accepted" : "Rejected"}`,
        type: "success",
      });
      redirectToList();
    } catch (error) {
      addNotification({
        message: "Error while updating approval status",
        type: "error",
      });
    }
  };

  const {
    modal: SkipModal,
    isOpen: isSkipModalOpen,
    open: openSkipModal,
    close: closeSkipModal,
  } = useSkipModal();

  if (isError) {
    return (
      <Typography
        sx={{
          textAlign: "center",
          fontSize: "0.875rem",
          lineHeight: "normal",
          color: "error.main",
        }}
      >
        Error while fetching data
      </Typography>
    );
  }

  if (isLoading || isFetching)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "300px",
        }}
      >
        <CircularProgress size={50} />
      </Box>
    );

  return (
    <div>
      <Stack direction={"row"} justifyContent={"space-between"} sx={{ pr: 2 }}>
        <Intro
          pageTitle={
            type === PRE_PRODUCTION_APPROVAL.NAMES.DESIGN
              ? `${mode} Pattern Approval`
              : type === PRE_PRODUCTION_APPROVAL.NAMES.PROTOTYPE
              ? `${mode} Prototype Approval`
              : "_"
          }
          pageTitleShort={
            type === PRE_PRODUCTION_APPROVAL.NAMES.DESIGN
              ? `${mode} Pattern Approval`
              : type === PRE_PRODUCTION_APPROVAL.NAMES.PROTOTYPE
              ? `${mode} Prototype Approval`
              : "_"
          }
          breadcrumbs={breadcrumbs({ mode, type: uiType })}
          additionalWidgets=""
        />
      </Stack>

      <ApprovalDetails {...data} />

      {status != PRE_PRODUCTION_APPROVAL.STATUS.SKIPPED && (
        <>
          <Box
            sx={(them) => ({
              display: "flex",
              flexDirection: "column",
              gap: 2,
              marginTop: 2,
            })}
          >
            <Typography variant="h5" style={{ paddingBottom: "10px" }}>
              {uiType} Approval Artworks ({images.length}/
              {PRE_PRODUCTION_APPROVAL.MAX_IMAGES})
            </Typography>
            <Grid container spacing={2}>
              {images.map(({ id, name, uri }, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={index}>
                  <Card>
                    <CardMedia
                      component={"img"}
                      height={350}
                      image={uri}
                      onClick={() => openImageView(uri)}
                    />
                    <Box className={classes.imageActions}>
                      <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        onClick={() => openImageView(uri)}
                      >
                        View
                      </Button>
                      {canInitiate && (
                        <DeleteIcon
                          onClick={() => deleteImage(id)}
                          style={{
                            fill: "white",
                            stroke: "black",
                            scale: "90%",
                          }}
                        />
                      )}
                    </Box>
                  </Card>
                </Grid>
              ))}
            </Grid>

            {isRejected() && rejected_note && (
              <Box className={classes.rejected_note_box}>
                <Typography
                  gutterBottom
                  variant="body"
                  component="div"
                  sx={{
                    fontSize: "1.125rem",
                    fontWeight: "bold",
                    color: "error.main",
                  }}
                >
                  This Approval is Rejected
                </Typography>
                <Divider />
                <Typography margin={"1rem"}>
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      color: "#FF0000",
                    }}
                    component={"span"}
                  >
                    {"Reason: "}
                  </Typography>
                  {rejected_note}
                </Typography>
              </Box>
            )}

            {checkPermission(PERMISSIONS.PRODUCTION_ORDERS) && canInitiate && (
              <>
                <ImagesUploader
                  handleImageUpload={handleImageUpload}
                  canAddNewImage={canAddNewImage}
                />

                <ButtonGroup spacing={2}>
                  {type === PRE_PRODUCTION_APPROVAL.NAMES.DESIGN && (
                    <>
                      <Button
                        onClick={() => openSkipModal({ id, type })}
                        variant="contained"
                        size="small"
                        color="secondary"
                        disabled={isInitiating || isSkipModalOpen}
                      >
                        Skip Pattern Approval
                      </Button>
                      <Button
                        onClick={() => openSkipModal({ id, type: "both" })}
                        variant="contained"
                        size="small"
                        color="warning"
                        disabled={isInitiating || isSkipModalOpen}
                      >
                        Skip Overall Pre-Production Approval
                      </Button>
                    </>
                  )}

                  <div style={{ flexGrow: 1 }}></div>
                  <Button
                    style={{ justifySelf: "flex-end" }}
                    onClick={handleInitiate}
                    variant="contained"
                    size="small"
                    color="primary"
                    disabled={isInitiating || isSkipModalOpen}
                  >
                    Initiate Approval
                  </Button>
                </ButtonGroup>
              </>
            )}

            {!isAdmin &&
              status === PRE_PRODUCTION_APPROVAL.STATUS.SUBMITTED && (
                <>
                  <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    disabled={isCustomerApprovalLoading}
                    onClick={onCustomerApproval(true)}
                  >
                    Accept
                  </Button>
                  <Typography textAlign={"center"}>OR</Typography>
                  <RejectionNote
                    onSubmit={onCustomerApproval(false)}
                    disabled={isCustomerApprovalLoading}
                  />
                </>
              )}
          </Box>

          <Dialog open={image.open} onClose={handleClose} fullScreen>
            <DialogContent>
              <AppBar elevation={0} sx={{ position: "relative" }}>
                <Toolbar sx={{ px: { sm: 2 } }}>
                  <Typography sx={{ flex: 1 }} variant="h6" component="div">
                    Image Preview
                  </Typography>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                </Toolbar>
              </AppBar>
              <img src={image.uri} alt="image" style={{ width: "100%" }} />
            </DialogContent>
          </Dialog>
          <SkipModal />
        </>
      )}
    </div>
  );
};

const RejectionNote = ({ onSubmit, disabled }) => {
  const validationSchema = yup.object({
    note: yup
      .string("Enter your note")
      .min(10, "Note is too short")
      .required("Note is required"),
  });

  const formik = useFormik({
    initialValues: {
      note: "",
    },
    validationSchema: validationSchema,
    onSubmit: ({ note }) => {
      onSubmit(note);
    },
  });

  return (
    <>
      <TextField
        id="reject-note"
        name="note"
        label="Rejection Note"
        multiline
        maxRows={4}
        value={formik.values.note}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.note && Boolean(formik.errors.note)}
        helperText={formik.touched.note && formik.errors.note}
        disabled={disabled}
      />
      <Typography fontSize={"0.6rem"}>
        Provide a rejection Note to Reject the Approval
      </Typography>
      <Button
        variant="outlined"
        size="small"
        color="warning"
        onClick={formik.submitForm}
        disabled={disabled}
      >
        Reject
      </Button>
    </>
  );
};

const ImagesUploader = ({ handleImageUpload, canAddNewImage }) => {
  return (
    <Box>
      <label htmlFor="image">
        <br />
        <Card
          elevation={0}
          sx={{
            border: "1px dashed #bbb",
            cursor: canAddNewImage ? "pointer" : "not-allowed",
            mt: 2,
            minHeight: "15rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
          onDrop={(e) => {
            e.preventDefault();
            handleImageUpload(e.dataTransfer.files);
          }}
          onDragOver={(e) => {
            e.preventDefault();
          }}
        >
          <CardContent>
            <Box>
              <SvgIcon
                sx={{
                  width: "3rem",
                  height: "3rem",
                  margin: "1rem auto 1.5rem auto",
                  opacity: "0.8",
                  display: "block",
                }}
              >
                <svg xmlSpace="preserve" viewBox="0 0 409.794 409.794">
                  <path d="M387.367 176.731c3.861-2.284 5.154-7.267 2.869-11.128L329.955 63.46a8.133 8.133 0 0 0-6.999-3.999H96.876a8.117 8.117 0 0 0-7.096 4.17L32.848 165.774a8.111 8.111 0 0 0 3.138 11.055 8.08 8.08 0 0 0 3.95 1.032 8.106 8.106 0 0 0 7.104-4.17l54.616-97.982h216.651l57.924 98.144a8.138 8.138 0 0 0 11.136 2.878zM401.665 196.768H301.196c-4.495 0-8.129 3.633-8.129 8.129s3.633 8.129 8.129 8.129h92.341v121.059H16.257v-114.37h83.139c4.495 0 8.129-3.633 8.129-8.129s-3.633-8.129-8.129-8.129H8.129A8.121 8.121 0 0 0 0 211.586v130.618a8.121 8.121 0 0 0 8.129 8.129h393.537a8.121 8.121 0 0 0 8.129-8.129V204.897a8.129 8.129 0 0 0-8.13-8.129z" />
                  <path d="m258.277 202.41-12.42 38.741h-76.254l-21.151-40.049c-2.089-3.967-7.015-5.511-10.982-3.39-3.967 2.097-5.495 7.007-3.39 10.982l23.443 44.382a8.12 8.12 0 0 0 7.186 4.333h87.081a8.12 8.12 0 0 0 7.738-5.641l14.233-44.382c1.374-4.276-.984-8.852-5.251-10.226-4.299-1.367-8.867.99-10.233 5.25zM333.011 168.652a8.107 8.107 0 0 0 10.949 3.503 8.128 8.128 0 0 0 3.503-10.949l-11.51-22.354a8.126 8.126 0 0 0-7.226-4.406H98.421a8.14 8.14 0 0 0-7.486 4.95L81.44 161.75c-1.756 4.129.171 8.901 4.308 10.665a8.102 8.102 0 0 0 3.178.642 8.141 8.141 0 0 0 7.486-4.95l7.389-17.403H323.76l9.251 17.948zM107.216 126.79a8.141 8.141 0 0 0 7.486-4.95l6.706-15.834h178l8.568 16.428a8.125 8.125 0 0 0 10.974 3.447 8.144 8.144 0 0 0 3.447-10.974l-10.852-20.801a8.138 8.138 0 0 0-7.21-4.365H116.027a8.14 8.14 0 0 0-7.486 4.95l-8.811 20.793c-1.756 4.137.187 8.909 4.308 10.665a8.313 8.313 0 0 0 3.178.641z" />
                </svg>
              </SvgIcon>
            </Box>
            {!canAddNewImage && (
              <Typography
                gutterBottom
                variant="body"
                component="div"
                sx={{
                  textAlign: "center",
                  fontSize: "0.875rem",
                  lineHeight: "normal",
                  color: "warning.main",
                }}
              >
                Max Images Limit Reached. Remove some images to add new ones.
              </Typography>
            )}

            <Typography
              gutterBottom
              variant="body"
              component="div"
              sx={{
                textAlign: "center",
                fontSize: "0.875rem",
                lineHeight: "normal",
              }}
            >
              Click or drag files here.
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ textAlign: "center", fontSize: "0.65rem" }}
            >
              (Accepted file types: JPEG, PNG, GIF, BMP)
            </Typography>
          </CardContent>
          <CardActions sx={{ pointerEvents: "none", mb: 1 }}>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              fullWidth
              disabled={!canAddNewImage}
            >
              Upload
            </Button>
          </CardActions>
        </Card>
      </label>
      <input
        type="file"
        id="image"
        name="image"
        accept="image/*"
        onChange={(e) => {
          handleImageUpload(e.target.files);
        }}
        style={{
          position: "absolute",
          pointerEvents: "none",
          visibility: "hidden",
        }}
        multiple={true}
        disabled={!canAddNewImage}
      />
    </Box>
  );
};

const ApprovalDetails = ({
  id: approvalId,
  type,
  created_at: createdAt,
  production_order,
  status,
}) => {
  const classes = useStyles();

  const {
    id: prodOrderNo,
    organization: { name: orgName } = { name: "You" },
    item_description: itemDescription,
  } = production_order || { organization: {} };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
        <Typography className={classes.informationLabel}>
          Approval ID:
        </Typography>
        <Typography className={classes.informationValue}>
          {approvalId}
        </Typography>
        <Divider />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
        <Typography className={classes.informationLabel}>
          Created Date:
        </Typography>
        <Typography className={classes.informationValue}>
          {createdAt || "_"}
        </Typography>
        <Divider />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
        <Typography className={classes.informationLabel}>Status:</Typography>
        <Typography
          className={classes.informationValue}
          sx={{
            color: status === "approved" ? "green" : "grey",
          }}
        >
          {capitalizeFirstLetter(status) || "_"}
        </Typography>
        <Divider />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
        <Typography className={classes.informationLabel}>
          Production Order Number:
        </Typography>
        <Typography className={classes.informationValue}>
          {prodOrderNo || "_"}
        </Typography>
        <Divider />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
        <Typography className={classes.informationLabel}>
          Organization Name:
        </Typography>
        <Typography className={classes.informationValue}>
          {orgName || "_"}
        </Typography>
        <Divider />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
        <Typography className={classes.informationLabel}>
          Item Description:
        </Typography>
        <Typography className={classes.informationValue}>
          {itemDescription || "_"}
        </Typography>
        <Divider />
      </Grid>
    </Grid>
  );
};

const useSkipModal = () => {
  const style = {
    background: "#FFFFFF",
    boxShadow:
      "0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "10px",
    height: "200px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  };
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({
    id: undefined,
    type: undefined,
  });

  const { addNotification } = useNotification();

  const [skipDesignApproval, { isLoading: isSkipDesignApprovalProcessing }] =
    useSkipDesignApprovalMutation();
  const [
    skipOverallPatternApproval,
    { isLoading: isSkipOverallPatternApprovalProcessing },
  ] = useSkipOverallPatternApprovalMutation();

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOpen = useCallback(async ({ id, type }) => {
    setOpen(true);
    setData({ id, type });
    console.log("data", { id, type });
  }, []);

  const handleConfirm = useCallback(async () => {
    try {
      if (data.type === PRE_PRODUCTION_APPROVAL.NAMES.DESIGN) {
        await skipDesignApproval(data).unwrap();
        addNotification({
          message: "Pattern Approval Skipped successfully",
          type: "success",
        });
      } else if (data.type === "both") {
        await skipOverallPatternApproval(data).unwrap();
        addNotification({
          message: "Overall Prototype Approval Skipped successfully",
          type: "success",
        });
      }
      handleClose();
    } catch (error) {
      addNotification({
        message: "Pre Production Approval Skipped Failed",
        type: "error",
      });
      handleClose();
    }
  }, [data]);

  return {
    isOpen: open,
    open: handleOpen,
    close: handleClose,
    modal: () => (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="skip-pre-production-approval"
        aria-describedby="skip-pre-production-approval-description"
      >
        <Box sx={style}>
          <Stack direction="row" flex justifyContent="end">
            <Button
              variant="text"
              size="small"
              color="primary"
              onClick={handleClose}
            >
              <CloseIcon />
            </Button>
          </Stack>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ mt: 2 }}
          >
            Are you sure you want to skip the{" "}
            {data.type === PRE_PRODUCTION_APPROVAL.NAMES.DESIGN
              ? "Pattern Approval"
              : "Overall Pre Production Flow"}
            ?
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            gap={10}
          >
            <Button
              variant="contained"
              size="small"
              color="warning"
              onClick={handleConfirm}
              disabled={
                isSkipDesignApprovalProcessing ||
                isSkipOverallPatternApprovalProcessing
              }
            >
              Confirm
            </Button>
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    ),
  };
};

export default PreProdAprDetailedView;
