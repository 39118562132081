import * as React from "react";
import {
  Box,
  Grid,
  Button,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import ProductionOrderUpdateStatusModal from "../../../Common/Modals/ProductionOrderUpdateStatus";
import ProductionStatusStepper from "../ProductionStatusStepper";
import { VERTICAL } from "../../../constants/Constants";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { useGetAllProdOrderTemplatesQuery } from "../../../services/api/parameters/production.order.template.service";
import { useState } from "react";
import { useEffect } from "react";

const useStyles = makeStyles({
  orderItemDetailsBar: {
    backgroundColor: "#e3e3e3",
    padding: "9px 0px 9px 28px",
  },
  label: {
    fontSize: "12px",
    color: "#000",
    opacity: 0.85,
    textAlign: "left",
  },
  detail: {
    color: "#000",
    opacity: 0.6,
    textAlign: "left",
  },
  imageContainer: {
    padding: "0px 16px",
    textAlign: "center",
    marginTop: "20px",
  },
});

const OrderItemDetailsComponent = (props) => {
  const classes = useStyles();
  const {
    id,
    productionOrder,
    activeItem,
    handleStoreStages,
    stages,
    activeStep,
    productionStatus,
    handleTemplateChange,
    template,
  } = props;
  const [open, setOpen] = React.useState(false);
  const passedStagesLastIndex =
    activeItem?.sales_order_item_passed_stages?.length - 1 || 0;
  const currentStage =
    (Object.keys(activeItem).length !== 0 &&
      activeItem?.sales_order_item_passed_stages[passedStagesLastIndex]?.stage
        ?.name) ||
    "-";

  const openProductOrderStatusModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdateStatus = () => {
    const stage = {
      id: 5,
      name: "QC Completed",
      keyword: "qc_completed",
    };
    handleStoreStages(stage);
    handleClose();
  };

  const OrderItemDetailsSelect = (props) => {
    const { data } = useGetAllProdOrderTemplatesQuery();

    const handleChange = (event) => {
      handleTemplateChange?.(event.target.value);
    };

    return (
      <Box sx={{ maxWidth: 200 }} paddingY={2}>
        <FormControl fullWidth>
          <InputLabel id="select-template-label">Template</InputLabel>
          <Select
            variant="filled"
            labelId="select-template-label"
            id="select-template"
            value={template}
            label="Template"
            onChange={handleChange}
          >
            {/* <MenuItem value="NONE">NONE</MenuItem> */}
            {data?.map((t, index) => (
              <MenuItem value={t} key={index}>
                {t.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    );
  };

  return (
    <>
      <Box className={classes.orderItemDetailsBar}>
        <Grid container>
          <Grid item xs={6}>
            <Box className={classes.label}>
              Production Order Number | 生产订单号
            </Box>
            <Box className={classes.detail}>
              {activeItem?.production_order?.id}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.label}>Date | 日期</Box>
            <Box className={classes.detail}>
              {moment(productionOrder?.created_at).format("DD/MM/YYYY")}
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className={classes.label}>Style | 风格</Box>
            <Box className={classes.detail}>
              {productionOrder?.style || activeItem?.style || "N/A"}
            </Box>
          </Grid>
          {/* <Grid item xs={2}>
            <Box className={classes.label}>Pattern | 图案</Box>
            <Box className={classes.detail}>STTS303</Box>
          </Grid> */}
        </Grid>
      </Box>
      <Grid container sx={{ padding: "27px 16px 50px 16px" }}>
        <Grid item xs={6}>
          <Box
            sx={{
              fontSize: 20,
              fontWeight: 500,
              lineHeight: "30px",
              padding: "9px 0",
            }}
          >
            <Box>{`Production order ${activeItem?.production_order?.id}   -  ${activeItem?.price?.item?.name}`}</Box>
            {/* <Box>生产订单 #AJM0932 - 男士比赛衬衫</Box> */}
            <OrderItemDetailsSelect />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            sx={{
              backgroundColor: "#ebf2e9",
              padding: "12px 18px",
              borderRadius: "4px",
            }}
          >
            <Grid container>
              <Grid item xs={3}>
                <Box className={classes.label}>Currently in:</Box>
                <Box className={classes.detail}>目前在:</Box>
              </Grid>
              <Grid item xs={5}>
                <Box className={classes.label}>{currentStage}</Box>
                <Box className={classes.detail}>生产版本</Box>
              </Grid>
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  onClick={openProductOrderStatusModal}
                  disabled={productionStatus !== "Approved"}
                  sx={{
                    fontWeight: 500,
                    fontSize: 12,
                    color: "#fff",
                    background: "#12830b",
                  }}
                >
                  QC Complete
                  <br />
                  质检完成
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Box className={classes.imageContainer} style={{ display: "flex" }}>
            <img src={`${activeItem?.image_url}`} width={"50%"} alt="" />
            {activeItem?.image_2_url && (
              <img src={`${activeItem?.image_2_url}`} width={"50%"} alt="" />
            )}
            {activeItem?.image_3_url && (
              <img src={`${activeItem?.image_3_url}`} width={"50%"} alt="" />
            )}
          </Box>
        </Grid>
        {/* <Grid item xs={4}>
          {Object.keys(activeItem).length !== 0 &&
            activeItem?.sales_order_item_passed_stages.length !== 0 && (
              <Stack
                direction={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ marginTop: "12px" }}
              >
                <ProductionStatusStepper
                  activeStep={activeStep}
                  orientation={VERTICAL}
                  steps={activeItem?.sales_order_item_passed_stages}
                  alternativeLabel={false}
                />
              </Stack>
            )}
        </Grid> */}
        {/*<ProductionOrderUpdateStatusModal*/}
        {/*    open={open}*/}
        {/*    handleClose={handleClose}*/}
        {/*    id={id}*/}
        {/*    item={activeItem?.price?.item?.name}*/}
        {/*    handleStoreStages={handleStoreStages}*/}
        {/*    stages={stages}*/}
        {/*    sales_order_item_passed_stages={activeItem?.sales_order_item_passed_stages}*/}
        {/*    activeStep={activeStep}*/}
        {/*/>*/}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Update Status to QC Complete?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You are about to update the status of production order to QC
              Completed. Are you sure?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleUpdateStatus} autoFocus>
              Accept
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
};

export default OrderItemDetailsComponent;
