import axios from "axios";
import { getCurrentUser, getUserToken } from "../../helpers";
import { apiConstants } from "../../constants";

function authHeader() {
  const currentUser = getCurrentUser();
  const token = getUserToken();

  const headers = {
    "Content-Type": "application/json",
  };

  if (currentUser && token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  return headers;
}

export const getAxiosInstance = () => {
  return axios.create({
    baseURL: apiConstants.API_URL,
    headers: authHeader(),
  });
}