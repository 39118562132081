import { couriersConstants } from "../../constants";

export function couriers(state = {}, action) {
  switch (action.type) {
    // get all
    case couriersConstants.GET_ALL_REQUEST:
      return {
        loading: true,
      };
    case couriersConstants.GET_ALL_SUCCESS:
      return {
        items: action.items,
      };
    case couriersConstants.GET_ALL_FAILURE:
      return {
        error: action.error,
      };

    // add
    case couriersConstants.ADD_REQUEST:
      return {
        loading: true,
      };
    case couriersConstants.ADD_SUCCESS:
      return {
        parameter: action.parameter,
      };
    case couriersConstants.ADD_FAILURE:
      return {
        error: action.error,
      };

    // update
    case couriersConstants.UPDATE_REQUEST:
      return {
        loading: true,
      };
    case couriersConstants.UPDATE_SUCCESS:
      return {
        parameter: action.parameter,
      };
    case couriersConstants.UPDATE_FAILURE:
      return {
        error: action.error,
      };

    // delete
    case couriersConstants.DELETE_REQUEST:
      return {
        loading: true,
      };
    case couriersConstants.DELETE_SUCCESS:
      return {
        parameter: action.parameter,
      };
    case couriersConstants.DELETE_FAILURE:
      return {
        error: action.error,
      };

    // clear
    case couriersConstants.CLEAR:
      return {};

    default:
      return state;
  }
}
