import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { sportCategoryActions } from "../../../../../../actions";
import DataTable from "../../../../../common/ui/table/DataTable/DataTable.component";

const SportCategories = () => {
  const dispatch = useDispatch();
  const sportCategories = useSelector((state) => state.sportCategories);

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "name",
      headerName: "Category",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 200,
      flex: 1,
      hide: true,
    },

    {
      field: "created_at",
      headerName: "Created At",
      width: 150,
      hide: true,
    },
    {
      field: "updated_at",
      headerName: "Updated At",
      width: 150,
      hide: true,
    },
  ];

  const recordIdentifier = "name";

  useEffect(() => {
    dispatch(sportCategoryActions.getAllParameters());
  }, [dispatch]);

  return (
    <Box>
      {sportCategories && (
        <DataTable
          loading={sportCategories?.loading}
          rows={sportCategories?.items?.data}
          columns={columns}
          recordIdentifier={recordIdentifier}
          actionIdentifier="sportCategoryActions"
        />
      )}
    </Box>
  );
};

export default SportCategories;
