import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { patternActions } from "../../../../../../actions";
import {
  fileUploaderActions,
  measurementActions,
  sizeActions,
} from "../../../../../../actions";
import {
  Divider,
  Button,
  TextField,
  Grid,
  Stack,
  FormGroup,
  FormControlLabel,
  Switch,
  Card,
  Typography,
  CardContent,
  CardActions,
  Box,
  SvgIcon,
  InputLabel,
  FormHelperText,
  CircularProgress,
  Autocomplete,
  IconButton,
  Tab,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { FieldArray, Form, Formik, getIn } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import styles from "./../ParameterForm.module.scss";
import patternStyles from "./Patterns.module.scss";

const Patterns = (props) => {
  const {
    title,
    titleSingle,
    mode,
    paramdata,
    handleSuccessDialog,
    handleErrorAlert,
  } = props;

  const dispatch = useDispatch();
  const paramState = useSelector((state) => state.patterns);
  const alertState = useSelector((state) => state.alert);
  const fileUploadState = useSelector((state) => state.fileUploads);
  const measurementsState = useSelector((state) => state.measurements);
  const sizes = useSelector((state) => state.sizes);

  const kidsSizes = sizes?.items?.data?.filter((s) => s.flag_kid_size === 1);
  const adultSizes = sizes?.items?.data?.filter((s) => s.flag_kid_size === 0);
  const allSizes = [];

  const kidsSizesInitial = [];
  const adultSizesInitial = [];

  kidsSizes?.map((size, i) => {
    kidsSizesInitial.push({
      size_id: size?.id,
      value: "",
    });
    return null;
  });
  adultSizes?.map((size, i) => {
    adultSizesInitial.push({
      size_id: size?.id,
      value: "",
    });
    return null;
  });

  const formikRef = React.createRef();

  const debug = false;
  // const [submitting, setSubmitting] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [file, setFile] = useState();
  const [filename, setFilename] = useState();
  const [csvFile, setCSVFile] = useState();
  const [csvFilename, setCSVFilename] = useState();
  const [tabValue, setTabValue] = React.useState("1");

  const successMsg = "";

  const validationSchema = Yup.object({
    name: Yup.string().required("Pattern Name is required"),
    code: Yup.string().required("Pattern Code is required"),
    image: Yup.mixed().required("Pattern Image is required"),
    measurementCSV: Yup.mixed().when("measurementMethod", {
      is: 1,
      then: Yup.mixed().required("Measurement file is required"),
    }),
    status: Yup.boolean().nullable(),
    measurementMethod: Yup.number().required(),
    measurements: Yup.array().of(
      Yup.object().shape({
        measurement_id: Yup.string().when("measurementMethod", {
          is: 2,
          then: Yup.string().required("Measurement is required"),
        }),
        sizes: Yup.array().of(
          Yup.object().shape({
            value: Yup.number(),
            size_id: Yup.number(),
          })
        ),
      })
    ),
  });

  const initialValues = {
    name: "",
    code: "",
    image: "",
    measurementCSV: "",
    status: true,
    measurementMethod: 1,
    measurements: [
      {
        measurement_id: "",
        sizes: [...kidsSizesInitial, ...adultSizesInitial],
      },
    ],
  };

  useEffect(() => {
    dispatch(fileUploaderActions.clear());
    dispatch(measurementActions.getAllParameters());
    dispatch(sizeActions.getAllParameters());
  }, [dispatch]);

  useEffect(() => {
    const prefillData = () => {
      if (mode === "edit" && paramdata) {
        const fields = ["name", "code", "image", "status", "measurements"];

        fields.forEach((field) =>
          formikRef.current.setFieldValue(field, paramdata[field], false)
        );
      }
    };

    prefillData();
  }, []);

  useEffect(() => {
    const handleSuccess = () => {
      if (paramState?.parameter?.data && alertState.type === "success") {
        handleSuccessDialog(successMsg);

        setFile(null);
        setFilename(null);
      }
    };

    const handleError = () => {
      if (paramState?.error && alertState.type === "error") {
        handleErrorAlert(successMsg);

        setFile(null);
        setFilename(null);
      }
    };

    handleSuccess();
    handleError();
  }, [paramState, handleSuccessDialog, alertState, handleErrorAlert]);

  useEffect(() => {
    const showImgPreview = () => {
      if (fileUploadState?.parameter?.data) {
        setFilename(fileUploadState?.parameter?.data?.path);
        formikRef.current.setFieldValue(
          "image",
          fileUploadState?.parameter?.data?.path
        );
      }
    };

    showImgPreview();
  }, [fileUploadState?.parameter?.data, formikRef]);

  const handleSubmit = (values) => {
    if (values && mode === "add") {
      dispatch(
        patternActions.addParameter(
          values.name,
          values.code,
          // values.image,
          filename,
          values.status,
          values.measurements,
          values.measurementMethod,
          csvFile
        )
      );
    }
    if (values && mode === "edit") {
      console.log(
        "🚀 ~ file: Patterns.component.js ~ line 146 ~ handleSubmit ~ values",
        values
      );

      // dispatch(
      //   patternActions.updateParameter(
      //     paramdata.id,
      //     values.name,
      //     values.code,
      //     values.image,
      //     // filename,
      //     values.status,
      //     values.measurements
      //   )
      // );
    }
  };

  const handleToggle = (e) => {
    setToggle(e.target.checked);
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setFile(URL.createObjectURL(file));

    dispatch(fileUploaderActions.addParameter(file));
    // await showImgPreview(file);
  };

  const handleCSVUpload = (e) => {
    const file = e.target.files[0];
    setCSVFile(file);
    setCSVFilename(file.name);
    formikRef.current.setFieldValue("measurementCSV", file);
    // dispatch(fileUploaderActions.addParameter(file));
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    formikRef.current.setFieldValue("measurementMethod", newValue);
  };

  const handleDownload = () => {
    const link = document.createElement("a");

    link.download = "specification-template.csv";
    link.href =
      "https://portal-staging.s-trend.com.au/downloads/specification-template.csv";
    link.click();
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        innerRef={formikRef}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values);
          resetForm();
        }}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          isValid,
          setFieldValue,
        }) => (
          <Form noValidate autoComplete="off">
            <input
              type="hidden"
              id="measurementMethod"
              name="measurementMethod"
              value={values.measurementMethod}
            />
            <Grid container spacing={{ xs: 2, sm: 2 }} sx={{ pt: 0, pb: 0 }}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="name"
                  name="name"
                  label="Pattern Name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                  variant="standard"
                  className={styles.textField}
                  size="small"
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  id="code"
                  name="code"
                  label="Pattern Code"
                  value={values.code}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.code && Boolean(errors.code)}
                  helperText={touched.code && errors.code}
                  variant="standard"
                  className={styles.textField}
                  size="small"
                />
              </Grid>

              <Grid item xs={12} md={2}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={toggle}
                        onChange={(e) => {
                          handleToggle(e);
                          setFieldValue("status", e.target.checked);
                        }}
                        id="status"
                        name="status"
                      />
                    }
                    label="Active"
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12} lg={6} sx={{ mt: { lg: 2 } }}>
                <Grid
                  container
                  spacing={{ xs: 2, sm: 2 }}
                  sx={{ pt: 0, pb: 0, mt: 2 }}
                >
                  <Grid item xs={12} sm={6}>
                    <label htmlFor="image">
                      <InputLabel>Sizing/Measurment Guide</InputLabel>
                      <Card
                        elevation={0}
                        sx={{
                          border: "1px dashed #bbb",
                          cursor: "pointer",
                          mt: 2,
                          minHeight: "15rem",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <CardContent>
                          <Box>
                            <SvgIcon
                              {...props}
                              sx={{
                                width: "3rem",
                                height: "3rem",
                                margin: "1rem auto 1.5rem auto",
                                opacity: "0.8",
                                display: "block",
                              }}
                            >
                              <svg
                                xmlSpace="preserve"
                                viewBox="0 0 409.794 409.794"
                              >
                                <path d="M387.367 176.731c3.861-2.284 5.154-7.267 2.869-11.128L329.955 63.46a8.133 8.133 0 0 0-6.999-3.999H96.876a8.117 8.117 0 0 0-7.096 4.17L32.848 165.774a8.111 8.111 0 0 0 3.138 11.055 8.08 8.08 0 0 0 3.95 1.032 8.106 8.106 0 0 0 7.104-4.17l54.616-97.982h216.651l57.924 98.144a8.138 8.138 0 0 0 11.136 2.878zM401.665 196.768H301.196c-4.495 0-8.129 3.633-8.129 8.129s3.633 8.129 8.129 8.129h92.341v121.059H16.257v-114.37h83.139c4.495 0 8.129-3.633 8.129-8.129s-3.633-8.129-8.129-8.129H8.129A8.121 8.121 0 0 0 0 211.586v130.618a8.121 8.121 0 0 0 8.129 8.129h393.537a8.121 8.121 0 0 0 8.129-8.129V204.897a8.129 8.129 0 0 0-8.13-8.129z" />
                                <path d="m258.277 202.41-12.42 38.741h-76.254l-21.151-40.049c-2.089-3.967-7.015-5.511-10.982-3.39-3.967 2.097-5.495 7.007-3.39 10.982l23.443 44.382a8.12 8.12 0 0 0 7.186 4.333h87.081a8.12 8.12 0 0 0 7.738-5.641l14.233-44.382c1.374-4.276-.984-8.852-5.251-10.226-4.299-1.367-8.867.99-10.233 5.25zM333.011 168.652a8.107 8.107 0 0 0 10.949 3.503 8.128 8.128 0 0 0 3.503-10.949l-11.51-22.354a8.126 8.126 0 0 0-7.226-4.406H98.421a8.14 8.14 0 0 0-7.486 4.95L81.44 161.75c-1.756 4.129.171 8.901 4.308 10.665a8.102 8.102 0 0 0 3.178.642 8.141 8.141 0 0 0 7.486-4.95l7.389-17.403H323.76l9.251 17.948zM107.216 126.79a8.141 8.141 0 0 0 7.486-4.95l6.706-15.834h178l8.568 16.428a8.125 8.125 0 0 0 10.974 3.447 8.144 8.144 0 0 0 3.447-10.974l-10.852-20.801a8.138 8.138 0 0 0-7.21-4.365H116.027a8.14 8.14 0 0 0-7.486 4.95l-8.811 20.793c-1.756 4.137.187 8.909 4.308 10.665a8.313 8.313 0 0 0 3.178.641z" />
                              </svg>
                            </SvgIcon>
                          </Box>
                          <Typography
                            gutterBottom
                            variant="body"
                            component="div"
                            sx={{
                              textAlign: "center",
                              fontSize: "0.875rem",
                              lineHeight: "normal",
                            }}
                          >
                            Click or drag files to upload pattern designs
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ textAlign: "center", fontSize: "0.65rem" }}
                          >
                            (Accepted file types: JPEG, PNG, GIF, BMP)
                          </Typography>
                        </CardContent>
                        <CardActions sx={{ pointerEvents: "none", mb: 1 }}>
                          <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            fullWidth
                          >
                            Upload
                          </Button>
                        </CardActions>
                      </Card>
                    </label>
                    <input
                      type="file"
                      id="image"
                      name="image"
                      accept="image/*"
                      onChange={(e) => {
                        // setFieldValue("image", e.currentTarget.files[0]);
                        // showImgPreview(e);
                        handleImageUpload(e);
                      }}
                      onBlur={handleBlur}
                      // error={touched.image && Boolean(errors.image)}
                      // helperText={touched.image && errors.image}
                      style={{
                        position: "absolute",
                        pointerEvents: "none",
                        visibility: "hidden",
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} sx={{ mt: { sm: 5 } }}>
                    <div className="upload-preview" style={{ height: "100%" }}>
                      <Card
                        elevation={0}
                        sx={{
                          border: "1px dashed #bbb",
                          height: {
                            sm: "100%",
                          },
                        }}
                      >
                        <Box sx={{ p: 1 }}>
                          {fileUploadState?.loading ? (
                            <Box
                              sx={{
                                display: "flex",
                                p: 5,
                                justifyContent: "center",
                              }}
                            >
                              <CircularProgress />
                            </Box>
                          ) : fileUploadState?.parameter?.data && file ? (
                            <img
                              alt="uploaded img"
                              src={file}
                              style={{
                                width: "100%",
                                height: "auto",
                                maxHeight: "11.5rem",
                                objectFit: "cover",
                              }}
                            />
                          ) : null}
                        </Box>
                        <CardContent
                          sx={{ px: 0, py: 0, pb: "1rem !important" }}
                        >
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{
                              textAlign: "center",
                              fontSize: "0.75rem",
                              m: 0,
                            }}
                          >
                            {fileUploadState?.parameter?.data?.path
                              ? fileUploadState?.parameter?.data?.path
                              : "Please upload a pattern image "}
                          </Typography>
                        </CardContent>
                      </Card>
                    </div>
                  </Grid>
                </Grid>

                {touched.image && Boolean(errors.image) && (
                  <FormHelperText sx={{ color: "red" }}>
                    {touched.image && errors.image}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={12} sx={{ mt: { lg: 2 } }}>
                <Grid
                  container
                  spacing={{ xs: 2, sm: 2 }}
                  sx={{ pt: 0, pb: 0, mt: 2 }}
                >
                  <Grid item xs={12}>
                    <label htmlFor="image">
                      <InputLabel>Measurements</InputLabel>
                    </label>

                    <Box sx={{ width: "100%" }}>
                      <TabContext value={tabValue}>
                        <Box>
                          <TabList
                            onChange={handleTabChange}
                            aria-label=" "
                            classes={{ root: patternStyles.tabs }}
                          >
                            <Tab
                              label="Upload Excel File"
                              value="1"
                              classes={{
                                root: patternStyles.tab,
                                selected: patternStyles.tabSelected,
                              }}
                            />
                            <Tab
                              label="Manual Entry"
                              value="2"
                              classes={{
                                root: patternStyles.tab,
                                selected: patternStyles.tabSelected,
                              }}
                            />
                          </TabList>
                        </Box>

                        <TabPanel value="1" className={patternStyles.tabPanel}>
                          <Typography variant="h5">
                            Excel file upload
                          </Typography>
                          <Alert
                            severity="info"
                            sx={{ mt: "1.2rem" }}
                            action={
                              <Button
                                color="inherit"
                                size="small"
                                variant="outlined"
                                startIcon={<FileDownloadOutlinedIcon />}
                                onClick={handleDownload}
                              >
                                Download Template
                              </Button>
                            }
                          >
                            Please use the provided template to fill the
                            measurement details
                          </Alert>
                          <Box>
                            <Grid container spacing={2}>
                              <Grid item xs={12} lg={6}>
                                <label htmlFor="measurementCSV">
                                  {/* <InputLabel>Measurement Guide</InputLabel> */}
                                  <Card
                                    elevation={0}
                                    sx={{
                                      border: "1px dashed #bbb",
                                      cursor: "pointer",
                                      mt: 2,
                                      minHeight: "15rem",
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <CardContent>
                                      <Box>
                                        <SvgIcon
                                          {...props}
                                          sx={{
                                            width: "3rem",
                                            height: "3rem",
                                            margin: "1rem auto 1.5rem auto",
                                            opacity: "0.8",
                                            display: "block",
                                          }}
                                        >
                                          <svg
                                            xmlSpace="preserve"
                                            viewBox="0 0 409.794 409.794"
                                          >
                                            <path d="M387.367 176.731c3.861-2.284 5.154-7.267 2.869-11.128L329.955 63.46a8.133 8.133 0 0 0-6.999-3.999H96.876a8.117 8.117 0 0 0-7.096 4.17L32.848 165.774a8.111 8.111 0 0 0 3.138 11.055 8.08 8.08 0 0 0 3.95 1.032 8.106 8.106 0 0 0 7.104-4.17l54.616-97.982h216.651l57.924 98.144a8.138 8.138 0 0 0 11.136 2.878zM401.665 196.768H301.196c-4.495 0-8.129 3.633-8.129 8.129s3.633 8.129 8.129 8.129h92.341v121.059H16.257v-114.37h83.139c4.495 0 8.129-3.633 8.129-8.129s-3.633-8.129-8.129-8.129H8.129A8.121 8.121 0 0 0 0 211.586v130.618a8.121 8.121 0 0 0 8.129 8.129h393.537a8.121 8.121 0 0 0 8.129-8.129V204.897a8.129 8.129 0 0 0-8.13-8.129z" />
                                            <path d="m258.277 202.41-12.42 38.741h-76.254l-21.151-40.049c-2.089-3.967-7.015-5.511-10.982-3.39-3.967 2.097-5.495 7.007-3.39 10.982l23.443 44.382a8.12 8.12 0 0 0 7.186 4.333h87.081a8.12 8.12 0 0 0 7.738-5.641l14.233-44.382c1.374-4.276-.984-8.852-5.251-10.226-4.299-1.367-8.867.99-10.233 5.25zM333.011 168.652a8.107 8.107 0 0 0 10.949 3.503 8.128 8.128 0 0 0 3.503-10.949l-11.51-22.354a8.126 8.126 0 0 0-7.226-4.406H98.421a8.14 8.14 0 0 0-7.486 4.95L81.44 161.75c-1.756 4.129.171 8.901 4.308 10.665a8.102 8.102 0 0 0 3.178.642 8.141 8.141 0 0 0 7.486-4.95l7.389-17.403H323.76l9.251 17.948zM107.216 126.79a8.141 8.141 0 0 0 7.486-4.95l6.706-15.834h178l8.568 16.428a8.125 8.125 0 0 0 10.974 3.447 8.144 8.144 0 0 0 3.447-10.974l-10.852-20.801a8.138 8.138 0 0 0-7.21-4.365H116.027a8.14 8.14 0 0 0-7.486 4.95l-8.811 20.793c-1.756 4.137.187 8.909 4.308 10.665a8.313 8.313 0 0 0 3.178.641z" />
                                          </svg>
                                        </SvgIcon>
                                      </Box>
                                      <Typography
                                        gutterBottom
                                        variant="body"
                                        component="div"
                                        sx={{
                                          textAlign: "center",
                                          fontSize: "0.875rem",
                                          lineHeight: "normal",
                                        }}
                                      >
                                        Click or drag files to upload
                                        measurement guide
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        sx={{
                                          textAlign: "center",
                                          fontSize: "0.65rem",
                                        }}
                                      >
                                        (Accepted file types: CSV)
                                      </Typography>
                                      {csvFilename && (
                                        <Typography
                                          variant="body2"
                                          color="text.secondary"
                                          sx={{
                                            textAlign: "center",
                                            fontSize: "0.65rem",
                                          }}
                                        >
                                          {csvFilename}
                                        </Typography>
                                      )}
                                    </CardContent>
                                    <CardActions
                                      sx={{ pointerEvents: "none", mb: 1 }}
                                    >
                                      <Button
                                        variant="outlined"
                                        size="small"
                                        color="primary"
                                        fullWidth
                                      >
                                        Upload
                                        <span
                                          style={{
                                            textTransform: "none",
                                            marginLeft: "0.3rem",
                                          }}
                                        >
                                          {csvFilename}
                                        </span>
                                      </Button>
                                    </CardActions>
                                  </Card>
                                </label>
                                <input
                                  type="file"
                                  id="measurementCSV"
                                  name="measurementCSV"
                                  accept=".csv"
                                  onChange={(e) => {
                                    // setFieldValue("image", e.currentTarget.files[0]);
                                    // showImgPreview(e);
                                    handleCSVUpload(e);
                                  }}
                                  onBlur={handleBlur}
                                  // error={touched.image && Boolean(errors.image)}
                                  // helperText={touched.image && errors.image}
                                  style={{
                                    position: "absolute",
                                    pointerEvents: "none",
                                    visibility: "hidden",
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Box>

                          {touched.measurementCSV &&
                            Boolean(errors.measurementCSV) && (
                              <FormHelperText sx={{ color: "red" }}>
                                {touched.measurementCSV &&
                                  errors.measurementCSV}
                              </FormHelperText>
                            )}

                          {/* <Grid item xs={12} sm={6} sx={{ mt: { sm: 5 } }}>
                            <div
                              className="upload-preview"
                              style={{ height: "100%" }}
                            >
                              <Card
                                elevation={0}
                                sx={{
                                  border: "1px dashed #bbb",
                                  height: {
                                    sm: "100%",
                                  },
                                }}
                              >
                                <Box sx={{ p: 1 }}>
                                  {fileUploadState?.loading ? (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        p: 5,
                                        justifyContent: "center",
                                      }}
                                    >
                                      <CircularProgress />
                                    </Box>
                                  ) : fileUploadState?.parameter?.data &&
                                    file ? (
                                    <img
                                      alt="uploaded img"
                                      src={file}
                                      style={{
                                        width: "100%",
                                        height: "auto",
                                        maxHeight: "11.5rem",
                                        objectFit: "cover",
                                      }}
                                    />
                                  ) : null}
                                </Box>
                                <CardContent
                                  sx={{ px: 0, py: 0, pb: "1rem !important" }}
                                >
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    sx={{
                                      textAlign: "center",
                                      fontSize: "0.75rem",
                                      m: 0,
                                    }}
                                  >
                                    {fileUploadState?.parameter?.data?.path
                                      ? fileUploadState?.parameter?.data?.path
                                      : "Please upload a pattern image "}
                                  </Typography>
                                </CardContent>
                              </Card>
                            </div>
                          </Grid> */}
                        </TabPanel>

                        <TabPanel value="2" className={patternStyles.tabPanel}>
                          <Typography variant="h5">Manual Entry</Typography>
                          <Box className={patternStyles.tableWrap}>
                            <TableContainer component={Paper}>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Measurement</TableCell>
                                    {kidsSizes &&
                                      kidsSizes.map((size) => (
                                        <TableCell key={size?.id}>
                                          {size?.name}
                                        </TableCell>
                                      ))}
                                    {adultSizes &&
                                      adultSizes.map((size) => (
                                        <TableCell key={size?.id}>
                                          {size?.name}
                                        </TableCell>
                                      ))}
                                    <TableCell>&nbsp;</TableCell>
                                  </TableRow>
                                </TableHead>

                                <TableBody>
                                  <FieldArray name="measurements">
                                    {({ push, remove }) => (
                                      <>
                                        {values.measurements.map((p, index) => {
                                          const measurementId = `measurements[${index}].measurement_id`;
                                          const touchedmeasurementId = getIn(
                                            touched,
                                            measurementId
                                          );
                                          const errormeasurementId = getIn(
                                            errors,
                                            measurementId
                                          );

                                          return (
                                            <TableRow key={p.id} translate="no">
                                              <TableCell>
                                                <Autocomplete
                                                  fullWidth
                                                  id={measurementId}
                                                  name={measurementId}
                                                  size="small"
                                                  disableClearable={true}
                                                  options={
                                                    measurementsState?.items
                                                      ?.data
                                                  }
                                                  onChange={(event, value) => {
                                                    setFieldValue(
                                                      measurementId,
                                                      value.id
                                                    );
                                                  }}
                                                  getOptionLabel={(option) =>
                                                    option?.name
                                                      ? option?.name
                                                      : ""
                                                  }
                                                  classes={{
                                                    root: styles.autocompleteRoot,
                                                    inputRoot:
                                                      styles.autocompleteInputRoot,
                                                    option: [
                                                      patternStyles.autoCompleteOption,
                                                    ],
                                                    groupLabel:
                                                      styles.autoCompleteGroupLabel,
                                                  }}
                                                  renderInput={(params) => (
                                                    <TextField
                                                      {...params}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      margin="normal"
                                                      label="Sizing Property"
                                                      fullWidth
                                                      // required
                                                      variant="outlined"
                                                      className={
                                                        styles.textField
                                                      }
                                                      size="small"
                                                      value={p.measurementId}
                                                      helperText={
                                                        touchedmeasurementId &&
                                                        errormeasurementId
                                                          ? errormeasurementId
                                                          : ""
                                                      }
                                                      error={Boolean(
                                                        touchedmeasurementId &&
                                                          errormeasurementId
                                                      )}
                                                    />
                                                  )}
                                                />
                                              </TableCell>

                                              {kidsSizes &&
                                                adultSizes &&
                                                [
                                                  ...kidsSizesInitial,
                                                  ...adultSizesInitial,
                                                ].map((size, i) => (
                                                  <TableCell
                                                    key={`measurement${index}-size${i}`}
                                                  >
                                                    <TextField
                                                      fullWidth
                                                      name={`measurements[${index}].sizes[${i}].value`}
                                                      label=""
                                                      value={p.sizes[i]?.value}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      // helperText={
                                                      //   touchedFourK && errorFourK
                                                      //     ? errorFourK
                                                      //     : ""
                                                      // }
                                                      // error={Boolean(
                                                      //   touchedFourK && errorFourK
                                                      // )}
                                                      variant="outlined"
                                                      className={
                                                        styles.textField
                                                      }
                                                      size="small"
                                                    />
                                                  </TableCell>
                                                ))}

                                              <TableCell>
                                                {index > 0 && (
                                                  <IconButton
                                                    aria-label="clear"
                                                    onClick={() =>
                                                      remove(index)
                                                    }
                                                    size="small"
                                                  >
                                                    <RemoveCircleOutlineIcon />
                                                  </IconButton>
                                                )}
                                              </TableCell>
                                            </TableRow>
                                          );
                                        })}

                                        <Grid item xs={12}>
                                          <Box
                                            elevation={0}
                                            sx={{ px: 2, py: 1 }}
                                          >
                                            <Button
                                              variant="outlined"
                                              startIcon={
                                                <AddCircleOutlineIcon />
                                              }
                                              size="small"
                                              onClick={() =>
                                                push({
                                                  measurement_id: "",
                                                  sizes: [
                                                    ...kidsSizesInitial,
                                                    ...adultSizesInitial,
                                                  ],
                                                })
                                              }
                                              className={styles.addButton}
                                            >
                                              Add Row
                                            </Button>
                                          </Box>
                                        </Grid>
                                      </>
                                    )}
                                  </FieldArray>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Box>
                        </TabPanel>
                      </TabContext>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Stack
                  direction="column-reverse"
                  justifyContent="space-between"
                  align-items="center"
                  sx={{
                    flexDirection: {
                      sm: "row",
                    },
                    mt: 2,
                  }}
                >
                  <Link to="../" style={{ display: "block" }}>
                    <Button
                      size="medium"
                      startIcon={<ArrowBackIcon size="small" />}
                      sx={{ textTransform: "none" }}
                    >
                      Back to {title}
                    </Button>
                  </Link>

                  <span style={{ flex: 1 }}>&nbsp;</span>

                  <LoadingButton
                    color="secondary"
                    variant="contained"
                    fullWidth
                    type="submit"
                    size="large"
                    loading={paramState?.loading}
                    // loadingPosition="end"
                    sx={{
                      width: {
                        xs: "100%",
                        sm: "auto",
                      },
                    }}
                  >
                    {`Save ${titleSingle}`}
                  </LoadingButton>
                </Stack>
              </Grid>
            </Grid>

            {debug && (
              <>
                <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                <pre style={{ textAlign: "left" }}>
                  <strong>Values</strong>
                  <br />
                  {JSON.stringify(values, null, 2)}
                </pre>
                <pre style={{ textAlign: "left" }}>
                  <strong>Errors</strong>
                  <br />
                  {JSON.stringify(errors, null, 2)}
                </pre>
              </>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Patterns;
