import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import {
  userActions,
  countryActions,
  sportCategoryActions,
  organizationActions,
} from "../../../../actions";
import DataTable from "../../../common/ui/table/DataTable/DataTable.component";
import { checkPermission } from "../../../../helpers/helpers";
import { authHeader } from "../../../../helpers";
import { apiConstants } from "../../../../constants";

const UserTable = ({ isAdmin, roleId }) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  // const paymentTerms = useSelector((state) => state.paymentTerms);
  const countries = useSelector((state) => state.countries);
  const organizations = useSelector((state) => state.organizations);
  const sportCategories = useSelector((state) => state.sportCategories);
  const [dataFotmatted, setDataFormatted] = useState();
  const isEnabledEdit = checkPermission("Edit Users");
  const [paginationData, setPaginationData] = React.useState({});

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "first_name",
      headerName: "First Name",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "code",
      headerName: "Code",
      width: 150,
      hide: true,
      showDetail: true,
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "contact_no",
      headerName: "Contact Number",
      width: 150,
    },
    {
      field: "address_line_1",
      headerName: "Address Line 1",
      width: 150,
      hide: true,
      showDetail: true,
    },
    {
      field: "address_line_2",
      headerName: "Address Line 2",
      width: 150,
      hide: true,
      showDetail: true,
    },
    {
      field: "postal_code",
      headerName: "Postal Code",
      width: 150,
      hide: true,
      showDetail: true,
    },
    {
      field: "country_id",
      headerName: "Country",
      width: 150,
      showDetail: true,
    },
    {
      field: "manager_id",
      headerName: "Manager",
      width: 150,
      hide: true,
      showDetail: true,
    },
    {
      field: "organization_id",
      headerName: "Organization",
      width: 150,
      hide: true,
      showDetail: true,
    },
    {
      field: "sport_id",
      headerName: "Sport",
      width: 150,
      hide: true,
      showDetail: true,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      hide: true,
      showDetail: true,
    },
  ];

  const recordIdentifier = "first_name";

  useEffect(() => {
    dispatch(userActions.getAll(1));
    dispatch(sportCategoryActions.getAllParameters());
    dispatch(organizationActions.getAllParameters());
    dispatch(countryActions.getAllParameters());
  }, [dispatch]);

  useEffect(() => {
    if (roleId) {
      const requestOptions = {
        method: "GET",
        headers: authHeader(),
      };
      fetch(
        `${apiConstants.API_URL}/auth/users/role/${roleId}`,
        requestOptions
      ).then((response) => {
        response.text().then((text) => {
          const data = text && JSON.parse(text);
          const formatData = () => {
            const formattedData = data?.data
              .filter((type) => type.is_admin === isAdmin)
              .map((item) => {
                const {
                  country_id,
                  sport_id,
                  organization_id,
                  manager_id,
                  roles,
                  is_admin,
                  ...rest
                } = item;

                if (country_id !== "") {
                  const country = countries?.items?.data.filter((term) => {
                    return term.id === country_id;
                  });
                  rest.country_id = country[0]?.name;
                }

                if (sport_id !== null) {
                  const sport = sportCategories?.items?.data.filter((term) => {
                    return term.id === sport_id;
                  });
                  rest.sport_id = sport[0]?.name;
                } else {
                  delete item["sport_id"];
                }

                if (is_admin === 0 && organization_id !== undefined) {
                  const organization = organizations?.items?.data.filter(
                    (term) => {
                      return term.id === organization_id;
                    }
                  );
                  rest.organization_id = organization[0]?.name;
                } else {
                  delete item["organization_id"];
                }

                if (is_admin === 0) {
                  rest.is_admin = false;
                } else {
                  rest.is_admin = true;
                }

                if (manager_id === null) {
                  delete item["manager_id"];
                }

                if (roles) {
                  delete item["roles"];
                }

                return rest;
              });
            setDataFormatted(formattedData);
            setPaginationData(data?.meta);
          };

          formatData();
        });
      });
    }
  }, [roleId]);

  useEffect(() => {
    const formatData = () => {
      if (
        users?.items?.data &&
        countries?.items?.data &&
        organizations?.items?.data &&
        sportCategories?.items?.data
      ) {
        const formattedData = users?.items?.data
          .filter((type) => type.is_admin === isAdmin)
          .map((item) => {
            const {
              country_id,
              sport_id,
              organization_id,
              manager_id,
              roles,
              is_admin,
              ...rest
            } = item;

            if (country_id !== "") {
              const country = countries?.items?.data.filter((term) => {
                return term.id === country_id;
              });
              rest.country_id = country[0]?.name;
            }

            if (sport_id !== null) {
              const sport = sportCategories?.items?.data.filter((term) => {
                return term.id === sport_id;
              });
              rest.sport_id = sport[0]?.name;
            } else {
              delete item["sport_id"];
            }

            if (is_admin === 0 && organization_id !== undefined) {
              const organization = organizations?.items?.data.filter((term) => {
                return term.id === organization_id;
              });
              rest.organization_id = organization[0]?.name;
            } else {
              delete item["organization_id"];
            }

            if (is_admin === 0) {
              rest.is_admin = false;
            } else {
              rest.is_admin = true;
            }

            if (manager_id === null) {
              delete item["manager_id"];
            }

            if (roles) {
              delete item["roles"];
            }

            return rest;
          });
        setDataFormatted(formattedData);
        setPaginationData(users?.items?.meta);
      }
    };

    formatData();
  }, [
    users?.items,
    countries?.items?.data,
    organizations?.items?.data,
    sportCategories?.items?.data,
    isAdmin,
  ]);

  const handlePageChange = (newPage) => {
    dispatch(userActions.getAll(newPage + 1));
  };

  return (
    <Box>
      {users && (
        <DataTable
          loading={
            users?.loading ||
            countries?.loading ||
            sportCategories?.loading ||
            organizations?.loading
          }
          rows={dataFotmatted}
          columns={columns}
          recordIdentifier={recordIdentifier}
          actionIdentifier="userActions"
          isEnabledEdit={isEnabledEdit}
          isEnabledDelete={false}
          paginationData={paginationData}
          handlePageChange={handlePageChange}
        />
      )}
    </Box>
  );
};

export default UserTable;
