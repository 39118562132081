import { couriersConstants } from "../../constants";
import { courierService } from "../../services";
import { alertActions } from "../alert.actions";

export const couriersActions = {
  getSingleParameter,
  getAllParameters,
  addParameter,
  updateParameter,
  deleteParameter,
  clear,
};

// get single parameter
function getSingleParameter() {}

// get all parameter
function getAllParameters() {
  return (dispatch) => {
    dispatch(request());

    courierService.getAllParameters().then(
      (items) => dispatch(success(items)),
      (error) => dispatch(failure(error))
    );
  };

  function request(params) {
    return { type: couriersConstants.GET_ALL_REQUEST, params };
  }
  function success(items) {
    return { type: couriersConstants.GET_ALL_SUCCESS, items };
  }
  function failure(error) {
    return { type: couriersConstants.GET_ALL_FAILURE, error };
  }
}

// add parameter
function addParameter(name, tracking_url) {
  return (dispatch) => {
    dispatch(request(name, tracking_url));

    courierService.addParameter(name, tracking_url).then(
      (parameter) => {
        dispatch(success(parameter));
        dispatch(alertActions.success(parameter));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(parameter) {
    return { type: couriersConstants.ADD_REQUEST, parameter };
  }
  function success(parameter) {
    return { type: couriersConstants.ADD_SUCCESS, parameter };
  }
  function failure(error) {
    return { type: couriersConstants.ADD_FAILURE, error };
  }
}

// update parameter
function updateParameter(id, name, tracking_url) {
  return (dispatch) => {
    dispatch(request(id, name, tracking_url));

    courierService.updateParameter(id, name, tracking_url).then(
      (parameter) => {
        dispatch(success(parameter));
        dispatch(alertActions.success(parameter));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(parameter) {
    return { type: couriersConstants.UPDATE_REQUEST, parameter };
  }
  function success(parameter) {
    return { type: couriersConstants.UPDATE_SUCCESS, parameter };
  }
  function failure(error) {
    return { type: couriersConstants.UPDATE_FAILURE, error };
  }
}

// delete parameter
function deleteParameter(id) {
  return (dispatch) => {
    dispatch(request(id));

    courierService.deleteParameter(id).then(
      (parameter) => {
        dispatch(success(parameter));
        dispatch(alertActions.success(parameter));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(parameter) {
    return { type: couriersConstants.DELETE_REQUEST, parameter };
  }
  function success(parameter) {
    return { type: couriersConstants.DELETE_SUCCESS, parameter };
  }
  function failure(error) {
    return { type: couriersConstants.DELETE_FAILURE, error };
  }
}

// clear parameter
function clear() {
  return { type: couriersConstants.CLEAR };
}
