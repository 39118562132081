import React from "react";
import Notification from "../../../Common/Notification";
import OrderTable from "../../../Common/Tables/OrderTable";
import CardContent from "@mui/material/CardContent";
import { Button, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddItemSalesOrderModal from "../../../Common/Modals/AddItemSalesOrderModal";
import { useSelector } from "react-redux";

const SalesOrderDetails = ({
  items,
  updateItems,
  colors,
  view,
  sports,
  addNewItems,
}) => {
  const enableItems = items && items?.length > 0;

  const [addModal, setAddModal] = React.useState(false);
  const closeAddModal = () => setAddModal(false);
  const { customer } = useSelector((state) => state.customer);

  const handleAddItem = () => {
    setAddModal(true);
  };

  return (
    <CardContent
      sx={{ textAlign: "left", minWidth: "500px", minHeight: "576px" }}
    >
      <Stack spacing={3}>
        <Stack flexDirection={"row"} justifyContent={"space-between"}>
          <Typography fontSize={"24px"}>Order Detailsa</Typography>
          {!view && (
            <Button
              variant="outlined"
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => handleAddItem()}
            >
              New Item
            </Button>
          )}
        </Stack>

        <Notification
          body="Please read and confirm details of the each item/style before continue to the next step. Use the EDIT option to update if you want to change any detail.Please note: Not all details are editable"
          extra="Please Note"
        />
        {enableItems && (
          <Stack spacing={2}>
            {items?.map((item, index) => (
              <OrderTable
                item={item}
                updateItems={updateItems}
                index={index}
                colors={colors}
                view={view}
              />
            ))}
          </Stack>
        )}
      </Stack>
      <AddItemSalesOrderModal
        handleClose={closeAddModal}
        open={addModal}
        sports={sports}
        items={items}
        addNewItems={addNewItems}
        countryId={customer?.country_id}
        gstEnabled={customer?.gst_enabled}
        colors={colors}
      />
    </CardContent>
  );
};

SalesOrderDetails.propTypes = {
  items: PropTypes.array,
};
SalesOrderDetails.defaultProps = {
  items: [],
};
export default SalesOrderDetails;
