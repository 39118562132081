import EditIcon from "@mui/icons-material/Edit";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import {
  Box,
  Button,
  Card,
  Divider,
  Stack,
  styled,
  Table,
  Typography,
  Skeleton,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "../../../API/axios";
import AcceptQuotationModal from "../../../Common/Modals/AcceptQuotationModal";
import AdditionalCustomerQuotation from "../../../Common/Modals/AddtionalCustomerQuotation";
import FailedModal from "../../../Common/Modals/FailedModal";
import SuccessModal from "../../../Common/Modals/SuccessModal";
import Intro from "../../../components/atoms/Intro/Intro.component";
import AlertToast from "../../../components/common/Toast";
import { apiConstants } from "../../../constants";
import { ADD_REQUEST, VERTICAL } from "../../../constants/Constants";
import { PERMISSIONS } from "../../../constants/data/rolesPermissions.constants";
import { authHeader, getCurrentUser } from "../../../helpers";
import {
  checkPermission,
  convertToDecimalPlaces,
  convertToPlaces,
} from "../../../helpers/helpers";
import QuotationActivityLogsStepper from "../../../Pages/QuotationActivityLogsStepper";
import { QuotationsService } from "../../../services/api/quotations/quotations.service";
import { UtilService } from "../../../services/api/util/util.service";
import ProgressCircularWithBackdrop from "../../../Common/ProgressCircular/ProgressCircularWithBackdrop";
import InnerTopBanner from "../../layout/pageLayout/InnerTopBanner/InnerTopBanner";

const StyledHeader = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.neutral.light,
  textAlign: "left",
  height: "93px",
  paddingLeft: "10px",
  paddingTop: "10px",
}));

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.neutral.light,
  padding: "20px",
}));

const StyledTableCell = styled(TableCell)({
  width: "200px",
  fontWeight: 700,
});

const StyledTypography = styled("span")({
  fontWeight: "400",
  fontSize: "14px",
});

const StyledItalic = styled("i")({
  fontSize: "12px",
});

const StyledLinked = styled(Typography)({
  textDecoration: "underline",
  textUnderlineOffset: "0.2em",
  cursor: "pointer",
});

const StyledImageContainer = styled("div")({
  background: "rgba(239, 239, 239, 0.65)",
  border: "2px dashed #9E9E9E",
  borderRadius: "8px",
  width: "100%",
  height: "auto",
});

const quotationsService = new QuotationsService();
const utilService = new UtilService();

const ViewAdminQuotation = () => {
  const navigate = useNavigate();
  const { id, admin } = useParams();
  const [data, setData] = useState([]);
  const [organizationEmail, setOrganizationEmail] = useState("");
  const [quotationStatus, setQuotationStatus] = useState("");
  const [countries, setCountries] = useState([]);
  const [success, setSuccess] = useState({
    open: false,
    title: "",
    body: "",
  });
  const [addNote, setAddNote] = useState(false);
  const [notes, setNotes] = useState("");
  const [openCustomer, updateCustomer] = useState(false);
  const [openAdditional, updateAdditional] = useState(false);
  const [rejectionNotes, setRejectionNote] = useState("");
  const [file, setFile] = useState([]);
  const [totalDelivery, setTotalDelivery] = React.useState(0);
  const [quotationActivityLogs, setQuotationActivityLogs] = React.useState([]);
  const [customerName, setCustomerName] = React.useState("");
  const { roles } = getCurrentUser();
  const [isAcceptDialogOpen, setIsAcceptDialogOpen] = React.useState(false);
  const [isAcceptBehlafClientChecked, setIsAcceptBehlafClientChecked] =
    React.useState(false);
  const [openProgressCircular, setOpenProgressCircular] = React.useState(false);
  const [quotationDataUpdated, setQuotationDataUpdated] = React.useState(false);
  const [grandTotal, setGrandTotal] = React.useState(0);

  const [toastState, setToastState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });
  const [toastMessage, setToastMessage] = React.useState("");
  const handleToastClose = () => setToastState({ open: false });
  const showEditQuotation =
    checkPermission(PERMISSIONS.QUOTATIONS) ||
    checkPermission(PERMISSIONS.SALES_AGENT_QUOTATIONS);
  const [error, setError] = React.useState({
    value: false,
    name: "",
    message: "",
  });

  console.log("roles_u", roles);

  let breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to="/admin/quotations">
      All Quotations
    </Link>,
    <Typography key="3">{"View Quotation"}</Typography>,
  ];

  useEffect(() => {
    (async () => {
      const response = await quotationsService.viewQuotationAsAdmin(id);
      const {
        custom_items,
        items,
        organization_email,
        quotation_status,
        rejected_reason,
        quotation_files,
        estimated_shipping_charges,
        organization_name,
        grand_total,
      } = response?.data;
      setGrandTotal(grand_total);
      setTotalDelivery(estimated_shipping_charges);
      setData([...custom_items, ...items]);
      setCustomerName(organization_name);
      setOrganizationEmail(organization_email);
      setQuotationStatus(quotation_status?.keyword);
      const quotationFiles = quotation_files.map((f) => {
        f.name = f.file.split("/")[2].split("_")[1];
        return f;
      });
      setFile(quotationFiles);
      rejected_reason && setRejectionNote(rejected_reason);
      // getQuotationActivityLog();

      try {
        const countriesResponse = await utilService.getAllCountries();
        setCountries(countriesResponse?.data);
      } catch (error) {}
    })();
  }, [id, quotationDataUpdated]);

  const handleAccept = (e) => {
    !isAcceptBehlafClientChecked && setIsAcceptDialogOpen(true);
    setIsAcceptBehlafClientChecked(e.target.checked);
  };

  const handleAcceptDialogClose = () => {
    setIsAcceptDialogOpen(false);
  };

  const handleAcceptAgree = () => {
    setIsAcceptBehlafClientChecked(true);
    setIsAcceptDialogOpen(false);
  };

  const handleAcceptDisAgree = () => {
    setIsAcceptBehlafClientChecked(false);
    setIsAcceptDialogOpen(false);
  };

  const acceptAction = () => {
    request("accept", notes);
  };

  let gst = 0;
  let totalPrice = 0;
  let originalTotal = 0;
  let extraCharges = 0;
  let unitDiscount = 0;
  let quantity = 0;
  let totalDiscount = 0;
  let artWorkCharges = 0;

  data?.map((item) => {
    totalPrice += item?.total_price;
    originalTotal += item?.original_total_price;
    quantity = item?.qty;
    gst = gst + item?.gst_amount || 0;
    unitDiscount = item?.unit_discount;
    // totalDelivery = totalDelivery + item?.estimated_shipping_charges || 0;
    extraCharges = extraCharges + item?.extra_charges || 0;
    totalDiscount = totalDiscount + item?.unit_discount * item?.qty;
    artWorkCharges += item?.artwork_charges || 0;

    return "";
  });
  // const totalDiscount = unitDiscount * quantity || 0;
  // const totalPriceWithDiscount = totalPrice + totalDiscount;
  // (gst + totalPrice + extraCharges + totalDelivery) - totalDiscount;
  console.log(
    "prices",
    gst,
    totalPrice,
    extraCharges,
    totalDelivery,
    totalDiscount
  );
  const request = (type, notes = "") => {
    if (type === "reject") {
      if (notes !== "") {
        submitAcceptQuotation(type, notes);
      } else {
        setError({ value: true, name: "", message: "Reason is required" });
      }
    } else {
      submitAcceptQuotation(type, notes);
    }
  };

  const submitAcceptQuotation = (type, notes = "") => {
    const requestOptions = {
      method: "PUT",
      headers: authHeader(),
      body: JSON.stringify({ note: notes }),
    };

    fetch(
      `${apiConstants.API_URL}/customer/quotations/${id}/${type}`,
      requestOptions
    ).then((response) => {
      response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
          if (response.status === 401) {
            // logout()
          }
          const error = (data && data.error) || response.statusText;
          updateAdditional(false);
          setToastMessage(error);
          setToastState({
            open: true,
            vertical: "top",
            horizontal: "right",
          });
        } else {
          if (type === "accept") {
            setSuccess({
              open: true,
              title: "Quotation is Accepted",
              body: `Quotation #${data.data.id} is accepted and we are ready to get started. A S-Trend team member will contact you and guide you through the process.`,
            });
          } else {
            setSuccess({
              open: true,
              title: "Quotation has been Rejected",
              body: "Quotation Has been Rejected Successfully",
            });
            updateAdditional(false);
          }
          updateCustomer(false);
        }
      });
    });
  };

  const createAccount = (e) => {
    axios.post("/organizations", e).then((res) => {
      request("accept");
      updateCustomer(false);
    });
  };

  const addMessage = (type, message) => {
    setNotes(() => message);
    setAddNote(false);
  };

  const downloadUsingAnchorElement = async (index) => {
    const anchor = document.createElement("a");
    anchor.href = file[index].file_url;
    anchor.download = "*";
    document.body.appendChild(anchor);
    anchor.click();
  };

  const handleEditQuotation = () => {
    navigate(`/admin/quotations/${id}/edit`);
  };

  // accept quotation behalf of the client
  const handleAcceptQuotation = async () => {
    try {
      setOpenProgressCircular(true);
      const updateQuotationResponse =
        await quotationsService.updateQuotationById(id, "");
      setQuotationDataUpdated(true);
    } catch (error) {
      console.log(error);
    } finally {
      setOpenProgressCircular(false);
    }
  };

  //console.log('data', data);

  return (
    <>
      <Stack direction={"row"} justifyContent={"space-between"} sx={{ pr: 2 }}>
        <Intro
          pageTitle={`View Quotation`}
          pageTitleShort={"View Quotation"}
          breadcrumbs={breadcrumbs}
          additionalWidgets=""
        />

        {showEditQuotation && quotationStatus === "rejected" && (
          <Button
            variant="contained"
            onClick={handleEditQuotation}
            sx={{ height: "46.67px" }}
          >
            <EditIcon sx={{ mr: 1 }} />
            Edit quotation
          </Button>
        )}
      </Stack>

      <InnerTopBanner
        mainText={customerName}
        subText={`Sales Quotation: #${id}`}
      />

      <Stack
        direction={"row"}
        spacing={2}
        sx={{
          padding: admin ? 0 : "40px 25px 40px 40px;",
          backgroundColor: admin ? "#fff" : "#F4F4F4",
        }}
      >
        <div>
          <Card style={{ minWidth: "500px" }}>
            <Stack direction={"column"} justifyContent="center">
              <StyledHeader>
                <Typography fontSize={"24px"}>Your Products</Typography>
                <Typography>Please note all prices are exclude GST</Typography>
              </StyledHeader>
              <Box>
                <Table>
                  <TableHead>
                    <TableRow style={{ height: "56px" }}>
                      <StyledTableCell>Description</StyledTableCell>
                      <StyledTableCell align="right">
                        Unit Price
                      </StyledTableCell>
                      <StyledTableCell align="right">Discount</StyledTableCell>
                      <StyledTableCell align="right">
                        Discounted Price
                      </StyledTableCell>
                      <StyledTableCell align="right">Quantity</StyledTableCell>
                      <StyledTableCell align="right">
                        Extra Charges
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        Style Name
                      </StyledTableCell>
                      <StyledTableCell align="right">Price</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {item?.name
                            ? item?.name
                            : `${item?.price?.item.name} - ${item?.price?.fabric.name}`}
                        </TableCell>
                        <TableCell align="right">
                          {convertToPlaces(item?.original_unit_price || 0)}
                        </TableCell>
                        <TableCell align="right">
                          {item?.unit_discount
                            ? convertToPlaces(item?.unit_discount)
                            : ""}
                        </TableCell>
                        <TableCell align="right">
                          {convertToPlaces(item?.unit_price)}
                        </TableCell>
                        <TableCell align="right">{item?.qty}</TableCell>
                        <TableCell align="right">
                          {item?.extra_charges
                            ? convertToPlaces(item?.extra_charges)
                            : ""}
                        </TableCell>
                        <TableCell align="right">{item?.style}</TableCell>
                        <TableCell align="right">
                          {convertToPlaces(item?.total_price)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Stack>
          </Card>
          <Box>
            {file.length !== 0 && (
              <>
                <Typography
                  sx={{
                    fontWeight: 600,
                    marginTop: "30px",
                    marginBottom: "20px",
                  }}
                >
                  Concept Design
                </Typography>
                <StyledImageContainer>
                  {file?.map((i, index) => (
                    <Box
                      key={i?.id}
                      sx={{
                        display: "flex",
                        padding: "5px",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ display: "flex" }}>
                        <InsertDriveFileIcon
                          color="primary"
                          sx={{ marginRight: "5px" }}
                        />
                        <Typography variant="span">{i?.name}</Typography>
                      </Box>
                      <Button onClick={() => downloadUsingAnchorElement(index)}>
                        Download File
                      </Button>
                    </Box>
                  ))}
                </StyledImageContainer>
              </>
            )}
          </Box>
        </div>
        <div>
          <Stack direction={"column"} style={{ minWidth: "375px" }} spacing={3}>
            <StyledCard>
              <Stack textAlign={"left"} spacing={3}>
                <Typography fontSize={"20px"}>Your Quote</Typography>
                <Stack spacing={1}>
                  {extraCharges > 0 && (
                    <Stack direction={"row"} justifyContent={"space-between"}>
                      <StyledTypography>Add-ons</StyledTypography>
                      <StyledTypography>
                        {convertToPlaces(extraCharges)}
                      </StyledTypography>
                    </Stack>
                  )}
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <StyledTypography>Order total (ex GST)</StyledTypography>
                    <StyledTypography>
                      {convertToPlaces(originalTotal)}
                    </StyledTypography>
                  </Stack>

                  {totalDiscount > 0 && (
                    <Stack direction={"row"} justifyContent={"space-between"}>
                      <StyledTypography>Discounts applied</StyledTypography>
                      <StyledTypography>
                        {convertToPlaces(totalDiscount)}
                      </StyledTypography>
                    </Stack>
                  )}
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    textAlign={"right"}
                  >
                    <StyledTypography>Total Excluding Tax</StyledTypography>
                    <Stack justifyContent={"flex-end"}>
                      {convertToPlaces(totalPrice)}
                    </Stack>
                  </Stack>
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <StyledTypography>Delivery</StyledTypography>
                    <StyledTypography>
                      {convertToPlaces(totalDelivery)}
                    </StyledTypography>
                  </Stack>
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <StyledTypography>Artwork charges</StyledTypography>
                    <StyledTypography>
                      {convertToPlaces(artWorkCharges)}
                    </StyledTypography>
                  </Stack>
                  <Divider style={{ backgroundColor: "#E0E0E0" }} />
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <StyledTypography>GST</StyledTypography>
                    <StyledTypography>{convertToPlaces(gst)}</StyledTypography>
                  </Stack>
                  <Divider style={{ backgroundColor: "#E0E0E0" }} />
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <StyledTypography>Total</StyledTypography>
                    <StyledTypography>
                      {/* {convertToPlaces(
                        extraCharges + grandTotal + totalDelivery + gst
                      )} */}
                      {convertToPlaces(grandTotal)}
                    </StyledTypography>
                  </Stack>
                </Stack>

                {/* {roles.length !== 0 &&
                  roles.map((role, index) =>
                    role.name === "Super Admin" ? (
                      <Stack key={index} direction={"row"} spacing={2}>
                        <ErrorOutlineIcon />
                        <StyledTypography paragraph fontSize={"13px"}>
                          <StyledItalic>
                            If you’d like to proceed with this order, simply
                            ‘Accept’ and we’ll get started! You can add
                            additional request by clicking
                            <StyledLinked onClick={() => setAddNote(true)}>
                              hear
                            </StyledLinked>
                          </StyledItalic>
                        </StyledTypography>
                      </Stack>
                    ) : (
                      ""
                    )
                  )} */}
              </Stack>
            </StyledCard>

            {roles.length !== 0 &&
              roles.map((role, index) =>
                role.name === "Super Admin" || role.name === "Sales Agent"
                  ? quotationStatus === "submitted" && (
                      <Box key={index}>
                        <FormGroup sx={{ marginBottom: "10px" }}>
                          <FormControlLabel
                            checked={isAcceptBehlafClientChecked}
                            control={<Checkbox />}
                            label="Accept on behalf of the client"
                            onChange={handleAccept}
                          />
                        </FormGroup>
                        {quotationStatus !== "accepted" && (
                          <Box>
                            <Button
                              size="large"
                              sx={{ width: "100%", padding: "15px" }}
                              disabled={!isAcceptBehlafClientChecked}
                              variant="contained"
                              onClick={handleAcceptQuotation}
                            >
                              Accept Quotation
                            </Button>
                          </Box>
                        )}
                      </Box>
                    )
                  : ""
              )}

            {admin && quotationStatus === "rejected" && (
              <Box>
                <Typography
                  sx={{ fontWeight: 600, color: "red", marginBottom: "10px" }}
                >
                  Reason to reject quotation
                </Typography>
                <Typography>{rejectionNotes}</Typography>
              </Box>
            )}
            {admin && (
              <Stack>
                <Typography
                  sx={{
                    fontWeight: 600,
                    marginTop: "30px",
                    marginBottom: "20px",
                  }}
                >
                  Quotation Activity logs
                </Typography>
                <QuotationActivityLogsStepper
                  activeStep={quotationActivityLogs.length - 1}
                  orientation={VERTICAL}
                  steps={quotationActivityLogs}
                />
              </Stack>
            )}
          </Stack>
        </div>
        <AcceptQuotationModal
          open={openCustomer}
          handleClose={acceptAction}
          countries={countries}
          id={id}
          request={request}
          createAccount={createAccount}
        />
        <AdditionalCustomerQuotation
          open={openAdditional}
          handleClose={updateAdditional}
          request={request}
          error={error}
        />
        <AdditionalCustomerQuotation
          open={addNote}
          handleClose={setAddNote}
          request={addMessage}
          type={ADD_REQUEST}
          error={error}
        />
        <FailedModal />
        <SuccessModal {...success} handleClose={setSuccess} />
        <AlertToast
          toastMessage={toastMessage}
          toastState={toastState}
          handleClose={handleToastClose}
        />
        <Dialog
          open={isAcceptDialogOpen}
          onClose={handleAcceptDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{ maxWidth: "400px", marginLeft: "auto", marginRight: "auto" }}
        >
          <DialogContent>
            <DialogContentText sx={{ textAlign: "center" }}>
              You are about to accept this quote on behalf of the client. Are
              you sure to proceed?
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button sx={{ color: "red" }} onClick={handleAcceptDisAgree}>
              Cancel
            </Button>
            <Button onClick={handleAcceptAgree} autoFocus>
              Proceed
            </Button>
          </DialogActions>
        </Dialog>
      </Stack>
      <ProgressCircularWithBackdrop open={openProgressCircular} />
    </>
  );
};

export default ViewAdminQuotation;
