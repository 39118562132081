import { apiConstants } from "../../constants";
import { authHeader } from "../../helpers";

export const storeItemService = {
  getSingleParameter,
  getAllParameters,
  // addParameter,
  // updateParameter,
  // deleteParameter,
  // clear,
};

// get single parameter
function getSingleParameter(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${apiConstants.API_URL}/customer/store-items/${id}`,
    requestOptions
  ).then(handleResponse);
}

// get all parameter
function getAllParameters() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${apiConstants.API_URL}/customer/store-items`,
    requestOptions
  ).then(handleResponse);
}

// add parameter
// function addParameter(count, max_limit, cost) {
//   const requestOptions = {
//     method: "POST",
//     headers: authHeader(),
//     body: JSON.stringify({
//       count: count,
//       max_limit: max_limit,
//       cost: cost,
//     }),
//   };

//   return fetch(`${apiConstants.API_URL}/artwork-charges`, requestOptions).then(
//     handleResponse
//   );
// }

// // update parameter
// function updateParameter(id, count, max_limit, cost) {
//   const requestOptions = {
//     method: "PUT",
//     headers: authHeader(),
//     body: JSON.stringify({
//       count: count,
//       max_limit: max_limit,
//       cost: cost,
//     }),
//   };

//   return fetch(
//     `${apiConstants.API_URL}/artwork-charges/${id}`,
//     requestOptions
//   ).then(handleResponse);
// }

// // delete parameter
// function deleteParameter(id) {
//   const requestOptions = {
//     method: "DELETE",
//     headers: authHeader(),
//   };

//   return fetch(
//     `${apiConstants.API_URL}/artwork-charges/${id}`,
//     requestOptions
//   ).then(handleResponse);
// }

// // clear parameter
// function clear() {}

// handle response
function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        // logout()
        // window.location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
