import React, { Fragment } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const SpecSizes = ({ sizeNames, sizeCharts }) => {
  const getValue = (code) => {
    const val = sizeCharts.filter((sc) => sc?.size?.code === code);
    return val[0]?.value ? val[0]?.value : "-";
  };

  return (
    <>
      {sizeNames.map((item) => (
        <Fragment key={item?.id}>
          <TableCell>{getValue(item?.code)}</TableCell>
        </Fragment>
      ))}
    </>
  );
};

const SpecHeaders = ({ sizeNames }) => {
  return (
    <>
      {sizeNames.map((item) => (
        <Fragment key={item?.id}>
          <TableCell>{item?.name}</TableCell>
        </Fragment>
      ))}
    </>
  );
};

const SpecTable = (props) => {
  const { measurements, sizes, mode } = props;

  return (
    <div>
      <Box sx={{ pb: 3, textTransform: "capitalize" }}>{mode} Size Chart</Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Measurement</TableCell>
              <TableCell>Code</TableCell>
              {sizes && <SpecHeaders sizeNames={sizes} />}
            </TableRow>
          </TableHead>

          <TableBody>
            {measurements.map((measurement) => (
              <TableRow key={measurement.id}>
                <TableCell component="th" scope="row">
                  {measurement?.name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {measurement?.code}
                </TableCell>
                {measurement?.size_charts && sizes && (
                  <SpecSizes
                    sizeNames={sizes}
                    sizeCharts={measurement?.size_charts}
                  />
                )}
                <></>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SpecTable;
