import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Stack,
  CircularProgress,
  DialogActions,
} from "@mui/material";

const SizeChart = () => {
  const [open, setOpen] = React.useState(false);
  const [sizeChartLoaded, setSizeChartLoaded] = useState(false);

  // size chart modal
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setSizeChartLoaded(false);
  };

  return (
    <Box>
      <Button variant="outlined" onClick={handleClickOpen}>
        View Size Chart
      </Button>
      <Dialog fullWidth={false} maxWidth="xl" open={open} onClose={handleClose}>
        <DialogContent>
          <Box>
            <img
              src="https://i.ibb.co/6086T1G/style-arc-size-chart-kids-teens-girls-1.jpg"
              style={{
                display: "block",
                maxWidth: "100%",
                height: "auto",
                margin: "auto",
              }}
              alt=""
              onLoad={() => {
                setSizeChartLoaded(true);
              }}
            />

            {!sizeChartLoaded && (
              <Stack justifyContent="center" alignItems="center" sx={{ p: 3 }}>
                <CircularProgress />
              </Stack>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SizeChart;
