import React, { useState, useEffect } from "react";
import { Button, Card, CardContent, Container, Stack, Typography, Box } from "@mui/material";
import EditOrderDetails from "../EditOrderDetails";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { addAllOrder, setAtiveSport } from "../../store/Store/orderSlice";
import { create } from "../../store/Store/Slice";
import EditQuotationSummery from "../EditQuotationSummery";
import { authHeader } from "../../helpers";
import { apiConstants } from "../../constants";
import {
    ORDER_DETAIL,
    CONCEPT_DETAIL,
    NEXT,
    FINISH,
} from "../../constants/Constants";
import EditConceptDesign from "../EditConceptDesign";
import styled from "@emotion/styled";
import Intro from "../atoms/Intro/Intro.component";
import ProgressCircularWithBackdrop from "../../Common/ProgressCircular/ProgressCircularWithBackdrop";

const StyledButton = styled(Button)({
    color: "white",
});

const EditQuotation = () => {

    const breadcrumbs = [
        <Link
          underline="hover"
          key="1"
          color="inherit"
          to="/admin/quotations"
        >
          All Quotations
        </Link>,
        <Typography key="3">{'Edit Quotation'}</Typography>,
    ]; 

    const { id } = useParams();
    const [sports, updateSports] = useState([]);
    const { order, listedItems } = useSelector((state) => state.orders);
    const [file, setFile] = useState([]);
    const dispatch = useDispatch();
    const enableSubmitForm = order.length > 0;
    const [stage, updateStage] = useState(ORDER_DETAIL);
    const [finished, updateFinish] = useState(false);
    const [defaultSports, setDefaultSports] = useState([]);
    const [additionalBody, setAdditionalBody] = useState("");
    const [totalDelivery, setTotalDelivery] = React.useState(0);
    const [updateTotalDelivery, setUpdateTotalDelivery] = React.useState(0);
    const [openProgressCircular, setOpenProgressCircular] = React.useState(false);

    useEffect(() => {
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
        };
        fetch(`${apiConstants.API_URL}/sports`, requestOptions).then((response)=>{
            response.text().then((text) => {
                const sportsData = text && JSON.parse(text);
                updateSports(sportsData?.data);
                fetch(`${apiConstants.API_URL}/quotations/${id}`, requestOptions).then((response)=>{
                    response.text().then((text) => {
                        const data = text && JSON.parse(text);
                        console.log(data.data);
                        const { custom_items, items, quotation_status, organization, estimated_shipping_charges} = data?.data;
                        setTotalDelivery(estimated_shipping_charges/100);
                        setUpdateTotalDelivery(estimated_shipping_charges/100);
                        console.log(organization);
                        let selectedSports =[];
                        items.map((s) => {
                            sportsData?.data.filter((a) => {
                                if(a?.id === s.price?.sport?.id){
                                    selectedSports.push(a);        
                                }
                            });
                        }); 
                        setDefaultSports([...new Set(selectedSports)]);
                        const customer = {
                            name: organization.name,
                            phone: organization.contact_no || data?.data?.contact_no,
                            email: organization.email,
                            address_line_1: organization.address_line_1,
                            address_line_2: organization.address_line_2,
                            postal_code: organization.postal_code,
                            country: organization.country,
                            organization_id: organization.id,
                            country_id:organization.country_id,
                            sales_rep_id:organization.sales_rep_id,
                            gst_enabled:organization?.gst_enabled === 1,
                        }
                        dispatch(create(customer));
        
                        const listItems = items.map((l) => {
                            let liningIds = l?.price?.linings.map((i) => i?.id);
                            let liningNames = l?.price?.linings.map((q) => q?.name);
                            let extraCharges = l?.quotation_item_extra_charges.map((c) => c?.extra_charge);
                            const item = {
                                ...l,
                                fabric_id: l.price?.fabric.id,
                                fabric_name:l.price?.fabric.name,
                                fabrics:[],
                                item_id:l.price.item.id,
                                lining_ids: liningIds,
                                lining_name: liningNames,
                                linings: l.price?.linings,
                                name: l.price?.item.name,
                                sport_id: l?.price?.sport?.id,
                                price_id: l?.price?.id,
                                id: l?.id,
                                selected:true,
                                existing:true,
                                extra_charges_list:extraCharges
                            }
                            return item;
                        }); 

                        dispatch(addAllOrder(listItems)); 
                        dispatch(setAtiveSport(listItems[0]?.sport_id));
                        const quotationFiles = data?.data?.quotation_files.map((f)=>{
                            f.newUpload = false;
                            f.name = (f.file.split('/')[2]).split('_')[1];
                            return f;
                        });
                        data?.data?.additional_email_body && setAdditionalBody(data?.data?.additional_email_body);
                        setFile(quotationFiles);
                    });
                });
            });
        });
       
    }, []);

     /**
   *
   * @param {*} screen
   * @returns
   */
  const renderPage = (screen) => {
    switch (screen) {
      case ORDER_DETAIL:
        return (
            <EditOrderDetails
                sports={sports}
                defaultSports={defaultSports}
                order={order}
                listedItems={listedItems}
            />
        );
      case CONCEPT_DETAIL:
        return <EditConceptDesign file={file} setFile={setFile}/>;
      default:
        return (
        <EditOrderDetails
        sports={sports}
        defaultSports={defaultSports}
        order={order}
        listedItems={listedItems} 
        />
        );
    }
  };

  const preAction = () => {
    switch (stage) {
      case CONCEPT_DETAIL:
        updateStage(ORDER_DETAIL);
        break;
      default:
        break;
    }
  };

   /**
   *
   */
    const nextAction = () => {
 
        switch (stage) {
          case ORDER_DETAIL:
            if (order.length > 0) {
              updateStage(CONCEPT_DETAIL);
              break;
            }
            break;
          default:
            break;
        }
      };

  const finishAction = () => {
    updateFinish(true);
  };

  const nextBtnText = stage === CONCEPT_DETAIL ? FINISH : NEXT;
  const btnAction = nextBtnText === FINISH ? finishAction : nextAction;

    return(
        <Box>
            <Intro
                pageTitle={'Edit Quotation'}
                pageTitleShort={'Edit Quotation'}
                breadcrumbs={breadcrumbs}
                additionalWidgets=""
            />
        
            <Stack direction={"row"} sx={{ direction: "column" }}>
                <Card
                    sx={{
                        width: "934px",
                        display: "flex",
                        flexDirection: "column",
                        paddingRight: "10px",
                    }}
                >
                    <CardContent
                        sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        height: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                    {renderPage(stage)}

                    {/* footer */}
                    <Container>
                    <Stack direction={"row"} sx={{ justifyContent: "space-between", marginTop:'30px'}}>
                    {stage !== 'customerDetailsScreen' ?
                        <Button variant="text" onClick={() => preAction()}>
                        BACK
                        </Button>:
                        <Box></Box>
                    }
                    {nextBtnText !== 'FINISH' &&
                        <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-around",
                            gap: "10px",
                        }}
                        >
                        <StyledButton
                            variant="contained"
                            onClick={() => {
                            btnAction();
                            }}
                        >
                            {nextBtnText}
                        </StyledButton>
                        </Box>
                    }
                    </Stack>
                    </Container>
                    </CardContent>
                </Card>
                <Card
                title="Quotation Summery"
                sx={{ width: "350px", backgroundColor: "#EFEFEF" }}
                >
                    <CardContent sx={{ textAlign: "left" }}>
                        <EditQuotationSummery enableSubmit={enableSubmitForm} id={id} file={file} 
                        additionalBody={additionalBody} 
                        setAdditionalBody={setAdditionalBody}
                        setTotalDelivery={setTotalDelivery}
                        totalDelivery={totalDelivery}
                        setUpdateTotalDelivery={setUpdateTotalDelivery}
                        updateTotalDelivery={updateTotalDelivery}
                        setLoading={setOpenProgressCircular}
                        />
                    </CardContent>
                </Card>
            </Stack>
            <ProgressCircularWithBackdrop
                open={openProgressCircular}
            />
        </Box>
    );
}

export default EditQuotation;

