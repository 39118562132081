import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { couriersActions } from "../../../../../../actions";
import DataTable from "../../../../../common/ui/table/DataTable/DataTable.component";
import {apiConstants} from "../../../../../../constants";
import {authHeader} from "../../../../../../helpers";
import AlertToast from "../../../../../common/Toast";
import AlertToastSuccess from "../../../../../common/ToastSuccess";

const PositionsList = () => {
  const dispatch = useDispatch();
  const couriers = useSelector((state) => state.couriers);
  const [positions, setPositions] = React.useState([]);
  const columns = [
    { field: "id", headerName: "ID", width: 70 },

    {
      field: "name",
      headerName: "Name",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "chinese_name",
      headerName: "Chinese Name",
      minWidth: 200,
      flex: 1,
    }
  ];

  // const row =[
  //   {
  //     id:1,
  //     name:"test",
  //     chinese_name:"test"
  //   }
  // ]

  const recordIdentifier = "name";

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    fetch(`${apiConstants.API_URL}/positions`, requestOptions).then(
        (response) => {
          response.text().then((text) => {
            const data = text && JSON.parse(text);
            if (!response.ok) {
              if (response.status === 401) {
                // logout()
              }
              const error = (data && data.error) || response.statusText;
              const errorMessage = Object.values(error).map((errorKey) => {
                return errorKey[0];
              });

            } else {
              setPositions(data?.data);
            }
          });
        }
    );

  }, [dispatch]);

  return (
    <Box>

      {positions && (
        <DataTable
          // loading={couriers?.loading}
          rows={positions&& positions}
          columns={columns}
          recordIdentifier={recordIdentifier}
          actionIdentifier="position"
          isEnabledEdit={true}
        />
      )}
    </Box>
  );
};

export default PositionsList;
