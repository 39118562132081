import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import ErrorIcon from "@mui/icons-material/Error";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../store/Store/messagesSlice";

const style = {
  background: "#FFFFFF",
  boxShadow:
    "0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
  borderRadius: "4px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "444px",
  padding: "10px",
};

const FailedModal = ({ ...props }) => {
  const dispatch = useDispatch();
  const { openModal, title, body } = useSelector((state) => state.message);
  return (
    <Modal
      open={openModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack spacing={3}>
          <Stack direction={"row"}>
            <ErrorIcon
              color="error"
              fontSize="small"
              style={{ margin: "5px 5px 0 0" }}
            />
            <Typography
              id="modal-modal-title"
              fontWeight={500}
              fontSize={"20px"}
            >
              {title}
            </Typography>
          </Stack>
          <Typography id="modal-modal-description" sx={{ mt: 2 }} s>
            {body}
          </Typography>
          <Stack direction={"row"} justifyContent={"flex-end"}>
            <Button onClick={() => dispatch(closeModal())} color="error">
              CLOSE
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

FailedModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  body: PropTypes.string,
};

FailedModal.defaultProps = {
  open: false,
  title: "",
  body: "",
  handleClose:()=> {}
};

export default FailedModal;
