import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

export function generatePDF(htmlDocumnet, fileName) {
  html2canvas(htmlDocumnet).then((canvas) => {
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "px", "a4");
    const width = pdf.internal.pageSize.getWidth();
    const pdfHeight = (canvas.height * width) / canvas.width;

    pdf.addImage(imgData, "PNG", 0, 0, width, pdfHeight);
    pdf.save(fileName);
  });
}
