import { Button, Checkbox, Stack, TableCell, TableRow } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  updateList,
  addOrder,
  removeOrder,
  removeList,
} from "../store/Store/orderSlice";
import { convertToPlaces, getDiscountedPrice } from "../helpers/helpers";
import EditItemModal from "../Common/Modals/EditItemModal";

const CalculatedRows = ({ index, row, showLining }) => {
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);

  const checkBoxAction = (event) => {
    const { checked } = event.target;
    let newItem = {
      ...row,
      selected: checked,
    };
    dispatch(updateList({ newItem }));
    if (checked) dispatch(addOrder(newItem));
    else dispatch(removeOrder({ newItem }));
  };

  const closeEdit = () => {
    setEdit(false);
  };

  const removeItem = () => {
    let newItem = {
      ...row,
      selected: false,
    };
    dispatch(removeOrder({ newItem }));
    dispatch(removeList({ newItem }));
  };

  return (
    <TableRow
      key={index}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        {/* <Checkbox onChange={checkBoxAction} checked={row?.selected} /> */}
        {row?.name}
      </TableCell>
      <TableCell align="right">{row?.style}</TableCell>
      <TableCell align="right">{row?.fabric_name}</TableCell>
      {showLining && (
        <TableCell align="right">{row?.lining_name?.join(" + ")}</TableCell>
      )}
      <TableCell align="right">{row?.qty}</TableCell>
      <TableCell align="right">
        {convertToPlaces(row?.original_unit_price)}
      </TableCell>
      <TableCell align="right">{convertToPlaces(row?.extra_charges)}</TableCell>
      <TableCell align="right">{convertToPlaces(row?.unit_discount)}</TableCell>
      <TableCell align="right">
        {convertToPlaces(
          getDiscountedPrice(row?.original_unit_price, row?.unit_discount)
        )}
      </TableCell>
      <TableCell>
        <Stack direction={"row"} spacing={2}>
          <Button color="error" variant="outlined" onClick={() => removeItem()}>
            REMOVE
          </Button>
          <Button onClick={() => setEdit(!edit)} variant="outlined">
            EDIT
          </Button>
        </Stack>
      </TableCell>
      <EditItemModal
        open={edit}
        handleClose={closeEdit}
        data={row}
        index={index}
      />
    </TableRow>
  );
};

export default CalculatedRows;
