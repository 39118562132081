import { Box } from "@mui/material";
import * as React from "react";
import { QuotationsService } from "../../../services/api/quotations/quotations.service";
import { Breadcrumbs } from "../../atoms/Breadcrumbs/Breadcrumbs";
import { GeneralPageLayout } from "../../molecules/PageLayout/GeneralPageLayout";
import { QuotationsTable } from "../../molecules/Quotations/QuotationsTable";

const quotationsService = new QuotationsService();

export const CustomerQuotations = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [quotations, setQuotations] = React.useState([]);
  const [paginationData, setPaginationData] = React.useState(null);

  const getQuotationsByPage = React.useCallback(async (page) => {
    setIsLoading(true);
    const results = await quotationsService.getAllCustomerQuotations(page + 1);
    setQuotations(results.data);
    setPaginationData(results.meta);
    setIsLoading(false);
  }, []);

  React.useEffect(() => {
    setIsLoading(true);
    (async () => {
      await getQuotationsByPage(0);
    })();
    setIsLoading(false);
  }, [getQuotationsByPage]);

  return (
    <GeneralPageLayout
      pageTitle="All Quotations"
      breadcrumbs={<Breadcrumbs lastStep="All Quotations" />}
    >
      <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
        <Box gridColumn="span 12">
          <QuotationsTable
            isLoading={isLoading}
            quotations={quotations}
            perPageCount={paginationData?.per_page}
            totalRowsConunt={paginationData?.total}
            handlePageChange={getQuotationsByPage}
            pathToView="/quotations/$id$"
          />
        </Box>
      </Box>
    </GeneralPageLayout>
  );
};
